import {SorteerRichting} from '.';
import {IPaginatieFilter} from './paginatie-filter.model';

export class Filter implements IPaginatieFilter {
    aantalItemsPerPagina: number;
    paginaNummer: number;
    naam?: string;
    bsn?: string;
    van: string;
    tot: string;
    sorteerRichting: SorteerRichting;
    sorteerVeld: string;
    haalAllesOp1PaginaOp?: boolean;
    regelingGeblokkeerdVoorNota?: boolean;
    includedRegelingnummers?: number[];
    excludedRegelingnummers?: number[];
}
