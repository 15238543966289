import { Component, OnInit } from '@angular/core';

import { BedrijfsgegevensService, UtilService } from '../../../shared/';
import { LoongegevensService } from '../loongegevens.service';

import {
    LoongegevensPeriode,
    LoongegevensPeriodeStatus,
} from '../../../model';

@Component({
    styles: [require('../loongegevens.scss')],
    template: require('./looncorrectie.component.html'),
})
export class LooncorrectieComponent implements OnInit {
    loongegevensPeriodes: LoongegevensPeriode[] = [];
    geselecteerdePeriode: LoongegevensPeriode;
    invullen: boolean = false;
    sectorNummer: number;

    constructor(
        private bedrijfsgegevensService: BedrijfsgegevensService,
        private loongegevensService: LoongegevensService,
        private utilService: UtilService,
    ) { }

    ngOnInit(): void {
        this.bedrijfsgegevensService.getBedrijfsgegevens().subscribe((werkgever) => {
            this.sectorNummer = werkgever.sectornummer;
            this.loongegevensService.raadpleegVerwerktePeriodes().subscribe((x) => {
                if (x) {
                    this.loongegevensPeriodes = x.filter((l) => l.status === LoongegevensPeriodeStatus.verwerkt);
                }
            });
        });
    }

    onSelect(periode: LoongegevensPeriode): void {
        this.geselecteerdePeriode = periode;
    }

    downloadVooringevuldeLoongegevens() {
        this.loongegevensService.downloadVooringevuldeLooncorrectie(this.geselecteerdePeriode).subscribe((x: any) => {
            this.utilService.downloadFile(x, 'Looncorrectie.csv');
        });
    }

    navigeerNaarFormulier(): void {
        this.invullen = true;
    }

    onFormulierAnnuleren(): void {
        this.invullen = false;
    }
}
