import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';

import { UpaMandaat } from '../../model';
import { UpaService } from '../upa.service';

@Component({
    template: require('./upa-mandaat-servicebureaus.component.html'),
    styles: ['.doorkolom { width: 50% }'],
})
export class UpaMandaatServicebureausComponent implements OnInit {
    mandaatLijst: UpaMandaat[];
    showIngetrokkenMandaat = false;
    accountType: string;
    loonheffingsnummer: string;
    mandaatId: number;
    editMode: boolean;
    geselecteerdeMandaat: UpaMandaat;

    constructor(private authenticationService: AuthenticationService,
                private service: UpaService,
                private router: Router,
                private route: ActivatedRoute,
                private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        this.accountType = this.authenticationService.currentUser.profile.accounttype;
        this.route.params.subscribe((params: Params) => {
            if (params.mandaatId) {
                this.mandaatId = +params.mandaatId;
                    this.getMandaatLijst(this.mandaatId);
            }
        });
    }

    nieuwMandaat() {
        this.editMode = true;
        this.geselecteerdeMandaat = new UpaMandaat();
        this.geselecteerdeMandaat.parentId = this.mandaatId;
        this.geselecteerdeMandaat.loonheffingsnummer = this.loonheffingsnummer;
    }

    mandaatWijzigen(mandaat: UpaMandaat) {
        this.editMode = true;
        this.geselecteerdeMandaat = mandaat;
    }

    wijzigingOpgeslagen() {
        this.geselecteerdeMandaat = null;
        this.editMode = false;
        this.ngOnInit();
    }

    cancel() {
        this.router.navigate(['upa']);
    }

    private getMandaatLijst(mandaatId: number) {
        this.service.getMandaatLijst()
            .subscribe((x) => {
                const parentMandaat = x.find((mandaat) => mandaat.id === mandaatId);
                this.mandaatLijst = parentMandaat.submandaten;
                if (parentMandaat) {
                    this.loonheffingsnummer = parentMandaat.loonheffingsnummer;
                }
        });
    }

    listToggle(event: any) {
        this.showIngetrokkenMandaat = !(event.target.checked);
    }
}
