import { Component, Inject, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { RegelingService } from '../regelingen/regeling.service';
import { Navigatie } from '../navigatie/navigatie.model';
import { NavigatieService } from '../navigatie/navigatie.service';
import { MeldingenService } from './meldingen.service';
import { Melding } from '../model/melding.model';
import { Regeling } from '../model';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  styles: ['.home-page-wrapper { margin-top: 60px; }'],
  template: require('./home.component.html'),
})
export class HomeComponent implements OnInit {
  navigation: Navigatie;
  melding: Melding;
  regeling: Regeling;
  logoPgb: string = 'assets/logo-PGB.png';
    logoAsf: string = 'assets/ASF-logo.svg';
    heeftAsf: boolean;
    heeftSfr: boolean;

  constructor(
    private navigatieService: NavigatieService,
    private meldingenService: MeldingenService,
    private regelingSvc: RegelingService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.navigatieService.getNavigatieSubject().subscribe((nav) => {
      this.navigation = nav;
    });
    this.meldingenService.getMelding().subscribe((melding) => {
      this.melding = melding;
    });
    this.regelingSvc.heeftAsf(this.authenticationService.getRelatienummer()).subscribe((heeftAsf) => {
      this.heeftAsf = heeftAsf;
    });

  }
  naarStartPagina() {
    this.router.navigate(['/']);
  }
}
