import { Component } from '@angular/core';

import { ToasterService } from 'angular2-toaster';

import { Nota } from '../../model/nota.model';
import { Meldingen, UtilService } from '../../shared';
import { NotaService } from '../../shared/services/nota.service';
import { FactuurFilter } from './factuur-filter.model';

@Component({
    template: require('./facturen-list.component.html'),
    styles: ['a{cursor: pointer}'],
})
export class FacturenListComponent {
    notas: Nota[];
    filter: FactuurFilter;

    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;

    constructor(
        private notaSvc: NotaService,
        private utilService: UtilService,
        private toasterService: ToasterService) {
    }

    handleFilterUpdated(filter: FactuurFilter): void {
        this.filter = filter;
        this.retrieveNotas();
    }

    private retrieveNotas(): void {
        this.notaSvc.getNotas(this.filter).subscribe({
            next: (x) => {
                const totAantal = x.paginaIndex * x.aantalPerPagina;
                this.notas = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (totAantal) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
                this.filter.paginaNummer = x.paginaIndex;
                this.filter.aantalItemsPerPagina = x.aantalPerPagina;
            },
            error: (err) => {
                this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
            },
        });
    }
}
