import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import 'rxjs/add/operator/switchMap';

import { PasswordValidator } from '../../shared/validators/password.validator';
import { AccountService } from '../account.service';

@Component({
    styles: [require('../account.style.scss')],
    template: require('./wachtwoord-reset-code.component.html'),
})
export class WachtwoordResetCodeComponent implements OnInit {
    form: FormGroup;
    wachtwoordResetCode: string;

    wachtwoordMessage: string;
    bevestigWachtwoordMessage: string;

    private wachtwoordValidationMessages = {
        required: 'Wachtwoord is verplicht.',
        strength: 'Wachtwoord voldoet niet aan onze eisen.',
    };
    private bevestigWachtwoordValidationMessages = {
        matchPassword: 'De wachtwoorden komen niet overeen.',
        required: 'Bevestig wachtwoord is verplicht',
    };

    constructor(private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private accountService: AccountService,
                private toasterService: ToasterService) { }

    ngOnInit(): void {
        const resetCode = 'resetcode';
        this.route.params.subscribe((params: Params) => {
            this.wachtwoordResetCode = params[resetCode];
            this.checkOfWachtwoordResetcodeGeldigIs(this.wachtwoordResetCode);
        });
        this.form = this.fb.group({
            wachtwoord: ['', [Validators.required, PasswordValidator.strength()]],
            bevestigWachtwoord: ['', [Validators.required]],
        },
        {
            validator: PasswordValidator.matchPassword, // your validation method
        });

        const wachtwoordControl = this.form.get('wachtwoord');
        wachtwoordControl.valueChanges.subscribe((value) => this.setWachtwoordMessage(wachtwoordControl));

        const bevestigWachtwoordControl = this.form.get('bevestigWachtwoord');
        this.form.valueChanges.subscribe((value) => this.setBevestigWachtwoordMessage(bevestigWachtwoordControl));
    }

    checkOfWachtwoordResetcodeGeldigIs(resetcode: string) {
        this.accountService.wachtwoordResetcodeIsGeldig(resetcode).subscribe((x: boolean) => {
            if (x === false) {
                this.router.navigate(['account/wachtwoord-reset']);
                this.toasterService.pop('error', '', 'De gebruikte wachtwoordherstelcode is ongeldig.');
            }
        });
    }

    save(): void {
        const wachtwoord = this.form.controls.wachtwoord.value;
        const bevestigWachtwoord = this.form.controls.bevestigWachtwoord.value;
        this.accountService.resetWachtwoord(this.wachtwoordResetCode, wachtwoord).subscribe((response: any) => {
            this.router.navigate(['account/wachtwoord-reset-success']);
        },
        (err: any) => {
            if (err.status === 400) {
                this.toasterService.pop('error', '', 'De gebruikte wachtwoordherstelcode is ongeldig.');
            } else {
                this.toasterService.pop('error', '', 'Er is een fout opgetreden bij het verwerken van uw verzoek.');
            }
            this.resetForm();
        });
    }

    resetForm(): void {
        this.form.controls.wachtwoord.setValue('');
        this.form.controls.wachtwoord.markAsPristine();
        this.form.controls.wachtwoord.markAsUntouched();
        this.form.controls.bevestigWachtwoord.setValue('');
        this.form.controls.bevestigWachtwoord.markAsPristine();
        this.form.controls.bevestigWachtwoord.markAsUntouched();
        this.form.markAsPristine();
        this.wachtwoordMessage = '';
        this.bevestigWachtwoordMessage = '';
    }

    cancel() {
        this.router.navigate(['']);
    }

    setWachtwoordMessage(c: AbstractControl): void {
        this.wachtwoordMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.wachtwoordMessage = Object.keys(c.errors).map((key) =>
                this.wachtwoordValidationMessages[key]).join(' ');
        }
    }

    setBevestigWachtwoordMessage(c: AbstractControl): void {
        this.bevestigWachtwoordMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.bevestigWachtwoordMessage = Object.keys(c.errors).map((key) =>
                this.bevestigWachtwoordValidationMessages[key]).join(' ');
        }
    }
}
