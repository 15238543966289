import { Component } from '@angular/core';

@Component({
    template: `
    <div class="container home-page-wrapper">
      <h1>Oeps, er ging iets mis...</h1>
      <p>We lossen dit graag voor u op. Wilt u daarom contact met ons opnemen?</p>
      <heeft-u-vragen></heeft-u-vragen>
    </div>`,
})

export class InternalServerErrorComponent {
}
