import { AbstractControl, ValidatorFn } from '@angular/forms';

export class TelefoonnummerValidator {

    static telefoonnummerCheck(): ValidatorFn {
        const telefoonnummerRegex = /^[\d\s]+$/i;
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== undefined && !telefoonnummerRegex.test(c.value)) {
                return { telefoonnummer: true };
            }
            return null;
        };
    }
}
