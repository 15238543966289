import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';

/**
 * Redirect de gebruiker naar het login scherm op de authenticatie service
 */
@Component({
    template: require('./login.component.html'),
})
export class LoginComponent implements OnInit {
    /**
     * Initialiseert een nieuwe instantie van LoginComponent
     * @param authenticationService Service used to authenticate the user
     */
    constructor(private authenticationService: AuthenticationService) {

    }

    /**
     * Initialiseert het scherm
     */
    ngOnInit(): void {
        this.authenticationService.signinRedirect();
    }
}
