import { Component, OnInit } from '@angular/core';
import { Headers, RequestOptions, Response } from '@angular/http';

import { ToasterService } from 'angular2-toaster';

import * as moment from 'moment';

import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';
import { UtilService } from '../../../shared/util.service';
import { LoongegevensService } from '../loongegevens.service';

import {
    Aanleverperiodiciteit,
    LoongegevensPeriode,
    LoongegevensPeriodeStatus,
    Werkgever,
} from '../../../model';
import { Sector } from '../../../shared/sector';

@Component({
    styles: [require('../loongegevens.scss')],
    template: require('./loonopgave.component.html'),
})
export class LoonopgaveComponent implements OnInit {
    sectorNummer: number;
    loonopgavePeriodes: LoongegevensPeriode[] = [];
    verschillenlijstPeriodes: LoongegevensPeriode[] = [];
    geselecteerdePeriode: LoongegevensPeriode;
    toonBlokken: boolean = false;
    invullen: boolean = false;
    periodeStatusIsAfgekeurd: boolean = false;

    constructor(
        private bedrijfsgegevensService: BedrijfsgegevensService,
        private loongegevensService: LoongegevensService,
        private toasterService: ToasterService,
        private utilService: UtilService,
    ) { }

    ngOnInit(): void {
        this.bedrijfsgegevensService.getBedrijfsgegevens().subscribe((x) => {
            this.bepaalOfPeriodesOfBlokkenGetoondMoetenWorden(x);
        });
    }

    onSelect(periode: LoongegevensPeriode): void {
        this.geselecteerdePeriode = periode;
        this.periodeStatusIsAfgekeurd = periode.status === LoongegevensPeriodeStatus.afgekeurd;
        this.toonBlokken = true;
    }

    bepaalOfPeriodesOfBlokkenGetoondMoetenWorden(werkgever: Werkgever) {
        if (werkgever.sectornummer !== Sector.Zeevisserij) {
            this.loongegevensService.raadpleegPeriodes().subscribe((x) => {
                this.sectorNummer = werkgever.sectornummer;
                if (x) {
                    this.loonopgavePeriodes = x.filter((l) => l.status === LoongegevensPeriodeStatus.afgekeurd ||
                        l.status === LoongegevensPeriodeStatus.geenAanlevering);
                    this.verschillenlijstPeriodes = x.filter((l) => l.status === LoongegevensPeriodeStatus.nietCompleet);
                }
            });
        } else {
            this.toonBlokken = true;
        }
    }

    downloadVooringevuldeLoonopgave() {
        this.loongegevensService.downloadVooringevuldeLoonopgave(this.geselecteerdePeriode).subscribe((x: any) => {
            this.utilService.downloadFile(x, 'Loonopgave.csv');
        });
    }

    navigeerNaarFormulier(): void {
        this.invullen = true;
        this.toonBlokken = false;
    }

    onFormulierAnnuleren(): void {
        this.invullen = false;
        this.toonBlokken = true;
    }
}
