import {
    Adres,
} from '.';

export class Partij {
    relatienummer: number;
    naam: string;
    email: string;
    partijTypeCode: string;
    adressen?: Adres[];
}
