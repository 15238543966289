import { Injectable } from '@angular/core';

import * as moment from 'moment';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import {
    Response,
    VoorschotWijziging,
} from '../../model';
import { ApiClient } from '../../shared/api-client.service';

@Injectable()
export class VoorschotService {

    constructor(private api: ApiClient) { }

    getVoorschotten(): Observable<VoorschotWijziging[]> {
        return this.api.get<Response<VoorschotWijziging>>('voorschotten?peildatum=' + moment().format('YYYY-MM-DD'))
                                            .map((r) => r.items);
    }

    getVoorschot(regelingNummer: number, begin: Date): Observable<VoorschotWijziging> {
        return this.api.get<VoorschotWijziging>(`voorschotten/${regelingNummer}/${begin}`).map((voorschot) => voorschot);
    }

    getVoorschotUitleg(regelingNummer: string): Observable<string> {
        return this.api.get<string>(`uitleg/voorschotten/${regelingNummer}`);
    }

    saveVoorschot(voorschotMutatie: VoorschotWijziging): Observable<VoorschotWijziging> {
        return this.api.put<VoorschotWijziging>('voorschotten/' + voorschotMutatie.voorschot.regeling.regelingNummer,
                        voorschotMutatie).map((voorschot) => voorschot);
    }
}
