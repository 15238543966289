import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';
import { IMyDateModel } from 'mydatepicker';
import { Constants } from '../constants';

@Directive({
  selector: '[leeftijd][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: LeeftijdValidatorDirective, multi: true }],
})
export class LeeftijdValidatorDirective implements Validator {
  @Input() leeftijd: string;

  static checkOuderDan(leeftijd: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      return isOuderDan(leeftijd)(c);
    };
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.leeftijd ? isOuderDan(+this.leeftijd)(control) : null;
  }
}

function isOuderDan(leeftijd: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let isGeboorteDatumEerderDanMin: boolean = true;
    const date = new Date();
    const y = date.getFullYear();
    if (control && control.value) {
      const gebDate = moment(control.value.jsdate);
      const min = moment(new Date(y - leeftijd, 0, 1));
      isGeboorteDatumEerderDanMin = gebDate.isBefore(min);
    } else {
      isGeboorteDatumEerderDanMin = true;
    }
    return isGeboorteDatumEerderDanMin ? null : { leeftijd: { value: control.value } };
  };
}
