import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeekijkenGuard } from '../authentication/meekijken-guard.service';
import { WijzigenGuard } from '../authentication/wijzigen-guard.service';
import { WerknemerAanmeldenComponent } from './aanmelden';
import {
    DienstverbandComponent,
    DienstverbandListComponent,
} from './dienstverbanden';
import { LooncontroleComponent } from './looncontrole/looncontrole.component';
import { LooncorrectieComponent, LoonopgaveComponent } from './loongegevens';
import { LoongegevensListComponent } from './loongegevens/overzicht';
import { BegindatumWijzigenComponent } from './mutatieformulieren/begindatum-wijzigen.component';
import { EinddatumWijzigenComponent } from './mutatieformulieren/einddatum-wijzigen.component';
import { FunctieWijzigenComponent } from './mutatieformulieren/functiewijzigen.component';
import { UitDienstComponent } from './mutatieformulieren/uitdienst.component';
import { WijzigingendoorgevenComponent } from './wijzigingendoorgeven';
import { OuderschapsverlofListComponent } from './ouderschapsverlof/ouderschapsverlof-list.component';

const routes: Routes = [
    { path: 'werknemers/loonopgave', component: LoonopgaveComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/looncontrole', component: LooncontroleComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/dienstverbanden/einddatum', component: EinddatumWijzigenComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/dienstverbanden/uitdienst', component: UitDienstComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/dienstverbanden/begindatum', component: BegindatumWijzigenComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/dienstverbanden/:relatienummer', component: DienstverbandComponent, canActivate: [MeekijkenGuard] },
    { path: 'werknemers/dienstverbanden', component: DienstverbandListComponent, canActivate: [MeekijkenGuard] },
    { path: 'werknemers/aanmelding', component: WerknemerAanmeldenComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/loongegevens', component: LoongegevensListComponent, canActivate: [MeekijkenGuard] },
    { path: 'werknemers/wijzigingendoorgeven', component: WijzigingendoorgevenComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/looncorrectie', component: LooncorrectieComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/functiewijzigen', component: FunctieWijzigenComponent, canActivate: [WijzigenGuard] },
    { path: 'werknemers/ouderschapsverlof', component: OuderschapsverlofListComponent, canActivate: [WijzigenGuard] },
];

export let routedComponents = [
    EinddatumWijzigenComponent,
    BegindatumWijzigenComponent,
    UitDienstComponent,
    DienstverbandComponent,
    DienstverbandListComponent,
    WerknemerAanmeldenComponent,
    LoongegevensListComponent,
    LooncorrectieComponent,
    LoonopgaveComponent,
    LooncontroleComponent,
    WijzigingendoorgevenComponent,
    FunctieWijzigenComponent,
    OuderschapsverlofListComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class WerknemersRoutingModule {
}
