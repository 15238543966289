import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import { Looncontrole } from '../../model/looncontrole.model';
import { UtilService } from '../../shared';
import { ModalService } from '../../shared/modal/modal.service';
import { LooncontroleService } from './looncontrole.service';

@Component({
  selector: 'app-looncontrole',
  template: require('./looncontrole.component.html'),
  styles: [require('../loongegevens/loongegevens.scss')],
})
export class LooncontroleComponent implements OnInit {
  file: File;
  looncontrole: Looncontrole;
  form: FormGroup;
  bestandUploadErrorMessage: string;

  constructor(
    private looncontroleService: LooncontroleService,
    private toasterService: ToasterService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private utilService: UtilService,
  ) {
    this.form = this.fb.group({
      file: [],
    });
  }

  ngOnInit(): void {
    this.looncontroleService.getLooncontrole().subscribe((x) => this.looncontrole = x);
  }

  fileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
    }
  }

  fileSubmitted() {
    if (this.file) {
      this.looncontroleService.upload(this.file).subscribe((x: any) => {
        if (x) {
          this.toasterService.pop('error', '', 'Het bestand is ongeldig.');
        } else {
          this.modalService.createBevestiging('Het bestand is is succesvol geüpload.');
        }
      }, (err: any) => {
          const message = this.utilService.getErrorMessage(err);
          this.bestandUploadErrorMessage = message.Message;
        });
    }
  }
}
