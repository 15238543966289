import { IBsnIdentificeerbaarItem } from './bsnIdentificeerbaarItem';

export class DeelnemerOuderschapsverlofMutatie implements IBsnIdentificeerbaarItem {
    naam: string;
    bsn: string;
    geslacht: string;
    dateOfBirth: Date;
    relatienummer: number;
    prevYear: boolean;
    currentYear: boolean;
    nextYear: boolean;

    getBsn(): string {
        return this.bsn;
    }
}
