import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { Nota } from '../../model/nota.model';
import { Response } from '../../model/response.model';
import { FactuurFilter } from '../../premie/facturen/factuur-filter.model';
import { ApiClient } from '../../shared/api-client.service';

@Injectable()
export class NotaService {

    constructor(private api: ApiClient) { }

    getNotas(filter: FactuurFilter): Observable<Response<Nota>> {
        return this.api.get<Response<Nota>>('notas', filter);
    }

    download(notaNummer: number): Observable<Blob> {
        return this.api.getFile('notas/' + notaNummer + '/acties/download', 'application/pdf');
    }

    downloadCsv(notaNummer: number): Observable<any> {
        return this.api.getFile('notas/' + notaNummer + '/acties/downloadcsv', 'text/csv');
    }

}
