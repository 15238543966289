import { MutatieType } from './mutatietype.model';

export class Mutatie {
    id: number;
    invoerdatum: Date;
    mutatieType: MutatieType;
    bsn: string;
    werknemer: string;
    ingangsdatum: Date;
    persoonNaam: string;
    partijNaam: string;
}
