import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InterneMedewerkerGuard } from '../authentication/interne-medewerker-guard.service';
import { VoorloopschermComponent } from './voorloopscherm';

const routes: Routes = [
    { path: 'beheer', component: VoorloopschermComponent, canActivate: [InterneMedewerkerGuard] },
];

export let routedComponents = [
    VoorloopschermComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class BeheerRoutingModule {
}
