import { Component } from '@angular/core';
import { Meldingen, UtilService } from '../shared';
import { ModalService } from '../shared/modal/modal.service';
import { DocumentBeheerService } from '../shared/services/documentbeheer.service';

@Component({
    styles: [require('./veelgestelde-vragen.component.scss')],
    template: require('./veelgestelde-vragen.component.html'),
})

export class VeelgesteldeVragenComponent {
    public static PgbToelichtingSpecificatie: string = 'Toelichting_specificatie_PGB';
    public static AsfToelichtingSpecificatie: string = 'Toelichting_specificatie_ASF';

    constructor(
        private documentBeheerService: DocumentBeheerService,
        private modalService: ModalService,
        private utilService: UtilService) {
    }

    ophalenInternDocument(documentnaam: string) {
        documentnaam += '.pdf';
        this.documentBeheerService.ophalenInternDocument(documentnaam)
            .subscribe((x: any) => {
                this.utilService.downloadFile(x, documentnaam, 'application/pdf');
            },
                (err) => {
                    this.modalService.createBevestiging(Meldingen.BESTANDSDOWNLOAD_FOUTMELDING);
                });
    }
}
