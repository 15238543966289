import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export class BsnValidator {
    static bsnCheck(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value !== undefined && !this.bsn11Valid(c.value)) {
                return { bsn: true };
            }
            return null;
        };
    }

    static bsn11Valid(bsn: string): boolean {
        if (!bsn) { return false; }
        if (bsn.length === 8) {
            bsn = `0${bsn}`;
        }
        if (bsn.length !== 9) {
            return false;
        }

        let i: number = 9;
        let total: number = 0;

        for (let j: number = 0; j < 8; j++) {
            const t = bsn[j];
            total += Number(t) * i;
            i--;
        }

        const rest: number = Number(bsn[8]);
        return total % 11 === rest;
    }
}

// tslint:disable-next-line:max-classes-per-file
@Directive({
  selector: '[bsn11check][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: Bsn11CheckValidatorDirective, multi: true }],
})
export class Bsn11CheckValidatorDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = bsn11check();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }
}

function bsn11check(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
        return null;
    }
    const isValideBsn = BsnValidator.bsn11Valid(control.value);
    return isValideBsn ? null : { bsn11check: { value: control.value } };
  };
}
