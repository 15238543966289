import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LoongegevensPeriode } from '../../model';

@Component({
    selector: 'loongegevens-periodes',
    template: require('./loongegevens-periodes.component.html'),
})
export class LoongegevensPeriodesComponent {
    @Input() loongegevensPeriodes: LoongegevensPeriode[];
    @Input() kop: string;
    @Input() omschrijving: string;
    @Output() onSelect = new EventEmitter<LoongegevensPeriode>();

    open(periode: LoongegevensPeriode) {
        this.onSelect.emit(periode);
    }
}
