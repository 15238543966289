import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizeCallbackComponent } from './authorize-callback/authorize-callback.component';
import { LoginComponent } from './login/login.component';
import { LogoutCallbackComponent } from './logout-callback/logout-callback.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'authorize_callback', component: AuthorizeCallbackComponent },
    { path: 'logout_callback', component: LogoutCallbackComponent },
];

/**
 * Definieert routing logica voor de authenticatie module
 */
@NgModule({
    exports: [RouterModule],
    imports: [RouterModule, RouterModule.forChild(routes)],
})
export class AuthenticationRoutingModule {

}

/**
 * Routed components
 * @type {Array}
 */
export let routedComponents = [
    LoginComponent,
    AuthorizeCallbackComponent,
    LogoutCallbackComponent,
];
