import {
    Dienstverband,
    Loonbegrip,
} from '.';
import {IBsnIdentificeerbaarItem} from './bsnIdentificeerbaarItem';

export class DienstverbandPeriode implements IBsnIdentificeerbaarItem {
    begin: Date;
    eind: Date;
    loonbedrag: number;
    loondervingPercentage: number;
    parttimePercentage: number;
    urendervingPercentage: number;
    werkbareDagen: number;
    gewerkteUren?: number;
    gewerkteDagen?: number;
    dienstverband: Dienstverband;
    loonbegrip: Loonbegrip;
    regeling: string;
    kenmerk?: string;
    variant?: string;
    regelingnummer?: number;

    getBsn(): string {
        return this.dienstverband.werknemer.burgerServiceNummer;
    }
}
