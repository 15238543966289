import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'percentage' })
export class PercentageFormatPipe implements PipeTransform {
    transform(value: number,
              decimalLength: number = 2,
              percentageChar: string = '%',
              decimalDelimiter: string = ','): string {

        if (value || value === 0) {
            const result = '\\d(?=(\\d)+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            // tslint:disable-next-line:no-bitwise
            const num = value.toFixed(Math.max(0, ~~decimalLength));

            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num)
                .replace(new RegExp(result, 'g'), '$&') + percentageChar;
        }
    }
}
