import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';

import {AuthenticationService} from '../../authentication/authentication.service';
import {Accounttype} from '../../shared';

import {UpaAccount, UpaAccountType, UpaMandaat} from '../../model';
import {UpaService} from '../upa.service';

@Component({
    selector: 'upa-account',
    template: require('./upa-account.component.html'),
    styles: ['.upa-account-label { font-weight: bold; }',
             '.upa-link { margin-left: 10px; }',
             '.sleutel, .naam { font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }',
             '.sleutel { border: 1px solid #636363; background: #eaeaea; padding: 3px 5px 0px 10px; }'],
})
export class UpaAccountComponent implements OnInit {
    @Input() account: UpaAccount;
    @Input() wachtwoord: string;

    constructor(
        private service: UpaService,
        private toasterService: ToasterService,
        private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        if (!this.account.sleutel && this.wachtwoord) {
            this.account.sleutel = this.wachtwoord;
        } else {
            this.account.sleutel = '**************';
        }
    }

    onEmailChange(email: string) {
        if (email) {
            this.service.opslaanEmail(this.account.accountNaam, email).subscribe((x) => {
                //
            });
        }
    }

    private genereerNieuweSleutel() {
        this.service.genereerSleutel()
        .subscribe((x) => {
            this.account.sleutel = x;
        },
        (err) => {
            this.toasterService.pop('error', '', `Kon geen nieuwe sleutel genereren`);
        });
    }
}
