import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { FacturenFilterComponent } from './facturen/facturen-filter.component';
import { FacturenListComponent } from './facturen/facturen-list.component';
import { FacturenTabelComponent } from './facturen/facturen-tabel.component';
import { SaldoListComponent } from './openstaandsaldo/saldo-list.component';
import { SaldoTabelComponent } from './openstaandsaldo/saldo-tabel.component';
import { PremieRoutingModule } from './premie-routing.module';
import { RekeninggegevensComponent } from './rekeninggegevens/rekeninggegevens.component';
import { VoorschotEditComponent } from './voorschot/voorschot-edit.component';
import { VoorschotListComponent } from './voorschot/voorschot-list.component';
import { VoorschotService } from './voorschot/voorschot.service';

@NgModule({
    declarations: [
        FacturenFilterComponent,
        FacturenListComponent,
        FacturenTabelComponent,
        RekeninggegevensComponent,
        // Zie #17543 en ADR
        // SaldoListComponent,
        // SaldoTabelComponent,
        VoorschotListComponent,
        VoorschotEditComponent,
    ],
    exports: [],
    imports: [
        PremieRoutingModule,
        SharedModule,
    ],
    providers: [
        VoorschotService,
    ],
})
export class PremieModule {
}
