import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyDateModel, IMyInputFieldChanged, IMyInputFocusBlur, IMyOptions } from 'mydatepicker';

import {
    Adres,
    Bedrijfsuitoefening,
    BinnenlandsAdres,
    BuitenlandsAdres,
    Dienstverband,
    Functie,
    Land,
    Persoon,
    Regeling,
} from '../../model';
import { WerknemerAanmelden } from '../../model/werknemer-aanmelden.model';
import { Urls } from '../../navigatie';
import { RegelingService } from '../../regelingen/regeling.service';
import {
    BedrijfsgegevensService,
    BsnValidator,
    Constants,
    Meldingen,
    MyDatePickerValidators,
} from '../../shared';
import { BufuService } from '../../shared/services/bufu.service';
import { DienstverbandService } from '../../shared/services/dienstverband.service';
import { LandService } from '../../shared/services/land.service';
import { LoonService } from '../../shared/services/loon.service';
import { PostcodeService } from '../../shared/services/postcode.service';
import { LeeftijdValidatorDirective } from '../../shared/validators/leeftijd.directive';

@Component({
    styles: [require('./werknemer-aanmelden.component.scss')],
    template: require('./werknemer-aanmelden.component.html'),
})
export class WerknemerAanmeldenComponent implements OnInit, AfterViewInit {
    lopendVerzoek: boolean = false;
    pageTitle: string = 'Werknemer aanmelden';
    selGeboortedatum: IMyDateModel;
    selInDienst: IMyDateModel;
    dienstverband: Dienstverband;
    aanmelding: WerknemerAanmelden;
    regelingen: Regeling[];
    binnenlandsAdres: BinnenlandsAdres;
    buitenlandsAdres: BuitenlandsAdres;
    uitleg: string;
    upaStartdatum: string;
    private huidigJaar: number = moment().year();

    @ViewChildren('firstControl') vc: any;

    private myDatePickerOptions: IMyOptions = {
        dateFormat: Constants.DATE_DEFAULT_FORMAT,
        height: Constants.DATEPICKER_DEFAULT_HEIGHT,
        selectionTxtFontSize: Constants.DATEPICKER_DEFAULT_FONTSIZE,
        showClearDateBtn: false,
        indicateInvalidDate: false,
        disableSince: { year: this.huidigJaar + 1, month: 1, day: 1 },
    };
    gezochtNaarAdres: boolean = false;
    bedrijfsuitoefeningen: Bedrijfsuitoefening[];
    functies: Functie[];
    private landCodeNederland = 6030;
    landen: Land[];

    constructor(private postcodeService: PostcodeService,
        private bufuService: BufuService,
        private dienstverbandService: DienstverbandService,
        private landService: LandService,
        private regelingService: RegelingService,
        private loonService: LoonService,
        private router: Router,
        private location: Location,
        private toasterService: ToasterService,
        private bedrijfsgegevensService: BedrijfsgegevensService) {
        this.aanmelding = new WerknemerAanmelden();
        this.dienstverband = new Dienstverband();
        this.dienstverband.werknemer = new Persoon();
        this.binnenlandsAdres = new BinnenlandsAdres();
        this.buitenlandsAdres = new BuitenlandsAdres();
        this.dienstverband.werknemer.adres = new BinnenlandsAdres();
        this.dienstverband.bedrijfsuitoefening = new Bedrijfsuitoefening();
        this.dienstverband.functie = new Functie();
        this.dienstverband.werknemer.geslacht = '';
        this.dienstverband.werknemer.adres.landnummer = this.landCodeNederland;
        this.dienstverband.bedrijfsuitoefening.code = null;
        this.dienstverband.functie.code = null;
    }

    ngOnInit(): void {
        this.initialiseerBedrijfsuitoefeningen();
        this.initialiseerFuncties();

        this.fillLanden();

        this.bedrijfsgegevensService.getBedrijfsgegevens().subscribe((x) => {
            if (x.heeftUpa) {
                this.upaStartdatum = x.upaAanleverwijzeStartdatum.toString();
            }
        });
    }

    ngAfterViewInit(): void {
        this.vc.first.nativeElement.focus();
    }

    save(): void {
        this.lopendVerzoek = true;
        this.fillDienstverband(this.dienstverband);
        const bedanktMessage: string =
            `Bedankt voor het aanmelden van ${this.dienstverband.werknemer.geslacht === 'M' ? 'meneer' : 'mevrouw'}
            ${this.dienstverband.werknemer.voorvoegsel === undefined ? '' : this.dienstverband.werknemer.voorvoegsel}
             ${this.dienstverband.werknemer.naam}.
            Wij verwerken deze wijziging zo spoedig mogelijk.`;
        this.aanmelding.dienstverband = this.dienstverband;
        if (this.aanmelding.dienstverband.werknemer.email === '') {
            this.aanmelding.dienstverband.werknemer.email = null;
        }
        this.dienstverbandService.saveWerknemer(this.aanmelding)
            .subscribe(() => {
                this.toasterService.pop('success', '', bedanktMessage);
                this.router.navigate([Urls.DoorgevenWijzigingen]);
            },
                (err) => {
                    if (err.status === 400) {
                        this.toasterService.pop('error', '', 'Niet alle velden zijn correct ingevuld');
                    } else {
                        this.toasterService.pop('error', '', Meldingen.ONBEKENDE_FOUT);
                    }
                    this.lopendVerzoek = false;
                });
    }

    postcodeHuisnummerChanged(): void {
        if (this.binnenlandsAdres.postcode && this.binnenlandsAdres.huisnummer) {
            this.gezochtNaarAdres = false;
            this.zoekAdres();
        }
    }

    private zoekAdres(): void {
        this.postcodeService.zoekAdres(this.binnenlandsAdres.postcode, +this.binnenlandsAdres.huisnummer)
            .subscribe((result) => {
                this.binnenlandsAdres.straat = result.straat;
                this.binnenlandsAdres.plaats = result.plaats;
                this.gezochtNaarAdres = true;
            });
    }

    initialiseerBedrijfsuitoefeningen(): void {
        this.bedrijfsuitoefeningen = [
            { omschrijving: Meldingen.SELECTEER_EERST_INDIENSTDATUM, code: null },
        ];
        this.dienstverband.bedrijfsuitoefening.code = null;
    }

    initialiseerFuncties(): void {
        this.functies = [
            { omschrijving: Meldingen.SELECTEER_EERST_BEDRIJFSUITOEFENING, code: null },
        ];
        this.dienstverband.functie.code = null;
    }

    fillBedrijfsuitoefeningen(peildatum: moment.Moment): void {
        this.bufuService.getBedrijfsuitoefeningen(
            moment.utc(this.selInDienst.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase()))
        .subscribe((result) => {
            this.bedrijfsuitoefeningen = [
                { omschrijving: Meldingen.DROPDOWN_DEFAULT_KEUZE, code: null },
            ];

            // tslint:disable-next-line:prefer-for-of
            for (let index = 0; index < result.length; index++) {
                const bu = result[index];
                if (bu) {
                    this.bedrijfsuitoefeningen.push(bu);
                }
            }
        });
    }

    fillFuncties(): void {
        this.bufuService.getFuncties(this.dienstverband.bedrijfsuitoefening.code,
            moment.utc(this.selInDienst.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase()))
            .subscribe((result) => {
                this.functies = [
                    { omschrijving: Meldingen.DROPDOWN_DEFAULT_KEUZE, code: null },
                ];
                // tslint:disable-next-line:prefer-for-of
                for (let index = 0; index < result.length; index++) {
                    const bu = result[index];
                    if (bu) {
                        this.functies.push(bu);
                    }
                }
            });
    }

    fillLanden(): void {
        this.landService.getLanden().subscribe((result) => {
            this.landen = result;
        });
    }

    onGebDatChanged(event: IMyInputFocusBlur, gebdatum: NgModel): void {
        if (event.reason === 2) {
            gebdatum.control.setValidators([Validators.required,
            LeeftijdValidatorDirective.checkOuderDan(10),
            MyDatePickerValidators.isValidDate()]);
            gebdatum.control.updateValueAndValidity();
        }
    }

    onInDienstChanged(event: IMyInputFieldChanged, inDienstDatum: NgModel) {
        // voorkom dat bij handmatig invullen van de datum voor ieder karakter de service wordt aangeroepen
        if (event.valid) {
            if (this.upaStartdatum) {
                inDienstDatum.control.setValidators([Validators.required,
                                                    MyDatePickerValidators.lessThan(this.upaStartdatum),
                                                    MyDatePickerValidators.isValidDate()]);
            } else {
                inDienstDatum.control.setValidators([Validators.required,
                                                    MyDatePickerValidators.isValidDate()]);
            }

            inDienstDatum.control.updateValueAndValidity();
            this.regelingen = null;
            this.dienstverband.functie.code = null;
            this.dienstverband.bedrijfsuitoefening.code = null;
            this.bedrijfsuitoefeningen = null;
            this.fillBedrijfsuitoefeningen(moment(event.value, 'DD-MM-YYYY'));
        } else {
            this.regelingen = null;
            this.initialiseerBedrijfsuitoefeningen();
            this.initialiseerFuncties();
        }
    }

    onInDienstBlur(event: IMyInputFocusBlur, inDienstDatum: NgModel) {
        // reason 2 = blur
        if (event.reason === 2) {
            if (this.upaStartdatum) {
                inDienstDatum.control.setValidators([Validators.required,
                MyDatePickerValidators.lessThan(this.upaStartdatum),
                MyDatePickerValidators.isValidDate()]);
            } else {
                inDienstDatum.control.setValidators([Validators.required,
                                                    MyDatePickerValidators.isValidDate()]);
            }
        }
    }

    buChanged() {
        this.dienstverband.functie.code = null;
        this.regelingen = null;
        this.fillFuncties();
    }

    buFuChanged(): void {
        this.regelingen = null;
        if (this.buFuValid()) {
            this.getRegelingen();
        }
    }

    private buFuValid(): boolean {
        let result = false;
        if (this.selInDienst) {
            result = (this.dienstverband.bedrijfsuitoefening.code != null && this.dienstverband.functie.code != null);
        } else {
            result = false;
        }
        return result;
    }

    getRegelingen(): void {
        this.regelingService.getRegelingenByBuFu({
            buCode: this.dienstverband.bedrijfsuitoefening.code,
            fuCode: this.dienstverband.functie.code,
            peildatum: moment.utc(this.selInDienst.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase()).format('YYYY-MM-DD'),
        })
            .subscribe((result) => {
                this.regelingen = this.bepaalTeTonenRegels(result);
                const sectorNummer = this.bepaalSectorNummer(this.regelingen);
                this.loonService.getLoongegevensUitleg(sectorNummer).subscribe((uitleg) => {
                    this.uitleg = uitleg;
                });
            });
    }

    private bepaalSectorNummer(regelingen: Regeling[]): number {
        for (const r of regelingen) {
            if (r.sectorNummer) {
                return r.sectorNummer;
            }
        }
        return null;
    }

    private bepaalTeTonenRegels(regelingen: Regeling[]): Regeling[] {
        const result = new Array<Regeling>();
        if (this.selInDienst) {
            const dBegin = moment(this.selInDienst.jsdate);
            for (const r of regelingen) {
                const rBegin = moment(r.begin);
                // als begindatum regeling < begindatum dvb dan begindatum dvb tonen
                // als begindatum regeling is >= begindatum dvb dan begindatum regeling
                // als regeling einddatum < begindatum dvb dan niet tonen
                if (rBegin.isBefore(dBegin)) {
                    r.begin = dBegin.toDate();
                    result.push(r);
                } else if (rBegin.isAfter(dBegin)) {
                    result.push(r);
                }
            }
        }
        return result;
    }

    isForeign(): boolean {
        return this.dienstverband.werknemer.adres.landnummer && this.dienstverband.werknemer.adres.landnummer !== this.landCodeNederland;
    }

    cancel() {
        this.location.back();
    }

    fillDienstverband(dienstverband: Dienstverband) {
        dienstverband.werknemer.geboortedatum = this.selGeboortedatum ?
            moment.utc(this.selGeboortedatum.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate() : null;
        dienstverband.begin = this.selInDienst ?
            moment.utc(this.selInDienst.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate() : null;

        dienstverband.bedrijfsuitoefening.omschrijving =
            this.bedrijfsuitoefeningen.find((x) => x.code === dienstverband.bedrijfsuitoefening.code).omschrijving;

        dienstverband.functie.omschrijving = this.functies ?
            this.functies.find((x) => x.code === dienstverband.functie.code).omschrijving : null;
        if (!dienstverband.functie.code) {
            dienstverband.functie.omschrijving = null;
        }
        dienstverband.functie.begin = dienstverband.begin;

        const land = this.landen.find((x) => x.code === dienstverband.werknemer.adres.landnummer);
        if (this.dienstverband.werknemer.adres.landnummer === this.landCodeNederland) {
            this.dienstverband.werknemer.adres = this.binnenlandsAdres;
        } else {
            this.dienstverband.werknemer.adres = this.buitenlandsAdres;
        }

        dienstverband.werknemer.adres.landnaam = land.omschrijving;
        dienstverband.werknemer.adres.landnummer = land.code;
    }
}
