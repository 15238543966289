// tslint:disable:max-classes-per-file

import {Werkgever} from '../model/werkgever.model';

export class Navigatie {
    werkgever: Werkgever;
    mainPanel: Panel;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
}

export class Panel {
    title: string;
    faIconClass?: string;
    height: string;
    color: string;
    links: Link[];
    footer?: PanelFooter;
}

export class Link {
    isDisabled: boolean;
    url: string;
    text: string;
}

export class PanelFooter {
    imgSrc?: string;
    alt?: string;
    title?: string;
    text?: string;
}
