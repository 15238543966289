import { Injectable } from '@angular/core';
import { Http, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';

import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { ApiRequestOptions } from './api-request-options.service';
import { LoaderService } from './modal/loading/loader.service';

/**
 * Maakt het werken met de API mogelijk vanuit de frontend
 */
@Injectable()
export class ApiClient {
    requestsInProgress: number = 0;
    /**
     * Initialiseert een nieuwe instantie van API Client
     * @param router            De router voor het redirecten bij een 401
     * @param http              HTTP client die moet worden gebruikt voor het afhandelen van requests
     * @param requestOptions    Helper class voor het maken van requests
     */
    constructor(
        private router: Router,
        private http: Http,
        private requestOptions: ApiRequestOptions,
        private loaderService: LoaderService,
    ) {

    }

    /**
     * Haalt data op met een GET request
     * @param path                          Pad op de server waar data opgehaald moet worden
     * @param queryStringParams             Querystring die je mee wilt geven bij het request
     * @returns {Observable<TResponse>}     Het resultaat van de operatie
     */
    get<TResponse>(path: string, queryStringParams?: any): Observable<TResponse> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path, queryStringParams),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                return self.http.get(url, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => response.json() as TResponse)
            .finally(() => self.onEnd());
    }

    /**
     * Haalt data op met een GET request
     * @param path                          Pad op de server waar data opgehaald moet worden
     * @param queryStringParams             Querystring die je mee wilt geven bij het request
     * @returns {Observable<TResponse>}     Het resultaat van de operatie
     */
    getPdf(path: string, queryStringParams?: any): Observable<Blob> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path, queryStringParams),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                options.headers.append('Accept', 'application/pdf');
                options.responseType = ResponseContentType.Blob;
                return self.http.get(url, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => {
                return response.blob();
            })
            .finally(() => self.onEnd());
    }

    /**
     * Stuurt data op naar de server met een POST request
     * @param path                      Pad naar de REST service
     * @param body                      Data voor de body van het request
     * @returns {Observable<TResponse>} Het resultaat van de operatie
     */
    post<TResponse>(path: string, body: any): Observable<TResponse> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                return self.http.post(url, body, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => response.json() as TResponse)
            .finally(() => self.onEnd());
    }

    /**
     * Stuurt een file en andere form data op naar de server met een POST request
     * @param path                      Pad naar de REST service
     * @param body                      Data voor de body van het request
     * @param file                      Data voor de file in het request
     * @returns {Observable<TResponse>} Het resultaat van de operatie
     */
    postFormData<TResponse>(path: string, body: any, file: File): Observable<TResponse> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const formData: FormData = new FormData();
                if (file) {
                    formData.append('file', file);
                    formData.append('extension', file.type);
                }

                for (const key in body) {
                    if (body.hasOwnProperty(key)) {
                        formData.append(key, body[key]);
                    }
                }

                const [url, options] = requestOpts;
                return self.http.post(url, formData, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => {
                // tslint:disable-next-line:no-string-literal
                if (response['_body']) {
                    return response.json() as TResponse;
                } else {
                    return null;
                }
            })
            .finally(() => self.onEnd());
    }

    /**
     * Stuurt een file op naar de server met een POST request
     * @param path                      Pad naar de REST service
     * @param body                      Data voor de body van het request
     * @returns {Observable<TResponse>} Het resultaat van de operatie
     */
    postfile<TResponse>(path: string, file: File): Observable<TResponse> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const formData: FormData = new FormData();
                formData.append('file', file);
                formData.append('extension', file.type);
                const [url, options] = requestOpts;
                return self.http.post(url, formData, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => {
                // tslint:disable-next-line:no-string-literal
                if (response['_body']) {
                    return response.json() as TResponse;
                } else {
                    return null;
                }
            })
            .finally(() => self.onEnd());
    }

    getFile(path: string, fileType: string, queryStringParams?: any): Observable<Blob> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path, queryStringParams),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                options.headers.append('Accept', fileType);
                options.responseType = ResponseContentType.Blob;
                return self.http.get(url, options);
            })
            .catch((response) => this.handleError(self, response))
            .map((response: Response) => {
                return response.blob();
            })
            .finally(() => self.onEnd());
    }

    /**
     * Stuurt data op naar de server met een PUT request
     * @param path                          Pad naar de REST service
     * @param body                          Data voor de body van het request
     * @returns {Observable<TResponse>}     Het resultaat van de operatie
     */
    put<TResponse>(path: string, body: any): Observable<TResponse> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                return self.http.put(url, body, options);
            })
            .catch((response) => self.handleError(self, response))
            .map((response: Response) => {
                if (response.status === 200) {
                    return response.json() as TResponse;
                } else {
                    return null;
                }
            })
            .finally(() => self.onEnd());
    }

    /**
     * Verwijdert data op de server middels een DELETE request
     * @param path                      Pad naar de REST service
     * @returns {Observable<Response>}  Het resultaat van de operatie
     */
    delete(path: string): Observable<Response> {
        const self = this;
        this.showLoader();
        return Observable
            .zip(
            this.requestOptions.absoluteUrlFor(path),
            this.requestOptions.authorizedRequestOptions(),
        )
            .flatMap((requestOpts) => {
                const [url, options] = requestOpts;
                return self.http.delete(url, options);
            })
            .catch((response) => this.handleError(self, response))
            .finally(() => self.onEnd());
    }

    private handleError(self: ApiClient, response: any): Observable<Response> {
        if (response.status === 401) {
            self.router.navigate(['/login']);
        }
        if (response.status === 500 || response.status === 0) {
            self.router.navigate(['/errors/internal-server-error']);
        }

        return Observable.throw(response);
    }

    private onEnd(): any {
        this.requestsInProgress--;
        this.hideLoader();
    }

    private showLoader(): any {
        this.requestsInProgress++;
        this.loaderService.show();
    }

    private hideLoader(): any {
        if (this.requestsInProgress === 0) {
            this.loaderService.hide();
        }
    }
}
