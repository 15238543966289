import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { BsnValidator } from '../validators';

@Component({
    selector: 'bsn-add',
    styles: [require('./bsn-add.component.scss')],
    template: require('./bsn-add.component.html'),
})
export class BsnAddComponent implements OnInit {
    @Output()
    toevoegen = new EventEmitter<string>();

    form: FormGroup;
    bsnControl: FormControl;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.bsnControl = this.fb.control('', [Validators.required, BsnValidator.bsnCheck()]);

        this.form = this.fb.group({
            bsn: this.bsnControl,
        });
    }

    onSubmit(): void {
        let bsn = this.bsnControl.value;
        if (bsn.length === 8) {
            bsn = `0${bsn}`;
        }
        this.toevoegen.emit(bsn);
        this.ngOnInit();
    }
}
