import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';

import { Document, UpaAccount, UpaAccountType, UpaMandaat, UpaSoftwarePakket } from '../../model';

import { AuthenticationService } from '../../authentication/authentication.service';
import { Accounttype, ApiRequestOptions, BedrijfsgegevensService } from '../../shared';
import { DocumentBeheerService } from '../../shared/services/documentbeheer.service';
import { UpaService } from '../upa.service';

@Component({
    template: require('./upa-wizard.component.html'),
})
export class UpaWizardComponent implements OnInit {
    magZelfInzenden: boolean = false;
    account: UpaAccount;
    mandaatLijst: UpaMandaat[];
    relatienummer: string;
    stap: number = 0;
    wachtwoord: string;

    editMode: boolean = false;
    mandaat: UpaMandaat;
    upaSoftwarePakketten: UpaSoftwarePakket[];
    upaSoftwarePakkett1: UpaSoftwarePakket;
    upaSoftwarePakkett2: UpaSoftwarePakket;

    upaCommunicatieDocumenten: Document[];

    startMetMachtigingenTab: boolean = false;

    constructor(private service: UpaService,
                private toasterService: ToasterService,
                private authenticationService: AuthenticationService,
                private apiRequestOptions: ApiRequestOptions,
                private bedrijfsgegevensService: BedrijfsgegevensService,
                private documentBeheerService: DocumentBeheerService) {
    }

    ngOnInit(): void {
        this.relatienummer = '';
        this.getAccount();
        this.documentBeheerService.zoekDocumenten(['UpaCommunicatie'], ['PGB'])
            .subscribe((x) => this.upaCommunicatieDocumenten = x);
    }

    private getAccount() {
        this.service.getAccount()
            .subscribe((x) => {
                this.account = x;
                this.getMandaatLijst();
                if (this.authenticationService.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR) {
                    this.clickzelfInstellen();
                }
                if (this.account.softwarePakketten[0] !== null) {
                    this.upaSoftwarePakketten = this.account.softwarePakketten;
                    this.setSoftwarePakketten();
                }
            },
            (err) => {
                this.toasterService.pop('error', '', 'Kon geen accountgegevens ophalen');
            });
    }

    private maakAccount() {
        this.service.maakAccount().subscribe((result) => {
            this.wachtwoord = result;
            this.getAccount();
            if (this.authenticationService.currentUser.profile.accounttype !== Accounttype.ADMINISTRATIEKANTOOR) {
                this.maakMandaat();
            }
        },
        (err) => {
            this.toasterService.pop('error', '', 'Er ging iets mis tijdens het aanmaken.');
        });
    }

    private maakMandaat() {
        this.bedrijfsgegevensService.getWerkgever().subscribe((x) => {
            const mandaat: UpaMandaat = new UpaMandaat();
            mandaat.startdatum = x.upaAanleverwijzeStartdatum;
            this.service.opslaanMandaat(mandaat).subscribe((result) => {
                this.stap = 2;
                this.getMandaatLijst();
            },
            (err) => {
                this.toasterService.pop('error', '', 'Er ging iets mis tijdens het aanmaken van mandaat.');
            });
        });
    }

    private getMandaatLijst() {
        this.service.getMandaatLijst()
        .subscribe(
        (result) => {
            this.mandaatLijst = result;
            if (this.mandaatLijst.length > 0 ||
                this.authenticationService.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR) {
                this.bepaalStap();
            } else {
                this.stap = 1;
            }
        },
        (err) => {
            this.toasterService.pop('error', '', 'Er ging iets mis tijdens het aanmaken.');
        });
    }

    private bepaalStap() {
        if (this.authenticationService.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR) {
            this.stap = 2;
            return;
        }

        const laatsteMandaat = this.mandaatLijst[this.mandaatLijst.length - 1];
        if ((this.authenticationService.currentUser.profile.accounttype === Accounttype.PGBMEDEWERKER &&
            parseInt(laatsteMandaat.relatienummer, 0) === parseInt(this.apiRequestOptions.getCookie('x-wgp-wgid'), 0)) ||
            (this.authenticationService.currentUser.profile.accounttype === Accounttype.WERKGEVER &&
            parseInt(laatsteMandaat.relatienummer, 0) ===
                                    parseInt(this.authenticationService.currentUser.profile.werkgeverrelatienummer, 0)) &&
            !laatsteMandaat.ingetrokken && !laatsteMandaat.einddatum) {
                this.stap = 2;
        } else {
            this.stap = 3;
        }
    }

    clickInzenderInstellen(upaMandaat: UpaMandaat) {
        this.mandaat = upaMandaat;
        this.stap = 3;
        this.editMode = true;
    }

    clickzelfInstellen() {
        if (this.account == null) {
            this.maakAccount();
        } else if (this.authenticationService.currentUser.profile.accounttype !== Accounttype.ADMINISTRATIEKANTOOR) {
            this.maakMandaat();
        } else {
            this.stap = 2;
        }
    }

    wijzigingOpgeslagen() {
        this.editMode = false;
        this.getMandaatLijst();
        this.startMetMachtigingenTab = true;
    }

    softwarepakketSelected(softwarePakketId: number, index: number) {
        const ids: number[] = [];

        if (this.upaSoftwarePakketten.some((x) => x.id === softwarePakketId)) {
            this.toasterService.pop('error', '', 'U heeft hetzelfde softwarepakket geselecteerd, deze wijziging wordt niet opgeslagen.');
            return;
        }

        if (softwarePakketId) {
            if (!this.upaSoftwarePakketten[index] || this.upaSoftwarePakketten[index].id !== softwarePakketId) {
                ids.push(softwarePakketId);
            } else {
                ids.push(this.upaSoftwarePakketten[index].id);
            }
        } else {
            if (index === 0 && this.upaSoftwarePakketten[1]) {
                ids.push(this.upaSoftwarePakketten[1].id);
            }
            this.upaSoftwarePakketten.splice(index, 1);
        }

        if (index === 0 && this.upaSoftwarePakketten[1]) {
            ids.push(this.upaSoftwarePakketten[1].id);
        }
        if (index === 1 && this.upaSoftwarePakketten[0]) {
            ids.push(this.upaSoftwarePakketten[0].id);
        }
        this.setSoftwarePakketten();

        this.service.opslaanSoftwarePakketten(this.account.accountNaam, ids).subscribe((x) => {
            this.getAccount();
        });
    }

    private setSoftwarePakketten() {
        this.upaSoftwarePakkett1 = this.upaSoftwarePakketten[0];
        this.upaSoftwarePakkett2 = this.upaSoftwarePakketten[1];
    }
}
