import { IPaginatieFilter } from '../../model/paginatie-filter.model';
import { SorteerRichting } from '../../model/sorteer-richting.model';

export class FactuurFilter implements IPaginatieFilter {
    aantalItemsPerPagina: number;
    paginaNummer: number;
    fonds: string;
    soort: string;
    notanummer: number;
    van: string;
    tot: string;
    sorteerRichting: SorteerRichting;
    sorteerVeld: string;
    openstaand: boolean;
}
