import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { ToasterService } from 'angular2-toaster';
import { Nota } from '../../model/nota.model';
import { SorteerRichting } from '../../model/sorteer-richting.model';
import { UtilService } from '../../shared/index';
import { NotaService } from '../../shared/services/nota.service';
import { FactuurFilter } from './factuur-filter.model';

@Component({
    selector: 'facturen-tabel',
    template: require('./facturen-tabel.component.html'),
})
export class FacturenTabelComponent {
    @Input() notas: Nota[];
    @Input() filter: FactuurFilter;

    @Output() sortingChanged = new EventEmitter();

    public columns: IColumn[] = [
        { title: 'Fonds', name: 'fonds', sortable: true, sort: 'asc', isSortActive: false },
        { title: 'Soort', name: 'soort', sortable: true, sort: 'desc', isSortActive: false },
        { title: 'Notanummer', name: 'notanummer', sortable: true, sort: 'desc', isSortActive: false },
        { title: 'Notadatum', name: 'datum', sortable: true, sort: 'desc', isSortActive: true },
        { title: 'Bedrag', name: 'bedrag', sortable: true, sort: 'desc', isSortActive: false, rightAlign: true },
    ];

    constructor(private notaSvc: NotaService,
        private utilSvc: UtilService,
        private toasterService: ToasterService) { }

    toggleSorting(columnName: string) {
        let richting: SorteerRichting = SorteerRichting.Ascending;
        this.columns.forEach((c: any) => {
            if (c.name !== columnName) {
                c.isSortActive = false;
            } else {
                if (c.isSortActive) {
                    c.sort = c.sort === 'asc' ? 'desc' : 'asc';
                } else {
                    c.isSortActive = true;
                }
                richting = c.sort === 'asc' ? SorteerRichting.Ascending : SorteerRichting.Descending;
            }
        });

        this.filter.paginaNummer = 1;
        this.filter.sorteerRichting = richting;
        this.filter.sorteerVeld = columnName;

        this.sortingChanged.emit(this.filter);
    }

    downloadNota(nummer: number): void {
        this.notaSvc.download(nummer).subscribe({
            next: (nota) => {
                this.utilSvc.savePdf(nota, nummer.toString() + '.pdf');
            },
            error: (err) => {
                let message;

                if (err.status === 404) {
                    message = 'De nota is op dit moment niet beschikbaar.';
                } else {
                    message = 'Er is een onbekende fout opgetreden.';
                }
                this.toasterService.pop('error', '', message);
            },
        });
    }

    downloadNotaCsv(nummer: number, notaSoort: string): void {
        this.notaSvc.downloadCsv(nummer).subscribe({
            next: (nota) => {
                this.utilSvc.downloadFile(nota, 'Nota_' + notaSoort + '_' + nummer.toString() + '.csv');
            },
            error: (err) => {
                let message;

                if (err.status === 404) {
                    message = 'De nota specificatie is op dit moment niet beschikbaar.';
                } else {
                    message = 'Er is een onbekende fout opgetreden.';
                }
                this.toasterService.pop('error', '', message);
            },
        });
    }
}

interface IColumn {
    title: string;
    name: string;
    sortable: boolean;
    sort: string;
    isSortActive: boolean;
    rightAlign?: boolean;
}
