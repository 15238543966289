import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AccountService } from '../account.service';

@Component({
    styles: [require('../account.style.scss')],
    template: require('./wachtwoord-reset.component.html'),
})
export class WachtwoordResetComponent implements OnInit {
    form: FormGroup;

    constructor(private router: Router,
                private fb: FormBuilder,
                private accountService: AccountService,
                private toasterService: ToasterService) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            gebruikersnaam: ['', [Validators.required]],
        });
    }

    save(): void {
        const username = this.form.controls.gebruikersnaam.value;
        this.accountService.vraagWachtwoordResetAan(username).subscribe((response: any) => {
            if (response.status === 200) {
              this.toasterService.pop('success', '', `Als het relatienummer bij ons bekend is wordt er een e-mail verstuurd met instructies om uw wachtwoord te herstellen.
              Als u de e-mail niet heeft ontvangen, controleer dan ook uw spamfilter of neem contact op
              met relatiebeheer via telefoonnummer 020 541 83 00.`);
            }
        },
        (err: any) => {
            this.toasterService.pop('error', '', 'Er is een fout opgetreden bij het verwerken van uw verzoek.');
        },
        () => this.form.controls.gebruikersnaam.setValue(''));
    }

    cancel() {
        this.router.navigate(['']);
    }
}
