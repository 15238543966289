import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { OpslaanLoonopgaveResponse, RaadpleegLoongegevensRegelsByBsnsRequest, RaadpleegLoongegevensRegelsRequest } from '../';
import { Filter, LoongegevensPeriode, LoongegevensPeriodeStatus, LoongegevensRegel } from '../../model';
import { Meldingen, NumberValidators, Validaties } from '../../shared';
import { ModalService } from '../../shared/modal/modal.service';
import { LoonService } from '../../shared/services';
import { LoongegevensService } from './loongegevens.service';

const loongegevensregelsControlIdentifier: string = 'loongegevensRegels';
const loongegevensregelControlIdentifier: string = 'loongegevensRegel';
const loongegevensregelMessageControlIdentifier: string = 'regelMessage';

@Component({
    selector: 'loongegevens-invullen',
    styles: ['input.ng-invalid.ng-dirty { border: red 0.5px solid; }'],
    template: require('./loongegevens-edit.component.html'),
})
export class LoongegevensEditComponent implements OnInit {
    @Input() sectorNummer: number;
    @Input() isLooncorrectie: boolean = false;
    @Input() loongegevensPeriode: LoongegevensPeriode;
    @Output() onAnnuleren = new EventEmitter<LoongegevensPeriode>();
    isVerschillenlijst: boolean;
    uitleg: string;
    form: FormGroup;

    totaalItems: number;
    aantalItemsPerPagina: number = 10;
    paginaNummer: number = 1;
    vanAantal: number;
    totAantal: number;
    filter: Filter;

    loonMessages = {
        required: Validaties.ONGELDIGE_WAARDE,
        range: Validaties.GEEF_BEDRAG_0_500000,
        isnotround: Validaties.GEEF_GEHEEL_GETAL_OP,
    };

    parttimeMessages = {
        required: Validaties.ONGELDIGE_WAARDE,
        range: Validaties.GEEF_WAARDE_1_100,
        isnotround: Validaties.GEEF_GEHEEL_GETAL_OP,
    };

    constructor(private router: Router,
        private loonService: LoonService,
        private loongegevensService: LoongegevensService,
        private toasterService: ToasterService,
        private modalService: ModalService,
        private fb: FormBuilder) { }

    ngOnInit(): void {
        const request = new RaadpleegLoongegevensRegelsRequest();
        request.periode = this.loongegevensPeriode;

        this.loonService.getLoongegevensUitleg(this.sectorNummer).subscribe((uitleg) => {
            this.uitleg = uitleg;
        });

        if (!this.isLooncorrectie) {
            this.getLoonGegevens(request);
        } else {
            this.initForm(new Array<LoongegevensRegel>());
        }
    }

    getLoonGegevens(request: RaadpleegLoongegevensRegelsRequest): void {
        this.isVerschillenlijst = this.loongegevensPeriode.status === LoongegevensPeriodeStatus.nietCompleet;
        this.loongegevensService.raadpleegLoongegevensRegels(request).subscribe((x) => {
            this.totaalItems = x.length;
            this.initForm(x);
        });
    }

    annuleren(): void {
        this.onAnnuleren.emit();
    }

    initForm(loongegevensRegels: LoongegevensRegel[]) {
        const array = this.fb.array([]);
        this.form = this.fb.group({
            loongegevensRegels: array,
        });

        loongegevensRegels.every((lr) => {
            const regel = this.fb.group({
                loongegevensRegel: [lr],
                naam: [`${lr.achternaam}, ${lr.voorletters} ${lr.voorvoegsels}`],
                geslacht: [lr.geslacht],
                bsn: [lr.burgerServiceNummer],
                geboortedatum: [lr.geboortedatum],
                beginLoontijdVak: [lr.datumIngangLoontijdvak],
                eindLoontijdVak: [lr.datumEindeLoontijdvak],
                loonbegrip: [lr.loonbegrip],
                loonbedragPlaceholder: [lr.loon],
                parttimePercentagePlaceholder: [lr.parttimePercentage],
                loonbedrag: ['', [Validators.required, NumberValidators.range(0, 500000), NumberValidators.isRoundNumber()]],
                parttimePercentage: ['', [Validators.required, NumberValidators.range(1, 100), NumberValidators.isRoundNumber()]],
                regelMessage: [lr.foutomschrijving],
            });

            array.push(regel);
            return true;
        });
        this.setParameters();
    }

    save() {
        const array: AbstractControl[] = (this.form.get(loongegevensregelsControlIdentifier) as FormArray).controls;
        const data = array.map((group) => {
            if (parseInt(group.get('loonbedrag').value, 0) !== group.get('loonbedragPlaceholder').value ||
                parseInt(group.get('parttimePercentage').value, 0) !== group.get('parttimePercentagePlaceholder').value) {
                const loongegevensRegel = group.get(loongegevensregelControlIdentifier).value as LoongegevensRegel;
                loongegevensRegel.loon = group.get('loonbedrag').value;
                loongegevensRegel.parttimePercentage = group.get('parttimePercentage').value;
                return loongegevensRegel;
            } else {
                return null;
            }
        }).filter((x) => x);

        if (!this.isLooncorrectie) {
            this.modalService.createBevestigingMetCallback('Let op!', () => {
                this.handOpslaanLoonopgave(data);
            }, Meldingen.LOONOPGAVE_BEVESTIGING);
        } else {
            this.handOpslaanLooncorrectie(data);
        }

    }

    handOpslaanLoonopgave(data: LoongegevensRegel[]): void {
        this.loongegevensService.opslaanLoonopgave(data).subscribe(
            (x) => this.handleSucces(x),
            (err) => {
                this.modalService.createBevestiging(Meldingen.FOUT_TIJDENS_VERSTUREN_LOONOPGAVE, Meldingen.PROBEER_LATER_NOGMAALS);
            });
    }

    handOpslaanLooncorrectie(data: LoongegevensRegel[]): void {
        this.loongegevensService.opslaanLooncorrectie(data).subscribe(
            (x) => this.handleSucces(x),
            (err) => {
                this.modalService.createBevestiging(Meldingen.FOUT_TIJDENS_VERSTUREN_LOONCORRECTIE, Meldingen.PROBEER_LATER_NOGMAALS);
            });
    }

    handleSucces(response: OpslaanLoonopgaveResponse): void {
        if (response.fouten === null) {
            const melding = this.isLooncorrectie ? Meldingen.LOONCORRECTIE_SUCCESVOL_AANGELEVERD :
                Meldingen.LOONOPGAVE_SUCCESVOL_AANGELEVERD;
            this.modalService.createBevestiging(melding);

            this.router.navigate(['']);
        } else {
            const melding = this.isLooncorrectie ? Meldingen.FOUT_TIJDENS_VERSTUREN_LOONCORRECTIE :
                Meldingen.FOUT_TIJDENS_VERSTUREN_LOONOPGAVE;
            this.modalService.createBevestiging(melding, Meldingen.PROBEER_LATER_NOGMAALS);
        }
    }

    hasErrors(control: AbstractControl): boolean {
        const regelMessageControl = control.get(loongegevensregelMessageControlIdentifier);
        return (control.dirty && control.invalid) ||
            (regelMessageControl && regelMessageControl.value && (this.isVerschillenlijst || this.isLooncorrectie));
    }

    getValidationMessage(c: AbstractControl, validationMessages: any): string {
        if ((c.touched || c.dirty) && c.errors) {
            const required = 'required';

            if (Object.keys(c.errors).find((key) => key === required)) {
                return validationMessages[required];
            }
            return Object.keys(c.errors).map((key) => validationMessages[key]).join(' ');
        }

        return '';
    }

    handlePaging(filter: Filter) {
        this.paginaNummer = filter.paginaNummer;
        this.setParameters();
    }

    setParameters(): void {
        const totAantal = (this.paginaNummer * this.aantalItemsPerPagina);
        this.vanAantal = (this.paginaNummer * this.aantalItemsPerPagina) - (this.aantalItemsPerPagina - 1);
        this.totAantal = totAantal > this.totaalItems ? this.totaalItems : totAantal;
        this.filter = {
            paginaNummer: this.paginaNummer,
            aantalItemsPerPagina: this.aantalItemsPerPagina,
            haalAllesOp1PaginaOp: false,
            tot: totAantal.toString(),
            van: this.vanAantal.toString(),
            sorteerRichting: null,
            sorteerVeld: null,
        };
    }

    removeItem(index: number) {
        const array = this.form.get(loongegevensregelsControlIdentifier) as FormArray;
        array.removeAt(index);
        this.totAantal--;
        this.totaalItems--;
        this.setParameters();
    }

    showAll(): void {
        const request = new RaadpleegLoongegevensRegelsRequest();
        request.periode = this.loongegevensPeriode;

        request.isLooncorrectie = this.isLooncorrectie;

        this.getLoonGegevens(request);
    }

    zoekOpNaam(naam: string): void {
        const request = new RaadpleegLoongegevensRegelsRequest();
        request.periode = this.loongegevensPeriode;

        if (this.isLooncorrectie) {
            request.isLooncorrectie = true;
            request.naam = naam;
        }
        this.loongegevensService.raadpleegLoongegevensRegels(request).subscribe((x) => {
            if (x.length === 0) {
                this.toasterService.pop('warning', '', Meldingen.WERKNEMER_NIET_GEVONDEN);
            }

            this.totaalItems = x.length;
            this.initForm(x);
        });
    }

    zoekBsn(bsn: string): void {
        const array = this.form.get(loongegevensregelsControlIdentifier) as FormArray;
        const komtBsnVoor = (array.controls.findIndex((x) => x.value.bsn === bsn)) !== -1;

        if (komtBsnVoor) {
            this.toasterService.pop('error', '', Meldingen.BSN_KOMT_AL_VOOR);
        } else {
            const request = new RaadpleegLoongegevensRegelsByBsnsRequest();
            request.van = this.loongegevensPeriode.begindatum;
            request.tot = this.loongegevensPeriode.einddatum;
            request.bsns = [bsn];
            request.isLoonCorrectie = true;

            this.loongegevensService.raadpleegLoongegevensRegelsByBsns(request).subscribe((x) => {
                if (x.length === 0) { this.toasterService.pop('error', '', Meldingen.GEEN_RESULTATEN_VOOR_BSN); }

                const lr = x[0];

                const regel = this.fb.group({
                    loongegevensRegel: [lr],
                    naam: [`${lr.achternaam}, ${lr.voorletters} ${lr.voorvoegsels}`],
                    geslacht: [lr.geslacht],
                    bsn: [lr.burgerServiceNummer],
                    geboortedatum: [lr.geboortedatum],
                    beginLoontijdVak: [lr.datumIngangLoontijdvak],
                    eindLoontijdVak: [lr.datumEindeLoontijdvak],
                    loonbegrip: [lr.loonbegrip],
                    loonbedragPlaceholder: [lr.loon],
                    parttimePercentagePlaceholder: [lr.parttimePercentage],
                    loonbedrag: ['', [Validators.required, NumberValidators.range(0, 500000), NumberValidators.isRoundNumber()]],
                    parttimePercentage: ['', [Validators.required, NumberValidators.range(1, 100), NumberValidators.isRoundNumber()]],
                    regelMessage: [lr.foutomschrijving],
                });

                array.push(regel);
                this.totAantal++;
                this.totaalItems++;
                this.setParameters();
            });
        }
    }

    zoekOpBsns(bsns: Set<string>): void {
        const request = new RaadpleegLoongegevensRegelsByBsnsRequest();
        request.van = this.loongegevensPeriode.begindatum;
        request.tot = this.loongegevensPeriode.einddatum;
        request.bsns = Array.from(bsns);
        request.isLoonCorrectie = true;

        this.loongegevensService.raadpleegLoongegevensRegelsByBsns(request).subscribe((x) => {

            this.totaalItems = x.length;
            this.initForm(x);
            this.handleBsns(Array.from(bsns), x);
        });
    }

    handleBsns(bsns: string[], loongegevensRegels: LoongegevensRegel[]) {
        const onbekendeBsns = Array.from(bsns).filter((bsn) => loongegevensRegels.every((item) => item.burgerServiceNummer !== bsn));
        if (onbekendeBsns.length > 0) {
            this.toasterService.pop('warning', '', Meldingen.NIET_ALLE_BSNS_GEVONDEN);
        }
        const array = this.form.get(loongegevensregelsControlIdentifier) as FormArray;

        onbekendeBsns.forEach((bsn) => {
            const regel = this.fb.group({
                loongegevensRegel: null,
                naam: this.fb.control('', [Validators.required]),
                geslacht: this.fb.control('', [Validators.required]),
                bsn: this.fb.control(bsn),
                geboortedatum: this.fb.control('', [Validators.required]),
                beginLoontijdVak: this.fb.control('', [Validators.required]),
                eindLoontijdVak: this.fb.control('', [Validators.required]),
                loonbegrip: this.fb.control('', [Validators.required]),
                loonbedragPlaceholder: this.fb.control(''),
                parttimePercentagePlaceholder: this.fb.control(''),
                loonbedrag: ['', [Validators.required, NumberValidators.range(0, 500000), NumberValidators.isRoundNumber()]],
                parttimePercentage: ['', [Validators.required, NumberValidators.range(1, 100), NumberValidators.isRoundNumber()]],
                regelMessage: this.fb.control(Meldingen.GEEN_RESULTATEN_VOOR_BSN),
            });
            regel.disable();
            array.push(regel);
            this.totAantal++;
            this.totaalItems++;
            this.setParameters();
        });
    }
}
