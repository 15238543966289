import { ToasterService } from 'angular2-toaster';
import { Constants } from '.';
import { DienstverbandPeriode, Werkgever } from '../model';
import { Filter } from '../model/filter.model';
import { ErrorMessage } from './errormessage';
import { Meldingen } from './meldingen';
import { Sector } from './sector';

export class UtilService {
    public constructor(private toasterService: ToasterService) {
    }

    public downloadFile(data: BlobPart, fileName: string, fileType: string = 'text/csv') {
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: fileType });
            const url = window.URL.createObjectURL(blob);
            // If IE, download by different method
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                this.save(url, fileName);
            }
        } catch (ex) {
            this.toasterService.pop('error', '', Meldingen.FOUT_BIJ_BESTAND_DOWNLOAD);
        }
    }

    public savePdf(blob: Blob, fileName: string, fileType: string = 'application/pdf') {
        try {
            const url = window.URL.createObjectURL(blob);
            // If IE, download by different method
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                this.save(url, fileName);
            }
        } catch (ex) {
            this.toasterService.pop('error', '', Meldingen.FOUT_BIJ_BESTAND_DOWNLOAD);
        }
    }

    private save(url: string, fileName: string): void {
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', fileName);
        const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: false,
            view: window,
        });
        linkElement.dispatchEvent(clickEvent);
    }

    public printContent(elementId: string, title: string): void {
        let printContents;
        let popupWin;
        printContents = document.getElementById(elementId).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`,
        );
        popupWin.document.close();
    }

    public printHtmlContent(content: string, title: string): void {
        let popupWin;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${content}</body>
      </html>`,
        );
        popupWin.document.close();
    }

    public bepaalParttimePercentageHeader(dvbps: DienstverbandPeriode[], werkgever: Werkgever): string {
        if (werkgever && werkgever.sectornummer === Sector.Zeevisserij) {
            return Constants.GEWERKTE_UREN_HEADER;
        } else {
            return Constants.PARTTIMEPERCENTAGE_HEADER;
        }
    }

    public copyDefaultFilterValues(filter: Filter, defaultFilter: Filter): void {
        filter.paginaNummer = defaultFilter.paginaNummer;
        filter.aantalItemsPerPagina = defaultFilter.aantalItemsPerPagina;
        filter.van = defaultFilter.van;
        filter.tot = defaultFilter.tot;
        filter.bsn = defaultFilter.bsn;
        filter.naam = defaultFilter.naam;
        filter.excludedRegelingnummers = defaultFilter.excludedRegelingnummers;
        filter.includedRegelingnummers = defaultFilter.includedRegelingnummers;
        filter.regelingGeblokkeerdVoorNota = defaultFilter.regelingGeblokkeerdVoorNota;
    }

    private gewerkteUrenGroterDanNul(element: DienstverbandPeriode, index: number, array: DienstverbandPeriode[]) {
        return element.gewerkteUren > 0;
    }

    private gewerkteDagenGroterDanNul(element: DienstverbandPeriode, index: number, array: DienstverbandPeriode[]) {
        return element.gewerkteDagen > 0;
    }

    public getErrorMessage(err: any): ErrorMessage {
        let errorMessage = err._body;

        if (errorMessage.indexOf(';') >= 0) {
            errorMessage = this.formatErrorMessageWithExtentions(errorMessage);
        }

        return {
            Message: errorMessage,
            StatusCode: err.status,
        };
    }

    private formatErrorMessageWithExtentions(errorMessage: string): string {
        const messageParts = errorMessage.split(';');
        let result = messageParts[0];
        messageParts.slice(1).forEach((filename) => {
            result += '<br/>' + filename;
        });
        return result;
    }

    public bepaalDagVoorDatum(datum: string | Date): Date {
        if (!datum) {
            return null;
        }

        let result: Date;
        if (typeof datum === 'string') {
            result = new Date(datum as string);
        } else {
            result = datum as Date;
        }
        result.setDate(result.getDate() - 1);

        return result;
    }
}
