import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NumberValidators {
    static isRoundNumber() {
         return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value !== undefined && ((c.value as string).indexOf(',') > -1 || (c.value as string).indexOf('.') > -1)) {
                return { isnotround: true };
            }
            return null;
         };
    }

    static range(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value !== undefined && (isNaN(c.value) || c.value < min || c.value > max)) {
                return { range: true };
            }
            return null;
        };
    }

    static currencyRange(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!c.value || typeof(c.value) === 'number') { return null; }
            const jaarPremie = parseFloat(c.value.replace(',', '.'));
            if (jaarPremie !== undefined && (isNaN(jaarPremie) || jaarPremie < min || jaarPremie > max)) {
                return { range: true };
            }
            return null;
        };
    }

    static validNumber(): ValidatorFn {
        const regex = /^[\d]+$/i;
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== undefined && !regex.test(c.value)) {
                return { valid: true };
            }
            return null;
        };
    }
}
