import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
];

/**
 * Router module for the application
 */
@NgModule({
    exports: [
        RouterModule,
    ],
    imports: [
        RouterModule,
        RouterModule.forRoot(routes),
    ],
})
export class AppRoutingModule {
}
