import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

import {ToasterService} from 'angular2-toaster';
import {IMyOptions} from 'mydatepicker';

import {IBsnIdentificeerbaarItem} from '../../model/bsnIdentificeerbaarItem';
import {Urls} from '../../navigatie';
import {BedrijfsgegevensService, Meldingen} from '../../shared';
import {IMutatieFormColumn} from './mutatie-form-column';
import {IMutatieFormConfig} from './mutatie-form-config';
import {MutatieFormService} from './mutatie-form.service';
import {INieuwFormulierVerzoek} from './nieuwFormulierVerzoek';

@Component({
    selector: 'mutatie-form',
    template: require('./mutatie-form.component.html'),
})
export class MutatieFormComponent implements OnInit {
    @Input() columns: IMutatieFormColumn[];
    @Input() config: IMutatieFormConfig;

    @Output() wijzig = new EventEmitter();
    @Output() bsnRequested = new EventEmitter();

    private mutForm: FormGroup;
    private myDatePickerOptions: IMyOptions;
    public upaStartdatum: string;

    get regels(): FormArray {
        return this.mutForm.get('regels') as FormArray;
    }

    constructor(private fb: FormBuilder,
                private router: Router,
                private mutatieFormService: MutatieFormService,
                private toasterService: ToasterService,
                private bedrijfsgegevensService: BedrijfsgegevensService) {
        this.createForm();
    }

    ngOnInit(): void {
        this.myDatePickerOptions = Object.assign({}, this.mutatieFormService.myDatePickerOptions);
        this.bedrijfsgegevensService.getWerkgever().subscribe((x) => {
            if (x.heeftUpa) {
                this.upaStartdatum = x.upaAanleverwijzeStartdatum.toString();
            }
        });
    }

    terugNaarDoorgevenWijzigingen(): void {
        this.router.navigate([Urls.DoorgevenWijzigingen]);
    }

    bevatWaarschuwing(regel: AbstractControl): boolean {
        const waarschuwingControl = regel.get(MutatieFormService.waarschuwingFormcontrolName);
        return (waarschuwingControl && waarschuwingControl.value);
    }

    bevatFout(regel: AbstractControl): boolean {
        return (regel.dirty && regel.invalid);
    }

    regelVerwijderen(index: number): void {
        this.regels.removeAt(index);
    }

    getBewerkbareRegels(): AbstractControl[] {
        return this.regels.controls.filter((control) => this.config.isBewerkbaar(control));
    }

    getFoutmelding(regel: AbstractControl): string {
        const foutmelding = this.config.getFoutmelding(regel);
        return foutmelding || 'Deze regel is ongeldig.';
    }

    submit(): void {
        const bewerkbareRegels = this.getBewerkbareRegels();
        this.wijzig.emit(bewerkbareRegels);
    }

    zoekBsn(bsn: string): void {
        const komtBsnVoor = this.regels.controls.find((control) => {
            const regel = control as FormGroup;
            const bsnValue = regel.get(MutatieFormService.bsnFormcontrolName).value;
            return bsnValue === bsn;
        });

        if (komtBsnVoor) {
            this.toasterService.pop('error', '', Meldingen.BSN_KOMT_AL_VOOR);
        } else {
            this.bsnRequested.emit(bsn);
        }
    }

    isDirty(): boolean {
        if (this.mutForm) {
            if (this.regels.length > 0 && this.mutForm.touched) {
                return true;
            }
        }
        return false;
    }

    initialiseerNieuwFormulier<T extends IBsnIdentificeerbaarItem>(verzoek: INieuwFormulierVerzoek<T>): void {
        const onbekendeBsns = this.mutatieFormService.getOnbekendeBsns(verzoek.bsns, verzoek.items);
        const frmArr = this.maakArrayVanFormgroupsAan<T>(verzoek, onbekendeBsns);
        if (verzoek.overschrijfOudFormulier) {
            this.createForm();
            this.mutForm.setControl(MutatieFormService.regelsFormarrayName, frmArr);
        } else {
            frmArr.controls.every((frmGrp) => {
                this.regels.push(frmGrp);
                return true;
            });
        }
        if (onbekendeBsns.length > 0) {
            this.toasterService.pop('warning', '', Meldingen.NIET_ALLE_BSNS_GEVONDEN);
        }
    }

    private maakArrayVanFormgroupsAan<T>(verzoek: INieuwFormulierVerzoek<T>, onbekendeBsns: string[]): FormArray {
        const frmArr = this.fb.array([]);
        this.voegOnbekendeBsnsAanFormArrayToe<T>(onbekendeBsns, verzoek, frmArr);
        this.voegDienstverbandenAanFormArrayToe<T>(verzoek, frmArr);
        return frmArr;
    }

    private createForm(): void {
        this.mutForm = this.fb.group({
            regels: this.fb.array([]),
        });
    }

    private voegOnbekendeBsnsAanFormArrayToe<T>(onbekendeBsns: string[], verzoek: INieuwFormulierVerzoek<T>, frmArr: FormArray) {
        onbekendeBsns.every((bsn) => {
            const frmGrp = verzoek.mapOnbekendeBsnToFormGroup(bsn, verzoek.self);
            frmArr.push(frmGrp);
            return true;
        });
    }

    private voegDienstverbandenAanFormArrayToe<T>(verzoek: INieuwFormulierVerzoek<T>, frmArr: FormArray) {
        verzoek.items.every((item) => {
            if (this.upaStartdatum) {
                verzoek.self.upaStartdatum = this.upaStartdatum;
            }
            const frmGrp = verzoek.mapItemToFormGroup(item, this.config, verzoek.self);
            frmArr.push(frmGrp);
            return true;
        });
    }
}
