export class UpaInzendRegelingDeelname {
    id: number;
    deelnameBegindatum: Date;
    deelnameEinddatum: Date;
    relatienummer: string;
    regelingKenmerk: string;
    regelingVariant: string;
    regelingLoon?: number;
    premiegrondslag: number;
    premieTotaal: number;
    premieWerknemerdeel: number;
    aantalVerloondeUren: number;
}
