import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import {
    RegelingKerngegevensComponent,
    RegelingRoutingModule,
    RegelingService,
    routedComponents,
} from '.';

@NgModule({
    declarations: [
        RegelingKerngegevensComponent,
        routedComponents,
    ],
    exports: [],
    imports: [
        RegelingRoutingModule,
        SharedModule,
    ],
    providers: [
        RegelingService,
    ],
})
export class RegelingModule {
}
