import { Component } from '@angular/core';

@Component({
    styles: [require('.//wijzigingendoorgeven.component.scss')],
    template: require('./wijzigingendoorgeven.component.html'),
})
export class WijzigingendoorgevenComponent {

    inEnUitDienst = {
        color: 'light-green',
        height: '400',
        links: [
            {
                isDisabled: false,
                text: 'Werknemer aanmelden',
                url: '/werknemers/aanmelding',
            },
            {
                isDisabled: false,
                text: 'Werknemer afmelden',
                url: '/werknemers/dienstverbanden/uitdienst',
            },
        ],
        title: 'In & Uit dienst',
    };

    dvbCorrecties = {
        color: 'light-green',
        height: '400',
        links: [
            {
                isDisabled: false,
                text: 'Functiegroep aanpassen',
                url: '/werknemers/functiewijzigen',
            },
            {
                isDisabled: false,
                text: 'Datum in dienst corrigeren',
                url: '/werknemers/dienstverbanden/begindatum',
            },
            {
                isDisabled: false,
                text: 'Datum uit dienst corrigeren',
                url: '/werknemers/dienstverbanden/einddatum',
            },
        ],
        title: 'Dienstverband',
    };

    loon = {
        color: 'light-green',
        height: '400',
        links: [
            {
                isDisabled: false,
                text: 'Loon corrigeren',
                url: '/werknemers/looncorrectie',
            },
        ],
        title: 'Loon',
    };
}
