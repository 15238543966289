import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import {
    Adres,
    GemachtigdenOverzichtResponse,
    Machtiging,
    Werkgever,
} from '../../model';
import { LooncontroleResponse } from '../../model/looncontrole-response.model';
import { Looncontrole } from '../../model/looncontrole.model';
import { ApiClient } from '../api-client.service';

@Injectable()
export class BedrijfsgegevensService {

    constructor(private api: ApiClient) { }

    getWerkgever(): Observable<Werkgever> {
        return this.api.get<Werkgever>('werkgevers');
    }

    getWerkgeverByLoonheffingsnummer(loonheffingsnummer: string): Observable<Werkgever> {
        return this.api.get<Werkgever>('werkgevers/loonheffingsnummer?loonheffingsnummer=' + loonheffingsnummer);
    }

    getBedrijfsgegevens(): Observable<Werkgever> {
        return this.api.get<Werkgever>('bedrijfsgegevens');
    }

    getWerkgeverRekeningGegevens(): Observable<Werkgever> {
        return this.api.get<Werkgever>('bedrijfsgegevens/rekeninggegevens');
    }

    getWerkgeverMachtigingen(): Observable<Werkgever> {
        return this.api.get<Werkgever>('machtigingen');
    }

    getGemachtigden(): Observable<GemachtigdenOverzichtResponse> {
        return this.api.get<GemachtigdenOverzichtResponse>('machtigingen/gemachtigden');
    }

    getGemachtigdenRecht(): Observable<string> {
        return this.api.get<string>('machtigingen/gemachtigden/recht');
    }

    saveWerkgeverMachtigingen(machtigingen: Machtiging[]): Observable<Machtiging[]> {
        return this.api.post<Machtiging[]>('machtigingen', machtigingen);
    }

    saveWerkgeverAdressen(correspondentieadres: Adres, factuuradres: Adres): Observable<Adres[]> {
        return this.api.put<Adres[]>('bedrijfsgegevens/adressen/', { correspondentieadres, factuuradres});
    }

    getLooncontrole(relatienummer: string): Observable<LooncontroleResponse> {
        return this.api.get<LooncontroleResponse>(`werkgevers/${relatienummer}/looncontrole`);
    }

    startLooncontrole(aanhef: string, achternaam: string, emailadres: string, looncontrole: Looncontrole): Observable<Looncontrole> {
        return this.api.post<Looncontrole>(`werkgevers/${looncontrole.relatienummer}/looncontrole`, {
            aanhef,
            achternaam,
            emailadres,
            looncontrole,
        });
    }

    sluitLooncontrole(looncontrole: Looncontrole): Observable<Looncontrole> {
        return this.api.put<Looncontrole>(`werkgevers/${looncontrole.relatienummer}/looncontrole`, looncontrole);
    }
}
