import {
    DeelnameReden,
    Fonds,
    Kerngegevens,
} from '.';

export class Regeling {
    regelingNummer: number;
    sectorNummer?: number;
    omschrijving: string;
    fonds: Fonds;
    begin?: Date;
    eind?: Date;
    afwijkendeVoorwaarden: boolean;
    voorwaarden: string;
    kenmerk?: string;
    variant?: string;
    deelnameReden?: DeelnameReden;
    kerngegevens?: Kerngegevens;
}
