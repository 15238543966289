import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import {
    Bedrijfsuitoefening,
    Dienstverband,
    Functie,
} from '../../model';
import {
    Constants,
    Meldingen,
    UtilService,
    Validaties,
} from '../../shared';
import { BufuService } from '../../shared/services/bufu.service';
import { DienstverbandService } from '../../shared/services/dienstverband.service';
import { MyDatePickerValidators } from '../../shared/validators/mydatepicker.validator';
import { IKeyValuePair } from './key-value-pair';
import { IMutatieFormColumn } from './mutatie-form-column';
import { IMutatieFormConfig } from './mutatie-form-config';
import { MutatieFormComponent } from './mutatie-form.component';
import { MutatieFormService } from './mutatie-form.service';
import { INieuwFormulierVerzoek } from './nieuwFormulierVerzoek';

@Component({
    styles: ['input.ng-invalid.ng-dirty { border: red 0.5px solid; }',
        'select.form-control { width: 150px; }'],
    template: require('./default-mutatie-form.html'),
})
export class FunctieWijzigenComponent {

    @ViewChild(MutatieFormComponent)
    private mutatieForm: MutatieFormComponent;
    private upaStartdatum: string;
    private titel: string = 'Functiegroep aanpassen';

    private columns: IMutatieFormColumn[] = [
        { titel: 'Naam', key: 'naam', controlType: 'display' },
        { titel: 'Geslacht', key: 'geslacht', controlType: 'display' },
        { titel: 'Geboortedatum', key: 'geboortedatum', controlType: 'displayDate' },
        { titel: 'BSN', key: 'bsn', controlType: 'display' },
        { titel: 'Begindatum', key: 'inDienst', controlType: 'displayDate' },
        { titel: 'Einddatum', key: MutatieFormService.uitDienstFormcontrolName, controlType: 'displayDate' },
        { titel: 'Ingangsdatum', key: MutatieFormService.bufuVanafFormcontrolName, controlType: 'editDate', cssClasses: ['width-200'] },
        {
            titel: 'Bedrijfsuitoefening', key: MutatieFormService.buCodeFormcontrolName, controlType: 'contextualDropdown',
            context: 'bedrijfsuitoefeningen',
        },
        {
            titel: 'Functiegroep', key: MutatieFormService.fuCodeFormcontrolName, controlType: 'contextualDropdown',
            context: 'functies',
        },
    ];
    private config: IMutatieFormConfig = {
        lopendVerzoek: false,
        validatieMeldingen: {
            required: Validaties.ALLE_VERPLICHT,
            lessOrSameAsMaxDate: Validaties.DATUM_VOOR_OF_IN_HUIDIG_JAAR,
            greaterOrSameAsMinDate: Validaties.DATUM_GROTER_OF_GELIJK_AAN_01_01_2014,
            greaterOrSameAs: Validaties.DATUM_GROTER_OF_GELIJK_AAN_BEGIN,
            lessOrSameAs: Validaties.DATUM_KLEINER_OF_GELIJK_AAN_EIND,
            lessThan: Validaties.DATUM_VOOR_UPA_STARTDATUM,
        },
        waarschuwingColspan: 6,
        isBewerkbaar(regel: AbstractControl): boolean {
            return !!regel.get(MutatieFormService.dienstverbandFormcontrolName);
        },
        getFoutmelding(regel: AbstractControl): string {
            const self = this;
            function getFout(control: AbstractControl): string {
                if ((control.touched || control.dirty) && control.errors) {
                    const required = 'required';

                    if (Object.keys(control.errors).find((key) => key === required)) {
                        return self.validatieMeldingen[required];
                    }
                    return Object.keys(control.errors).map((key) => self.validatieMeldingen[key]).join(' ');
                }
                return '';
            }

            const buCode = regel.get(MutatieFormService.buCodeFormcontrolName);
            const fuCode = regel.get(MutatieFormService.fuCodeFormcontrolName);
            const bufuVanaf = regel.get(MutatieFormService.bufuVanafFormcontrolName);

            return `${getFout(buCode)} ${getFout(fuCode)} ${getFout(bufuVanaf)}`;
        },
    };

    constructor(private fb: FormBuilder,
        private dvbSvc: DienstverbandService,
        private bufuService: BufuService,
        private mutatieFormService: MutatieFormService,
        private utilService: UtilService) {
    }

    wijzigen(bewerkbareDienstverbanden: AbstractControl[]): void {
        const data = this.mutatieFormService.valideerWijzigingsVerzoekEnExtraheerData<Dienstverband>(this.config,
            bewerkbareDienstverbanden, this.extraheer);
        if (!data) {
            return;
        }
        this.dvbSvc.updateFuncties(data).subscribe(
            () => this.mutatieFormService.onSuccesWijziging(),
            (err) => this.mutatieFormService.onErrorWijziging(this.config));
    }

    zoekBsn(bsn: string) {
        const verzoek = this.maakVerzoekAan([bsn], false);

        this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm,
            false, false, this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
    }

    zoekOpBsns(bsns: Set<string>) {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan(Array.from(bsns), true);

            this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm,
                false, false, this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    zoekOpNaam(naam: string) {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan([], true);

            this.mutatieFormService.haalDienstverbandenObvNaam(naam, verzoek, this.mutatieForm,
                false, false, this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    private fillBedrijfsuitoefeningen(dienstverband: FormGroup, date: moment.Moment): void {
        this.bufuService.getBedrijfsuitoefeningen(date)
            .subscribe((bedrijfsuitoefeningen) => {
                const buOpties: IKeyValuePair[] = [];
                buOpties.push({ key: 'Maak uw keuze', value: '' });

                bedrijfsuitoefeningen.forEach((bu) => {
                    const opt = { key: bu.omschrijving, value: bu.code } as IKeyValuePair;
                    buOpties.push(opt);
                });

                dienstverband.get(MutatieFormService.bedrijfsuitoefeningenFormcontrolName).setValue(buOpties);
            });
    }

    private fillFuncties(dienstverband: FormGroup, functies: IKeyValuePair[], clearFuCode: boolean) {
        functies.splice(0, 0, { key: 'Maak uw keuze', value: '' });
        dienstverband.get(MutatieFormService.functiesFormcontrolName).setValue(functies);
        const fuCodeControl = dienstverband.get(MutatieFormService.fuCodeFormcontrolName);
        fuCodeControl.enable();

        if (clearFuCode) {
            fuCodeControl.setValue('');
            fuCodeControl.markAsDirty();
        }
    }

    private extraheer(group: FormGroup): Dienstverband {
        const dienstverband = group.get(MutatieFormService.dienstverbandFormcontrolName).value as Dienstverband;
        const buCode = group.get(MutatieFormService.buCodeFormcontrolName).value;
        dienstverband.bedrijfsuitoefening.code = buCode;
        dienstverband.bedrijfsuitoefening.omschrijving = group.get(MutatieFormService.bedrijfsuitoefeningenFormcontrolName).value
            .find((x: IKeyValuePair) => x.value === buCode).key;

        const fuCode = group.get(MutatieFormService.fuCodeFormcontrolName).value;
        dienstverband.functie.begin = moment.utc(group.get(MutatieFormService.bufuVanafFormcontrolName).value.formatted,
            Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();
        dienstverband.functie.code = fuCode;

        const functies = group.get(MutatieFormService.functiesFormcontrolName).value as IKeyValuePair[];
        dienstverband.functie.omschrijving = functies.find((x) => x.value === fuCode).key;

        return dienstverband;
    }

    private maakVerzoekAan(bsns: string[], overschrijf: boolean): INieuwFormulierVerzoek<Dienstverband> {
        return {
            bsns,
            items: [],
            overschrijfOudFormulier: overschrijf,
            self: this,
            mapOnbekendeBsnToFormGroup: this.mapOnbekendeBsnToFormGroup,
            mapItemToFormGroup: this.mapItemToFormGroup,
        };
    }

    private mapOnbekendeBsnToFormGroup(bsn: string, self: any): FormGroup {
        return self.fb.group({
            naam: self.fb.control(''),
            geslacht: self.fb.control(''),
            geboortedatum: self.fb.control(''),
            bsn: self.fb.control(bsn),
            inDienst: self.fb.control(''),
            uitDienst: self.fb.control(''),
            buCode: self.fb.control(''),
            bedrijfsuitoefeningen: [[]],
            functies: [[]],
            fuCode: self.fb.control(''),
            bufuVanaf: self.fb.control(''),
            bufuVanafPlaceholder: self.fb.control(''),
            waarschuwing: self.fb.control(Meldingen.GEEN_RESULTATEN_VOOR_BSN),
        });
    }

    private mapItemToFormGroup(item: Dienstverband, config: IMutatieFormConfig, self: FunctieWijzigenComponent): FormGroup {
        const buCodeControl = self.fb.control(item.bedrijfsuitoefening.code, [Validators.required]);
        const fuCodeControl = self.fb.control(item.functie.code, [Validators.required]);
        let end: string = null;
        if (item.eind) {
            end = item.eind.toString();
        }
        const validators = [
            Validators.required,
            MyDatePickerValidators.lessOrSameAsMaxDate(),
            MyDatePickerValidators.greaterOrSameAsMinDate(),
            MyDatePickerValidators.greaterOrSameAs(item.begin.toString()),
            MyDatePickerValidators.lessOrSameAs(end)];
        // blokkeer mutaties na de UPA startdatum
        if (self.upaStartdatum) {
            validators.push(MyDatePickerValidators.lessThan(self.upaStartdatum));
        }

        const bufuVanafCtrl = self.fb.control('', validators);
        const frmGrp = self.fb.group({
            dienstverband: self.fb.control(item),
            bedrijfsuitoefeningen: [[]],
            naam: [item.werknemer.volledigeNaam],
            geslacht: [item.werknemer.geslacht],
            geboortedatum: [item.werknemer.geboortedatum],
            bsn: [item.werknemer.burgerServiceNummer],
            inDienst: [item.begin],
            uitDienst: [item.eind],
            buCode: buCodeControl,
            functies: [[]],
            fuCode: fuCodeControl,
            bufuVanaf: bufuVanafCtrl,
            bufuVanafPlaceholder: self.fb.control(''),
            waarschuwing: self.fb.control(''),
        });

        self.fillBedrijfsuitoefeningen(frmGrp, moment());

        self.bufuService.getFuncties(item.bedrijfsuitoefening.code, moment())
            .map((functies: Functie[]) => {
                const opties: IKeyValuePair[] = [];
                functies.every((f) => {
                    const opt = { key: f.omschrijving, value: f.code } as IKeyValuePair;
                    opties.push(opt);
                    return true;
                });
                return opties;
            })
            .subscribe((functies: IKeyValuePair[]) => self.fillFuncties(frmGrp, functies, false));

        buCodeControl.valueChanges
            .flatMap((buCode) => {
                fuCodeControl.disable();
                const options: Functie[] = [];
                if (!buCode) {
                    return Observable.from([options]);
                }
                return self.bufuService.getFuncties(buCode, moment());
            })
            .map((functies: Functie[]) => {
                const opties: IKeyValuePair[] = [];
                functies.every((f) => {
                    const opt = { key: f.omschrijving, value: f.code } as IKeyValuePair;
                    opties.push(opt);
                    return true;
                });
                return opties;
            })
            .subscribe((functies: IKeyValuePair[]) => self.fillFuncties(frmGrp, functies, true));

        bufuVanafCtrl.valueChanges
            .subscribe((value: any) => {
                if (value) {
                    self.fillBedrijfsuitoefeningen(frmGrp, moment(value.formatted, 'DD-MM-YYYY'));
                } else {
                    self.fillBedrijfsuitoefeningen(frmGrp, moment());
                }

            });

        return frmGrp;
    }
}
