import { Component } from '@angular/core';

@Component({
    selector: 'heeft-u-vragen',
    styles: ['.attention {font-weight: bold;}'],
    template: require('./heeft-u-vragen.component.html'),
})

export class HeeftUVragenComponent {
}
