import { NgModule } from '@angular/core';

import { MyDatePickerModule } from 'mydatepicker';

import { SharedModule } from '../shared/shared.module';
import { MutatieDetailsComponent } from './detail/mutatie-details.component';
import { MutatieListFilterComponent } from './mutatie-list-filter.component';
import { MutatieListComponent } from './mutatie-list.component';
import { MutatieService } from './mutatie.service';
import { MutatiesRoutingModule } from './mutaties-routing.module';

@NgModule({
    declarations: [
        MutatieListComponent,
        MutatieDetailsComponent,
        MutatieListFilterComponent,
    ],
    exports: [],
    imports: [
        SharedModule,
        MutatiesRoutingModule,
        MyDatePickerModule],
    providers: [
        MutatieService,
    ],
})
export class MutatieModule {
}
