import { UpaAccountType } from './upa-accounttype.model';

export class UpaMandaat {
    id: number;
    relatienummer: string;
    loonheffingsnummer: string;
    partijnaam: string;
    startdatum: Date;
    einddatum?: Date;
    ingetrokken: boolean;
    serviceBureau: boolean;
    submandaten: UpaMandaat[];
    parentId: number;
}
