import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from './authentication.service';

/**
 * Guards a route against activation by an unauthenticated user
 */
@Injectable()
export class IngelogdGuard implements CanActivate {
    /**
     * Initializes a new instance of AuthenticatedGuard
     * @param authenticationService The authentication service to use for the authentication check
     * @param router                The router to use for redirecting users to the login page
     */
    constructor(private authenticationService: AuthenticationService, private router: Router) {

    }

    /**
     * Checks if the route can be activated
     * @param route
     * @param state
     * @returns Observable<boolean>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const self = this;

        const authenticatedPromise = this.authenticationService.getUser()
            .then((user) => {
                if (user != null && !user.expired) {
                    if (this.authenticationService.currentUserNeedsToResetPassword()) {
                        if (state.url.indexOf('account/wachtwoord-wijzigen') < 0) {
                            self.router.navigate(['account/wachtwoord-wijzigen']);
                        }
                        return true;
                    }
                    if (this.authenticationService.currentUserNeedsToCreateProfile()) {
                        if (state.url.indexOf('profiel/gebruikersgegevens') < 0) {
                            self.router.navigate(['profiel/gebruikersgegevens']);
                        }
                        return true;
                    }
                    if (sessionStorage.getItem('moetvoorkeurenzetten') === 'true') {
                        if (state.url.indexOf('profiel/voorkeuren') < 0) {
                            self.router.navigate(['profiel/voorkeuren']);
                        }
                        return true;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            })
            .then((authenticated) => {
                if (!authenticated) {
                    self.router.navigate(['login']);
                }
                return authenticated;
            });

        return Observable.fromPromise(authenticatedPromise);
    }
}
