import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GemachtigdeGuard } from '../authentication/gemachtigde-guard.service';
import { VoorloopschermComponent } from './voorloopscherm';

const routes: Routes = [
    { path: 'meekijken', component: VoorloopschermComponent, canActivate: [GemachtigdeGuard] },
];

export let routedComponents = [
    VoorloopschermComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class MachtigingenRoutingModule {
}
