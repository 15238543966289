import { AbstractControl, ValidatorFn } from '@angular/forms';

export class EmailValidator {

    static emailCheck(): ValidatorFn {
        const emailRegex = new RegExp(['^(([^<>()[\\]\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\.,;:\\s@\"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
            '[0-9]{1,3}\])|(([a-zA-Z\\-0-9]+\\.)+',
            '[a-zA-Z]{2,}))$'].join(''));
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== undefined && !emailRegex.test(c.value)) {
                return { email: true };
            }
            return null;
        };
    }
}
