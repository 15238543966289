import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyDate, IMyDateModel, IMyOptions } from 'mydatepicker';

import {
    Filter,
    SorteerRichting,
} from '../../../model';
import {
    Constants,
} from '../../../shared';
import { UtilService } from '../../../shared/util.service';

// tslint:disable:no-string-literal
@Component({
    selector: 'dienstverband-filter',
    template: require('./dienstverband-filter.component.html'),
})
export class DienstverbandFilterComponent implements OnInit {
    @Output() filterUpdated = new EventEmitter();
    @Output() deleteFilter = new EventEmitter();

    vanDate: IMyDate = { year: 0, month: 0, day: 0 };
    tmDate: IMyDate = { year: 0, month: 0, day: 0 };
    currentYear: number = moment().year();
    defaultFilter: Filter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        bsn: null,
        naam: null,
        tot: moment([this.currentYear, 11, 31]).toISOString(),
        van: moment([this.currentYear, 0, 1]).toISOString(),
        sorteerRichting: SorteerRichting.Ascending,
        sorteerVeld: 'naam',
    };
    filter: Filter;

    private myDatePickerOptions: IMyOptions = {
        dateFormat: Constants.DATE_DEFAULT_FORMAT,
        height: Constants.DATEPICKER_DEFAULT_HEIGHT,
        selectionTxtFontSize: Constants.DATEPICKER_DEFAULT_FONTSIZE,
        showClearDateBtn: false,
    };

    constructor(
        private utilSvc: UtilService,
        private toasterService: ToasterService,
        private route: ActivatedRoute) {
        this.filter = Object.assign({}, this.defaultFilter);
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['aantalItemsPerPagina']) {
            this.filter.aantalItemsPerPagina = this.route.snapshot.queryParams['aantalItemsPerPagina'];
            this.filter.bsn = this.route.snapshot.queryParams['bsn'];
            this.filter.naam = this.route.snapshot.queryParams['naam'];
            this.filter.paginaNummer = this.route.snapshot.queryParams['paginaNummer'];
            this.filter.sorteerRichting = this.route.snapshot.queryParams['sorteerRichting'];
            this.filter.sorteerVeld = this.route.snapshot.queryParams['sorteerVeld'];
            this.filter.van = this.route.snapshot.queryParams['van'];
            this.filter.tot = this.route.snapshot.queryParams['tot'];
            const van = moment(this.filter.van);
            this.vanDate = { year: van.year(), month: van.month() + 1, day: van.date() };
            const tot = moment(this.filter.tot);
            this.tmDate = { year: tot.year(), month: tot.month() + 1, day: tot.date() };
        } else {
            this.vanDate = { year: this.currentYear, month: 1, day: 1 };
            this.tmDate = { year: this.currentYear, month: 12, day: 31 };
        }
        this.filterUpdated.emit(this.filter);
    }

    vanDateValueChanged(event: IMyDateModel): void {
        if (event.jsdate) {
            this.filter.van = event.jsdate.toISOString();
        }
    }

    tmDateValueChanged(event: IMyDateModel): void {
        if (event.jsdate) {
            this.filter.tot = event.jsdate.toISOString();
        }
    }

    onFilter(): void {
        this.filter.paginaNummer = this.defaultFilter.paginaNummer;
        this.filterUpdated.emit(this.filter);
    }

    onWisFilter(): void {
        this.utilSvc.copyDefaultFilterValues(this.filter, this.defaultFilter);
        this.vanDate = { year: this.currentYear, month: 1, day: 1 };
        this.tmDate = { year: this.currentYear, month: 12, day: 31 };

        this.deleteFilter.emit(this.filter);
    }
}
