import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { ApiClient } from '..';
import { BinnenlandsAdres } from '../../model';

@Injectable()
export class PostcodeService {
    constructor(private api: ApiClient) { }

    zoekAdres(postcode: string, huisnummer: number): Observable<BinnenlandsAdres> {
        return this.api.get<BinnenlandsAdres>('postcodes', { postcode, huisnummer });
    }
}
