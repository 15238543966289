import { Claim } from './claim.model';

export class Account {
    id: number;
    relatienummer: string;
    username: string;
    type: string;
    enabled: boolean;
    laatsteLogin: Date;
    laatsteLoginSuccesvol: boolean;
    emailadres: string;
    datumGebruikerAangemaakt: Date;
    datumWachtwoordGewijzigd: Date;
    claims?: Claim[];
}
