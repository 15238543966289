import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import './app/assets/css/bootstrap-theme.css';
import './app/assets/css/custom.css';
import './app/assets/css/pgb-fonts.css';
import './app/assets/css/toastr.css';

import './app/assets/favicon.ico';
import './app/assets/images/ASF-logo.svg';
import './app/assets/images/header-bar.jpg';
import './app/assets/images/logo-PGB.png';
import './app/assets/images/logo.svg';
import './app/assets/images/nieuws_placeholder.png';
import './app/assets/images/Werkgeversdesk.jpg';

import './app/assets/templates/dienstverbanddatumindienstwijzigen.html';
import './app/assets/templates/dienstverbanddatumuitdienstwijzigen.html';
import './app/assets/templates/dienstverbandfunctiewijzigen.html';
import './app/assets/templates/dienstverbandverwijderen.html';
import './app/assets/templates/indienst.html';
import './app/assets/templates/looncorrectie.html';
import './app/assets/templates/ouderschapsverlofaanmelden.html';
import './app/assets/templates/ouderschapsverlofafmelden.html';
import './app/assets/templates/ouderschapsverlofpercentageswijzigen.html';
import './app/assets/templates/ouderschapsverlofperiodewijzigen.html';
import './app/assets/templates/uitdienst.html';
import './app/assets/templates/voorschot.html';

if (process.env.ENV === 'production') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
