import { Voorschot } from '.';

export class VoorschotWijziging {
    voorschot: Voorschot;
    begin: Date;
    eind: Date;
    ingangsdatum?: Date;
    reden?: string;
    bedrag?: number;
    isWijzigbaar?: boolean;
}
