import {Component, EventEmitter, Input, Output} from '@angular/core';

import { IPaginatieFilter  } from '../../model';

@Component({
    selector: 'paging',
    template: require('./paging.component.html'),
    styles: ['.page-number { font-size: 17px; padding: 5px; }',
             '.selected-page-number { padding: 5px; font-size: 17px; }',
             '.paging .pager { padding: 7px 0px; }',
             '.page-navigation { display: inline-block; }',
             '.last .glyphicon.pgb-chevron-right, .first .glyphicon.pgb-chevron-left { margin-left: -15px; }',
             'span.pager.last { margin-left: 15px; }'],
})
export class PagingComponent {
    @Input() filter: IPaginatieFilter;
    @Input() totaalItems: number = 0;
    @Input() vanAantal: number = 0;
    @Input() totAantal: number = 0;

    @Output() pageNumberChanged = new EventEmitter();

    getMaxPageNumber(): number {
        return Math.floor(this.totaalItems / this.filter.aantalItemsPerPagina) + 1;
    }

    previouspage(): void {
        this.filter.paginaNummer--;
        this.pageNumberChanged.emit(this.filter);
    }

    nextpage(): void {
        this.filter.paginaNummer++;
        this.pageNumberChanged.emit(this.filter);
    }

    goToPage(paginaNummer: number): void {
        this.filter.paginaNummer = paginaNummer;
        this.pageNumberChanged.emit(this.filter);
    }

    firstpage(): void {
        this.filter.paginaNummer = 1;
        this.pageNumberChanged.emit(this.filter);
    }

    lastpage(): void {
        this.filter.paginaNummer = Math.floor(this.totaalItems / this.filter.aantalItemsPerPagina) + 1;
        this.pageNumberChanged.emit(this.filter);
    }
}
