import { NgModule } from '@angular/core';

import {
    LooncorrectieComponent,
    LoongegevensEditComponent,
    LoongegevensPeriodesComponent,
    LoongegevensService,
    LoongegevensUploadComponent,
    routedComponents,
    WerknemersRoutingModule,
} from '.';
import { SharedModule } from '../shared/shared.module';
import { ValidateOnBlurDirective } from '../shared/validators';
import { DienstverbandFilterComponent } from './dienstverbanden/overzicht/dienstverband-filter.component';
import { DienstverbandTabelComponent } from './dienstverbanden/overzicht/dienstverband-tabel.component';
import { LooncontroleService } from './looncontrole/looncontrole.service';
import { LoongegevensFilterComponent } from './loongegevens/overzicht/loongegevens-filter.component';
import { LoongegevensTabelComponent } from './loongegevens/overzicht/loongegevens-tabel.component';
import { MutatieFormTdComponent } from './mutatieformulieren/mutatie-form-td.component';
import { MutatieFormComponent } from './mutatieformulieren/mutatie-form.component';
import { MutatieFormService } from './mutatieformulieren/mutatie-form.service';
import { OuderschapsverlofTableComponent } from './ouderschapsverlof/ouderschapsverlof-tabel.component';
import { OuderschapsverlofFilterComponent } from './ouderschapsverlof/ouderschapsverlof-filter.component';
import { AuthenticationService } from '../authentication/authentication.service';
import { OuderschapsverlofService } from '../shared/services/ouderschapsverlof.service';

@NgModule({
    imports: [
        SharedModule,
        WerknemersRoutingModule,
    ],
    declarations: [
        routedComponents,
        DienstverbandTabelComponent,
        DienstverbandFilterComponent,
        MutatieFormComponent,
        MutatieFormTdComponent,
        LooncorrectieComponent,
        LoongegevensFilterComponent,
        LoongegevensTabelComponent,
        LoongegevensEditComponent,
        LoongegevensPeriodesComponent,
        LoongegevensUploadComponent,
        ValidateOnBlurDirective,
        OuderschapsverlofFilterComponent,
        OuderschapsverlofTableComponent,
    ],
    exports: [],
    providers: [
        LoongegevensService,
        LooncontroleService,
        MutatieFormService,
        AuthenticationService,
        OuderschapsverlofService,
    ],
})
export class WerknemersModule {
}
