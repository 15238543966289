export class Validaties {
    public static ONGELDIGE_DATUM = 'Ongeldige datum.';
    public static AANHEF_VERPLICHT = 'Aanhef is verplicht.';
    public static DATUM_GROTER_OF_GELIJK_AAN_BEGIN = 'Kies een datum op of na de begindatum.';
    public static DATUM_GROTER_OF_GELIJK_AAN_BEGIN_DVB = 'Kies een datum op of na begindatum dienstverband.';
    public static DATUM_KLEINER_OF_GELIJK_AAN_EIND = 'Kies een datum vóór of op de einddatum.';
    public static DATUM_KLEINER_OF_GELIJK_AAN_EIND_DVB = 'Kies een datum vóór of op einddatum dienstverband.';
    public static DATUM_GROTER_OF_GELIJK_AAN_01_01_2014 = 'Kies een datum op of na 01-01-2014.';
    public static DATUM_VOOR_OF_IN_HUIDIG_JAAR = 'Kies een datum vóór of in het huidige jaar.';
    public static DATUM_VOOR_UPA_STARTDATUM = 'Kies een datum voor de UPA startdatum';
    public static EMAIL_VERPLICHT = 'E-mailadres is verplicht.';
    public static ALLE_VERPLICHT = 'Alle velden zijn verplicht.';
    public static BU_FU_VERPLICHT = 'Bedrijfsuitoefening/Functie is verplicht.';
    public static FUNCTIE_VERPLICHT = 'Functie is verplicht.';
    public static GEBRUIKERSNAAM_VERPLICHT = 'Gebruikersnaam is verplicht.';
    public static GEEF_BEDRAG_0_500000 = 'Geef een bedrag op van 0 t/m 500.000.';
    public static GEEF_GEHEEL_GETAL_OP = 'Geef een geheel getal op.';
    public static GEEF_WAARDE_1_100 = 'Geef een waarde op van 1 t/m 100';
    public static GELDIG_EMAIL = 'Voer een geldig e-mailadres in.';
    public static GELDIG_TELEFOONNUMMER = 'Voer een geldig telefoonnummer in (alleen cijfers, spaties toegestaan).';
    public static PARTTIMEPERC_VOOR_VERLOF_VERPLICHT = 'Parttime percentage voor verlof is verplicht';
    public static NAAM_VERPLICHT = 'Naam is verplicht.';
    public static ONGELDIGE_WAARDE = 'Ongeldige waarde.';
    public static RECHTEN_VERPLICHT = 'Rechten is verplicht.';
    public static TELEFOONNUMMER_VERPLICHT = 'Telefoonnummer is verplicht.';
    public static URENVERLAGING_VERPLICHT = 'Urenverlaging is verplicht.';
}
