import { Component, Input, OnInit } from '@angular/core';

import * as moment from 'moment';

import {
    Kerngegevens,
    Regeling,
} from '../../model';
import { MyDateModel } from '../../shared/myDateModel.interface';

@Component({
    selector: 'regeling-kerngegevens',
    styles: ['.kerngegevens-container{ padding: 15px 15px 30px 15px; border-bottom: 1px #ddd solid;}'],
    template: require('./regeling-kerngegevens.component.html'),
})

export class RegelingKerngegevensComponent implements OnInit {
    @Input() kerngegevens: Kerngegevens[];
    @Input() fondsId: number;
    @Input() regeling: Regeling;
    componentTitle: string = 'Kerncijfers';
    selectedKerngegevensId: number;
    selectedKerngegevens: Kerngegevens;
    peilDatum: MyDateModel;

    ngOnInit(): void {
        const today = moment();
        this.peilDatum = { date: { year: today.year(), month: today.month() + 1, day: today.date() } };
        if (this.kerngegevens.length > 0) {
            this.selectedKerngegevensId = this.kerngegevens[0].regelingNummer;
            this.selectedKerngegevens = this.kerngegevens[0];
        }
    }
}
