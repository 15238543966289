import { Component, Input } from '@angular/core';

@Component({
    selector: 'accordion',
    styles: [require('./accordion.component.scss')],
    template: require('./accordion.component.html'),
})
export class AccordionComponent {
    @Input() active: boolean;
    @Input() form: boolean;
    @Input() subItem: boolean;

    toggle() {
        this.active = !this.active;
    }
}
