export class Upa {
    id: number;
    upaInzenddatum: Date;
    tijdvak: string;
    inzender: string;
    laatsteStatus: string;
    loonheffingsnummer: string;
    xmlResponseAck: string;
    xmlResponse: string;
    xml: string;
}
