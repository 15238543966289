import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import { Account } from '../../../model/account.model';
import { Profiel } from '../../../model/profiel.model';
import { Urls } from '../../../navigatie/urls';
import { EmailValidator, TelefoonnummerValidator } from '../../../shared';
import { Meldingen } from '../../../shared/meldingen';
import { Validaties } from '../../../shared/validaties';
import { SubaccountsService } from '../subaccounts.service';

@Component({
  template: require('./subaccount-detail.component.html'),
})
export class SubaccountDetailComponent implements OnInit {
  account: Account;
  rechttype: string;
  lezenRechten: boolean;
  schrijvenRechten: boolean;

  profiel: Profiel = new Profiel();
  profielLoaded: boolean = false;
  form: FormGroup;
  looncontroleForm: FormGroup;

  aanhefMessage: string;
  aanhefValidationMessages = {
    required: Validaties.AANHEF_VERPLICHT,
  };

  naamMessage: string;
  naamValidationMessages = {
    required: Validaties.NAAM_VERPLICHT,
  };

  emailadresMessage: string;
  emailadresValidationMessages = {
    email: Validaties.GELDIG_EMAIL,
    required: Validaties.EMAIL_VERPLICHT,
  };

  functieMessage: string;
  functieValidationMessages = {
    required: Validaties.FUNCTIE_VERPLICHT,
  };

  telefoonnummerMessage: string;
  telefoonnummerValidationMessages = {
    required: Validaties.TELEFOONNUMMER_VERPLICHT,
    telefoonnummer: Validaties.GELDIG_TELEFOONNUMMER,
  };

  beginJaarMessage: string;
  beginJaarValidationMessages = {
    range: 'Het ingevoerde begin jaar is niet geldig',
    required: 'Het begin jaar is verplicht',
  };

  constructor(
    private subaccountSvc: SubaccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toasterService: ToasterService) { }

  ngOnInit(): void {
    const gebruikersnaamParam = 'gebruikersnaam';

    this.form = this.fb.group({
      aanhef: this.fb.control(''),
      voornaam: this.fb.control(''),
      voorvoegsels: this.fb.control(''),
      achternaam: this.fb.control(''),
      emailadres: this.fb.control(''),
      functie: this.fb.control(''),
      telefoonnummer: this.fb.control(''),
    });

    this.looncontroleForm = this.fb.group({
      beginJaar: this.fb.control(''),
      eindJaar: this.fb.control(''),
      opleverenVoor: this.fb.control(''),
    });

    this.route.params
      .switchMap((params: Params) => this.subaccountSvc.getAccount(params[gebruikersnaamParam]))
      .subscribe((response: Account) => {
        if (!response) {
          this.toasterService.pop('error', '', 'Account niet gevonden.');
        } else {
          this.account = response;
          this.subaccountSvc.getProfiel(this.account.username)
            .subscribe(
            (result) => {
              this.profiel = result;
              this.initForm();
            },
            () => {
              this.toasterService.pop('error', '', Meldingen.FOUT_OPHALEN_GEGEVENS);
            });
          const rechtClaim = response.claims.find((x) => x.type === 'recht');
          if (rechtClaim) {
            this.rechttype = rechtClaim.value;
            if (this.rechttype === 'schrijven') {
              this.lezenRechten = true;
              this.schrijvenRechten = true;
            } else if (this.rechttype === 'lezen') {
              this.lezenRechten = true;
            }
          }
        }
      },
      (err: any) => { this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG); },
    );
  }

  activeerAccount(gebruikersnaam: string): void {
    this.subaccountSvc.activeerAccount(gebruikersnaam)
      .subscribe((x) => {
        this.toasterService.pop('success', '', 'Account is geactiveerd.');
        this.account.enabled = true;
      },
      (err) => {
        this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
      });
  }

  deactiveerAccount(gebruikersnaam: string): void {
    this.subaccountSvc.deactiveerAccount(gebruikersnaam)
      .subscribe((x) => {
        this.toasterService.pop('success', '', 'Account is gedeactiveerd.');
        this.account.enabled = false;
      },
      (err) => {
        this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
      });
  }

  onBack(): void {
    this.gaNaarAccountOverzicht();
  }

  wijzigAccounttype(): void {
    this.subaccountSvc.wijzigAccountType(this.account.username, this.rechttype)
      .subscribe((x) => {
        this.toasterService.pop('success', '', 'Account autorisatie succesvol gewijzigd');
        this.ngOnInit();
      },
      (err) => {
        this.toasterService.pop('error', '', 'Account autorisatie is niet succesvol gewijzigd');
      });
  }

  verwijderAccount(): void {
    const result = confirm(`Weet u zeker dat het account ${this.account.username} wilt verwijderen?`);
    if (!result) {
      return;
    }
    this.subaccountSvc.verwijderAccount(this.account.username)
      .subscribe((x) => {
        this.toasterService.pop('success', '', 'Account is verwijderd');
        this.gaNaarAccountOverzicht();
      },
      (err) => {
        this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
      });
  }

  private gaNaarAccountOverzicht(): void {
    this.router.navigate([Urls.SubaccountBeheer]);
  }

  initForm(): void {
    const aanhefControl = new FormControl(this.profiel.aanhef, [Validators.required]);
    const voornaamControl = new FormControl(this.profiel.voornaam, [Validators.required]);
    const voorvoegselsControl = new FormControl(this.profiel.voorvoegsels);
    const achternaamControl = new FormControl(this.profiel.achternaam, [Validators.required]);
    const emailadresControl = new FormControl(this.profiel.emailadres,
      [Validators.required, EmailValidator.emailCheck()]);
    const functieControl = new FormControl(this.profiel.functie, [Validators.required]);
    const telefoonnummerControl =
      new FormControl(this.profiel.telefoonnummer,
        [Validators.required, TelefoonnummerValidator.telefoonnummerCheck()]);

    this.form = this.fb.group({
      aanhef: aanhefControl,
      voornaam: voornaamControl,
      voorvoegsels: voorvoegselsControl,
      achternaam: achternaamControl,
      emailadres: emailadresControl,
      functie: functieControl,
      telefoonnummer: telefoonnummerControl,
    });

    aanhefControl.valueChanges.subscribe(() => {
      this.aanhefMessage = this.getValidationMessage(aanhefControl, this.aanhefValidationMessages);
    });
    voornaamControl.valueChanges.subscribe(() => {
      this.naamMessage =
        this.getValidationMessage(voornaamControl, this.naamValidationMessages);
    });
    achternaamControl.valueChanges.subscribe(() => {
      this.naamMessage =
        this.getValidationMessage(achternaamControl, this.naamValidationMessages);
    });
    emailadresControl.valueChanges.subscribe(() => {
      this.emailadresMessage =
        this.getValidationMessage(emailadresControl, this.emailadresValidationMessages);
    });
    functieControl.valueChanges.subscribe(() => {
      this.functieMessage =
        this.getValidationMessage(functieControl, this.functieValidationMessages);
    });
    telefoonnummerControl.valueChanges.subscribe(() => {
      this.telefoonnummerMessage =
        this.getValidationMessage(telefoonnummerControl, this.telefoonnummerValidationMessages);
    });
  }

  isDirty(): boolean {
    return this.profiel.aanhef !== this.form.controls.aanhef.value ||
      this.profiel.voornaam !== this.form.controls.voornaam.value ||
      (!(this.form.controls.voorvoegsels.value === '' && this.profiel.voorvoegsels === null) &&
        this.profiel.voorvoegsels !== this.form.controls.voorvoegsels.value) ||
      this.profiel.achternaam !== this.form.controls.achternaam.value ||
      this.profiel.functie !== this.form.controls.functie.value ||
      this.profiel.telefoonnummer !== this.form.controls.telefoonnummer.value ||
      this.profiel.emailadres !== this.form.controls.emailadres.value;

  }

  getValidationMessage(c: AbstractControl, validationMessages: any): string {
    if ((c.touched || c.dirty) && c.errors) {
      return Object.keys(c.errors).map((key) => validationMessages[key]).join(' ');
    }

    return '';
  }

  saveGebruikersgegevens() {
    this.profiel.aanhef = this.form.controls.aanhef.value;
    this.profiel.voornaam = this.form.controls.voornaam.value;
    this.profiel.voorvoegsels = this.form.controls.voorvoegsels.value;
    this.profiel.achternaam = this.form.controls.achternaam.value;
    this.profiel.functie = this.form.controls.functie.value;
    this.profiel.telefoonnummer = this.form.controls.telefoonnummer.value;
    this.profiel.emailadres = this.form.controls.emailadres.value;

    this.subaccountSvc.opslaanProfiel(this.profiel)
      .subscribe(
      () => {
        this.toasterService.pop('success', '', Meldingen.SUCCESSVOL_ONTVANGEN);
        this.ngOnInit();
      },
      () => {
        this.toasterService.pop('error', '', Meldingen.FOUT_TIJDENS_WIJZIGEN);
      });
  }

  updateDisplayRechten(chbSchrijvenRechten: HTMLInputElement, chbLezenRechten: HTMLInputElement): void {
    if (!chbLezenRechten.checked && chbSchrijvenRechten.checked) {
        chbLezenRechten.click();
    }

    if (chbSchrijvenRechten.checked) {
      this.rechttype = 'schrijven';
    } else if (chbLezenRechten.checked) {
      this.rechttype = 'lezen';
    } else {
      this.rechttype = '';
    }
  }
}
