import { NgModule } from '@angular/core';

import { TreeModule } from 'ng2-tree';

import { OpenstaandeWijzigingenGuard } from '../shared';
import { DocumentBeheerService } from '../shared/services/documentbeheer.service';
import { SharedModule } from '../shared/shared.module';
import { UpaAccountComponent } from './upa-account/upa-account.component';
import { UpaSoftwareLeverancierComponent } from './upa-account/upa-softwareleverancier.component';
import { UpaCommunicatieOverzichtComponent } from './upa-communicatie/upa-communicatie.component';
import { UpaDetailsXmlViewComponent } from './upa-details/upa-details-xmlview.component';
import { UpaDetailsComponent } from './upa-details/upa-details.component';
import { UpaMandaatOverzichtComponent } from './upa-mandaat/upa-mandaat-overzicht.component';
import { UpaMandaatServicebureausComponent } from './upa-mandaat/upa-mandaat-servicebureaus.component';
import { UpaOverzichtComponent } from './upa-overzicht/upa-overzicht.component';
import { UpaRoutingModule } from './upa-routing.module';
import { UpaInzenderInstellenComponent } from './upa-wizard/upa-inzender-instellen.component';
import { UpaWizardComponent } from './upa-wizard/upa-wizard.component';
import { UpaService } from './upa.service';

@NgModule({
    declarations: [
        UpaWizardComponent,
        UpaAccountComponent,
        UpaSoftwareLeverancierComponent,
        UpaDetailsXmlViewComponent,
        UpaDetailsComponent,
        UpaInzenderInstellenComponent,
        UpaOverzichtComponent,
        UpaMandaatOverzichtComponent,
        UpaMandaatServicebureausComponent,
        UpaCommunicatieOverzichtComponent,
    ],
    exports: [
    ],
    imports: [
        UpaRoutingModule,
        SharedModule,
        TreeModule,
    ],
    providers: [
        UpaService,
        OpenstaandeWijzigingenGuard,
        DocumentBeheerService,
    ],
})
export class UpaModule {
}
