import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { ApiClient } from '../shared/api-client.service';

import { InzendPeriodes, Upa, UpaAccount, UpaMandaat, UpaSoftwareLeverancier } from '../model';

@Injectable()
export class UpaService {

    constructor(private api: ApiClient, private http: Http) { }

    getUpaLijst(jaar: number): Observable<Upa[]> {
        return this.api.get<Upa[]>('upa?jaar=' + jaar);
    }

    getUpa(id: number): Observable<Upa> {
        return this.api.get<Upa>('upa/' + id);
    }

    getAccount(): Observable<UpaAccount> {
        return this.api.get<UpaAccount>('upa/account');
    }

    getAccountNaam(relatienummer: string): Observable<string> {
        return this.api.get<string>('upa/accountnaam?relatienummer=' + relatienummer);
    }

    maakAccount(): Observable<string> {
        return this.api.post<string>('upa/account', null);
    }

    opslaanEmail(acccountNaam: string, email: string) {
        return this.api.post('upa/account/email?accountNaam=' + acccountNaam + '&email=' + email, null);
    }

    getServicekantoren(): Observable<UpaAccount[]> {
        return this.api.get<UpaAccount[]>('upa/servicekantoren');
    }

    genereerSleutel(): Observable<string> {
        return this.api.put<string>('upa/sleutel', null);
    }

    opslaanMandaat(mandaat: UpaMandaat) {
        return this.api.post('upa/mandaten', mandaat);
    }

    getMandaatLijst(): Observable<UpaMandaat[]> {
        return this.api.get<UpaMandaat[]>('upa/mandaten');
    }

    intrekkenMandaat(mandaatId: number) {
        return this.api.put('upa/mandaten/' + mandaatId + '/intrekken', null);
    }

    beeindigMandaat(mandaatId: number, einddatum: Date) {
        return this.api.put('upa/mandaten/' + mandaatId + '/beeindigen', einddatum);
    }

    getUpaSoftwareLeveranciers(): Observable<UpaSoftwareLeverancier[]> {
        return this.api.get<UpaSoftwareLeverancier[]>('upa/softwareleveranciers', null);
    }

    opslaanSoftwarePakketten(accountNaam: string, softwarePakketIds: number[]) {
        let idstring = '';
        softwarePakketIds.forEach((x) => {
            idstring += '&softwarePakketIds=' + x;
        });
        return this.api.post('upa/softwarepakketten?accountNaam=' + accountNaam + idstring, null);
    }

    maakSoftwareLeverancier(naam: string) {
        return this.api.post('upa/softwareleveranciers?naam=' + naam, null);
    }

    maakSoftwarePakket(softwareLeverancierId: number, naam: string) {
        return this.api.post('upa/softwarepakketten?softwareLeverancierId=' + softwareLeverancierId + '&naam=' + naam, null);
    }
}
