import { Component, Input } from '@angular/core';
import { Document } from '../../model';
import { DocumentBeheerService } from '../../shared/services/documentbeheer.service';

import { ToasterService } from 'angular2-toaster';
import { UtilService } from '../../shared';

@Component({
    selector: 'upa-communicatie',
    template: require('./upa-communicatie.component.html'),
    styles: ['.doorkolom { width: 50% }'],
})
export class UpaCommunicatieOverzichtComponent {
    @Input() upaCommunicatieDocumenten: Document[];

    constructor(private documentBeheerService: DocumentBeheerService,
        private utilService: UtilService,
        private toasterService: ToasterService) { }

    ophalenDocument(documentId: number) {
        this.documentBeheerService.ophalenDocument(documentId)
            .subscribe((x: any) => {
                this.utilService.downloadFile(x, 'temp.pdf', 'application/pdf');
            }, (error) => {
                this.toasterService.pop('error', '', 'Er is een fout opgetreden bij het ophalen van een document.');
            });
    }
}
