import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

/**
 * Voltooid de callback voor het afmelden van de gebruiker
 */
@Component({
    template: require('./logout-callback.component.html'),
})
export class LogoutCallbackComponent implements OnInit {
    /**
     * Initialiseert een nieuwe instantie van LogoutCallbackComponent
     * @param authenticationService Authentication service voor het afhandelen van de callback
     * @param router                De router voor het doorsturen van de gebruiker naar de login pagina
     */
    constructor(private authenticationService: AuthenticationService, private router: Router) {

    }

    /**
     * Initializes the component
     */
    ngOnInit(): void {
        const self = this;
        // Remove WgpWgid and WgpWgnaam Cookies & Redirect user to signout page
        document.cookie = 'x-wgp-wgid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'x-wgp-pid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'x-wgp-wgnaam=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'passwordisreset=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'profilecreated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        this.authenticationService.signoutRedirect();
    }
}
