import {
    Bedrijfsuitoefening,
    Functie,
    Persoon,
    Werkgever,
} from '.';
import {IBsnIdentificeerbaarItem} from './bsnIdentificeerbaarItem';

export class Dienstverband implements IBsnIdentificeerbaarItem {
    dienstverbandIdentificatie?: number;
    begin: Date;
    eind?: Date;
    nummerInkomstenverhouding?: number;
    bedrijfsuitoefening: Bedrijfsuitoefening;
    functie: Functie;
    werkgever: Werkgever;
    werknemer: Persoon;
    beginOud?: Date;
    eindOud?: Date;
    loon?: number;
    parttimePercentage?: number;

    getBsn(): string {
        return this.werknemer.burgerServiceNummer;
    }
}
