import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bedrag',
})
export class BedragFormatPipe implements PipeTransform {
    transform(value: number,
              currencySign: string = '',
              decimalLength: number = 2,
              chunkDelimiter: string = '.',
              decimalDelimiter: string = ',',
              chunkLength: number = 3): string {

                if (value || value === 0) {
                    const regex = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
                    // tslint:disable-next-line:no-bitwise
                    const num = value.toFixed(Math.max(0, ~~decimalLength));
                    const result = (decimalDelimiter ? num.replace('.', decimalDelimiter) : num)
                        .replace(new RegExp(regex, 'g'), '$&' + chunkDelimiter);
                    if (currencySign) {
                        return currencySign + result;
                    } else {
                        return result;
                    }
                }
    }
}
