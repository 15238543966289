import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import * as moment from 'moment';

import {SorteerRichting} from '../../model/sorteer-richting.model';
import { Constants } from '../../shared/constants';
import { UtilService } from '../../shared/util.service';
import { FactuurFilter } from './factuur-filter.model';

@Component({
    selector: 'facturen-filter',
    template: require('./facturen-filter.component.html'),
})
export class FacturenFilterComponent implements OnInit {
    @Output() filterUpdated = new EventEmitter();

    huidigJaar: number = moment().year();
    jaren: number[] = [];
    selectedYear: number;

    defaultFilter: FactuurFilter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        fonds: '',
        soort: '',
        notanummer: null,
        openstaand: false,
        van: moment([this.huidigJaar, 0, 1]).toISOString(),
        tot: moment([this.huidigJaar, 11, 31]).toISOString(),
        sorteerRichting: SorteerRichting.Descending,
        sorteerVeld: 'datum',
    };
    filter: FactuurFilter;

    constructor() {
        this.filter = Object.assign({}, this.defaultFilter);
    }

    ngOnInit() {
        this.bouwJarenOp();
        this.selectedYear = this.huidigJaar;
        this.filterUpdated.emit(this.filter);
    }

    onFilter(): void {
        this.filter.paginaNummer = this.defaultFilter.paginaNummer;
        this.filterUpdated.emit(this.filter);
    }

    onWisFilter(): void {
        this.filter.paginaNummer = this.defaultFilter.paginaNummer;
        this.filter.aantalItemsPerPagina = this.defaultFilter.aantalItemsPerPagina;
        this.filter.van = this.defaultFilter.van;
        this.filter.tot = this.defaultFilter.tot;
        this.filter.fonds = this.defaultFilter.fonds;
        this.filter.soort = this.defaultFilter.soort;
        this.filter.notanummer = this.defaultFilter.notanummer;

        this.selectedYear = this.jaren[0];

        this.filterUpdated.emit(this.filter);
    }

    onSelectedYearChanged(): void {
        this.filter.van = moment([this.selectedYear, 0, 1]).toISOString();
        this.filter.tot = moment([this.selectedYear, 11, 31]).toISOString();
    }

    private bouwJarenOp(): void {
        for (let jaar = this.huidigJaar; jaar > this.huidigJaar - Constants.MAX_YEARS_HISTORY && jaar >= Constants.MIN_YEAR_NOTAS; jaar--) {
            this.jaren.push(jaar);
        }
    }
}
