import { Injectable } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/operator/map';

import { AuthenticationService } from '../authentication/authentication.service';
import { ApplicationSettings } from './applicationsettings.service';

/**
 * Construeert een query string voor het request
 * @param parameters    Parameters die moeten worden gecodeerd
 * @returns {string}    De querystring
 */
function asQueryString(parameters: any): string {
    const queryStringParameters = [];

    for (const key in parameters) {
        if (parameters.hasOwnProperty(key) && parameters[key] !== undefined && parameters[key] !== null) {
            queryStringParameters.push(`${encodeURI(key)}=${encodeURI(parameters[key])}`);
        }
    }

    return queryStringParameters.join('&');
}

/**
 * Deze service kan worden gebruikt om absolute URLS te genereren voor een API call
 */
@Injectable()
export class ApiRequestOptions {
    /**
     * Initialiseert een nieuwe instantie van ApiRequestOptions
     * @param settings
     * @param authenticationService
     */
    constructor(private settings: ApplicationSettings, private authenticationService: AuthenticationService) {

    }

    /**
     * Genereert een absolute url voor een API call
     * @param path              Pad naar de API operatie om aan te roepen
     * @param queryStringParams Eventuele querystring parameters die je wilt meegeven
     */
    absoluteUrlFor(path: string, queryStringParams?: any): Observable<string> {
        const self = this;
        return Observable.fromPromise(this.settings.get()).map((settings) => {
            let absoluteUrl = settings.api.serviceUrl;

            if (!absoluteUrl.endsWith('/')) {
                absoluteUrl += '/';
            }

            absoluteUrl += path;

            if (queryStringParams) {
                absoluteUrl  = absoluteUrl + '?' + asQueryString(queryStringParams);
            }

            return absoluteUrl;
        });
    }

    /**
     * Genereert request options for een geauthoriseerd request
     * @returns {Observable<R>} Observable met de request opties
     */
    authorizedRequestOptions(): Observable<RequestOptions> {
        return this.authenticationService.getUserObservable().map((user) => {
            let token = '';
            if (user && user.access_token) {
                token = user.access_token;
            }

            const headers = new Headers({
                Authorization: `Bearer ${token}`,
            });
            headers.append('X-Wgp-Wgid', this.GetRelatienummer(user));
            headers.append('X-Wgp-Pid', this.GetPartijIdentificatie(user));
            return new RequestOptions({ headers });
        });
    }

    // If user has Cookie value set Impersonated werkgeverrelatienummer, else return werkgeverrelatienummer
    GetRelatienummer(user: Oidc.User) {
        const cookieValue = this.getCookie('x-wgp-wgid');

        if (cookieValue) {
            return cookieValue;
        } else {
            return user.profile.werkgeverrelatienummer;
        }
    }

    // If user has Cookie value set Impersonated partijidentificatie, else return partijidentificatie
    GetPartijIdentificatie(user: Oidc.User) {
        const cookieValue = this.getCookie('x-wgp-pid');

        if (cookieValue) {
            return cookieValue;
        } else {
            return user.profile.partijidentificatie;
        }
    }

    getCookie(cname: string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
