import { Adres } from '.';

export class Persoon {
    relatieNummer: number;
    burgerServiceNummer: string;
    naam: string;
    voorvoegsel: string;
    voorletters: string;
    geboortedatum: Date;
    geslacht: string;
    overleden?: boolean;
    volledigeNaam: string;
    adres: Adres;
    email: string;
}
