import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeekijkenGuard } from '../authentication/meekijken-guard.service';
import { MutatieDetailsComponent } from './detail/mutatie-details.component';
import { MutatieListComponent } from './mutatie-list.component';

const routes: Routes = [
    { path: 'mutaties', component: MutatieListComponent, canActivate: [MeekijkenGuard] },
    { path: 'mutaties/:id', component: MutatieDetailsComponent, canActivate: [MeekijkenGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class MutatiesRoutingModule {
}
