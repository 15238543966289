import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import {
    DienstverbandPeriode,
    Filter,
    Looncorrectie,
    Response,
} from '../../model';
import { ApiClient } from '../api-client.service';

// tslint:disable-next-line:no-var-requires
const querystring = require('querystring');

@Injectable()
export class LoonService {

    constructor(private api: ApiClient) { }

    getLoongegevens(filter: Filter): Observable<Response<DienstverbandPeriode>> {
        return this.api.get<Response<DienstverbandPeriode>>('loongegevens', filter);
    }

    getLoongegevensByBSN(bsns: string[]): Observable<Response<DienstverbandPeriode>> {
        return this.api.post<Response<DienstverbandPeriode>>('loongegevens', { bsns });
    }

    update(looncorrecties: Looncorrectie[]): Observable<Looncorrectie[]> {
        return this.api.put<Looncorrectie[]>('loongegevens', { looncorrecties });
    }

    download(filter: Filter): Observable<Blob> {
        return this.api.getFile('loongegevens/actions/download?' + querystring.stringify(filter), 'text/csv');
    }

    getLoongegevensUitleg(sectorNummer: number): Observable<string> {
        return this.api.get<string>('uitleg/loongegevens/' + sectorNummer);
    }
}
