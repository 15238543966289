import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import {
    DeelnemerOuderschapsverlofMutatie,
    Filter,
} from '../../model';
import {
    UtilService,
    Meldingen,
} from '../../shared';

import { OuderschapsverlofService } from '../../shared/services/ouderschapsverlof.service';
import { DatePipe } from '@angular/common';

import DOMPurify = require('dompurify');

@Component({
    styles: [],
    template: require('./ouderschapsverlof-list.component.html'),
})
export class OuderschapsverlofListComponent implements OnInit {
    ouderschapsverlofMutaties: DeelnemerOuderschapsverlofMutatie[];
    filter: Filter;
    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;
    heeftSchrijvenRecht: boolean = false;

    constructor(
        private ouderschapsverlofService: OuderschapsverlofService,
        private router: Router,
        private utilService: UtilService,
        private toasterService: ToasterService) {
    }

    // tslint:disable-next-line: no-empty
    ngOnInit(): void { }

    handleFilterUpdated(filter: Filter): void {
        this.filter = filter;
        this.retrieveOuderschapverlofMutaties();
    }

    handleDeleteFilter(filter: Filter): void {
        this.filter = filter;
        this.retrieveOuderschapverlofMutaties();
    }

    handlePaging(filter: Filter) {
        this.filter = filter;
        this.retrieveOuderschapverlofMutaties();
    }

    handleSorting(filter: Filter) {
        this.filter = filter;
        this.retrieveOuderschapverlofMutaties();
    }

    onPrint(): void {
        this.filter.haalAllesOp1PaginaOp = true;
        this.ouderschapsverlofService.getOuderschapsverlof(this.filter)
            .subscribe(
                (x) => {
                    this.utilService.printHtmlContent(this.renderMutatiesAsTable(x.items), 'Overzicht Ouderschapsverlof');
                },
                (err) => {
                    let message;
                    if (err.status === 404) {
                        this.ouderschapsverlofMutaties = [];
                    } else {
                        message = Meldingen.ONBEKENDE_FOUT;
                        this.toasterService.pop('error', '', message);
                    }
                });

    }

    private renderMutatiesAsTable(mutaties: DeelnemerOuderschapsverlofMutatie[]) {
        const year = (new Date()).getFullYear();
        const yearPrev = (year - 1);
        const yearCurrent = (year);
        const yearNext = (year + 1);

        const pipe = new DatePipe('nl-NL');

        let tableHtml = '';
        tableHtml += `<table><thead><th>Naam</th><th>Geslacht</th><th>Geboortedatum</th><th>BSN</th><th>${yearPrev}</th><th>${yearCurrent}</th><th>${yearNext}</th></thead><tbody>`;
        mutaties.forEach((m) => {
            tableHtml += `<tr>`;
            tableHtml += `<td>${m.naam}</td>`;
            tableHtml += `<td>${m.geslacht}</td>`;
            tableHtml += `<td>${pipe.transform(m.dateOfBirth, 'dd-MM-yyyy')}</td>`;
            tableHtml += `<td>${m.bsn}</td>`;
            tableHtml += `<td>${m.prevYear ? 'Ja' : 'Nee'}</td>`;
            tableHtml += `<td>${m.currentYear ? 'Ja' : 'Nee'}</td>`;
            tableHtml += `<td>${m.nextYear ? 'Ja' : 'Nee'}</td>`;
            tableHtml += `</tr>`;
        });
        tableHtml += '</tbody><table>';
        return DOMPurify.sanitize(tableHtml);
    }

    onDownload(): void {
        this.filter.haalAllesOp1PaginaOp = true;
        this.ouderschapsverlofService.download(this.filter)
            .subscribe((d) => this.utilService.downloadFile(d, 'OverzichtDienstverbanden.csv'));
    }

    private retrieveOuderschapverlofMutaties(): void {
        this.ouderschapsverlofService.getOuderschapsverlof(this.filter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.ouderschapsverlofMutaties = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
                this.filter.paginaNummer = x.paginaIndex;
                this.filter.aantalItemsPerPagina = x.aantalPerPagina;
                this.filter.haalAllesOp1PaginaOp = false;
            },
                (err) => {
                    let message;
                    if (err.status === 404) {
                        this.ouderschapsverlofMutaties = [];
                    } else {
                        message = Meldingen.ONBEKENDE_FOUT;
                        this.toasterService.pop('error', '', message);
                    }
                });
    }
}
