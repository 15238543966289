import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Response as PagedResponse } from '../../model';

import {
    BestandInfo,
} from '../../model/bestandinfo.model';

import { BestandsuitwisselingFilter } from '../../model/bestandsuitwisseling/bestandsuitwisseling-filter.model';
import { ApiClient } from '../api-client.service';

@Injectable()
export class BestandsuitwisselingService {

    constructor(private api: ApiClient) { }

    getBestanden(filter: BestandsuitwisselingFilter): Observable<PagedResponse<BestandInfo>> {
        return this.api.get<PagedResponse<BestandInfo>>('bestandsuitwisseling/bestanden', filter);
    }

    getGeuploadeBestanden(filter: BestandsuitwisselingFilter): Observable<PagedResponse<BestandInfo>> {
        return this.api.get<PagedResponse<BestandInfo>>('bestandsuitwisseling/geuploadebestanden', filter);
    }

    download(bestandsnaam: string, mimeType: string, action: string): Observable<Blob> {
        return this.api.getFile('bestandsuitwisseling/download?bestandsnaam=' + bestandsnaam + '&actie=' + action, mimeType);
    }

    upload(file: File, opmerking: string = ''): Observable<Response> {
        return this.api.postFormData<Response>('bestandsuitwisseling/upload', { opmerking }, file);
    }
}
