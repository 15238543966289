import {
    Bedrijfsuitoefening,
    Functie,
} from '.';

export class BuFu {
    bedrijfsuitoefening: Bedrijfsuitoefening;
    functie: Functie;
    begin: Date;
    eind: Date;
}
