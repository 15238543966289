import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import {
    Adres,
    AdresType,
    BinnenlandsAdres,
    Werkgever,
} from '../../../model';
import {
    BedrijfsgegevensService,
    Meldingen,
} from '../../../shared';
import { ModalService } from '../../../shared/modal/modal.service';

@Component({
    template: require('./adresgegevens.component.html'),
})
export class AdresgegevensComponent implements OnInit {
    form: FormGroup;
    werkgever: Werkgever;
    vestigingsadres: BinnenlandsAdres;
    correspondentieadres: BinnenlandsAdres;
    oldCorrespondentieAdres: BinnenlandsAdres;
    factuuradres: BinnenlandsAdres;
    oldFactuurAdres: BinnenlandsAdres;

    displayVestigingForFactuur: boolean;
    displayVestigingForCor: boolean;

    constructor(
        private router: Router,
        private bedrijfsgegevensService: BedrijfsgegevensService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private modalService: ModalService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            afwijkendFactuuradres: ['', Validators.required],
            afwijkendCorrespondentieadres: ['', Validators.required],
        });
        this.bedrijfsgegevensService.getBedrijfsgegevens()
            .subscribe((result) => {
                this.werkgever = result;
                this.vestigingsadres = result.adressen.find((x) => x.adresType === AdresType.vestigingsadres);
                this.correspondentieadres = result.adressen.find((x) => x.adresType === AdresType.correspondentieadres);
                this.oldCorrespondentieAdres = Object.assign({}, this.correspondentieadres);
                this.factuuradres = result.adressen.find((x) => x.adresType === AdresType.factuuradres);
                this.oldFactuurAdres = Object.assign({}, this.factuuradres);
                this.displayVestigingForCor = !this.isAfwijkend(this.correspondentieadres);
                this.displayVestigingForFactuur = !this.isAfwijkend(this.factuuradres);
                this.form.patchValue({ afwijkendCorrespondentieadres: !this.displayVestigingForCor ? 'Ja' : 'Nee' });
                this.form.patchValue({ afwijkendFactuuradres: !this.displayVestigingForFactuur ? 'Ja' : 'Nee' });

                this.subscribeToFormControl('afwijkendCorrespondentieadres', () => this.setAfwijkendCorrespondentieadresMessage());
                this.subscribeToFormControl('afwijkendFactuuradres', () => this.setAfwijkendFactuuradresMessage());
            },
            () => { this.toasterService.pop('error', '', Meldingen.FOUT_OPHALEN_GEGEVENS); });
    }

    cancel() {
        this.router.navigate(['profiel/bedrijfsgegevens']);
    }

    isDirty(): boolean {
        return this.correspondentieadres === null || this.factuuradres === null ||
            JSON.stringify(this.oldCorrespondentieAdres) !== JSON.stringify(this.correspondentieadres) ||
            JSON.stringify(this.oldFactuurAdres) !== JSON.stringify(this.factuuradres);
    }

    save() {
        const bedanktMessage: string = `Bedankt voor het doorgeven van uw nieuwe adres.
                                        Wij verwerken deze wijziging zo spoedig mogelijk.`;

        if (this.form.controls.afwijkendCorrespondentieadres.value === 'Nee') {
            this.correspondentieadres = this.vestigingsadres;
        }

        if (this.form.controls.afwijkendFactuuradres.value === 'Nee') {
            this.factuuradres = this.vestigingsadres;
        }

        this.bedrijfsgegevensService.saveWerkgeverAdressen(this.correspondentieadres, this.factuuradres)
            .subscribe(() => {
                this.modalService.createBevestiging(bedanktMessage);
                this.router.navigate(['']);
            },
            () => {
                this.toasterService.pop('error', '', Meldingen.FOUT_TIJDENS_WIJZIGEN);
            });
    }

    subscribeToFormControl(controlName: string, callBack: (value: any) => void) {
        const control = this.form.get(controlName);
        control.valueChanges.subscribe(callBack);
    }

    getAdres(afwijkend: string, type: AdresType): BinnenlandsAdres {
        if (afwijkend === 'Nee') {
            return null;
        } else if (afwijkend === 'Ja') {
            const adres = new BinnenlandsAdres();
            adres.adresType = type;
            adres.landnummer = 6030;

            return adres;
        }
    }

    setAfwijkendCorrespondentieadresMessage(): void {
        const value = this.form.get('afwijkendCorrespondentieadres').value;
        this.correspondentieadres = this.getAdres(value, AdresType.correspondentieadres);
        this.displayVestigingForCor = value === 'Nee';
    }

    setAfwijkendFactuuradresMessage(): void {
        const value = this.form.get('afwijkendFactuuradres').value;
        this.factuuradres = this.getAdres(value, AdresType.factuuradres);
        this.displayVestigingForFactuur = value === 'Nee';
    }

    isAfwijkend(adres: BinnenlandsAdres): boolean {
        return adres !== null && adres !== undefined &&
            (adres.huisnummer !== this.vestigingsadres.huisnummer ||
                adres.huisnummerToevoeging !== this.vestigingsadres.huisnummerToevoeging ||
                adres.plaats !== this.vestigingsadres.plaats ||
                adres.postcode !== this.vestigingsadres.postcode ||
                adres.straat !== this.vestigingsadres.straat);
    }
}
