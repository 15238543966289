import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify = require('dompurify');

@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

 public transform(value: any, type: string): any {
    const sanitizedContent = DOMPurify.sanitize(value, {ALLOWED_TAGS: [ 'b', 'i', 'em', 'strong', 'p', 'u']});
     return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
