import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyDate, IMyDateModel, IMyOptions } from 'mydatepicker';

import {
    MutatieFilter,
    SorteerRichting,
} from '../model';
import {
    Constants,
} from '../shared';

// tslint:disable:no-string-literal
@Component({
    selector: 'mutatielist-filter',
    template: require('./mutatie-list-filter.component.html'),
    styles: ['.form-horizontal .control-label { text-align: right }'],
})
export class MutatieListFilterComponent implements OnInit {
    @Output() filterUpdated = new EventEmitter();
    @Output() deleteFilter = new EventEmitter();

    vanDate: IMyDate;
    tmDate: IMyDate;
    ingangsdatum: IMyDate;

    defaultFilter: MutatieFilter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        bsn: null,
        naam: null,
        tot: null,
        van: null,
        ingangsdatum: null,
        mutatieType: null,
        sorteerRichting: SorteerRichting.Ascending,
        sorteerVeld: 'naam',
    };
    filter: MutatieFilter;

    private myDatePickerOptions: IMyOptions = {
        dateFormat: Constants.DATE_DEFAULT_FORMAT,
        height: Constants.DATEPICKER_DEFAULT_HEIGHT,
        selectionTxtFontSize: Constants.DATEPICKER_DEFAULT_FONTSIZE,
        showClearDateBtn: false,
    };

    constructor(
        private toasterService: ToasterService,
        private route: ActivatedRoute) {
        this.filter = Object.assign({}, this.defaultFilter);
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['aantalItemsPerPagina']) {
            this.filter.aantalItemsPerPagina = this.route.snapshot.queryParams['aantalItemsPerPagina'];
            this.filter.bsn = this.route.snapshot.queryParams['bsn'];
            this.filter.naam = this.route.snapshot.queryParams['naam'];
            this.filter.paginaNummer = this.route.snapshot.queryParams['paginaNummer'];
            this.filter.sorteerRichting = this.route.snapshot.queryParams['sorteerRichting'];
            this.filter.sorteerVeld = this.route.snapshot.queryParams['sorteerVeld'];
            this.filter.mutatieType = this.route.snapshot.queryParams['mutatieType'];
            this.filter.van = this.route.snapshot.queryParams['van'];
            this.filter.tot = this.route.snapshot.queryParams['tot'];
            this.filter.ingangsdatum = this.route.snapshot.queryParams['ingangsdatum'];
            const van = moment(this.filter.van);
            this.vanDate = { year: van.year(), month: van.month() + 1, day: van.date() };
            const tot = moment(this.filter.tot);
            this.tmDate = { year: tot.year(), month: tot.month() + 1, day: tot.date() };
            const ingangsdatum = moment(this.filter.ingangsdatum);
            this.ingangsdatum = { year: ingangsdatum.year(), month: ingangsdatum.month() + 1, day: ingangsdatum.date() };

            this.filterUpdated.emit(this.filter);
        }
    }

    vanDateValueChanged(event: IMyDateModel): void {
        if (event.jsdate) {
            this.filter.van = event.jsdate.toISOString();
        }
    }

    tmDateValueChanged(event: IMyDateModel): void {
        if (event.jsdate) {
            this.filter.tot = event.jsdate.toISOString();
        }
    }

    ingangsdatumDateValueChanged(event: IMyDateModel): void {
        if (event.jsdate) {
            this.filter.ingangsdatum = event.jsdate.toISOString();
        }
    }

    onFilter(): void {
        this.filter.paginaNummer = this.defaultFilter.paginaNummer;
        this.filterUpdated.emit(this.filter);
    }

    onWisFilter(): void {
        this.copyDefaultFilterValues(this.filter, this.defaultFilter);

        this.deleteFilter.emit(this.filter);
    }

    public copyDefaultFilterValues(filter: MutatieFilter, defaultFilter: MutatieFilter): void {
        filter.paginaNummer = defaultFilter.paginaNummer;
        filter.aantalItemsPerPagina = defaultFilter.aantalItemsPerPagina;
        filter.van = defaultFilter.van;
        filter.tot = defaultFilter.tot;
        filter.bsn = defaultFilter.bsn;
        filter.naam = defaultFilter.naam;
        filter.ingangsdatum = defaultFilter.ingangsdatum;
        filter.mutatieType = defaultFilter.mutatieType;
        const nullDate: IMyDate = null;
        this.vanDate = nullDate;
        this.tmDate = nullDate;
        this.ingangsdatum = nullDate;
    }
}
