import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {ToasterModule, ToasterService} from 'angular2-toaster';
import 'moment/locale/nl';

import { AccountModule } from './account/account.module';
import { AccountService } from './account/account.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AuthenticationModule } from './authentication/authentication.module';
import { BeheerModule } from './beheer/beheer.module';
import { BestandsuitwisselingModule } from './bestandsuitwisseling/bestandsuitwisseling.module';
import { ContactComponent } from './home/contact.component';
import { DitDoenWijComponent } from './home/dit-doen-wij.component';
import { DitDoetUComponent } from './home/dit-doet-u.component';
import { HomeRoutingModule, routedComponents } from './home/home.routes';
import { InternalServerErrorComponent } from './home/internal-server-error.component';
import { VeelgesteldeVragenComponent } from './home/veelgestelde-vragen.component';
import { MachtigingenModule } from './machtigingen/machtigingen.module';
import { MutatieModule } from './mutaties/mutatie.module';
import { NavigatieComponent, NavigatieService } from './navigatie';
import { PremieModule } from './premie/premie.module';
import { ProfielModule } from './profiel/profiel.module';
import { RegelingModule } from './regelingen/regeling.module';
import { SharedModule } from './shared/shared.module';
import { UpaModule } from './upa/upa.module';
import { WerknemersModule } from './werknemers/werknemers.module';
import { MeldingenService } from './home/meldingen.service';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        DitDoenWijComponent,
        DitDoetUComponent,
        VeelgesteldeVragenComponent,
        ContactComponent,
        InternalServerErrorComponent,
        routedComponents,
        NavigatieComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        AuthenticationModule,
        HomeRoutingModule,
        AccountModule,
        RegelingModule,
        MachtigingenModule,
        MutatieModule,
        PremieModule,
        WerknemersModule,
        ProfielModule,
        ToasterModule,
        BeheerModule,
        UpaModule,
        BestandsuitwisselingModule,
    ],
    providers: [
        AccountService,
        NavigatieService,
        MeldingenService,
    ],
})
export class AppModule {
}
