import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IngelogdGuard } from '../authentication/ingelogd-guard.service';
import { MeekijkenGuard } from '../authentication/meekijken-guard.service';

import { AccountAanmakenComponent } from './aanmaken/account-aanmaken.component';
import { WachtwoordResetCodeComponent, WachtwoordResetCodeSuccesComponent, WachtwoordResetComponent } from './wachtwoord-reset/';
import { WachtwoordWijzigenComponent } from './wachtwoord-wijzigen/wachtwoord-wijzigen.component';

const routes: Routes = [
    { path: 'account/wachtwoord-reset', component: WachtwoordResetComponent },
    { path: 'account/wachtwoord-reset/:resetcode', component: WachtwoordResetCodeComponent },
    { path: 'account/wachtwoord-reset-success', component: WachtwoordResetCodeSuccesComponent },
    { path: 'account/account-aanmaken', component: AccountAanmakenComponent },
    { path: 'account/wachtwoord-wijzigen', component: WachtwoordWijzigenComponent, canActivate: [IngelogdGuard] },
];

export let routedComponents = [
    AccountAanmakenComponent,
    WachtwoordResetComponent,
    WachtwoordResetCodeComponent,
    WachtwoordResetCodeSuccesComponent,
    WachtwoordWijzigenComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class AccountRoutingModule {
}
