import { Component } from '@angular/core';
import { ModalContainer } from '../modal-container.model';
import { BevestigingModalComponent } from './bevestiging-modal.component';

export type BevestigingCallback = () => any;

@Component({
    selector: 'bevestiging-callback-modal',
    styles: [require('../modal.scss')],
    template: require('./bevestiging-callback-modal.component.html'),
})
export class BevestigingCallbackModalComponent extends BevestigingModalComponent {
    public callback: BevestigingCallback;

    constructor() {
        super();
    }

    public bevestig() {
        this.callback();
        this.closeModal();
    }
}
