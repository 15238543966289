import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[roundNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RoundNumberValidatorDirective, multi: true }],
})
export class RoundNumberValidatorDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } {
    const regex = /^[\d]+$/i;
    if (control.value &&
      (!regex.test(control.value) || ((control.value as string).indexOf(',') > -1 || (control.value as string).indexOf('.') > -1))) {
      return { roundNumber: control.value };
  }
  return null;
  }
}
