import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import { Account, Response as PagedResponse } from '../../model';
import { Profiel } from '../../model';
import { ApiClient, Recht } from '../../shared';

@Injectable()
export class SubaccountsService {

    constructor(private api: ApiClient) { }

    bestaatGebruikersnaam(gebruikersnaam: string): Observable<boolean> {
        return this.api.get<boolean>('subaccounts/actions/bestaat-gebruikersnaam/' + gebruikersnaam);
    }

    getAccount(gebruikersnaam: string): Observable<Account> {
        return this.api.get<Account>('subaccounts/' + gebruikersnaam);
    }

    getProfiel(gebruikersnaam: string): Observable<Profiel> {
        return this.api.get<Profiel>('subaccounts/profiel/' + gebruikersnaam);
    }

    opslaanProfiel(profiel: Profiel): Observable<Response> {
        return this.api.put<Response>('subaccounts/profiel/' + profiel.gebruikersnaam, profiel);
    }

    getAccounts(): Observable<PagedResponse<Account>> {
        return this.api.get<PagedResponse<Account>>('subaccounts');
    }

    activeerAccount(gebruikersnaam: string): Observable<Response> {
        return this.api.post<Response>('subaccounts/actions/activeer-account', { gebruikersnaam });
    }

    deactiveerAccount(gebruikersnaam: string): Observable<Response> {
        return this.api.post<Response>('subaccounts/actions/deactiveer-account', { gebruikersnaam });
    }

    createAccount(profiel: Profiel, rechtType: string): Observable<Response> {
        return this.api.post<Response>('subaccounts', {profiel, rechtType});
    }

    verwijderAccount(gebruikersnaam: string): Observable<Response> {
        return this.api.delete('subaccounts/' + gebruikersnaam);
    }

    wijzigAccountType(gebruikersnaam: string, rechtType: string): Observable<Response> {
        return this.api.put<Response>('subaccounts', {
            gebruikersnaam,
            rechtType,
        });
    }
}
