import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[validate-onblur]',
    host: {
        '(focus)': 'onFocus($event)',
        '(blur)': 'onBlur($event)',
    },
})
export class ValidateOnBlurDirective {

    private hasFocus = false;

    constructor(public formControl: NgControl) {
    }

    // mark control pristine on focus
    onFocus($event: any) {
        this.hasFocus = true;
        this.formControl.control.valueChanges
            .filter(() => this.hasFocus)
            .subscribe(() => {
                this.formControl.control.markAsPristine();
            });
    }

    // mark control  dirty on focus lost
    onBlur($event: any) {
        this.hasFocus = false;
        if ($event.target.value === '') {
            this.formControl.control.markAsTouched();
            this.formControl.control.markAsPristine();
        } else {
            this.formControl.control.markAsDirty();
        }
    }
}
