import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../authentication/authentication.service';
import { VoorschotWijziging } from '../../model';
import { BedrijfsgegevensService } from '../../shared/services/bedrijfsgegevens.service';
import { VoorschotService } from './voorschot.service';

@Component({
    template: require('./voorschot-list.component.html'),
})
export class VoorschotListComponent implements OnInit {
    voorschotMutaties: VoorschotWijziging[];
    private heeftSchrijvenRecht = false;

    constructor(
        private voorschotService: VoorschotService,
        private authSvc: AuthenticationService,
        private bedrijfsgegevensService: BedrijfsgegevensService) { }

    ngOnInit(): void {
        this.voorschotService.getVoorschotten().subscribe((result) => this.voorschotMutaties = result);
        const isGemachtigde = (this.authSvc.currentUser.profile.accounttype === 'administratiekantoor' ||
        this.authSvc.currentUser.profile.accounttype === 'concern');
        if (isGemachtigde) {
            this.bedrijfsgegevensService.getGemachtigdenRecht()
            .subscribe((recht) => {
                if (recht === 'schrijven') {
                    this.heeftSchrijvenRecht = true;
                } else {
                    this.heeftSchrijvenRecht = false;
                }
            });
        } else {
            this.heeftSchrijvenRecht = this.authSvc.currentUser.profile.recht === 'schrijven';
        }
    }

}
