export class Kerngegevens {
    regelingNummer: number;
    begin: string;
    eind: string;
    franchise: string;
    maximumLoon: string;
    loonbegrip: string;
    premiepercentage: string;
    opbouwpercentageOuderdomspensioen: string;
    percentageSpaardeelPartnerpensioen: string;
    percentageVerzekerdPartnerpensioenOpRisicobasis: string;
    normuren: number;
    benuttingspercentage: string;
    verzekerdbedragAnw: string;
    staffelpercentage: string;
}
