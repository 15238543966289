import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { UtilService } from '../shared/util.service';

import { Mutatie, MutatieFilter, SorteerRichting } from '../model';
import { MutatieService } from './mutatie.service';

@Component({
    styles: [require('./mutatie-list.component.scss')],
    template: require('./mutatie-list.component.html'),
})
export class MutatieListComponent implements OnInit {
    mutaties: Mutatie[];
    result: string = null;

    defaultFilter: MutatieFilter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        sorteerRichting: SorteerRichting.Ascending,
        sorteerVeld: 'invoerdatum',
    };
    filter: MutatieFilter;

    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;

    constructor(
        private mutatieService: MutatieService,
        private router: Router,
        private utilService: UtilService,
        private toasterService: ToasterService) {
        this.filter = {
            aantalItemsPerPagina: this.defaultFilter.aantalItemsPerPagina,
            paginaNummer: this.defaultFilter.paginaNummer,
            sorteerRichting: this.defaultFilter.sorteerRichting,
            sorteerVeld: this.defaultFilter.sorteerVeld,
        };
    }

    ngOnInit(): void {
        this.mutaties = [];
        this.retrieveMutaties();
    }

    onSelect(mutatie: Mutatie): void {
        this.router.navigate(['/mutaties/', mutatie.id]);
    }

    onDownload(): void {
        this.mutatieService.downloadMutaties(this.filter)
                           .subscribe((d: any) => this.utilService.downloadFile(d, 'OverzichtMutaties.csv'));
    }

    handlePaging(filter: MutatieFilter) {
        this.filter = filter;
        this.retrieveMutaties();
    }

    handleFilterUpdated(filter: MutatieFilter): void {
        this.filter = filter;
        this.retrieveMutaties();
    }

    handleDeleteFilter(filter: MutatieFilter): void {
        this.filter = filter;
        this.retrieveMutaties();
    }

    private retrieveMutaties(): void {
        this.mutatieService.getMutaties(this.filter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.mutaties = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
                this.filter.paginaNummer = x.paginaIndex;
                this.filter.aantalItemsPerPagina = x.aantalPerPagina;
            },
            (err) => {
                this.toasterService.pop('error', '', `Ongeldige aanvraag`);
            });
    }
}
