import { Injectable } from '@angular/core';
import { ApiClient } from '../shared/api-client.service';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../authentication/authentication.service';
import { DigitaleNotaVoorkeur, Profiel } from '../model';

@Injectable()
export class ProfielService {
    constructor(private api: ApiClient, private authSvc: AuthenticationService) { }

    getProfiel(): Observable<Profiel> {
        const username = this.authSvc.currentUser.profile.sub;

        return this.api.get<Profiel>('accounts/' + username)
            .map((x) => {
                x.gebruikersnaam = username;
                return x;
            });
    }

    getGebruikersgegevens(username: string): Observable<Profiel> {
        return this.api.get<Profiel>('accounts/' + username);
    }

    saveProfiel(profiel: Profiel): Observable<Profiel> {
        const username = this.authSvc.currentUser.profile.sub;

        profiel.gebruikersnaam = username;

        return this.api.put<Profiel>('accounts/' + username, profiel);
    }

    saveGebruikersgegevens(profiel: Profiel): Observable<Profiel> {
        return this.api.put<Profiel>('accounts/' + profiel.gebruikersnaam, profiel);
    }

    registreerVoorkeur(digitaleNieuwsbriefIngeschakeld: boolean) {
        const username = this.authSvc.currentUser.profile.sub;

        return this.api.post('voorkeuren/registreerdigitalenieuwsbriefvoorkeur',
            { digitaleNieuwsbriefIngeschakeld, gebruikersnaam: username });
    }

    getDigitalNotePreference(): Observable<DigitaleNotaVoorkeur> {
        const werkgeverrelatienummer = this.authSvc.currentUser.profile.werkgeverrelatienummer;
        return this.api.get<DigitaleNotaVoorkeur>(`voorkeuren/raadpleegdigitalenotavoorkeur/${werkgeverrelatienummer}`);
    }

    registerDigitalNotePreference(digitaleNotaVoorkeur: DigitaleNotaVoorkeur) {
        digitaleNotaVoorkeur.relatienummer = this.authSvc.currentUser.profile.werkgeverrelatienummer;
        return this.api.post('voorkeuren/registreerdigitalenotavoorkeur', digitaleNotaVoorkeur);
    }
}
