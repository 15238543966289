import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfielService } from '../../profiel';
import { AuthenticationService } from '../authentication.service';

/**
 * Voltooid het login proces
 */
@Component({
    template: require('./authorize-callback.component.html'),
})
export class AuthorizeCallbackComponent implements OnInit {

    /**
     * Initialiseert een nieuwe instantie van AuthorizeCallbackComponent
     * @param authenticationService Authentication service voor het afhandelen van de callback
     * @param router                De router voor het redirecten van de gebruikers
     */
    constructor(private authenticationService: AuthenticationService,
        private profielService: ProfielService,
        private router: Router) {

    }

    /**
     * Initialiseert het scherm
     */
    ngOnInit(): void {
        const self = this;
        this.authenticationService.signinRedirectCallback().then((result) => {
            const user = this.authenticationService.currentUser;
            this.profielService.getProfiel()
                .subscribe((p) => {
                    // ## The following flow has been disabled because the session variable will for now default to false.
                    // ## This session variable 'moetvoorkeurenzetten' was set to redirect the user to the voorkeuren screen.
                    // if (p.digitaleNieuwsbriefIngeschakeld === true) {
                    //     sessionStorage.setItem('moetvoorkeurenzetten', 'false');
                    // } else {
                    //     sessionStorage.setItem('moetvoorkeurenzetten', 'true');
                    // }
                    sessionStorage.setItem('moetvoorkeurenzetten', 'false');
                    if (this.authenticationService.currentUserNeedsToResetPassword()) {
                        self.router.navigate(['account/wachtwoord-wijzigen']);
                    } else if (this.authenticationService.currentUserNeedsToCreateProfile()) {
                        self.router.navigate(['profiel/gebruikersgegevens']);
                    } else if (user !== null && !user.expired && user.profile.accounttype === 'pgbmedewerker') {
                        sessionStorage.setItem('moetvoorkeurenzetten', 'false');
                        self.router.navigate(['beheer']);
                    } else if (user !== null && !user.expired &&
                        (user.profile.accounttype === 'administratiekantoor' || user.profile.accounttype === 'concern')) {
                        self.router.navigate(['meekijken']);
                    } else {
                        self.router.navigate(['']);
                    }
                });
        });
    }
}
