import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeekijkenGuard } from '../authentication/meekijken-guard.service';
import { Urls } from '../navigatie';
import { RegelingListComponent } from './overzicht/regeling-list.component';

const routes: Routes = [
    { path: Urls.OverzichtRegelingen, component: RegelingListComponent, canActivate: [MeekijkenGuard] },
];

export const routedComponents = [
    RegelingListComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class RegelingRoutingModule {
}
