import { UpaInzendInkomstenverhouding } from '.';

export class UpaInzendBericht {
    id: number;
    loonheffingsnummer: string;
    berichtId: string;
    werkgeverNaam: string;
    werkgeverRelatienummer: number;
    status: string;
    begindatumTijdvak: Date;
    einddatumTijdvak: Date;
    upaInzenddatum: Date;
    inkomstenverhoudingen: UpaInzendInkomstenverhouding[];
}
