export class Urls {
    public static Bedrijfsgegevens: string = 'profiel/bedrijfsgegevens';
    public static BestandsUitwisseling: string = 'informatie/bestandsuitwisseling';
    public static Betaalwijze: string = 'premie/betaalwijze';
    public static Bestandsuitwisseling: string = 'informatie/bestandsuitwisseling';
    public static BijzondereAfspraken: string = 'regelingen/bijzondereafspraken';
    public static Buitendienst: string = 'werknemers/looncontrole';
    public static DitDoenWij: string = 'informatie/ditdoenwij';
    public static DitDoetU: string = 'informatie/ditdoetu';
    public static DoorgevenWijzigingen: string = 'werknemers/wijzigingendoorgeven';
    public static FacturenEnSpecificaties: string = 'premie/facturenenspecificaties';
    public static Gebruikersgegevens: string = 'profiel/gebruikersgegevens';
    public static InternalServerError: string = 'errors/internal-server-error';
    public static LoonopgaveDoen: string = 'werknemers/loonopgave';
    public static Machtigingen: string = 'profiel/machtigingen';
    // Zie #17543 en ADR
    // public static OpenstaandBedrag: string = 'premie/openstaandbedrag';
    public static OverzichtLoongegevens: string = 'werknemers/loongegevens';
    public static OverzichtRegelingen: string = 'upa/regelingen';
    public static OverzichtWerknemers: string = 'werknemers/dienstverbanden';
    public static SubaccountBeheer: string = 'subaccounts';
    public static SubaccountAanmaken: string = 'subaccounts/aanmaken';
    public static SubaccountDetail: string = 'subaccounts/detail';
    public static VeelgesteldeVragen: string = 'informatie/veelgesteldevragen';
    public static Voorkeuren: string = 'profiel/voorkeuren';
    public static Voorschotbedragen: string = 'premie/voorschotbedragen';
    public static Upa: string = 'upa';
    public static UpaOverzicht: string = 'upa/overzicht';
    public static UpaServiceBureaus: string = 'upa/servicebureaus';
    public static Contact: string = 'contact';
    public static Ouderschapsverlof: string = 'werknemers/ouderschapsverlof';
}
