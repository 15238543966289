export class AccountAanmakenRequest {
    accountType: string;
    rolType: string;
    rechtType: string;
    relatienummer: string;
    kvk: string;
    postcode: string;
    huisnummer: number;
    email: string;
}
