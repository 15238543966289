import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { EmailValidator } from '../../shared/validators/email.validator';
import { PostcodeValidator } from '../../shared/validators/postcode.validator';
import { AccountService } from '../account.service';
import { AccountAanmakenRequest } from './account-aanmaken.model';

@Component({
    styles: [require('../account.style.scss')],
    template: require('./account-aanmaken.component.html'),
})
export class AccountAanmakenComponent implements OnInit {
    form: FormGroup;
    isWerkgever: boolean = true;
    isTypeAccountBekend: boolean = false;

    relatienummerMessage: string;
    kvkMessage: string;
    postcodeMessage: string;
    huisnummerMessage: string;
    emailMessage: string;

    private relatienummerValidationMessages = {
        minlength: 'Vul een geldig relatienummer in.',
        maxlength: 'Vul een geldig relatienummer in.',
        required: 'Relatienummer is verplicht',
    };
    private kvkValidationMessages = {
        required: 'KvK-nummer is verplicht',
    };

    private postcodeValidationMessages = {
        required: 'Postcode is verplicht',
        postcode: 'Vul een geldige postcode',
    };

    private huisnummerValidationMessages = {
        required: 'Huisnummer is verplicht',
    };

    private emailValidationMessages = {
        required: 'E-mail is verplicht',
        email: 'Vul een geldig e-mail adres in.',
    };

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private accountService: AccountService,
        private toasterService: ToasterService) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            accounttype: this.fb.control('', [Validators.required]),
            relatienummer: this.fb.control('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
            kvk: this.fb.control('', [Validators.required]),
            postcode: this.fb.control('', [Validators.required, PostcodeValidator.postcodeCheck()]),
            huisnummer: this.fb.control('', [Validators.required]),
            email: this.fb.control('', [Validators.required, EmailValidator.emailCheck()]),
        });

        const relatienummerControl = this.form.get('relatienummer');
        relatienummerControl.valueChanges.subscribe((value) => this.setAanlsuitnummerMessage(relatienummerControl));

        const kvkControl = this.form.get('kvk');
        kvkControl.valueChanges.subscribe((value) => this.setKvkMessage(kvkControl));

        const postcodeControl = this.form.get('postcode');
        postcodeControl.valueChanges.subscribe((value) => this.setPostcodeMessage(postcodeControl));

        const huisnummerControl = this.form.get('huisnummer');
        huisnummerControl.valueChanges.subscribe((value) => this.setHuisnummerMessage(huisnummerControl));

        const emailControl = this.form.get('email');
        emailControl.valueChanges.subscribe((value) => this.setEmailMessage(emailControl));
    }

    resetForm(): void {
        this.form.controls.accounttype.setValue('');
        this.form.controls.accounttype.markAsPristine();
        this.form.controls.accounttype.markAsUntouched();
        this.form.controls.relatienummer.setValue('');
        this.form.controls.relatienummer.markAsPristine();
        this.form.controls.relatienummer.markAsUntouched();
        if (this.form.controls.kvk) {
            this.form.controls.kvk.setValue('');
            this.form.controls.kvk.markAsPristine();
            this.form.controls.kvk.markAsUntouched();
        }
        this.form.controls.postcode.setValue('');
        this.form.controls.postcode.markAsPristine();
        this.form.controls.postcode.markAsUntouched();
        this.form.controls.huisnummer.setValue('');
        this.form.controls.huisnummer.markAsPristine();
        this.form.controls.huisnummer.markAsUntouched();
        this.form.controls.email.setValue('');
        this.form.controls.email.markAsPristine();
        this.form.controls.email.markAsUntouched();
        this.form.markAsPristine();
        this.relatienummerMessage = '';
        this.kvkMessage = '';
        this.postcodeMessage = '';
        this.huisnummerMessage = '';
        this.emailMessage = '';
    }

    handleAccounttypeChange(event: any): void {
        if (event.target.value === 'Werkgever') {
            this.isWerkgever = true;
            this.isTypeAccountBekend = true;
            this.ngOnInit();
            this.form.controls.accounttype.setValue('Werkgever');
        } else if (event.target.value === '') {
            this.isTypeAccountBekend = false;
        } else {
            this.isWerkgever = false;
            this.isTypeAccountBekend = true;
            this.form.removeControl('kvk');
        }
    }

    save(): void {
        const data = new AccountAanmakenRequest();
        data.accountType = this.form.get('accounttype').value;
        if (data.accountType === 'Werkgever') {
            data.rolType = 'master';
            data.rechtType = 'schrijven';
        }
        data.relatienummer = this.form.get('relatienummer').value;
        data.kvk = this.form.get('kvk') ? this.form.get('kvk').value : '';
        data.postcode = this.form.get('postcode').value;
        data.huisnummer = this.form.get('huisnummer').value;
        data.email = this.form.get('email').value;

        this.accountService.createAccount(data)
            .subscribe(
            (result) => {
                this.toasterService.pop('success', '', 'Bedankt uw aanvraag. Wij verwerken deze zo spoedig mogelijk.' +
                                                                       'U zult een brief ontvangen op het opgegeven adres');
                this.resetForm();
            },
            (err) => {
                this.toasterService.pop('error', '', 'Er ging iets mis tijdens het aanmaken.');
            });
    }

     cancel() {
        this.router.navigate(['']);
    }

    setAanlsuitnummerMessage(c: AbstractControl): void {
        this.relatienummerMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.relatienummerMessage = Object.keys(c.errors).map((key) =>
                this.relatienummerValidationMessages[key]).join(', ');
        }
    }

    setKvkMessage(c: AbstractControl): void {
        this.kvkMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.kvkMessage = Object.keys(c.errors).map((key) =>
                this.kvkValidationMessages[key]).join(', ');
        }
    }

    setPostcodeMessage(c: AbstractControl): void {
        this.postcodeMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.postcodeMessage = Object.keys(c.errors).map((key) =>
                this.postcodeValidationMessages[key]).join(', ');
        }
    }

    setHuisnummerMessage(c: AbstractControl): void {
        this.huisnummerMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.huisnummerMessage = Object.keys(c.errors).map((key) =>
                this.huisnummerValidationMessages[key]).join(', ');
        }
    }

    setEmailMessage(c: AbstractControl): void {
        this.emailMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.emailMessage = Object.keys(c.errors).map((key) =>
                this.emailValidationMessages[key]).join(', ');
        }
    }
}
