import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';

import { LoongegevensPeriode } from '../../model';
import { Meldingen } from '../../shared';
import { ModalService } from '../../shared/modal/modal.service';
import { UtilService } from '../../shared/util.service';
import { LoongegevensService } from './loongegevens.service';

@Component({
    selector: 'loongegevens-upload',
    styles: [require('./loongegevens.scss')],
    template: require('./loongegevens-upload.component.html'),
})
export class LoongegevensUploadComponent implements OnInit {
    @Input() geselecteerdePeriode: LoongegevensPeriode;
    @Input() isAfgekeurd: boolean;
    @Input() isCorrectie: boolean = false;
    foutverslag: any;
    file: File;
    form: FormGroup;
    titel: string;
    uitleg: string;
    bestandUploadErrorMessage: string;

    constructor(
        private loongegevensService: LoongegevensService,
        private toasterService: ToasterService,
        private utilService: UtilService,
        private modalService: ModalService,
        private fb: FormBuilder,
    ) {
        this.form = this.fb.group({
            file: [],
        });
    }

    ngOnInit(): void {
        this.titel = !this.isAfgekeurd ? 'Uploaden bestand' : 'Opnieuw uploaden bestand ';
        this.uitleg = !this.isAfgekeurd ? `Heeft u het gedownloade bestand ingevuld?
                                           Of heeft u uw eigen bestand ingevuld? Dan kunt u hier uw bestand uploaden.`
            :
            `Eerder heeft u loongegevens aangeleverd die niet juist zijn.
                                           U kunt hier een nieuw bestand uploaden met de juiste loongegevens.`;
    }

    fileChange(event: any) {
        this.foutverslag = null;
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
        }
    }

    fileSubmitted() {
        if (!this.isCorrectie) {
            this.modalService.createBevestigingMetCallback('Let op!', () => {
                this.uploadFile();
            }, Meldingen.LOONOPGAVE_BEVESTIGING);
        } else {
            this.uploadFile();
        }
    }

    uploadFile() {
        if (this.file) {
            if (!this.geselecteerdePeriode) { this.geselecteerdePeriode = { begindatum: null, einddatum: null, status: null }; }
            this.loongegevensService.uploadLoongegevens({
                file: this.file,
                periode: this.geselecteerdePeriode,
                isLooncorrectie: this.isCorrectie,
            }).subscribe((x) => {
                if (x) {
                    this.modalService.createBevestiging(Meldingen.FOUT_BIJ_BESTAND_UPLOAD,
                        Meldingen.FOUTVERSLAG_DOWNLOADEN);
                    this.foutverslag = x;
                    this.bestandUploadErrorMessage = null;
                } else {
                    const melding = this.isCorrectie ? Meldingen.LOONCORRECTIE_SUCCESVOL_AANGELEVERD :
                        Meldingen.LOONOPGAVE_SUCCESVOL_AANGELEVERD;
                    this.modalService.createBevestiging(melding);

                    this.foutverslag = null;
                    this.bestandUploadErrorMessage = null;
                }
            },
                (err) => {
                    const errorMessage = this.utilService.getErrorMessage(err);
                    this.bestandUploadErrorMessage = errorMessage.Message;
                    this.foutverslag = null;
                });
        }
        this.form.reset();
    }

    downloadFoutverslag() {
        if (this.foutverslag) {
            this.utilService.downloadFile(this.foutverslag, 'Foutverslag.txt', 'text/plain');
        }
    }
}
