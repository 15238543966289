import {AbstractControl, ValidatorFn} from '@angular/forms';

export class PasswordValidator {

    static matchPassword(c: AbstractControl): boolean {
       const password = c.get('wachtwoord').value; // to get value in input tag
       const confirmPassword = c.get('bevestigWachtwoord').value; // to get value in input tag
        if (password !== confirmPassword) {
            c.get('bevestigWachtwoord').setErrors( {matchPassword: true} );
        } else {
            return null;
        }
    }

    static strength(): ValidatorFn {
        // ^                         Start anchor
        // (?=.*[A-Z])               Minimaal 1 hoofdletter.
        // (?=.*[!@#$&?_])            Minimaal 1 leesteken.
        // (?=.*[0-9])               Minimaal 1 cijfer.
        // (?=.*[a-z])               Minimaal 1 kleine letter.
        // .{8}                      Minimaal 8 karakters lang.
        // $                         End anchor.

        const passwordRegex = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&?_])(?=.*[0-9])(?=.*[a-z])[a-z0-9A-Z!@#$&?_]{8,}$');
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== undefined && !passwordRegex.test(c.value)) {
                return { strength: true };
            }
            return null;
        };
    }
}
