import { Component, Input } from '@angular/core';
import { Melding } from '../model/melding.model';
import { MeldingType } from '../model/meldingtype.model';

@Component({
    selector: 'home-melding',
    template: require('./home-melding.component.html'),
})
export class HomeMeldingComponent {
    @Input() melding: Melding;
    cssClass: string;

    ngOnInit() {
        switch (this.melding.typeMelding) {
            case MeldingType.blauw: {
                this.cssClass = 'alert-info';
                break;
            }
            case MeldingType.groen: {
                this.cssClass = 'alert-success';
                break;
            }
            case MeldingType.oranje: {
                this.cssClass = 'alert-warning';
                break;
            }
            case MeldingType.rood: {
                this.cssClass = 'alert-danger';
                break;
            }
        }
    }
}
