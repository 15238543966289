import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { ApiClient } from '..';
import { Land } from '../../model';

@Injectable()
export class LandService {
    constructor(private api: ApiClient) { }

    getLanden(): Observable<Land[]> {
        return this.api.get<Land[]>('landen');
    }
}
