import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import { Dienstverband } from '../../model/dienstverband.model';
import {
    Constants,
    DienstverbandService,
    Meldingen,
    UtilService,
    Validaties,
} from '../../shared';
import { MyDatePickerValidators } from '../../shared/validators/mydatepicker.validator';
import { IMutatieFormColumn } from './mutatie-form-column';
import { IMutatieFormConfig } from './mutatie-form-config';
import { MutatieFormComponent } from './mutatie-form.component';
import { MutatieFormService } from './mutatie-form.service';
import { INieuwFormulierVerzoek } from './nieuwFormulierVerzoek';

@Component({
    template: require('./default-mutatie-form.html'),
})
export class UitDienstComponent {
    private titel: string = 'Werknemer afmelden';
    private upaStartdatum: string;
    private displayInUitDienstUitleg: boolean = true;
    @ViewChild(MutatieFormComponent)
    private mutatieForm: MutatieFormComponent;
    private columns: IMutatieFormColumn[] = [
        { titel: 'Naam', key: 'naam', controlType: 'display' },
        { titel: 'Geslacht', key: 'geslacht', controlType: 'display' },
        { titel: 'Geboortedatum', key: 'geboortedatum', controlType: 'displayDate' },
        { titel: 'BSN', key: 'bsn', controlType: 'display' },
        { titel: 'Begindatum', key: 'inDienst', controlType: 'displayDate' },
        { titel: 'Einddatum (t/m)', key: 'uitDienst', controlType: 'editDate', cssClasses: ['width-200'] },
        { titel: 'Overleden', key: 'overleden', controlType: 'radio',
            opties: [{ key: 'Ja', value: 'true' }, { key: 'Nee', value: 'false' }] },
    ];
    private config: IMutatieFormConfig = {
        lopendVerzoek: false,
        validatieMeldingen: {
            required: Validaties.ONGELDIGE_DATUM,
            greaterOrSameAs: Validaties.DATUM_GROTER_OF_GELIJK_AAN_BEGIN,
            greaterOrSameAsMinDate: Validaties.DATUM_GROTER_OF_GELIJK_AAN_01_01_2014,
            lessOrSameAsMaxDate: Validaties.DATUM_VOOR_OF_IN_HUIDIG_JAAR,
            lessThan: Validaties.DATUM_VOOR_UPA_STARTDATUM,
        },
        waarschuwingColspan: 5,
        isBewerkbaar(regel: AbstractControl): boolean {
            const uitDienstPlaceholderControl = regel.get('uitDienstPlaceholder');
            return !!regel.get(MutatieFormService.dienstverbandFormcontrolName) &&
                uitDienstPlaceholderControl && !uitDienstPlaceholderControl.value;
        },
        getFoutmelding(regel: AbstractControl): string {
            const uitDienst = regel.get(MutatieFormService.uitDienstFormcontrolName);
            if ((uitDienst.touched || uitDienst.dirty) && uitDienst.errors) {
                const required = 'required';

                if (Object.keys(uitDienst.errors).find((key) => key === required)) {
                    return this.validatieMeldingen[required];
                }
                return Object.keys(uitDienst.errors).map((key) => this.validatieMeldingen[key]).join(' ');
            }
            return '';
        },
    };

    constructor(private fb: FormBuilder,
        private dienstverbandService: DienstverbandService,
        private mutatieFormService: MutatieFormService,
        private utilService: UtilService) {
    }

    wijzigen(bewerkbareDienstverbanden: AbstractControl[]): void {
        const data = this.mutatieFormService.valideerWijzigingsVerzoekEnExtraheerData<Dienstverband>(this.config,
            bewerkbareDienstverbanden, this.extraheerDienstverband);
        if (!data) {
            return;
        }

        this.dienstverbandService.updateUitDienst(data)
            .subscribe(() => this.mutatieFormService.onSuccesWijziging(),
                () => this.mutatieFormService.onErrorWijziging(this.config));
    }

    zoekBsn(bsn: string): void {
        const verzoek = this.maakVerzoekAan([bsn], false);
        this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm, false, false,
            this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
    }

    /**
     * Handelt een verzoek af om dienstverbanden te zoeken op basis van bsn nummers.
     * @param {Set<string>} bsns
     */
    zoekOpBsns(bsns: Set<string>): void {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan(Array.from(bsns), true);
            this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm, false, false,
                this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    /**
     * Handelt een verzoek af om dienstverbanden te zoeken op basis van naam.
     * @param {string} naam
     */
    zoekOpNaam(naam: string): void {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan([], true);
            this.mutatieFormService.haalDienstverbandenObvNaam(naam, verzoek, this.mutatieForm, false, false,
                this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    private extraheerDienstverband(group: FormGroup): Dienstverband {
        const dienstverband = group.get(MutatieFormService.dienstverbandFormcontrolName).value as Dienstverband;
        dienstverband.eind = moment.utc(group.get(MutatieFormService.uitDienstFormcontrolName).value.formatted,
            Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();
        dienstverband.werknemer.overleden = group.get(MutatieFormService.overledenFormcontrolName).value === 'true';
        return dienstverband;
    }

    private maakVerzoekAan(bsns: string[], overschrijf: boolean): INieuwFormulierVerzoek<Dienstverband> {
        return {
            self: this,
            bsns,
            items: [],
            overschrijfOudFormulier: overschrijf,
            mapOnbekendeBsnToFormGroup: this.mapOnbekendeBsnToFormGroup,
            mapItemToFormGroup: this.mapItemToFormGroup,
        };
    }

    private mapOnbekendeBsnToFormGroup(bsn: string, self: UitDienstComponent): FormGroup {
        return self.fb.group({
            naam: self.fb.control(''),
            geslacht: self.fb.control(''),
            geboortedatum: self.fb.control(''),
            bsn: self.fb.control(bsn),
            inDienst: self.fb.control(''),
            uitDienstPlaceholder: self.fb.control(''),
            uitDienst: self.fb.control(''),
            overleden: self.fb.control('false'),
            waarschuwing: self.fb.control(Meldingen.GEEN_RESULTATEN_VOOR_BSN),
        });
    }

    private mapItemToFormGroup(item: Dienstverband, config: IMutatieFormConfig, self: UitDienstComponent): FormGroup {
        const validators = [
            Validators.required,
            MyDatePickerValidators.greaterOrSameAs(item.begin.toString()),
            MyDatePickerValidators.greaterOrSameAsMinDate(),
            MyDatePickerValidators.lessOrSameAsMaxDate(),
        ];
        // blokkeer mutaties na de UPA startdatum
        if (self.upaStartdatum) {
            validators.push(MyDatePickerValidators.lessThan(self.upaStartdatum));
        }

        const uitDienstControl = self.fb.control('', validators);
        const waarschuwingControl = self.fb.control('');
        const frmGrp = self.fb.group({
            dienstverband: self.fb.control(item),
            naam: self.fb.control(item.werknemer.volledigeNaam),
            geslacht: self.fb.control(item.werknemer.geslacht),
            geboortedatum: self.fb.control(item.werknemer.geboortedatum),
            bsn: self.fb.control(item.werknemer.burgerServiceNummer),
            inDienst: self.fb.control(item.begin),
            uitDienst: uitDienstControl,
            uitDienstPlaceholder: self.fb.control(item.eind),
            overleden: self.fb.control('false'),
            waarschuwing: waarschuwingControl,
        });
        if (!config.isBewerkbaar(frmGrp)) {
            waarschuwingControl.setValue(Meldingen.DIENSTVERBAND_KAN_NIET_BEWERKEN);
            uitDienstControl.setValidators([]);
        }
        return frmGrp;
    }
}
