import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';
import {
    Administratiekantoor,
    Adres,
    AdresType,
    Concern,
    Machtiging,
    Werkgever,
} from '../../model';
import { BedrijfsgegevensService } from '../../shared/services/bedrijfsgegevens.service';

@Component({
    styles: [`label { font-weight: normal; }`],
    template: require('./machtigingen.component.html'),
})

export class MachtigingenComponent implements OnInit {
    con: Concern;
    akAdres: Adres;
    akMachtiging: Machtiging;
    conAdres: Adres;
    conMachtiging: Machtiging;
    displayMachtigingen: boolean = true;
    heeftSchrijvenRecht: boolean = false;

    initialStates: any = {};

    werkgever: Werkgever;

    constructor(private bedrijfsgegevensService: BedrijfsgegevensService,
                private router: Router,
                private authSvc: AuthenticationService,
                private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        this.bedrijfsgegevensService.getWerkgeverMachtigingen().subscribe((result) => {
            this.werkgever = result;
            if (result.administratiekantoor) {
                if (result.administratiekantoor.adressen) {
                    this.akAdres = result.administratiekantoor.adressen.find(zoekVestigingsAdres);
                }
                if (result.administratiekantoor.machtigingen) {
                    this.akMachtiging = result.administratiekantoor.machtigingen.find(
                        (x) => x.gemachtigdeRelatienummer === result.administratiekantoor.relatienummer);
                    this.setInitialStatesAkMachtiging();
                }
            }
            if (result.concern) {
                if (result.concern.adressen) {
                    this.conAdres = result.concern.adressen.find(zoekVestigingsAdres);
                }
                if (result.concern.machtigingen) {
                    this.conMachtiging = result.concern.machtigingen.find(
                        (x) => x.gemachtigdeRelatienummer === result.concern.relatienummer);
                    this.setInitialStatesConMachtiging();
                }
            }
        },
            (err) => {
                let message;
                if (err.status === 404) {
                    message = 'De machtigingen konden niet gevonden worden.';
                } else {
                    message = 'Er is een onbekende fout opgetreden.';
                }
                this.displayMachtigingen = false;
                this.toasterService.pop('error', '', message);
            });

            const isGemachtigde = (this.authSvc.currentUser.profile.accounttype === 'administratiekantoor' ||
            this.authSvc.currentUser.profile.accounttype === 'concern');
            if (isGemachtigde) {
                this.bedrijfsgegevensService.getGemachtigdenRecht()
                .subscribe((recht) => {
                    if (recht === 'schrijven') {
                        this.heeftSchrijvenRecht = true;
                    } else {
                        this.heeftSchrijvenRecht = false;
                    }
                });
            } else {
                this.heeftSchrijvenRecht = this.authSvc.currentUser.profile.recht === 'schrijven';
            }
    }

    updateDisplayRechten(src: HTMLInputElement, target: HTMLInputElement): void {
        if (!target.checked && src.checked) {
            target.click();
        }
    }

    isDirty(): boolean {
        return (this.akMachtiging && (this.akMachtiging.raadplegen !== this.initialStates.akRaadplegen ||
            this.akMachtiging.muteren !== this.initialStates.akMuteren)) ||
            (this.conMachtiging && (this.conMachtiging.raadplegen !== this.initialStates.conRaadplegen ||
                this.conMachtiging.muteren !== this.initialStates.conMuteren));
    }

    opslaan(): void {
        const machtigingen: Machtiging[] = [];
        if (this.akMachtiging) {
            machtigingen.push(this.akMachtiging);
        }
        if (this.conMachtiging) {
            machtigingen.push(this.conMachtiging);
        }
        this.bedrijfsgegevensService.saveWerkgeverMachtigingen(machtigingen).subscribe((result: Machtiging[]) => {
            this.toasterService.pop('success', '', 'De machtigingen zijn opgeslagen.');
            this.setInitialStates();
        },
            (err: any) => {
                this.toasterService.pop('error', '', 'Het is niet gelukt om de machtigingen op te slaan.');
            });
    }

    private setInitialStates(): void {
        this.setInitialStatesAkMachtiging();
        this.setInitialStatesConMachtiging();
    }

    private setInitialStatesAkMachtiging(): void {
        if (this.akMachtiging) {
            this.initialStates.akRaadplegen = this.akMachtiging.raadplegen;
            this.initialStates.akMuteren = this.akMachtiging.muteren;
        }
    }

    private setInitialStatesConMachtiging(): void {
        if (this.conMachtiging) {
            this.initialStates.conRaadplegen = this.conMachtiging.raadplegen;
            this.initialStates.conMuteren = this.conMachtiging.muteren;
        }
    }
}

function zoekVestigingsAdres(adres: Adres): boolean {
    return adres.adresType === AdresType.vestigingsadres;
}
