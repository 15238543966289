import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import {
    AccountRoutingModule,
    AccountService,
    routedComponents,
} from '.';

@NgModule({
    declarations: [
        routedComponents,
    ],
    exports: [],
    imports: [
        SharedModule,
        AccountRoutingModule,
    ],
    providers: [
        AccountService,
    ],
})
export class AccountModule {
}
