import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import * as moment from 'moment';

import { Ng2TreeSettings, TreeModel } from 'ng2-tree';

@Component({
  selector: 'upa-tree',
  template: `<tree [tree]="tree" [settings]="treeSettings"></tree>`,
})
export class UpaDetailsXmlViewComponent implements OnInit {
    @Input() xmlString: string;

    public tree: TreeModel;
    lineNumber: number = 1;

    treeSettings: Ng2TreeSettings = {
        rootIsVisible: false,
    };

    ngOnInit(): void {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(this.xmlString, 'text/xml');
        const tree = this.xmlToJson(xmlDoc);
        tree.settings = { static: true, isCollapsedOnInit: false, cssClasses:
            { collapsed: 'tree-node-collapsed', expanded: 'tree-node-expanded', leaf: 'tree-node-leaf' },
        };
        this.tree = tree;
    }

    xmlToJson(xml: any) {
        let obj: TreeModel;
        const lineNumber = this.lineNumber++;
        if (xml.hasChildNodes()) {
            const childnodes: TreeModel[] = [];
            let label: string = xml.nodeName;
            for (let i = 0; i < xml.childNodes.length; i++) {
                const item = xml.childNodes.item(i);
                if (item.childNodes.length > 0) {
                    childnodes.push(this.xmlToJson(item));
                }
                if (item.childNodes.length === 0 && item.nodeValue) {
                    let nodeValue: string;
                    if (this.isValidDate(item.nodeValue)) {
                        const datePipe = new DatePipe('nl');
                        nodeValue = datePipe.transform(item.nodeValue, 'dd-MM-yyyy, hh:mm');
                    } else {
                        nodeValue = item.nodeValue;
                    }
                    label = `${xml.nodeName}: ${nodeValue}`;
                }
            }
            if (childnodes.length > 0) {
                obj = { value: `${lineNumber}: ${label}`, children: childnodes };
            } else {
                obj = { value: `${lineNumber}: ${label}` };
            }
        }

        return obj;
    }

    isValidDate(dateString: string) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.substr(0, 10).match(regEx)) {
            return false;
        }
        const date = moment(dateString);
        return date.isValid();
      }
}
