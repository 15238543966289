import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Document } from '../model';
import { Aanleverperiodiciteit } from '../model/aanleverperiodiciteit.enum';
import { Werkgever } from '../model/werkgever.model';
import { ProfielService } from '../profiel';
import { Accounttype, ApplicationSettings, FeatureToggles, LoaderService } from '../shared';
import { Sector } from '../shared/sector';
import { BedrijfsgegevensService } from '../shared/services/bedrijfsgegevens.service';
import { DocumentBeheerService } from '../shared/services/documentbeheer.service';
import { Navigatie, Panel } from './navigatie.model';
import { Urls } from './urls';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavigatieService {
    subjectNavigation: BehaviorSubject<Navigatie>;
    promiseWerkgever: Promise<Werkgever>;
    private isZeevisser: Subject<boolean> = new Subject<boolean>();
    private recht: string;
    private magBestandenUitwisselen: boolean = false;
    private werkgever: Werkgever;

    get betreftZeevisser(): Observable<boolean> {
        return this.isZeevisser.asObservable();
    }

    constructor(private bedrijfsgegevensService: BedrijfsgegevensService,
        private authService: AuthenticationService,
        private applicationSettings: ApplicationSettings,
        private loaderService: LoaderService,
        private profielService: ProfielService) {
        this.authService.userIsBekend.subscribe(() => {
            if (this.authService.isCurrentUserMasterAccount() || this.authService.isCurrentUserWerkgeverSubaccount()) {
                this.profielService.getGebruikersgegevens(this.authService.currentUser.profile.sub)
                    .subscribe((response) => {
                        this.magBestandenUitwisselen = response.magBestandenUitwisselen;
                    });
            }
        });
    }

    getNavigatieSubject(): BehaviorSubject<Navigatie> {
        if (!this.subjectNavigation) {
            this.subjectNavigation = new BehaviorSubject<Navigatie>(null);
        }
        this.getNavigatie();

        return this.subjectNavigation;
    }

    private getNavigatie(): void {
        const moetWerkgeverGegevensWordenVerverst = this.moetWerkgeverGegevensWordenVerverst();
        if (moetWerkgeverGegevensWordenVerverst || !this.promiseWerkgever) {
            this.promiseWerkgever = this.bedrijfsgegevensService.getWerkgever().toPromise();
        }

        if (moetWerkgeverGegevensWordenVerverst || !this.werkgever) {
            if (this.authService.isCurrentUserGemachtigde()) {
                this.bedrijfsgegevensService.getGemachtigdenRecht()
                    .subscribe((recht) => {
                        this.recht = recht;
                    });
            } else {
                this.recht = this.authService.getRechtClaimOfCurrentUser();
            }

            this.promiseWerkgever
                .then((werkgever) => {

                    this.werkgever = werkgever;

                    if (this.authService.isCurrentUserApplicatiebeheerder() ||
                        this.authService.isCurrentUserMedewerkerbeheerder()) {
                        this.magBestandenUitwisselen = werkgever.magBestandenUitwisselen;
                    }

                    this.createNavigatie(werkgever).then((navigatie) => this.subjectNavigation.next(navigatie));
                }).catch(() => {
                    this.promiseWerkgever = null;
                });
        } else {
            this.createNavigatie(this.werkgever).then((navigatie) => this.subjectNavigation.next(navigatie));
        }
    }

    private createNavigatie(werkgever: Werkgever): Promise<Navigatie> {
        return this.applicationSettings.get().then((s) => {
            const isZeevisser = werkgever.sectornummer === Sector.Zeevisserij;
            this.isZeevisser.next(isZeevisser);
            return this.initialiseerNavigatie(werkgever, s.featureToggles);
        });
    }

    private moetWerkgeverGegevensWordenVerverst(): boolean {
        const currentWgid = +this.authService.getRelatienummer();
        let haalWgOp = false;
        if (!currentWgid) {
            this.werkgever = null;
            haalWgOp = true;
        } else {
            if (this.werkgever) {
                // Als wgId anders is dan currentWgid dan is er een andere
                // werkgever 'overgenomen'.
                if (this.werkgever.relatienummer !== currentWgid) {
                    this.werkgever = null;
                    haalWgOp = true;
                }
            }
        }

        return haalWgOp;
    }

    private initialiseerNavigatie(werkgever: Werkgever, featureToggles: FeatureToggles): Navigatie {
        const nav = new Navigatie();
        nav.werkgever = werkgever;
        nav.mainPanel = this.buildMijnProfielPanel(werkgever, featureToggles);
        nav.panel1 = this.buildWerknemersPanel(werkgever);
        nav.panel2 = this.buildUPAPanel(werkgever);
        nav.panel3 = this.buildPremiePanel(werkgever);
        nav.panel4 = this.buildInformatiePanel(werkgever);
        return nav;
    }

    private buildMijnProfielPanel(werkgever: Werkgever, featureToggles: FeatureToggles): Panel {
        const panel: Panel = {
            color: 'deep-sea-blue',
            faIconClass: 'pgb-profile',
            footer: {
                imgSrc: 'assets/Werkgeversdesk.jpg',
                text: `Heeft u vragen? Onze relatiebeheerders <a href="/${Urls.Contact}">helpen u graag.</a>`,
            },
            height: '600',
            links: [],
            title: 'Mijn Profiel',
        };
        panel.links.push({
            isDisabled: this.authService.isCurrentUserGemachtigde(),
            text: 'Bedrijfsgegevens',
            url: Urls.Bedrijfsgegevens,
        });
        panel.links.push({
            isDisabled: this.authService.isCurrentUserGemachtigde(),
            text: 'Machtigingen',
            url: Urls.Machtigingen,
        });
        if (this.authService.isCurrentUserMasterAccount()) {
            panel.links.push({
                isDisabled: this.authService.isCurrentUserGemachtigde(),
                text: 'Voorkeuren',
                url: Urls.Voorkeuren,
            });
        }
        if (this.authService.isCurrentUserEenWerkgeverAccounttype()) {
            panel.links.push({
                isDisabled: false,
                text: 'Gebruiker',
                url: Urls.Gebruikersgegevens,
            });
        }
        if (this.authService.isCurrentUserMasterAccount() && featureToggles.subaccounts) {
            panel.links.push({
                isDisabled: false,
                text: 'Accounts',
                url: Urls.SubaccountBeheer,
            });
        }

        return panel;
    }

    private buildWerknemersPanel(werkgever: Werkgever): Panel {
        const panel: Panel = {
            color: 'light-green',
            faIconClass: 'pgb-werknemers',
            height: '275',
            links: [],
            title: 'Werknemers',
        };

        panel.links.push({
            isDisabled: false,
            text: 'Overzicht werknemers',
            url: Urls.OverzichtWerknemers,
        });

        panel.links.push({
            isDisabled: false,
            text: 'Overzicht loongegevens',
            url: Urls.OverzichtLoongegevens,
        });
        /* pbi 43426: Overzicht oudershapsverlof mag niet meer zichtbaar zijn.
         * Onderstaande stuk code gecommentarieerd omwille van de bijhorende backend compenenten wat er nog wel in staat.
            * panel.links.push({
            *    isDisabled: false,
            *    text: 'Overzicht Ouderschapverlof',
            *    url: Urls.Ouderschapsverlof,
            *});
        */
        if (werkgever.sectornummer !== Sector.Zeevisserij) {
            panel.links.push({
                isDisabled: this.recht === 'lezen',
                text: 'Doorgeven wijzigingen',
                url: Urls.DoorgevenWijzigingen,
            });
        }

        return panel;
    }

    private buildUPAPanel(werkgever: Werkgever): Panel {
        const panel: Panel = {
            color: 'light-green',
            faIconClass: 'pgb-regelingen',
            height: '275',
            links: [
                {
                    isDisabled: false,
                    text: 'Overzicht regelingen',
                    url: Urls.OverzichtRegelingen,
                },
            ],
            title: 'UPA',
        };

        if (!this.authService.isCurrentUserWerkgeverSubaccount() && werkgever.loonheffingsnummer) {
            if (!this.authService.isCurrentUserGemachtigde()) {
                panel.links.push({
                    isDisabled: false,
                    text: 'UPA instellingen',
                    url: Urls.Upa,
                });
            }
            panel.links.push({
                isDisabled: false,
                text: 'Overzicht UPA-berichten',
                url: Urls.UpaOverzicht,
            });
        }

        return panel;
    }

    private buildPremiePanel(werkgever: Werkgever): Panel {
        const panel: Panel = {
            color: 'light-green',
            faIconClass: 'pgb-premie',
            height: '275',
            title: 'Premie',
            links: [],
        };

        // Zie #17543 en ADR
        // panel.links.push({
        //     isDisabled: false,
        //     text: 'Openstaand bedrag',
        //     url: Urls.OpenstaandBedrag,
        // });

        panel.links.push({
            isDisabled: false,
            text: 'Facturen en specificaties',
            url: Urls.FacturenEnSpecificaties,
        });

        if (werkgever.aanleverPeriodiciteit !== Aanleverperiodiciteit.maandelijks &&
            werkgever.aanleverPeriodiciteit !== Aanleverperiodiciteit.vierwekelijks &&
            (werkgever.upaAanleverwijzeStartdatum && moment(werkgever.upaAanleverwijzeStartdatum) < moment())) {
            panel.links.push({
                isDisabled: false,
                text: 'Voorschotbedragen',
                url: Urls.Voorschotbedragen,
            });
        }

        panel.links.push({
            isDisabled: false,
            text: 'Betaalwijze',
            url: Urls.Betaalwijze,
        });

        return panel;
    }

    private buildInformatiePanel(werkgever: Werkgever): Panel {
        const panel: Panel = {
            color: 'light-green',
            faIconClass: 'pgb-info',
            height: '275',
            links: [
                {
                    isDisabled: false,
                    text: 'Dit doen wij',
                    url: Urls.DitDoenWij,
                },
                {
                    isDisabled: false,
                    text: 'Dit doet u',
                    url: Urls.DitDoetU,
                },
                {
                    isDisabled: false,
                    text: 'Veelgestelde vragen',
                    url: Urls.VeelgesteldeVragen,
                },
            ],
            title: 'Informatie',
        };

        if (werkgever.heeftOpenstaandeLooncontrole) {
            panel.links.push({
                isDisabled: this.recht === 'lezen',
                text: 'Controle Buitendienst',
                url: Urls.Buitendienst,
            });
        }

        if (this.magBestandenUitwisselen) {
            panel.links.push({
                isDisabled: false,
                text: 'Bestandsuitwisseling',
                url: Urls.BestandsUitwisseling,
            });
        }

        return panel;
    }
}
