import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BodyOutputType, ToasterConfig } from 'angular2-toaster';
import { Observable } from 'rxjs/Observable';

import * as moment from 'moment';
import { AuthenticationService } from './authentication/authentication.service';
import { NavigatieService } from './navigatie/navigatie.service';
import { ProfielService } from './profiel/profiel.service';
import {
    Accounttype,
    Recht,
    Rol,
} from './shared';
import { ApplicationSettings } from './shared/applicationsettings.service';

// tslint:disable-next-line:ban-types
declare const ga: Function;

@Component({
    selector: 'wp-app',
    styles: [require('./app.component.scss')],
    template: require('./app.component.html'),
})
export class AppComponent implements OnInit {
    pageTitle: string = 'Portaal';
    logoPgbDesk: string = 'assets/logo.svg';
    kijktMeeAls: Observable<string>;
    isZeevisser: Observable<boolean>;
    magBestandenUitwisselen: Observable<boolean> = Observable.of(false);
    currentYear: number;
    toasterconfig: ToasterConfig =
        new ToasterConfig({
            showCloseButton: true,
            tapToDismiss: true,
            positionClass: 'toast-top-full-width',
            bodyOutputType: BodyOutputType.TrustedHtml,
            timeout: {
                error: 5000,
                success: 5000,
            },
            mouseoverTimerStop: true,
        });

    constructor(private router: Router,
                private authSvc: AuthenticationService,
                private applicationSettings: ApplicationSettings,
                private navSvc: NavigatieService,
                private profielService: ProfielService) {
        this.kijktMeeAls = this.authSvc.kijktMeeAls;
        this.isZeevisser = this.navSvc.betreftZeevisser;
        this.setupGoogleAnalytics(router);
    }

    ngOnInit() {
        this.currentYear = moment().year();
        this.checkIfMagBestandenUitwisselen();
    }

    checkIfMagBestandenUitwisselen() {
        this.authSvc.userIsBekend.subscribe(() => {
            if (this.isGemachtigde()) {
                this.authSvc.getUserObservable().subscribe((user) => {
                    this.profielService.getGebruikersgegevens(user.profile.sub)
                    .subscribe((response) =>
                        this.magBestandenUitwisselen = Observable.of(response.magBestandenUitwisselen));
                });
            }
        });
    }

    setupGoogleAnalytics(router: Router): void {
        // If Google Analytics is set in settings.json, then send statistics
        this.applicationSettings.get().then((settings) => {
            if (settings.general.googleAnalytics) {
                // Using Rx's built in `distinctUntilChanged ` feature to handle url change c/o @dloomb's answer
                router.events.distinctUntilChanged((previous: any, current: any) => {
                    // Subscribe to any `NavigationEnd` events where the url has changed
                    if (current instanceof NavigationEnd) {
                        return previous.url === current.url;
                    }
                    return true;
                }).subscribe((x: any) => {
                    ga('set', 'page', x.url);
                    ga('send', 'pageview');
                });
            }
        });
    }

    checkIfVoorkeurenGezetZijn(): boolean {
        return sessionStorage.getItem('moetvoorkeurenzetten') === 'false';
    }

    isApplicatiebeheerder(): boolean {
        return this.authSvc.isCurrentUserApplicatiebeheerder();
    }

    isAuthenticated(): boolean {
        return this.authSvc.currentUser !== null;
    }

    isIntern(): boolean {
        return this.authSvc.currentUser !== null && this.authSvc.currentUser.profile.accounttype === Accounttype.PGBMEDEWERKER;
    }

    heeftSchrijfRecht(): boolean {
        return this.authSvc.currentUser != null && this.authSvc.currentUser.profile.recht === Recht.SCHRIJVEN;
    }

    isWerkgever(): boolean {
        return this.authSvc.currentUser !== null && this.authSvc.currentUser.profile.accounttype === Accounttype.WERKGEVER;
    }

    isAdministratiekantoor(): boolean {
        return this.authSvc.currentUser !== null && this.authSvc.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR;
    }

    isGemachtigde(): boolean {
        return this.authSvc.currentUser !== null &&
            (this.authSvc.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR ||
                this.authSvc.currentUser.profile.accounttype === Accounttype.CONCERN);
    }

    isOvername(): boolean {
        return this.authSvc.wordtMeegegeken();
    }

    stopMeekijken(): void {
        this.authSvc.stopMeekijken();
        if (this.isGemachtigde) {
            this.router.navigate(['meekijken']);
        } else  {
            this.router.navigate(['beheer']);
        }
    }
}
