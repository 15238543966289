import {SorteerRichting} from '..';
import {IPaginatieFilter} from '../paginatie-filter.model';

export class MutatieFilter implements IPaginatieFilter {
    aantalItemsPerPagina: number;
    paginaNummer: number;
    naam?: string;
    bsn?: string;
    van?: string;
    tot?: string;
    ingangsdatum?: string;
    mutatieType?: number;
    sorteerRichting: SorteerRichting;
    sorteerVeld: string;
}
