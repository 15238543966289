import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import * as moment from 'moment';

import {
    Filter,
    Regeling,
    SorteerRichting,
} from '../../../model';
import { Aanleverperiodiciteit } from '../../../model/aanleverperiodiciteit.enum';
import { RegelingService } from '../../../regelingen';
import {
    Constants,
} from '../../../shared';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';
import { UtilService } from '../../../shared/util.service';
import { Jaar } from './jaar.model';
import { Periode } from './periode.model';

@Component({
    selector: 'loongegevens-filter',
    template: require('./loongegevens-filter.component.html'),
})
export class LoongegevensFilterComponent implements OnInit {
    @Output() filterUpdated = new EventEmitter();
    @Output() deleteFilter = new EventEmitter();

    aanleverperiodiciteit: Aanleverperiodiciteit = Aanleverperiodiciteit.jaarlijks;
    huidigJaar: number = moment().year();
    toonPeriodes: boolean = false;

    jaren: Jaar[] = null;
    regelingen: Regeling[] = [];

    selectedYear: Jaar;
    selectedPeriod: Periode;
    selectedRegelingnummer: number;

    defaultFilter: Filter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        bsn: null,
        naam: null,
        van: moment([this.huidigJaar - 1, 0, 1]).toISOString(),
        tot: moment([this.huidigJaar - 1, 11, 31]).toISOString(),
        sorteerRichting: SorteerRichting.Ascending,
        sorteerVeld: 'naam',
        regelingGeblokkeerdVoorNota: false,
        includedRegelingnummers: null,
    };
    filter: Filter;

    constructor(
        private utilSvc: UtilService,
        private bedrijfsSvc: BedrijfsgegevensService,
        private regelingService: RegelingService) {
        this.filter = Object.assign({}, this.defaultFilter);
    }

    ngOnInit() {
        this.bedrijfsSvc.getBedrijfsgegevens()
            .subscribe((werkgever) => {
                this.aanleverperiodiciteit = werkgever.aanleverPeriodiciteit;
                this.initPeriodPicker();
                this.filterUpdated.emit(this.filter);
            });

        this.regelingService.getRegelingenHistorie()
            .subscribe((regelingen) => {
                regelingen.forEach((regeling) => {
                    const regelinNummers = this.regelingen.map((r) => {
                        return r.regelingNummer;
                    });

                    if (!regelinNummers.some((element) => element === regeling.regelingNummer)) {
                        this.regelingen.push(regeling);
                    }
                });
            });
    }

    onFilter(): void {
        this.filter.paginaNummer = this.defaultFilter.paginaNummer;
        this.filter.van = this.selectedPeriod.van.toISOString();
        this.filter.tot = this.selectedPeriod.tot.toISOString();
        if (this.selectedRegelingnummer !== undefined && this.selectedRegelingnummer !== null) {
            this.filter.includedRegelingnummers = [this.selectedRegelingnummer];
        }

        this.filterUpdated.emit(this.filter);
    }

    onSelectedYearChanged(): void {
        this.selectedPeriod = this.selectedYear.periodes[0];
    }

    onWisFilter(): void {
        this.utilSvc.copyDefaultFilterValues(this.filter, this.defaultFilter);
        this.selectedYear = this.jaren[0];
        this.selectedPeriod = this.selectedYear.periodes[this.selectedYear.periodes.length - 1];
        this.selectedRegelingnummer = undefined;
        this.deleteFilter.emit(this.filter);
    }

    private initPeriodPicker(): void {
        const startJaar = (this.aanleverperiodiciteit === Aanleverperiodiciteit.onbekend ||
            this.aanleverperiodiciteit === Aanleverperiodiciteit.jaarlijks) ?
            this.huidigJaar - 1 :
            this.huidigJaar;

        const jaren = this.bouwJarenMetPeriodesOp(startJaar);

        this.selectedYear = jaren[0];
        this.selectedPeriod = this.selectedYear.periodes[this.selectedYear.periodes.length - 1];

        this.defaultFilter.van = moment(this.selectedPeriod.van).toISOString();
        this.defaultFilter.tot = moment(this.selectedPeriod.tot).toISOString();
        this.filter.van = this.defaultFilter.van;
        this.filter.tot = this.defaultFilter.tot;
        this.jaren = jaren;
    }

    private bouwJarenMetPeriodesOp(startJaar: number): Jaar[] {
        const jaren: Jaar[] = [];
        for (let jaar = startJaar; jaar > this.huidigJaar - Constants.MAX_YEARS_HISTORY && jaar >= Constants.MIN_YEAR; jaar--) {
            const periodes: Periode[] = [];
            let tot = moment([jaar, 11, 31]);
            if (moment().year() === jaar) {
                tot = moment();
            }

            periodes.push({
                naam: 'Alle',
                van: moment([jaar, 0, 1]).toDate(),
                tot: tot.toDate(),
            });

            if (this.aanleverperiodiciteit === Aanleverperiodiciteit.maandelijks) {
                this.voegPeriodesPerMaandToe(jaar, periodes);
                this.toonPeriodes = true;
            } else if (this.aanleverperiodiciteit === Aanleverperiodiciteit.vierwekelijks) {
                this.voegPeriodesPerVierWekenToe(jaar, periodes);
                this.toonPeriodes = true;
            }

            if (this.aanleverperiodiciteit === Aanleverperiodiciteit.onbekend ||
                this.aanleverperiodiciteit === Aanleverperiodiciteit.jaarlijks ||
                periodes.length > 1) {
                jaren.push({ jaar, periodes });
            }
        }
        return jaren;
    }

    private voegPeriodesPerMaandToe(jaar: number, periodes: Periode[]) {
        for (let month = 1; month <= 12; month++) {
            const begin = moment([jaar, month - 1, 1]);
            const eind = begin.clone().add(1, 'months').add(-1, 'days');
            if (moment().isBetween(begin, eind)) {
                break;
            }
            periodes.push({
                naam: begin.toDate().toLocaleString('nl-nl', { month: 'long' }),
                van: begin.toDate(),
                tot: eind.toDate(),
            });
        }
    }

    private voegPeriodesPerVierWekenToe(jaar: number, periodes: Periode[]) {
        const start = moment([jaar, 0, 1]);
        for (let periode = 0; periode < 13; periode++) {
            const begin = start.clone().add(periode * 4, 'weeks');
            if (periode > 0) {
                begin.add(1, 'days');
            }
            const eind = begin.clone().add(4, 'weeks').add(-1, 'days');
            if (moment().isBetween(begin, eind)) {
                break;
            }
            periodes.push({
                naam: (periode + 1).toString(),
                van: begin.toDate(),
                tot: eind.toDate(),
            });
        }
    }
}
