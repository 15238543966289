import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';
import { Werkgever } from '../../model';
import {
    BedrijfsgegevensService,
    NumberValidators,
} from '../../shared';

@Component({
    styles: ['input.ng-invalid.ng-dirty { border: red 0.5px solid;'],
    template: require('./voorloopscherm.component.html'),
})
export class VoorloopschermComponent implements OnInit {
    form: FormGroup;
    validatieMessage: string;

    private validatieMessages = {
        required: 'Het relatienummer is verplicht.',
        valid: 'Het ingevoerde relatienummer is geen geldig nummer.',
    };

    constructor(private router: Router,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private toasterService: ToasterService,
                private bedrijfsgegevensService: BedrijfsgegevensService,
                private authSvc: AuthenticationService) { }

    ngOnInit(): void {
        if (document.cookie.indexOf('x-wgp-wgid=') > -1) {
            this.router.navigate(['']);
        }
        this.form = this.fb.group({
            werkgeverRelatienummer: ['', [Validators.required,
                                        //   NumberValidators.range(10000000000, 99999999999),
                                          NumberValidators.validNumber()]],
        });

        const werkgeverrelatienummerControl = this.form.get('werkgeverRelatienummer');
        werkgeverrelatienummerControl.valueChanges.subscribe((value) => this.setRelatienummerMessage(werkgeverrelatienummerControl));
    }

    impersonate(): void {
        if (this.form.controls.werkgeverRelatienummer.valid) {
            const werkgeverrelatienummer = this.form.controls.werkgeverRelatienummer.value;
            document.cookie = `x-wgp-wgid=${werkgeverrelatienummer}; path=/`;
            this.bedrijfsgegevensService.getWerkgever().subscribe((werkgever: Werkgever) => {
                this.authSvc.startMeekijkenMetWerkever(werkgever).subscribe((x) => {
                    this.router.navigate(['']);
                });
            },
            (err) => {
                if (err.status === 404) {
                    this.toasterService.pop('error', '', `Opgegeven relatienummer is niet bij ons bekend.`);
                } else {
                    this.toasterService.pop('error', '', `Er is iets fout gegaan tijdens het opvragen van de werkgever.`);
                }
                this.authSvc.stopMeekijken().subscribe();
            });
        }
    }

    setRelatienummerMessage(c: AbstractControl): void {
        this.validatieMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.validatieMessage = Object.keys(c.errors).map((key) =>
                this.validatieMessages[key]).join(' ');
        }
    }

    accountOverzicht() {
        this.router.navigate(['beheer/accounts']);
    }
}
