import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../authentication/authentication.service';
import { ApiClient } from '../shared/api-client.service';
import { ApiRequestOptions } from '../shared/api-request-options.service';

import { AccountAanmakenRequest } from './aanmaken/account-aanmaken.model';

import { LoaderService } from '../shared/modal/loading/loader.service';

@Injectable()
export class AccountService {

    constructor(private http: Http,
                private requestOptions: ApiRequestOptions,
                private api: ApiClient,
                private loaderService: LoaderService) { }

    vraagWachtwoordResetAan(gebruikersnaam: string): Observable<Response> {
        const path = 'accounts/actions/wachtwoord-reset/' + gebruikersnaam;

        this.loaderService.show();

        return this.requestOptions.absoluteUrlFor(path, null)
            .flatMap((url) => {
                return this.http.get(url);
            })
            .finally(() =>  this.loaderService.hide());
    }

    vraagWachtwoordResetVoorAccountAan(gebruikersnaam: string): Observable<Response> {
        const path = 'accounts/actions/wachtwoord-reset-account/' + gebruikersnaam;

        return this.api.get<Response>(path);
    }

    resetWachtwoord(wachtwoordResetCode: string, nieuwWachtwoord: string): Observable<Response> {
        const path = 'accounts/actions/wachtwoord-reset/';

        this.loaderService.show();

        return this.requestOptions.absoluteUrlFor(path, null)
        .flatMap((url) => {
            return this.http.post(url, {
                wachtwoordResetCode,
                nieuwWachtwoord,
            });
        })
        .finally(() =>  this.loaderService.hide());
    }

    wachtwoordResetcodeIsGeldig(resetcode: string): Observable<boolean> {
        const path = 'accounts/actions/resetcodeisgeldig/' + resetcode;

        this.loaderService.show();

        return this.requestOptions.absoluteUrlFor(path, null)
        .flatMap((url) => {
            return this.http.get(url).map((x: Response) => {
                this.loaderService.hide();
                return x.json();
            });
        })
        .finally(() =>  this.loaderService.hide());
    }

    wachtwoordWijzigen(oudWachtwoord: string, nieuwWachtwoord: string): Observable<Response> {
        return this.api.post<Response>('accounts/actions/wachtwoord-wijzigen', {oudWachtwoord, nieuwWachtwoord});
    }

    createAccount(account: AccountAanmakenRequest): Observable<Response> {
        const path = 'accounts/';

        this.loaderService.show();

         return this.requestOptions.absoluteUrlFor(path, null)
        .flatMap((url) => {
            return this.http.post(url, account)
                            .finally(() =>  this.loaderService.hide());
        });
    }
}
