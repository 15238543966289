import { EventEmitter, Injectable } from '@angular/core';

import { Machtiging, Werkgever } from '../model';

import { User, UserManager } from 'oidc-client';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { Accounttype } from '../shared/accounttype';
import { ApplicationSettings } from '../shared/applicationsettings.service';
import { Recht } from '../shared/recht';
import { Rol } from '../shared/rol';

/**
 * Bevat logica voor het aanmelden/afmelden van gebruikers
 */
@Injectable()
export class AuthenticationService {
    userIsBekend: EventEmitter<boolean> = new EventEmitter();
    private userMgr: UserManager;
    private meekijkenAls: Subject<string> = new Subject<string>();
    private loggedIn = false;
    private isImpersonatie = false;

    currentUser: User = null;

    set userManager(value: UserManager) {
        this.userMgr = value;
    }

    get kijktMeeAls(): Observable<string> {
        return this.meekijkenAls.asObservable();
    }

    /**
     * Initialiseert een nieuwe instantie van de AuthenticationService
     */
    constructor(private applicationSettings: ApplicationSettings) {
        this.ensureUserManager().then((usrMgr) => usrMgr.getUser()
            .then((usr) => {
                if (usr) {
                    this.loggedIn = true;
                    this.currentUser = usr;
                    this.userIsBekend.emit(true);
                    if (usr.profile.accounttype === 'pgbmedewerker') {
                        const relatienummer = this.getCookie('x-wgp-wgid');
                        const partijIdentificatie = this.getCookie('x-wgp-pid');
                        const naam = this.getCookie('x-wgp-wgnaam');

                        if (relatienummer && partijIdentificatie && naam) {
                            this.isImpersonatie = true;
                            this.meekijkenAls.next(naam);
                        }
                    }
                } else {
                    this.loggedIn = false;
                }
            })
            .catch((err) => this.loggedIn = false))
            .catch((err) => this.loggedIn = false);
    }

    public startMeekijkenMetWerkever(werkgever: Werkgever): Observable<boolean> {
        document.cookie = `x-wgp-wgid=${werkgever.relatienummer}; path=/`;
        document.cookie = `x-wgp-pid=${werkgever.identificatie}; path=/`;
        document.cookie = `x-wgp-wgnaam=${werkgever.naam}; path=/`;

        this.isImpersonatie = true;
        this.meekijkenAls.next(werkgever.naam);

        return Observable.of(true);
    }

    public startMeekijkenMetMachtiging(machtiging: Machtiging): Observable<boolean> {
        document.cookie = `x-wgp-wgid=${machtiging.werkgeverRelatienummer}; path=/`;
        document.cookie = `x-wgp-pid=${machtiging.werkgeverPartijIdentificatie}; path=/`;
        document.cookie = `x-wgp-wgnaam=${machtiging.werkgevernaam}; path=/`;

        this.isImpersonatie = true;
        this.meekijkenAls.next(machtiging.werkgevernaam);

        return Observable.of(true);
    }

    public stopMeekijken(): Observable<boolean> {
        document.cookie = 'x-wgp-wgid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'x-wgp-pid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        document.cookie = 'x-wgp-wgnaam=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';

        this.isImpersonatie = false;
        this.meekijkenAls.next();

        return Observable.of(true);
    }

    public wordtMeegegeken(): boolean {
        return this.isImpersonatie;
    }

    /**
     * Redirect de gebruiker naar de login pagina
     * @returns {Promise<any>} Een promise
     */
    public signinRedirect(): Promise<any> {
        return this.ensureUserManager().then((userManager) => userManager.signinRedirect());
    }

    /**
     * Handelt de callback voor het aanmelden af
     * @returns {Promise<User>} Een promise met de aangemelde gebruiker
     */
    public signinRedirectCallback(): Promise<User> {
        return this.ensureUserManager().then((userManager) => userManager.signinRedirectCallback()
            .then((user) => {
                this.currentUser = user;
                this.loggedIn = true;
                this.userIsBekend.emit(true);
                return user;
            }));
    }

    /**
     * Redirect de gebruiker naar de afmeldpagina
     */
    public signoutRedirect() {
        this.ensureUserManager().then((userManager) => userManager.signoutRedirect());
    }

    /**
     * Handelt de callback voor het afmelden af.
     * @returns {Promise<any>} Een promise
     */
    public signoutRedirectCallback(): Promise<any> {
        return this.ensureUserManager().then((userManager) => userManager.signoutRedirectCallback()
            .then(() => {
                this.currentUser = null;
                this.loggedIn = false;
            }));
    }

    /**
     * Haalt de gebruiker op die is aangemeld
     * @returns {Promise<User>} A promise containing the current user
     */
    public getUser(): Promise<User> {
        return this.ensureUserManager().then((userManager) => userManager.getUser());
    }

    /**
     * Haalt de gebruiker op die is aangemeld als observable
     * @returns {Observable<User>} Observable met de huidige gebruiker
     */
    public getUserObservable(): Observable<User> {
        return Observable.fromPromise(this.getUser());
    }

    public getCookie(cname: string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    public isCurrentUserApplicatiebeheerder(): boolean {
        return this.isCurrentUserProfileDefined() &&
            this.currentUser.profile.accounttype === Accounttype.PGBMEDEWERKER &&
            this.currentUser.profile.rol === Rol.APPLICATIEBEHEERDER &&
            this.currentUser.profile.recht === Recht.SCHRIJVEN;
    }

    public isCurrentUserMedewerkerbeheerder(): boolean {
        return this.isCurrentUserProfileDefined() && this.currentUser.profile.accounttype === Accounttype.PGBMEDEWERKER &&
            this.currentUser.profile.rol === Rol.MEDEWERKER_BEHEERDER &&
            this.currentUser.profile.recht === Recht.SCHRIJVEN;
    }

    public isCurrentUserMasterAccount(): boolean {
        return this.isCurrentUserProfileDefined() &&
            this.currentUser.profile.accounttype === Accounttype.WERKGEVER &&
            this.currentUser.profile.rol === Rol.MASTER &&
            this.currentUser.profile.recht === Recht.SCHRIJVEN;
    }

    public isCurrentUserWerkgeverSubaccount(): boolean {
        return this.isCurrentUserProfileDefined() &&
            this.currentUser.profile.accounttype === Accounttype.WERKGEVER &&
            this.currentUser.profile.rol === Rol.SUB_ACCCOUNT;
    }

    public isCurrentUserEenWerkgeverAccounttype(): boolean {
        return this.isCurrentUserProfileDefined() && this.currentUser.profile.accounttype === Accounttype.WERKGEVER;
    }

    public isCurrentUserGemachtigde(): boolean {
        return this.isCurrentUserProfileDefined() &&
            (this.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR ||
            this.currentUser.profile.accounttype === Accounttype.CONCERN);
    }

    public isCurrentUserExpired(): boolean {
        return this.currentUser && this.currentUser.expired;
    }

    public currentUserNeedsToResetPassword(): boolean {
        return this.currentUser &&
            this.currentUser.profile.PasswordResetRequired &&
            this.currentUser.profile.idp === 'local' &&
            document.cookie.indexOf('passwordisreset=true') < 0;
    }

    public currentUserNeedsToCreateProfile(): boolean {
        return this.currentUser &&
            this.currentUser.profile.ProfileCreationRequired &&
            document.cookie.indexOf('profilecreated=true') < 0;
    }

    public getRechtClaimOfCurrentUser(): string {
        return this.currentUser ? this.currentUser.profile.recht : '';
    }

    public getRelatienummer() {
        const cookieValue = this.getCookie('x-wgp-wgid');

        if (cookieValue) {
            return cookieValue;
        } else if (this.currentUser) {
            return this.currentUser.profile.werkgeverrelatienummer;
        } else {
            return -1;
        }
    }

    private isCurrentUserProfileDefined(): boolean {
        if (this.currentUser && this.currentUser.profile) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Zorgt ervoor dat er een user manager beschikbaar is
     * @returns {Promise<UserManager>}  Een promise met de geinitialiseerde user manager
     */
    private ensureUserManager(): Promise<UserManager> {
        const self = this;
        return this.applicationSettings.get().then((settings) => {
            if (self.userMgr) {
                return self.userMgr;
            } else {
                self.userMgr = new UserManager({
                    authority: settings.authentication.authority,
                    automaticSilentRenew: true,
                    client_id: settings.authentication.clientId,
                    metadataUrl: settings.authentication.metadataUrl,
                    redirect_uri: settings.authentication.redirectUrl,
                    response_type: settings.authentication.responseType,
                    scope: settings.authentication.scope,
                    post_logout_redirect_uri: settings.authentication.postLogoutRedirectUrl,
                    clockSkew: settings.authentication.clockSkew,
                });

                return self.userMgr;
            }
        });
    }

    isCurrentUserAdministratieKantoor(): boolean {
        return this.currentUser && this.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR;
    }
}
