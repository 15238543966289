import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Accounttype } from '../shared';
import { AuthenticationService } from './authentication.service';

/**
 * Alleen een geauthenticeerde user met als accounttype 'pgbmedewerker' mag deze route benaderen.
 */
@Injectable()
export class InterneMedewerkerGuard implements CanActivate {
    /**
     * Initializes a new instance of AuthenticatedGuard
     * @param authenticationService The authentication service to use for the authentication check
     * @param router                The router to use for redirecting users to the login page
     */
    constructor(private authenticationService: AuthenticationService, private router: Router) {

    }

    /**
     * Checks if the route can be activated
     * @param route
     * @param state
     * @returns Observable<boolean>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const self = this;
        let authenticated = false;
        const user = this.authenticationService.currentUser;
        if (this.authenticationService.currentUserNeedsToResetPassword()) {
            if (state.url.indexOf('account/wachtwoord-wijzigen') < 0) {
                self.router.navigate(['account/wachtwoord-wijzigen']);
            }
            return Observable.of(true);
        }
        if (this.authenticationService.currentUserNeedsToCreateProfile()) {
            if (state.url.indexOf('profiel/gebruikersgegevens') < 0) {
                self.router.navigate(['profiel/gebruikersgegevens']);
            }
            return Observable.of(true);
        }
        if (sessionStorage.getItem('moetvoorkeurenzetten') === 'true') {
            if (state.url.indexOf('profiel/voorkeuren') < 0) {
                self.router.navigate(['profiel/voorkeuren']);
            }
            return Observable.of(true);
        }
        if (user !== null && !user.expired &&  user.profile.accounttype === Accounttype.PGBMEDEWERKER) {
            authenticated = true;
        }
        if (!authenticated) {
            self.router.navigate(['login']);
        }

        return Observable.of(authenticated);
    }
}
