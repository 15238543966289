import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { ToasterService } from 'angular2-toaster';

import {AuthenticationService} from '../../authentication/authentication.service';

import { UpaSoftwareLeverancier, UpaSoftwarePakket} from '../../model';
import { UpaService } from '../upa.service';

@Component({
    selector: 'upa-softwareleverancier',
    template: require('./upa-softwareleverancier.component.html'),
})
export class UpaSoftwareLeverancierComponent implements OnChanges, OnInit {
    @Input() titel: string;
    @Input() upaSoftwarePakket: UpaSoftwarePakket;
    @Output() softwarepakketSelected = new EventEmitter<number>();
    softwareLeveranciers: UpaSoftwareLeverancier[] = [];
    selectedSoftwareLeverancier: UpaSoftwareLeverancier;
    selectedSoftwarePakket: UpaSoftwarePakket;
    eigenKeuze: boolean = false;

    constructor(private service: UpaService) {
    }

    ngOnInit(): void {
        this.service.getUpaSoftwareLeveranciers().subscribe((softwareLeveranciers) => {
            this.softwareLeveranciers = softwareLeveranciers;

            if (this.upaSoftwarePakket) {
                this.selectedSoftwareLeverancier =
                        softwareLeveranciers.find((x) => x.softwarePakketten.some((y) => y.id === this.upaSoftwarePakket.id));
                this.selectedSoftwarePakket =
                        this.selectedSoftwareLeverancier.softwarePakketten.find((x) => x.id === this.upaSoftwarePakket.id);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.upaSoftwarePakket.firstChange && !changes.upaSoftwarePakket.currentValue) {
            this.selectedSoftwarePakket = undefined;
            this.selectedSoftwareLeverancier = undefined;
        } else if (!changes.upaSoftwarePakket.firstChange && changes.upaSoftwarePakket.currentValue) {
            this.ngOnInit();
        }
    }

    selectSoftwareLeverancier(softwareLeverancier: UpaSoftwareLeverancier) {
        if (!softwareLeverancier) {
            this.selectedSoftwareLeverancier = null;
            this.selectedSoftwarePakket = null;
            this.eigenKeuze = true;
        } else {
            this.selectedSoftwareLeverancier = this.softwareLeveranciers.find((x) => x.id === softwareLeverancier.id);
            this.eigenKeuze = false;
        }
    }

    selectSoftwarePakket(softwarePakket: UpaSoftwarePakket) {
        if (!softwarePakket) {
            this.selectedSoftwarePakket = null;
            this.eigenKeuze = true;
        } else {
            this.selectedSoftwarePakket =
                this.selectedSoftwareLeverancier.softwarePakketten.find((x) => x.id === softwarePakket.id);
            this.softwarepakketSelected.emit(softwarePakket.id);
            this.eigenKeuze = false;
        }
    }

    onSoftwareLeverancierAndersChange(naam: string) {
        if (confirm('Weet je zeker dat je een nieuw item wilt aanmaken voor: ' + naam)) {
            this.service.maakSoftwareLeverancier(naam).subscribe((x) => {
                this.service.getUpaSoftwareLeveranciers().subscribe((softwareLeveranciers) => {
                    this.softwareLeveranciers = softwareLeveranciers;
                    this.selectedSoftwareLeverancier = softwareLeveranciers.find((s) => s.naam === naam);
                });
            });
        }
    }

    onSoftwarePakketAndersChange(naam: string, selectedSoftwareLeverancierId: number) {
        if (confirm('Weet je zeker dat je een nieuw item wilt aanmaken voor: ' + naam)) {
            this.service.maakSoftwarePakket(selectedSoftwareLeverancierId, naam).subscribe((x) => {
                this.service.getUpaSoftwareLeveranciers().subscribe((softwareLeveranciers) => {
                    this.softwareLeveranciers = softwareLeveranciers;
                    this.selectedSoftwareLeverancier = softwareLeveranciers.find((s) => s.id === selectedSoftwareLeverancierId);
                    this.selectedSoftwarePakket = this.selectedSoftwareLeverancier.softwarePakketten.find((s) => s.naam === naam);
                    this.selectSoftwarePakket(this.selectedSoftwarePakket);
                    this.eigenKeuze = false;
                });
            });
        }
    }

    wisKeuze() {
        this.softwarepakketSelected.emit(null);
    }
}
