import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { DigitaleNotaVoorkeur } from '../../model';
import { EmailValidator, Meldingen, Validaties } from '../../shared';
import { ModalService } from '../../shared/modal/modal.service';
import { ProfielService } from '../profiel.service';

@Component({
    template: require('.//voorkeuren-registreren.component.html'),
})
export class VoorkeurenRegistrerenComponent implements OnInit {
    private digitaleNieuwsbriefIngeschakeld: boolean = false;

    private form: FormGroup;
    private aanhefControl: AbstractControl;
    private voornaamControl: AbstractControl;
    private voorvoegselsControl: AbstractControl;
    private achternaamControl: AbstractControl;
    private emailadresControl: AbstractControl;
    private digitaleNotaControl: AbstractControl;
    private nietDigitaleNotaControl: AbstractControl;
    private digitaleNotaProfielEmailControl: AbstractControl;
    private digitaleNotaAlternatiefControl: AbstractControl;
    private digitaleNotaVoorkeur: DigitaleNotaVoorkeur;
    private digitaleNota: boolean = false;
    private saved: boolean = false;

    aanhefMessage: string;
    aanhefValidationMessages = {
        required: Validaties.AANHEF_VERPLICHT,
    };

    naamMessage: string;
    naamValidationMessages = {
        required: Validaties.NAAM_VERPLICHT,
    };

    emailadresMessage: string;
    emailadresValidationMessages = {
        email: Validaties.GELDIG_EMAIL,
        required: Validaties.EMAIL_VERPLICHT,
    };

    functieMessage: string;
    functieValidationMessages = {
        required: Validaties.FUNCTIE_VERPLICHT,
    };

    telefoonnummerMessage: string;
    telefoonnummerValidationMessages = {
        required: Validaties.TELEFOONNUMMER_VERPLICHT,
        telefoonnummer: Validaties.GELDIG_TELEFOONNUMMER,
    };
    constructor(
        private profielService: ProfielService,
        private router: Router,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.profielService.getProfiel().subscribe(
            (profile) => {
                this.digitaleNieuwsbriefIngeschakeld = true;
                this.profielService
                    .getDigitalNotePreference()
                    .subscribe(
                        (response) => {
                            if (
                                response.voorkeurDigitaal &&
                                !response.email
                            ) {
                                // Default Flow: Use profile details
                                response.voorkeurDigitaal = true;
                                response.email = profile.emailadres;
                                response.aanhef = profile.aanhef;
                                response.voornaam = profile.voornaam;
                                response.achternaam = profile.achternaam;
                                response.voorvoegsels = profile.voorvoegsels;
                            }

                            this.digitaleNotaVoorkeur = response;
                            this.initForm();
                        },
                        () =>
                            this.toasterService.pop(
                                '',
                                '',
                                Meldingen.FOUT_OPHALEN_GEGEVENS,
                            ),
                    );
            },
            () =>
                this.toasterService.pop('error', '', Meldingen.FOUT_OPHALEN_GEGEVENS),
        );
    }

    initForm(): void {
        this.aanhefControl = new FormControl(this.digitaleNotaVoorkeur.aanhef, [
            Validators.required,
        ]);
        this.voornaamControl = new FormControl(this.digitaleNotaVoorkeur.voornaam, [
            Validators.required,
        ]);
        this.voorvoegselsControl = new FormControl(
            this.digitaleNotaVoorkeur.voorvoegsels,
        );
        this.achternaamControl = new FormControl(
            this.digitaleNotaVoorkeur.achternaam,
            [Validators.required],
        );
        this.emailadresControl = new FormControl(this.digitaleNotaVoorkeur.email, [
            Validators.required,
            EmailValidator.emailCheck(),
        ]);

        this.digitaleNotaControl = new FormControl(
            this.digitaleNotaVoorkeur.voorkeurDigitaal,
        );
        this.nietDigitaleNotaControl = new FormControl(
            !this.digitaleNotaVoorkeur.voorkeurDigitaal,
        );

        this.digitaleNotaAlternatiefControl = new FormControl('');
        this.digitaleNotaProfielEmailControl = new FormControl('');

        this.form = this.fb.group({
            aanhef: this.aanhefControl,
            voornaam: this.voornaamControl,
            voorvoegsels: this.voorvoegselsControl,
            achternaam: this.achternaamControl,
            emailadres: this.emailadresControl,
            digitaalNota: this.digitaleNotaControl,
            profielEmail: this.digitaleNotaProfielEmailControl,
            alternatiefEmail: this.digitaleNotaAlternatiefControl,
            nietDigitaleNota: this.nietDigitaleNotaControl,
        });

        this.aanhefControl.valueChanges.subscribe(() => {
            this.aanhefMessage = this.getValidationMessage(
                this.aanhefControl,
                this.aanhefValidationMessages,
            );
        });
        this.voornaamControl.valueChanges.subscribe(() => {
            this.naamMessage = this.getValidationMessage(
                this.voornaamControl,
                this.naamValidationMessages,
            );
        });
        this.achternaamControl.valueChanges.subscribe(() => {
            this.naamMessage = this.getValidationMessage(
                this.achternaamControl,
                this.naamValidationMessages,
            );
        });
        this.emailadresControl.valueChanges.subscribe(() => {
            this.emailadresMessage = this.getValidationMessage(
                this.emailadresControl,
                this.emailadresValidationMessages,
            );
        });

        if (
            this.digitaleNotaVoorkeur.voorkeurDigitaal
        ) {
            this.selectDigitaleNota();
        }
    }

    // Voorkeuren
    verder() {
        if (
            this.digitaleNieuwsbriefIngeschakeld === null ||
            this.digitaleNieuwsbriefIngeschakeld === undefined
        ) {
            this.digitaleNieuwsbriefIngeschakeld = false;
        }

        this.profielService
            .registreerVoorkeur(this.digitaleNieuwsbriefIngeschakeld)
            .subscribe(() => {
                sessionStorage.setItem('moetvoorkeurenzetten', 'false');
                this.return();
            });
    }

    // Digitale Nota Voorkeuren
    save() {
        this.digitaleNotaVoorkeur.voorkeurDigitaal = this.form.controls.digitaalNota.value && !this.form.controls.nietDigitaleNota.value;

        if (this.digitaleNotaVoorkeur.voorkeurDigitaal) {
            this.digitaleNotaVoorkeur.email = this.form.controls.emailadres.value;
            this.digitaleNotaVoorkeur.aanhef = this.form.controls.aanhef.value;
            this.digitaleNotaVoorkeur.voornaam = this.form.controls.voornaam.value;
            this.digitaleNotaVoorkeur.voorvoegsels = this.form.controls.voorvoegsels.value;
            this.digitaleNotaVoorkeur.achternaam = this.form.controls.achternaam.value;
        }

        this.profielService
            .registerDigitalNotePreference(this.digitaleNotaVoorkeur)
            .subscribe(
                () => {
                    this.toasterService.pop(
                        'success',
                        '',
                        Meldingen.BEDANKT_VOOR_WIJZIGING,
                    );
                    this.saved = true;
                    this.return();
                },
                () =>
                    this.toasterService.pop('error', '', Meldingen.FOUT_TIJDENS_WIJZIGEN),
            );
    }

    return() {
        if (!this.form.dirty || this.saved) {
            this.router.navigate(['']);
        } else {
            this.modalService.createBevestigingMetCallback(
                'Wijzigingen Opslaan',
                () => this.router.navigate(['']),
                Meldingen.OPENSTAANDE_WIJZIGINGEN,
            );
        }
    }

    onSelectionChanged(e: any) {
        if (e.target.name === 'digitaalNota') {
            this.selectDigitaleNota();
        } else if (e.target.name === 'nietDigitaleNota') {
            this.selectNietDigitaleNota();
        }
    }

    private selectDigitaleNota() {
        this.form.controls.digitaalNota.setValue(true);
        this.form.controls.nietDigitaleNota.setValue(false);
        this.digitaleNota = true;
    }

    private selectNietDigitaleNota() {
        this.form.controls.digitaalNota.setValue(false);
        this.form.controls.nietDigitaleNota.setValue(true);
        this.digitaleNota = false;
    }

    getValidationMessage(c: AbstractControl, validationMessages: any): string {
        if (c.invalid) {
            return Object.keys(c.errors)
                .map((key) => validationMessages[key])
                .join(' ');
        }

        return '';
    }

    bepaalOfVeldInvalidIs(c: AbstractControl): boolean {
        if ((c.value && c.invalid) || (c.touched && c.dirty && c.invalid)) {
            return true;
        }

        return false;
    }
}
