import { HostListener } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { Meldingen } from '.';

export interface IComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export class OpenstaandeWijzigingenGuard implements CanDeactivate<IComponentCanDeactivate> {
  canDeactivate(component: IComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ?
      true :
      confirm(Meldingen.OPENSTAANDE_WIJZIGINGEN);
  }
}
