import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateValidators {
    static inyear(year: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let selectedYear = 0;
            if (c.value) {
                selectedYear = c.value.jsdate.getFullYear();
            }
            const isValid = selectedYear === year;
            return isValid ? null : { currentyear: true };
        };
    }
}
