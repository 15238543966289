import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../authentication/authentication.service';
import { SorteerRichting } from '../model';
import { BestandInfo } from '../model/bestandinfo.model';
import { BestandsuitwisselingFilter } from '../model/bestandsuitwisseling/bestandsuitwisseling-filter.model';
import { Urls } from '../navigatie/urls';
import {
    Accounttype,
    Constants,
    Meldingen,
    UtilService,
} from '../shared';
import { BestandsuitwisselingService } from '../shared/services/bestandsuitwisseling.service';

@Component({
    styles: [require('./bestandsuitwisseling.component.scss')],
    template: require('./bestandsuitwisseling.component.html'),
})
export class BestandsuitwisselingComponent implements OnInit {
    beschikbareBestanden: BestandInfo[];
    geuploadeBestanden: BestandInfo[];
    file: File;
    form: FormGroup;
    enableUpload: boolean = true;
    defaultFilter: BestandsuitwisselingFilter = {
        aantalItemsPerPagina: 10,
        paginaNummer: 1,
        sorteerRichting: SorteerRichting.Descending,
        sorteerVeld: 'lastwritetime',
    };
    filter: BestandsuitwisselingFilter;
    uploadFilter: BestandsuitwisselingFilter;

    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;

    uploadTotaalItems: number = 0;
    uploadVanAantal: number = 0;
    uploadTotAantal: number = 0;

    bestandUploadErrorMessage: string = '';

    constructor(
        private bestandsuitwisselingService: BestandsuitwisselingService,
        private authService: AuthenticationService,
        private utilService: UtilService,
        private fb: FormBuilder,
        private router: Router,
        private toasterService: ToasterService) {
        this.form = this.fb.group({
            file: [],
            opmerking: this.fb.control(''),
        });
        this.filter = {
            aantalItemsPerPagina: this.defaultFilter.aantalItemsPerPagina,
            paginaNummer: this.defaultFilter.paginaNummer,
            sorteerRichting: this.defaultFilter.sorteerRichting,
            sorteerVeld: this.defaultFilter.sorteerVeld,
        };
        this.uploadFilter = {
            aantalItemsPerPagina: this.defaultFilter.aantalItemsPerPagina,
            paginaNummer: this.defaultFilter.paginaNummer,
            sorteerRichting: this.defaultFilter.sorteerRichting,
            sorteerVeld: this.defaultFilter.sorteerVeld,
        };
    }

    ngOnInit(): void {
        this.getBestanden();

        this.enableUpload = this.authService.currentUser.profile.accounttype !== Accounttype.PGBMEDEWERKER;
    }

    handlePaging(filter: BestandsuitwisselingFilter) {
        this.filter = filter;
        this.getBestanden();
    }

    private getBestanden(): void {
        // Haal de door PGB klaargezette documenten op.
        this.bestandsuitwisselingService.getBestanden(this.filter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.beschikbareBestanden = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
            },
                (err) => {
                    this.toasterService.pop('error', '', 'Er is een fout opgetreden bij het ophalen van de voor u beschikbare documenten.');
                });
        // Haal de door de werkgever aangeleverde documenten op.
        this.bestandsuitwisselingService.getGeuploadeBestanden(this.uploadFilter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.geuploadeBestanden = x.items;
                this.uploadTotaalItems = x.totaalItems;
                this.uploadVanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.uploadTotAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
            },
                (err) => {
                    this.toasterService.pop('error', '',
                        'Er is een fout opgetreden bij het ophalen van de door u aangeleverde documenten.');
                });
    }

    download(bestandsnaam: string, mimeType: string, action: string): void {
        this.bestandsuitwisselingService.download(bestandsnaam, mimeType, action)
            .subscribe((x: any) => {
                this.utilService.downloadFile(x, bestandsnaam, mimeType);
            });
    }

    fileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
        }
    }

    fileSubmitted() {
        if (this.file) {
          let opmerking = this.form.controls.opmerking.value;
          if (!opmerking) { opmerking = ''; }
            this.bestandsuitwisselingService.upload(this.file, opmerking).subscribe((x: any) => {
                if (x) {
                    this.toasterService.pop('error', '', 'Het bestand is ongeldig.');
                } else {
                    this.getBestanden();
                    this.file = null;
                    this.form.reset();
                    this.bestandUploadErrorMessage = null;
                    this.toasterService.pop('success', '', 'Het bestand is is succesvol geüpload.');
                }
            }, (err: any) => {
                    const message = this.utilService.getErrorMessage(err);
                    this.bestandUploadErrorMessage = message.Message;
                });
        }
    }
}
