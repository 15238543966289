import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';

import { SorteerRichting, Upa } from '../../model';
import { BedrijfsgegevensService } from '../../shared';
import { UpaService } from '../upa.service';

@Component({
    template: require('./upa-overzicht.component.html'),
    styles: ['.warning { background-color: #fbdec2 !important;', '.error { background-color: #ffdde1 !important; }'],
})
export class UpaOverzichtComponent implements OnInit {
    upas: Upa[];
    jaren: number[];
    huidigJaar: number;

    public columns: any[] = [
        { title: 'Id', name: 'id', sort: 'asc', type: 'number', isSortActive: true },
        { title: 'Inzenddatum', name: 'inzendDatum', sort: 'desc', type: 'date', isSortActive: false },
        { title: 'Tijdvak', name: 'tijdvak', sort: 'desc', type: 'date', isSortActive: false },
        { title: 'Inzender', name: 'inzender', sort: 'desc', type: 'string', isSortActive: false },
        { title: 'Status', name: 'status', sort: 'desc', type: 'string', isSortActive: false },
    ];

    constructor(private router: Router,
                private service: UpaService,
                private toasterService: ToasterService,
                private bedrijfsgegevensService: BedrijfsgegevensService) {
    }

    ngOnInit(): void {
        this.SetJaren();
        this.service.getUpaLijst((new Date()).getFullYear())
        .subscribe((x) => {
            this.upas = x;
        },
        (err) => {
            this.toasterService.pop('error', '', `Er ging iets mis bij het ophalen van Upas`);
        });
    }

    onSelect(upa: Upa) {
        this.router.navigate(['/upa/overzicht', upa.id]);
    }

    onJaarChange(jaar: any) {
        this.service.getUpaLijst(jaar)
        .subscribe((x) => {
            this.upas = x;
        },
        (err) => {
            this.toasterService.pop('error', '', `Er ging iets mis bij het ophalen van Upas`);
        });
    }

    toggleSorting(columnName: string) {
        let richting: SorteerRichting = SorteerRichting.Ascending;
        let type: string;
        this.columns.forEach((c: any) => {
            if (c.name !== columnName) {
                c.isSortActive = false;
            } else {
                if (c.isSortActive) {
                    c.sort = c.sort === 'asc' ? 'desc' : 'asc';
                } else {
                    c.isSortActive = true;
                }
                richting = c.sort === 'asc' ? SorteerRichting.Ascending : SorteerRichting.Descending;
                type = c.type;
            }
        });

        this.upas = this.upas.sort((a, b) => {
            switch (type) {
                case 'date':
                    return this.compareDates(a, b, richting, columnName);
                case 'number':
                    return this.compareNumbers(a, b, richting, columnName);
                case 'string':
                    return this.compareStings(a, b, richting, columnName);
                default:
                    break;
            }
        });
    }

    compareStings(a: any, b: any, richting: SorteerRichting, columnName: string): number {
        if (richting === SorteerRichting.Ascending) {
            return (b[columnName]).localeCompare(a[columnName]);
        } else {
            return (a[columnName]).localeCompare(b[columnName]);
        }
    }

    compareNumbers(a: any, b: any, richting: SorteerRichting, columnName: string): number {
        if (richting === SorteerRichting.Ascending) {
            return a[columnName] - b[columnName];
        } else {
            return a[columnName] + b[columnName];
        }
    }

    compareDates(a: any, b: any, richting: SorteerRichting, columnName: string): number {
        if (richting === SorteerRichting.Ascending) {
                return moment(a[columnName]).diff(moment(b[columnName]));
        } else {
                return moment(b[columnName]).diff(moment(a[columnName]));
        }
    }

    SetJaren() {
        this.huidigJaar = moment().year();
        this.bedrijfsgegevensService.getWerkgever().subscribe((x) => {
            const jaren = [];
            for (let jaar: number = moment().year(); jaar >= moment(x.upaAanleverwijzeStartdatum).year(); jaar--) {
                jaren.push(jaar);
            }
            this.jaren = jaren;
        });
    }
}
