import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import { Dienstverband } from '../../model';
import {
    Constants,
    DienstverbandService,
    Meldingen,
    MyDatePickerValidators,
    UtilService,
    Validaties,
} from '../../shared';
import { IMutatieFormColumn } from './mutatie-form-column';
import { IMutatieFormConfig } from './mutatie-form-config';
import { MutatieFormComponent } from './mutatie-form.component';
import { MutatieFormService } from './mutatie-form.service';
import { INieuwFormulierVerzoek } from './nieuwFormulierVerzoek';

@Component({
    template: require('./default-mutatie-form.html'),
})
export class BegindatumWijzigenComponent {
    private titel: string = 'Datum in dienst corrigeren';
    private displayDienstverbandPeriodeUitleg: boolean = true;
    private upaStartdatum: string;
    @ViewChild(MutatieFormComponent)
    private mutatieForm: MutatieFormComponent;
    private columns: IMutatieFormColumn[] = [
        { titel: 'Naam', key: 'naam', controlType: 'display' },
        { titel: 'Geslacht', key: 'geslacht', controlType: 'display' },
        { titel: 'Geboortedatum', key: 'geboortedatum', controlType: 'displayDate' },
        { titel: 'BSN', key: 'bsn', controlType: 'display' },
        { titel: 'Begindatum', key: 'inDienst', controlType: 'editDate', cssClasses: ['width-200'] },
        { titel: 'Einddatum', key: 'uitDienst', controlType: 'displayDate' },
    ];
    private config: IMutatieFormConfig = {
        lopendVerzoek: false,
        validatieMeldingen: {
            required: Validaties.ONGELDIGE_DATUM,
            lessOrSameAs: Validaties.DATUM_KLEINER_OF_GELIJK_AAN_EIND,
            greaterOrSameAsMinDate: Validaties.DATUM_GROTER_OF_GELIJK_AAN_01_01_2014,
            lessOrSameAsMaxDate: Validaties.DATUM_VOOR_OF_IN_HUIDIG_JAAR,
            lessThan: Validaties.DATUM_VOOR_UPA_STARTDATUM,
        },
        waarschuwingColspan: 4,
        isBewerkbaar(regel: AbstractControl): boolean {
            return regel.get('inDienstPlaceholder') && regel.get('inDienstPlaceholder').value;
        },
        getFoutmelding(regel: AbstractControl): string {
            const inDienst = regel.get(MutatieFormService.inDienstFormcontrolName);
            if ((inDienst.touched || inDienst.dirty) && inDienst.errors) {
                const required = 'required';

                if (Object.keys(inDienst.errors).find((key) => key === required)) {
                    return this.validatieMeldingen[required];
                }
                return Object.keys(inDienst.errors).map((key) => this.validatieMeldingen[key]).join(' ');
            }
            return '';
        },
    };

    constructor(private fb: FormBuilder,
        private dienstverbandService: DienstverbandService,
        private mutatieFormService: MutatieFormService,
        private utilService: UtilService) {
    }

    wijzigen(bewerkbareDienstverbanden: AbstractControl[]): void {
        const data = this.mutatieFormService.valideerWijzigingsVerzoekEnExtraheerData<Dienstverband>(this.config,
            bewerkbareDienstverbanden, this.converteerNaarDienstverband);
        if (!data) {
            return;
        }

        this.dienstverbandService.updateBegindatum(data)
            .subscribe(() => this.mutatieFormService.onSuccesWijziging(),
                () => this.mutatieFormService.onErrorWijziging(this.config));
    }

    zoekBsn(bsn: string): void {
        const verzoek = this.maakVerzoekAan([bsn], false);
        this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm, false, false,
            this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
    }

    /**
     * Handelt een verzoek af om dienstverbanden te zoeken op basis van bsn nummers.
     * @param {Set<string>} bsns
     */
    zoekOpBsns(bsns: Set<string>): void {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan(Array.from(bsns), true);
            this.mutatieFormService.haalDienstverbandenObvBsns(verzoek, this.mutatieForm, false, false,
                this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    /**
     * Handelt een verzoek af om dienstverbanden te zoeken op basis van naam.
     * @param {string} naam
     */
    zoekOpNaam(naam: string): void {
        if (this.mutatieFormService.doorgaanMetOphalen(this.mutatieForm)) {
            const verzoek = this.maakVerzoekAan([], true);
            this.mutatieFormService.haalDienstverbandenObvNaam(naam, verzoek, this.mutatieForm, false, false,
                this.utilService.bepaalDagVoorDatum(this.mutatieForm.upaStartdatum));
        }
    }

    private converteerNaarDienstverband(group: FormGroup): Dienstverband {
        const dienstverband = group.get(MutatieFormService.dienstverbandFormcontrolName).value as Dienstverband;
        dienstverband.beginOud = dienstverband.begin;
        dienstverband.begin = moment.utc(group.get(MutatieFormService.inDienstFormcontrolName).value.formatted,
            Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();
        return dienstverband;
    }

    private maakVerzoekAan(bsns: string[], overschrijf: boolean): INieuwFormulierVerzoek<Dienstverband> {
        return {
            self: this,
            bsns,
            items: [],
            overschrijfOudFormulier: overschrijf,
            mapOnbekendeBsnToFormGroup: this.mapOnbekendeBsnToFormGroup,
            mapItemToFormGroup: this.mapItemToFormGroup,
        };
    }

    private mapOnbekendeBsnToFormGroup(bsn: string, self: BegindatumWijzigenComponent): FormGroup {
        return self.fb.group({
            naam: self.fb.control(''),
            geslacht: self.fb.control(''),
            geboortedatum: self.fb.control(''),
            bsn: self.fb.control(bsn),
            inDienst: self.fb.control(''),
            inDienstPlaceholder: self.fb.control(''),
            uitDienst: self.fb.control(''),
            waarschuwing: self.fb.control(Meldingen.GEEN_RESULTATEN_VOOR_BSN),
        });
    }

    private mapItemToFormGroup(item: Dienstverband, config: IMutatieFormConfig, self: BegindatumWijzigenComponent): FormGroup {
        let end: string = null;
        if (item.eind) {
            end = item.eind.toString();
        }
        const validators = [Validators.required, MyDatePickerValidators.lessOrSameAs(end),
        MyDatePickerValidators.greaterOrSameAsMinDate(), MyDatePickerValidators.lessOrSameAsMaxDate()];

        // blokkeer mutaties na de UPA startdatum
        if (self.upaStartdatum) {
            validators.push(MyDatePickerValidators.lessThan(self.upaStartdatum));
        }

        const inDienstControl = self.fb.control('', validators);
        const waarschuwingControl = self.fb.control('');
        const frmGrp = self.fb.group({
            dienstverband: self.fb.control(item),
            naam: self.fb.control(item.werknemer.volledigeNaam),
            geslacht: self.fb.control(item.werknemer.geslacht),
            geboortedatum: self.fb.control(item.werknemer.geboortedatum),
            bsn: self.fb.control(item.werknemer.burgerServiceNummer),
            inDienstPlaceholder: self.fb.control(item.begin != null ? item.begin : ''),
            inDienst: inDienstControl,
            uitDienst: self.fb.control(item.eind),
            waarschuwing: waarschuwingControl,
        });

        if (!config.isBewerkbaar(frmGrp)) {
            waarschuwingControl.setValue(Meldingen.DIENSTVERBAND_KAN_NIET_BEWERKEN);
            inDienstControl.setValidators([]);
        }
        return frmGrp;
    }
}
