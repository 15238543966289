import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';
import { Profiel } from '../../model';
import {
    EmailValidator,
    Meldingen,
    TelefoonnummerValidator,
    Validaties,
} from '../../shared';
import { Accounttype } from '../../shared/accounttype';
import { ModalService } from '../../shared/modal/modal.service';
import { SharedModule } from '../../shared/shared.module';
import { ProfielService } from '../profiel.service';

@Component({
    template: require('./gebruikersgegevens.component.html'),
})
export class GebruikersgegevensComponent implements OnInit {

    private aanhefControl: AbstractControl;
    private voornaamControl: AbstractControl;
    private voorvoegselsControl: AbstractControl;
    private achternaamControl: AbstractControl;
    private emailadresControl: AbstractControl;
    private functieControl: AbstractControl;
    private telefoonnummerControl: AbstractControl;
    private digitaleNieuwsbriefControl: AbstractControl;

    profiel: Profiel;
    form: FormGroup;
    profielOpslaanVerplicht: boolean = false;
    profielAlGevuld: boolean = false;

    aanhefMessage: string;
    aanhefValidationMessages = {
        required: Validaties.AANHEF_VERPLICHT,
    };

    naamMessage: string;
    naamValidationMessages = {
        required: Validaties.NAAM_VERPLICHT,
    };

    emailadresMessage: string;
    emailadresValidationMessages = {
        email: Validaties.GELDIG_EMAIL,
        required: Validaties.EMAIL_VERPLICHT,
    };

    functieMessage: string;
    functieValidationMessages = {
        required: Validaties.FUNCTIE_VERPLICHT,
    };

    telefoonnummerMessage: string;
    telefoonnummerValidationMessages = {
        required: Validaties.TELEFOONNUMMER_VERPLICHT,
        telefoonnummer: Validaties.GELDIG_TELEFOONNUMMER,
    };

    constructor(private router: Router,
        private profielService: ProfielService,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        this.profielOpslaanVerplicht = this.authenticationService.currentUser.profile.ProfileCreationRequired &&
            document.cookie.indexOf('profilecreated=true') < 0;

        this.retrieve();
    }

    retrieve() {
        this.profielService.getProfiel()
            .subscribe(
                (result) => {
                    this.profiel = result;
                    if (this.profielOpslaanVerplicht && result.aanhef && result.voornaam && result.achternaam
                        && result.emailadres && result.functie && result.telefoonnummer) {
                        this.profielAlGevuld = true;
                    }

                    this.initForm();
                },
                () => {
                    this.toasterService.pop('error', '', Meldingen.FOUT_OPHALEN_GEGEVENS);
                });
    }

    save() {
        this.profiel.aanhef = this.form.controls.aanhef.value;
        this.profiel.voornaam = this.form.controls.voornaam.value;
        this.profiel.voorvoegsels = this.form.controls.voorvoegsels.value;
        this.profiel.achternaam = this.form.controls.achternaam.value;
        this.profiel.functie = this.form.controls.functie.value;
        this.profiel.telefoonnummer = this.form.controls.telefoonnummer.value;
        this.profiel.emailadres = this.form.controls.emailadres.value;
        this.profiel.digitaleNieuwsbriefIngeschakeld = this.form.controls.digitaleNieuwsbrief.value || false;

        this.profielService.saveProfiel(this.profiel)
            .subscribe(
                () => {
                    this.toasterService.pop('success', '', Meldingen.BEDANKT_VOOR_WIJZIGING);
                    this.authenticationService.getUser().then((user) => {
                        if (user.profile.ProfileCreationRequired) {
                            document.cookie = 'profilecreated=true; path=/';
                            sessionStorage.setItem('moetvoorkeurenzetten', 'false');
                        }
                    });
                    this.return();
                },
                () => {
                    this.toasterService.pop('error', '', Meldingen.FOUT_TIJDENS_WIJZIGEN);
                });
    }

    return() {
        this.router.navigate(['']);
    }

    initForm(): void {
        this.aanhefControl = new FormControl(this.profiel.aanhef, [Validators.required]);
        this.voornaamControl = new FormControl(this.profiel.voornaam, [Validators.required]);
        this.voorvoegselsControl = new FormControl(this.profiel.voorvoegsels);
        this.achternaamControl = new FormControl(this.profiel.achternaam, [Validators.required]);
        this.emailadresControl = new FormControl(this.profiel.emailadres,
            [Validators.required, EmailValidator.emailCheck()]);
            this.functieControl = new FormControl(this.profiel.functie, [Validators.required]);
        this.telefoonnummerControl =
            new FormControl(this.profiel.telefoonnummer,
                [Validators.required, TelefoonnummerValidator.telefoonnummerCheck()]);
                this.digitaleNieuwsbriefControl = new FormControl(this.profiel.digitaleNieuwsbriefIngeschakeld);

        this.form = this.fb.group({
            aanhef: this.aanhefControl,
            voornaam: this.voornaamControl,
            voorvoegsels: this.voorvoegselsControl,
            achternaam: this.achternaamControl,
            emailadres: this.emailadresControl,
            functie: this.functieControl,
            telefoonnummer: this.telefoonnummerControl,
            digitaleNieuwsbrief: this.digitaleNieuwsbriefControl,
        });

        this.aanhefControl.valueChanges.subscribe(() => {
            this.aanhefMessage = this.getValidationMessage(this.aanhefControl, this.aanhefValidationMessages);
        });
        this.voornaamControl.valueChanges.subscribe(() => {
            this.naamMessage =
                this.getValidationMessage(this.voornaamControl, this.naamValidationMessages);
        });
        this.achternaamControl.valueChanges.subscribe(() => {
            this.naamMessage =
                this.getValidationMessage(this.achternaamControl, this.naamValidationMessages);
        });
        this.emailadresControl.valueChanges.subscribe(() => {
            this.emailadresMessage =
                this.getValidationMessage(this.emailadresControl, this.emailadresValidationMessages);
        });
        this.functieControl.valueChanges.subscribe(() => {
            this.functieMessage =
                this.getValidationMessage(this.functieControl, this.functieValidationMessages);
        });
        this.telefoonnummerControl.valueChanges.subscribe(() => {
            this.telefoonnummerMessage =
                this.getValidationMessage(this.telefoonnummerControl, this.telefoonnummerValidationMessages);
        });
    }

    getValidationMessage(c: AbstractControl, validationMessages: any): string {
        if (c.invalid) {
            return Object.keys(c.errors).map((key) => validationMessages[key]).join(' ');
        }

        return '';
    }

    bepaalOfVeldInvalidIs(c: AbstractControl): boolean {
        if (c.value && c.invalid || c.touched && c.dirty && c.invalid) {
            return true;
        }

        return false;
    }
}
