import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../../authentication/authentication.service';
import {
    Dienstverband,
    Filter,
} from '../../../model';
import {
    DienstverbandService,
    Meldingen,
    UtilService,
} from '../../../shared';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';

@Component({
    styles: [],
    template: require('./dienstverband-list.component.html'),
})
export class DienstverbandListComponent implements OnInit {
    dienstverbanden: Dienstverband[];
    filter: Filter;
    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;
    heeftSchrijvenRecht: boolean = false;

    constructor(
        private diensverbandenService: DienstverbandService,
        private authSvc: AuthenticationService,
        private bedrijfsgegevensService: BedrijfsgegevensService,
        private router: Router,
        private utilService: UtilService,
        private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        const isGemachtigde = (this.authSvc.currentUser.profile.accounttype === 'administratiekantoor' ||
        this.authSvc.currentUser.profile.accounttype === 'concern');
        if (isGemachtigde) {
            this.bedrijfsgegevensService.getGemachtigdenRecht()
            .subscribe((recht) => {
                if (recht === 'schrijven') {
                    this.heeftSchrijvenRecht = true;
                } else {
                    this.heeftSchrijvenRecht = false;
                }
            });
        } else {
            this.heeftSchrijvenRecht = this.authSvc.currentUser.profile.recht === 'schrijven';
        }
    }

    handleFilterUpdated(filter: Filter): void {
        this.filter = filter;
        this.retrieveDienstverbanden();
    }

    handleDeleteFilter(filter: Filter): void {
        this.filter = filter;
        this.retrieveDienstverbanden();
    }

    handlePaging(filter: Filter) {
        this.filter = filter;
        this.retrieveDienstverbanden();
    }

    handleSorting(filter: Filter) {
        this.filter = filter;
        this.retrieveDienstverbanden();
    }

    onDownload(): void {
        this.filter.haalAllesOp1PaginaOp = true;
        this.diensverbandenService.download(this.filter)
                                  .subscribe((d) => this.utilService.downloadFile(d, 'OverzichtDienstverbanden.csv'));
    }

    onPrint(): void {
        this.utilService.printContent('overzicht-dienstverbanden', 'Overzicht dienstverbanden');
    }

    goToWerknemerAanmelden() {
        this.router.navigate(['/werknemers/aanmelding']);
    }

    private retrieveDienstverbanden(): void {
        this.diensverbandenService.getDienstverbanden(this.filter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.dienstverbanden = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;

                this.filter.paginaNummer = x.paginaIndex;
                this.filter.aantalItemsPerPagina = x.aantalPerPagina;
                this.filter.haalAllesOp1PaginaOp = false;
            },
            (err) => {
                this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
            });
    }
}
