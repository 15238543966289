import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyOptions } from 'mydatepicker';
import 'rxjs/add/operator/map';

import { IBsnIdentificeerbaarItem } from '../../model/bsnIdentificeerbaarItem';
import { DienstverbandLoonderving } from '../../model/dienstverband-loonderving.model';
import { DienstverbandPeriode } from '../../model/dienstverband-periode.model';
import { Dienstverband } from '../../model/dienstverband.model';
import { Filter } from '../../model/filter.model';
import { Response } from '../../model/response.model';
import { SorteerRichting } from '../../model/sorteer-richting.model';
import { Werkgever } from '../../model/werkgever.model';
import { Urls } from '../../navigatie/urls';
import { Constants } from '../../shared/constants';
import { Meldingen } from '../../shared/meldingen';
import { DienstverbandService } from '../../shared/services/dienstverband.service';
import { LoonService } from '../../shared/services/loon.service';
import { UtilService } from '../../shared/util.service';
import { IMutatieFormColumn } from './mutatie-form-column';
import { IMutatieFormConfig } from './mutatie-form-config';
import { MutatieFormComponent } from './mutatie-form.component';
import { INieuwFormulierVerzoek } from './nieuwFormulierVerzoek';

@Injectable()
export class MutatieFormService {
    public static bedrijfsuitoefeningenFormcontrolName: string = 'bedrijfsuitoefeningen';
    public static beginFormcontrolName: string = 'begin';
    public static buCodeFormcontrolName: string = 'buCode';
    public static bufuVanafFormcontrolName: string = 'bufuVanaf';
    public static bsnFormcontrolName: string = 'bsn';
    public static datumGeldigTotFormcontrolName: string = 'datumGeldigTot';
    public static datumGeldigVanFormcontrolName: string = 'datumGeldigVan';
    public static datumGeldigVanPlaceholderFormcontrolName: string = 'datumGeldigVanPlaceholder';
    public static dienstverbandFormcontrolName: string = 'dienstverband';
    public static dvbLoondervingFormcontrolName: string = 'dienstverbandLoonderving';
    public static einddatumFormcontrolName: string = 'einddatum';
    public static einddatumPlaceholderFormcontrolName: string = 'einddatumPlaceholder';
    public static fuCodeFormcontrolName: string = 'fuCode';
    public static functiesFormcontrolName: string = 'functies';
    public static inDienstFormcontrolName: string = 'inDienst';
    public static loongegevenFormcontrolName: string = 'loongegeven';
    public static loonbedragFormcontrolName: string = 'loonbedrag';
    public static overledenFormcontrolName: string = 'overleden';
    public static parttimePercentageFormcontrolName: string = 'parttimePercentage';
    public static ppVoorVerlofFormcontrolName: string = 'ppVoorVerlof';
    public static regelsFormarrayName: string = 'regels';
    public static uitDienstFormcontrolName: string = 'uitDienst';
    public static urenverlagingFormcontrolName: string = 'urenverlaging';
    public static waarschuwingFormcontrolName: string = 'waarschuwing';

    public myDatePickerOptions: IMyOptions = {
        dateFormat: Constants.DATE_DEFAULT_FORMAT,
        height: Constants.DATEPICKER_DEFAULT_HEIGHT,
        selectionTxtFontSize: Constants.DATEPICKER_DEFAULT_FONTSIZE,
        showClearDateBtn: false,
        indicateInvalidDate: false,
    };

    constructor(private router: Router,
        private dvbSvc: DienstverbandService,
        private loonService: LoonService,
        private utilService: UtilService,
        private toasterService: ToasterService) {
    }

    /**
     * Kijkt of dienstverband formulier zich in een dirty state bevindt. Zo ja, vraagt of wijzigingen overschreven mogen worden.
     * @returns {boolean}
     */
    public doorgaanMetOphalen(dvbMutatieForm: MutatieFormComponent): boolean {
        if (dvbMutatieForm.isDirty()) {
            return confirm(Meldingen.CONFIRM_DIENSTVERBANDEN_VERLOREN);
        }

        return true;
    }

    public getOnbekendeBsns(bsns: string[], items: IBsnIdentificeerbaarItem[]): string[] {
        return bsns.filter((bsn) => items.every((item) => item.getBsn() !== bsn));
    }

    public haalDienstverbandenObvBsns(verzoek: INieuwFormulierVerzoek<Dienstverband>,
        mutatieForm: MutatieFormComponent,
        alsDienstverbandLoonderving: boolean,
        isOuderschapsVerlof: boolean,
        maximumIngangsDatumInclusief?: Date) {
        if (isOuderschapsVerlof) {
            maximumIngangsDatumInclusief = null;
        } else if (!maximumIngangsDatumInclusief) {
            maximumIngangsDatumInclusief = Constants.MAX_DATUM_DIENSTVERBAND_MUTATIES;
        }

        this.dvbSvc.getDienstverbandenByBsn(verzoek.bsns, maximumIngangsDatumInclusief)
            .subscribe((response) => {
                if (alsDienstverbandLoonderving) {
                    const dvbLVerzoek = this.converteerNaarDienstverbandLoondervingVerzoek(verzoek, response);
                    mutatieForm.initialiseerNieuwFormulier(dvbLVerzoek);
                } else {
                    verzoek.items = this.converteerResponseNaarDienstverbanden(response);
                    mutatieForm.initialiseerNieuwFormulier(verzoek);
                }
            },
                () => this.toonOngeldigeAanvraag());
    }

    public haalDienstverbandenObvNaam(naam: string,
        verzoek: INieuwFormulierVerzoek<Dienstverband>,
        mutatieForm: MutatieFormComponent,
        alsDienstverbandLoonderving: boolean,
        isOuderschapsVerlof: boolean,
        maximumIngangsDatumInclusief?: Date) {

        let filter: Filter;
        if (isOuderschapsVerlof) {
            filter = this.maakOpNaamFilter(naam);
        } else if (!maximumIngangsDatumInclusief) {
            filter = this.maakOpNaamFilter(naam, Constants.MAX_DATUM_DIENSTVERBAND_MUTATIES);
        } else {
            filter = this.maakOpNaamFilter(naam, maximumIngangsDatumInclusief);
        }
        this.dvbSvc.getDienstverbanden(filter)
            .subscribe((response) => {
                if (alsDienstverbandLoonderving) {
                    const dvbLVerzoek = this.converteerNaarDienstverbandLoondervingVerzoek(verzoek, response);
                    mutatieForm.initialiseerNieuwFormulier(dvbLVerzoek);
                } else {
                    verzoek.items = this.converteerResponseNaarDienstverbanden(response);
                    mutatieForm.initialiseerNieuwFormulier(verzoek);
                }
            },
                () => this.toonOngeldigeAanvraag());
    }

    public haalDienstverbandPeriodesObvBsns(verzoek: INieuwFormulierVerzoek<DienstverbandPeriode>, mutatieForm: MutatieFormComponent,
        werkgever: Werkgever) {
        this.loonService.getLoongegevensByBSN(verzoek.bsns)
            .subscribe((response) => {
                verzoek.items = this.converteerResponseNaarDienstverbandPeriodes(response, werkgever);
                this.pasParttimePercentageKolomnaamAan(mutatieForm, response.items, werkgever);
                mutatieForm.initialiseerNieuwFormulier(verzoek);
            },
                (err) => this.toonOngeldigeAanvraag());
    }

    public haalDienstverbandPeriodesObvNaam(naam: string, verzoek: INieuwFormulierVerzoek<DienstverbandPeriode>,
        mutatieForm: MutatieFormComponent, werkgever: Werkgever) {
        const filter = this.maakOpNaamFilter(naam);
        this.loonService.getLoongegevens(filter)
            .subscribe((response) => {
                verzoek.items = this.converteerResponseNaarDienstverbandPeriodes(response, werkgever);
                this.pasParttimePercentageKolomnaamAan(mutatieForm, response.items, werkgever);
                mutatieForm.initialiseerNieuwFormulier(verzoek);
            },
                () => this.toonOngeldigeAanvraag());
    }

    public maakOpNaamFilter(naam: string, totDatum?: Date) {
        const filter: Filter = {
            aantalItemsPerPagina: 100,
            paginaNummer: 1,
            bsn: null,
            naam,
            van: moment([2014, 0, 1]).toISOString(),
            tot: totDatum ? totDatum.toISOString() : moment([moment().year(), 11, 31]).toISOString(),
            sorteerRichting: SorteerRichting.Ascending,
            sorteerVeld: 'naam',
            haalAllesOp1PaginaOp: true,
        };
        return filter;
    }

    public onSuccesWijziging(): void {
        this.toasterService.pop('success', '', Meldingen.BEDANKT_VOOR_WIJZIGING);
        this.router.navigate([Urls.DoorgevenWijzigingen]);
    }

    public onErrorWijziging(config: IMutatieFormConfig): void {
        this.toasterService.pop('error', '', Meldingen.FOUT_TIJDENS_WIJZIGEN);
        config.lopendVerzoek = false;
    }

    public toonOngeldigeAanvraag(): void {
        this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
    }

    public valideerWijzigingsVerzoekEnExtraheerData<T>(config: IMutatieFormConfig, bewerkbareDienstverbanden: AbstractControl[],
        extraheer: (grp: FormGroup) => T): T[] {
        config.lopendVerzoek = true;
        if (bewerkbareDienstverbanden.length <= 0) {
            this.toasterService.pop('warning', '', Meldingen.GEEN_MUTATIES_OPGEGEVEN);
            config.lopendVerzoek = false;
            return;
        }

        return bewerkbareDienstverbanden.map(extraheer);
    }

    private converteerNaarDienstverbandLoondervingVerzoek(verzoek: INieuwFormulierVerzoek<Dienstverband>,
        response: Response<Dienstverband>) {
        const dvbLoondervingen: DienstverbandLoonderving[] = [];
        const dvbLVerzoek: INieuwFormulierVerzoek<DienstverbandLoonderving> = {
            items: [],
            bsns: verzoek.bsns,
            self: verzoek.self,
            overschrijfOudFormulier: verzoek.overschrijfOudFormulier,
            mapOnbekendeBsnToFormGroup: verzoek.mapOnbekendeBsnToFormGroup,
            mapItemToFormGroup: verzoek.mapItemToFormGroup,
        };
        response.items.forEach((dvb) => dvbLoondervingen.push(
            {
                dienstverband: Object.assign(new Dienstverband(), dvb),
                begin: null,
                eind: null,
                loondervingPercentage: null,
                urendervingPercentage: null,
                getBsn: () => dvb.werknemer.burgerServiceNummer,
            }));
        dvbLVerzoek.items = dvbLoondervingen;
        return dvbLVerzoek;
    }

    private pasParttimePercentageKolomnaamAan(mutatieForm: MutatieFormComponent, dienstverbandPeriodes: DienstverbandPeriode[],
        werkgever: Werkgever) {
        mutatieForm.columns.find((y: IMutatieFormColumn) => y.key === MutatieFormService.parttimePercentageFormcontrolName)
            .titel = this.utilService.bepaalParttimePercentageHeader(dienstverbandPeriodes, werkgever);
    }

    private converteerResponseNaarDienstverbanden(response: Response<Dienstverband>): Dienstverband[] {
        return response.items.map((x) => Object.assign(new Dienstverband(), x));
    }

    // Toon alleen loongegevens van voor de UPA startdatum
    private converteerResponseNaarDienstverbandPeriodes(response: Response<DienstverbandPeriode>,
        werkgever: Werkgever): DienstverbandPeriode[] {
        const items = werkgever.heeftUpa ? response.items.filter((x) => x.begin < werkgever.upaAanleverwijzeStartdatum) : response.items;
        return items.map((x) => Object.assign(new DienstverbandPeriode(), x));
    }

    private converteerResponseNaarDienstverbandLoondervingen(response: Response<DienstverbandLoonderving>): DienstverbandLoonderving[] {
        return response.items.map((x) => Object.assign(new DienstverbandLoonderving(), x));
    }
}
