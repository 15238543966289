export class Constants {
    public static DATE_DEFAULT_FORMAT = 'dd-mm-yyyy';
    public static DATEPICKER_DEFAULT_HEIGHT = '25px';
    public static DATEPICKER_DEFAULT_FONTSIZE = '14px';
    public static MAX_YEARS_HISTORY: number = 5;
    public static MIN_YEAR: number = 2014;
    public static MIN_YEAR_NOTAS: number = 2020;
    public static PARTTIMEPERCENTAGE_HEADER: string = 'Parttime %';
    public static GEWERKTE_UREN_HEADER: string = 'Gewerkte uren/dagen';
    public static MAX_DATUM_DIENSTVERBAND_MUTATIES = new Date('2018-12-31');
}
