import { Component, OnInit } from '@angular/core';

import { Regeling } from '../../model';
import { BedrijfsgegevensService } from '../../shared/services/bedrijfsgegevens.service';
import { RegelingService } from '../regeling.service';
import { RegelingKerngegevensComponent } from './regeling-kerngegevens.component';

@Component({
    entryComponents: [RegelingKerngegevensComponent],
    styles: [require('./regeling-list.component.scss')],
    template: require('./regeling-list.component.html'),
})
export class RegelingListComponent implements OnInit {
    regelingen: Regeling[];
    heeftUpa: boolean = false;

    constructor(private regelingService: RegelingService,
                private bedrSvc: BedrijfsgegevensService) { }

    ngOnInit(): void {
        this.bedrSvc.getWerkgever().subscribe((w) => this.heeftUpa = w.heeftUpa);
        this.regelingService.getRegelingen().subscribe((result) => {
            this.regelingen = result;
        });
    }

    toggleKerngegevens(div: any) {
        if (div.classList.contains('active') === false) {
            div.classList.add('active');
        } else {
            div.classList.remove('active');
        }
        const kerngegevens = div.parentElement.getElementsByClassName('kerngegevens')[0];
        if (kerngegevens.classList.contains('hide')) {
            kerngegevens.classList.remove('hide');
        } else {
            kerngegevens.classList.add('hide');
        }
    }
}
