import {
    BuFu,
    Dienstverband,
    DienstverbandLoonderving,
    DienstverbandPeriode,
    IndividueelContract,
    Kerngegevens,
    Regeling,
} from '.';

export class DienstverbandOverzicht {
    dienstverband: Dienstverband;
    regelingen: Regeling[];
    buFus: BuFu[];
    dienstverbandPeriodes: DienstverbandPeriode[];
    dienstverbandLoonderving?: DienstverbandLoonderving;
    individueleContracten: IndividueelContract[];
    kerngegevens?: Kerngegevens;
    geopendTonen: boolean;
}
