import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { Constants } from '../constants';

export class MyDatePickerValidators {

    static greaterThan(other: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && c.value.formatted) {
                const o = moment.utc(other, moment.defaultFormatUtc);
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isAfter(o)) {
                    return null;
                } else {
                    return { greaterThan: true };
                }
            } else {
                return null;
            }
        };
    }

    static greaterOrSameAs(other: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && c.value.formatted) {
                const o = moment.utc(other, moment.defaultFormatUtc);
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isSameOrAfter(o)) {
                    return null;
                } else {
                    return { greaterOrSameAs: true };
                }
            } else {
                return null;
            }
        };
    }

    static lessThan(other: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (other !== null && c.value && c.value.formatted) {
                const o = moment.utc(other, moment.defaultFormatUtc);
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isBefore(o)) {
                    return null;
                } else {
                    return { lessThan: true };
                }
            } else {
                return null;
            }
        };
    }

    static lessOrSameAs(other: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (other !== null && c.value && c.value.formatted) {
                const o = moment.utc(other, moment.defaultFormatUtc);
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isSameOrBefore(o)) {
                    return null;
                } else {
                    return { lessOrSameAs: true };
                }
            } else {
                return null;
            }
        };
    }

    static greaterOrSameAsMinDate(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && c.value.formatted) {
                const minDate = moment.utc('01-01-2014', Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isSameOrAfter(minDate)) {
                    return null;
                } else {
                    return { greaterOrSameAsMinDate: true };
                }
            } else {
                return null;
            }
        };
    }

    static lessOrSameAsMaxDate(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && c.value.formatted) {
                const now = moment.utc();
                const maxDate = moment.utc(`31-12-${now.year()}`, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                const selected = moment.utc(c.value.formatted, Constants.DATE_DEFAULT_FORMAT.toUpperCase());
                if (selected.isSameOrBefore(maxDate)) {
                    return null;
                } else {
                    return { lessOrSameAsMaxDate: true };
                }
            } else {
                return null;
            }
        };
    }

    static isValidDate(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && c.value.formatted) {
                const date = moment.utc(c.value.formatted);
                if (date.isValid) {
                    return null;
                } else {
                    return { isValidDate: true };
                }
            } else {
                return { isValidDate: true };
            }
        };
    }
}
