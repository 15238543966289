import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeekijkenGuard } from '../authentication/meekijken-guard.service';
import { WijzigenGuard } from '../authentication/wijzigen-guard.service';
import { FacturenListComponent } from './facturen/facturen-list.component';
import { SaldoListComponent } from './openstaandsaldo/saldo-list.component';
import { RekeninggegevensComponent } from './rekeninggegevens/rekeninggegevens.component';
import { VoorschotEditComponent } from './voorschot/voorschot-edit.component';
import { VoorschotListComponent } from './voorschot/voorschot-list.component';

const routes: Routes = [
    { path: 'premie/betaalwijze', component: RekeninggegevensComponent, canActivate: [MeekijkenGuard] },
    { path: 'premie/facturenenspecificaties', component: FacturenListComponent, canActivate: [MeekijkenGuard] },
    // Zie #17543 en ADR
    // { path: 'premie/openstaandbedrag', component: SaldoListComponent, canActivate: [MeekijkenGuard] },
    { path: 'premie/voorschotbedragen', component: VoorschotListComponent, canActivate: [MeekijkenGuard] },
    {
        canActivate: [WijzigenGuard],
        component: VoorschotEditComponent,
        path: 'premie/voorschotbedragen/:regelingnummer/:begin',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class PremieRoutingModule {
}
