import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Link, Panel } from '../navigatie/navigatie.model';

@Component({
    selector: 'home-panel',
    styles: [require('./home-panel.component.scss')],
    template: require('./home-panel.component.html'),
})
export class HomePanelComponent {
    @Input() panel: Panel;

    constructor(private router: Router) {}

    isDisabled(link: Link): boolean {
        return link.isDisabled;
    }
}
