export class UpaInzendInkomstenPeriode {
    id: number;
    begindatum: Date;
    soortInkomstenverhouding: number;
    soortInkomstenverhoudingOmschrijving: string;
    codeOnbepaaldeTijd: string;
    invloedVerzekeringsplicht: string;
    caoCode: number;
    normUren: number;
    parttimePercentage?: number;
    aantalGewerkteDagen?: number;
}
