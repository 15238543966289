import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import {
    Dienstverband,
    DienstverbandOverzicht,
    Filter,
    Response,
} from '../../model';
import { WerknemerAanmelden } from '../../model/werknemer-aanmelden.model';
import { ApiClient } from '../api-client.service';

// tslint:disable-next-line:no-var-requires
const querystring = require('querystring');

@Injectable()
export class DienstverbandService {

    constructor(private api: ApiClient) { }

    download(filter: Filter): Observable<any> {
        return this.api.getFile('dienstverbanden/acties/download?' + querystring.stringify(filter), 'text/csv');
    }

    getDienstverbandOverzichtenWerknemer(relatienummer: number): Observable<DienstverbandOverzicht[]> {
        return this.api
            .get<Response<DienstverbandOverzicht>>('dienstverbanden/' + relatienummer)
            .map((x) => x.items);
    }

    getDienstverbanden(filter: Filter): Observable<Response<Dienstverband>> {
        return this.api.get<Response<Dienstverband>>('dienstverbanden', filter);
    }

    getDienstverbandenByBsn(bsns: string[], maximumIngangsDatumInclusief?: Date): Observable<Response<Dienstverband>> {
        let verzoek: any = { bsns };
        if (maximumIngangsDatumInclusief) {
            verzoek = { bsns, maximumIngangsDatumInclusief };
        }
        return this.api.post<Response<Dienstverband>>('dienstverbanden', verzoek);
    }

    getDienstverbandenHistorieByBsn(bsns: string[]): Observable<Response<Dienstverband>> {
        return this.api.post<Response<Dienstverband>>('dienstverbanden/historie', { bsns });
    }

    updateBegindatum(dienstverbanden: Dienstverband[]): Observable<Dienstverband[]> {
        return this.api.put<Dienstverband[]>('dienstverbanden/acties/begindatum', { dienstverbanden });
    }

    updateEinddatum(dienstverbanden: Dienstverband[]): Observable<Dienstverband[]> {
        return this.api.put<Dienstverband[]>('dienstverbanden/acties/einddatum', { dienstverbanden });
    }

    updateFuncties(dienstverbanden: Dienstverband[]): Observable<Dienstverband[]> {
        return this.api.put<Dienstverband[]>('dienstverbanden/acties/functiewijzigen', { dienstverbanden });
    }

    updateUitDienst(dienstverbanden: Dienstverband[]): Observable<Dienstverband[]> {
        return this.api.put<Dienstverband[]>('dienstverbanden/acties/uitdienst', { dienstverbanden });
    }

    saveWerknemer(aanmelding: WerknemerAanmelden): Observable<Dienstverband> {
        return this.api.post<Dienstverband>('werknemers', aanmelding);
    }
}
