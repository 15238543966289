import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { Accounttype, Recht, Rol } from '../shared';
import { AuthenticationService } from './authentication.service';

/**
 * Alleen een geauthenticeerde user met als accounttype 'pgbmedewerker' mag deze route benaderen.
 */
@Injectable()
export class InternApplicatiebeheerderSchrijvenGuard implements CanActivate {
    /**
     * Initializes a new instance of AuthenticatedGuard
     * @param authSvc The authentication service to use for the authentication check
     * @param router                The router to use for redirecting users to the login page
     */
    constructor(private authSvc: AuthenticationService, private router: Router) {

    }

    /**
     * Checks if the route can be activated
     * @param route
     * @param state
     * @returns Observable<boolean>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const self = this;
        let authenticated = false;
        if (this.authSvc.currentUserNeedsToResetPassword()) {
            if (state.url.indexOf('account/wachtwoord-wijzigen') < 0) {
                self.router.navigate(['account/wachtwoord-wijzigen']);
            }
            return Observable.of(true);
        }
        if (this.authSvc.currentUserNeedsToCreateProfile()) {
            if (state.url.indexOf('profiel/gebruikersgegevens') < 0) {
                self.router.navigate(['profiel/gebruikersgegevens']);
            }
            return Observable.of(true);
        }
        if (sessionStorage.getItem('moetvoorkeurenzetten') === 'true') {
            if (state.url.indexOf('profiel/voorkeuren') < 0) {
                self.router.navigate(['profiel/voorkeuren']);
            }
            return Observable.of(true);
        }
        if (this.authSvc.isCurrentUserApplicatiebeheerder() && !this.authSvc.isCurrentUserExpired()) {
            authenticated = true;
        }
        if (!authenticated) {
            self.router.navigate(['login']);
        }

        return Observable.of(authenticated);
    }
}
