export enum MutatieType {
    InDienst = 0,
    UitDienst = 1,
    DienstverbandFunctieWijzigen = 2,
    DienstverbandDatumInDienstWijzigen = 3,
    DienstverbandDatumUitDienstWijzigen = 4,
    DienstverbandVerwijderen = 5,
    Looncorrectie = 6,
    OuderschapsverlofAanmelden = 7,
    OuderschapsverlofPeriodeWijzigen = 8,
    OuderschapsverlofPercentagesWijzigen = 9,
    OuderschapsverlofAfmelden = 10,
    Voorschot = 11,
}
