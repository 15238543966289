import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IMyDateModel } from 'mydatepicker';

import * as moment from 'moment';

export class StringDateValidator {
    static stringDateCheck(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const m = moment(c.value, 'DD-MM-YYYY', 'nl', true);

            if (c.value === '' || m.isValid()) {
                return null;
            }
            return { format: true };
        };
    }

    static stringDateGreaterOrSameAs(other: Date): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const m = moment(c.value, 'DD-MM-YYYY', 'nl', true);

            if (c.value === '' || m.isValid() && m.isSameOrAfter(other)) {
                return null;
            }
            return { greaterOrSameAs: true };
        };
    }

    static stringDateGreaterThan(other: Date): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const m = moment(c.value);

            if (c.value === '' || m.isValid() && m.isAfter(other)) {
                return null;
            }
            return { greaterOrSameAs: true };
        };
    }
}
