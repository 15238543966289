import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { LoaderState } from './loader-state';
import { LoaderService } from './loader.service';

@Component({
    selector: 'loader',
    template: require('./loader.component.html'),
    styles: [require('../modal.scss')],
})

export class LoaderComponent implements OnInit {
    show: boolean;

    private subscription: Subscription;

    constructor(
            private loaderService: LoaderService,
        ) { }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
