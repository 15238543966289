import {
    Adres,
    Machtiging,
} from '.';

export class Concern {
    naam: string;
    relatienummer: number;
    adressen?: Adres[];
    machtigingen?: Machtiging[];
}
