import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import {
    DeelnemerOuderschapsverlofMutatie,
    WerkgeverOuderschapsverlofMutatie,
    Filter,
    Response,
} from '../../model';
import { ApiClient } from '../api-client.service';

// tslint:disable-next-line:no-var-requires
const querystring = require('querystring');

@Injectable()
export class OuderschapsverlofService {

    constructor(private api: ApiClient) { }

    download(filter: Filter): Observable<any> {
        return this.api.getFile('ouderschapsverlof/acties/download?' + querystring.stringify(filter), 'text/csv');
    }

    getOuderschapsverlof(filter: Filter): Observable<Response<DeelnemerOuderschapsverlofMutatie>> {
        return this.api.get<Response<DeelnemerOuderschapsverlofMutatie>>('ouderschapsverlof', filter);
    }

    getOuderschapsverlofByBsns(bsns: string[], maximumIngangsDatumInclusief?: Date):
        Observable<Response<DeelnemerOuderschapsverlofMutatie>> {
        let verzoek: any = { bsns };
        if (maximumIngangsDatumInclusief) {
            verzoek = { bsns, maximumIngangsDatumInclusief };
        }
        return this.api.get<Response<DeelnemerOuderschapsverlofMutatie>>('ouderschapsverlof/GetByBsns', verzoek);
    }

    doorgevenOuderschapsverlof(mutatie: WerkgeverOuderschapsverlofMutatie): Observable<number> {
        return this.api.post<number>('ouderschapsverlof', mutatie);
    }
}
