export class LoongegevensRegel {
    werkgeverRelatienummer: string;
    bedrijfsnaam: string;
    datumIngangAangifte: Date;
    datumEindeAangifte: Date;
    datumIngangLoontijdvak: Date;
    datumEindeLoontijdvak: Date;
    burgerServiceNummer: string;
    geboortedatum: Date;
    voorletters: string;
    voorvoegsels: string;
    achternaam: string;
    geslacht: string;
    loonbegrip: string;
    loon: number;
    parttimePercentage: number;
    foutomschrijving: string;
}
