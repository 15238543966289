import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { MachtigingenRoutingModule,
         routedComponents } from '.';

@NgModule({
    declarations: [
        routedComponents,
    ],
    exports: [],
    imports: [
        SharedModule,
        MachtigingenRoutingModule,
    ],
    providers: [
    ],
})
export class MachtigingenModule {}
