import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

import { Upa } from '../../model';
import { UpaService } from '../upa.service';

@Component({
    template: require('./upa-details.component.html'),
    styles: ['tr { height: initial; }'],
})
export class UpaDetailsComponent implements OnInit {
    upa: Upa;

    constructor(private route: ActivatedRoute,
                private service: UpaService,
                private toasterService: ToasterService) {
}

    ngOnInit(): void {
        const upaidParam = 'upaid';
        this.route.params.switchMap((params: Params) =>  this.service.getUpa(+params[upaidParam]))
                         .subscribe((response: Upa) => {
                            this.upa = response;
        });
    }
}
