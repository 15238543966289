import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { Navigatie } from './navigatie.model';
import { NavigatieService } from './navigatie.service';

@Component({
    selector: 'navigatie',
    styles: [require('./navigatie.component.scss')],
    template: require('./navigatie.component.html'),
})
export class NavigatieComponent implements OnInit {
    navigation: Navigatie;
    isHomepage: boolean = false;
    profiel: boolean = false;
    upa: boolean = false;
    werknemers: boolean = false;
    premie: boolean = false;
    informatie: boolean = false;
    verbergNavigatie: boolean = false;

    constructor(private router: Router,
        private navigationService: NavigatieService,
        private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.getNavigation();

        this.router.events.subscribe((event: NavigationStart) => {
            this.bepaalWizardPaginas(event);

            if (event.url === '/' || event.url === '/login' ||
                event.url.indexOf('/authorize_callback') >= 0 ||
                event.url === '/logout_callback' ||
                event.url.indexOf('/beheer') >= 0 ||
                event.url.indexOf('/meekijken') >= 0 ||
                event.url.indexOf('/account/wachtwoord-reset') >= 0 ||
                event.url.indexOf('/account/account-aanmaken') >= 0 ||
                this.verbergNavigatie) {
                this.isHomepage = true;
            } else {
                this.isHomepage = false;
            }

            if (event.url.startsWith('/profiel')) {
                this.profiel = true;
                this.upa = false;
                this.werknemers = false;
                this.premie = false;
                this.informatie = false;
            } else if (event.url.startsWith('/upa')) {
                this.profiel = false;
                this.upa = true;
                this.werknemers = false;
                this.premie = false;
                this.informatie = false;
            } else if (event.url.startsWith('/werknemers')) {
                this.profiel = false;
                this.upa = false;
                this.werknemers = true;
                this.premie = false;
                this.informatie = false;
            } else if (event.url.startsWith('/premie')) {
                this.profiel = false;
                this.upa = false;
                this.werknemers = false;
                this.premie = true;
                this.informatie = false;
            } else if (event.url.startsWith('/informatie')) {
                this.profiel = false;
                this.upa = false;
                this.werknemers = false;
                this.premie = false;
                this.informatie = true;
            } else if (event.url.startsWith('/upa')) {
                this.profiel = true;
                this.upa = false;
                this.werknemers = false;
                this.premie = false;
                this.informatie = false;
            }
        });
    }

    private getNavigation() {
        this.navigationService.getNavigatieSubject()
            .subscribe((nav) => {
                this.navigation = nav;
            });
    }

    // De navigatie moet niet getoond worden wanneer deze pagina in de eerste keer inloggen flow zitten.
    private bepaalWizardPaginas(event: NavigationStart) {
        this.authenticationService.getUser()
            .then((user) => {
                if (user != null) {
                    this.verbergNavigatie =
                        (event.url.indexOf('account/wachtwoord-wijzigen') >= 0 &&
                        this.authenticationService.currentUserNeedsToResetPassword()) ||
                        (event.url.indexOf('profiel/gebruikersgegevens') >= 0 &&
                        this.authenticationService.currentUserNeedsToCreateProfile()) ||
                        (event.url.indexOf('profiel/voorkeuren') >= 0 && sessionStorage.getItem('moetvoorkeurenzetten') === 'true');
                }
            });
    }
}
