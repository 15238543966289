import { Component, OnInit } from '@angular/core';

import { Werkgever } from '../../model';
import { BedrijfsgegevensService } from '../../shared';

@Component({
    template: require('./rekeninggegevens.component.html'),
})
export class RekeninggegevensComponent implements OnInit {
    werkgever: Werkgever;

    constructor(private bedrijfsgegevensService: BedrijfsgegevensService) {
    }

    ngOnInit(): void {
        this.retrieve();
    }

    private retrieve(): void {
        this.bedrijfsgegevensService.getWerkgeverRekeningGegevens().subscribe((response) =>
                                                                                this.werkgever = response);
    }
}
