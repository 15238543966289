import { Component, OnInit } from '@angular/core';

import { ToasterService } from 'angular2-toaster';

import {
    DienstverbandPeriode,
    Filter,
    SorteerRichting,
    Werkgever,
} from '../../../model';
import {
    Constants,
    Meldingen,
    UtilService,
} from '../../../shared';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';
import { LoonService } from '../../../shared/services/loon.service';

const parttimepercentage: string = 'parttimepercentage';

@Component({
    styles: ['a{cursor: pointer}'],
    template: require('./loongegevens-list.component.html'),
})
export class LoongegevensListComponent implements OnInit {
    dienstverbandPeriodes: DienstverbandPeriode[];
    werkgever: Werkgever;
    filter: Filter;

    totaalItems: number = 0;
    vanAantal: number = 0;
    totAantal: number = 0;

    constructor(private loonSvc: LoonService,
        private utilService: UtilService,
        private bedrSvc: BedrijfsgegevensService,
        private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        this.bedrSvc.getBedrijfsgegevens().subscribe((x) => this.werkgever = x);
    }

    handleFilterUpdated(filter: Filter): void {
        this.filter = filter;
        this.retrieveLoongegevens();
    }

    handleDeleteFilter(filter: Filter): void {
        this.filter = filter;
        this.retrieveLoongegevens();
    }

    handlePaging(filter: Filter) {
        this.filter = filter;
        this.retrieveLoongegevens();
    }

    handleSorting(filter: Filter) {
        this.filter = filter;
        this.retrieveLoongegevens();
    }

    onDownload(): void {
        this.filter.haalAllesOp1PaginaOp = true;
        this.loonSvc.download(this.filter)
                    .subscribe((d: any) => this.utilService.downloadFile(d, 'OverzichtLoongegevens.csv'));
    }

    onPrint(): void {
        this.utilService.printContent('overzicht-loongegevens', 'Overzicht loongegevens');
    }

    private retrieveLoongegevens(): void {
        this.loonSvc.getLoongegevens(this.filter)
            .subscribe((x) => {
                const totAantal = (x.paginaIndex * x.aantalPerPagina);
                this.dienstverbandPeriodes = x.items;
                this.totaalItems = x.totaalItems;
                this.vanAantal = (x.paginaIndex * x.aantalPerPagina) - (x.aantalPerPagina - 1);
                this.totAantal = totAantal > x.totaalItems ? x.totaalItems : totAantal;
                this.filter.paginaNummer = x.paginaIndex;
                this.filter.aantalItemsPerPagina = x.aantalPerPagina;
            },
            (err) => {
                this.toasterService.pop('error', '', Meldingen.ONGELDIGE_AANVRAAG);
            });
    }
}
