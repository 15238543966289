export class Meldingen {
    public static BEDANKT_VOOR_WIJZIGING = 'Bedankt voor uw wijziging. Wij verwerken deze zo spoedig mogelijk.';
    public static SUCCESSVOL_ONTVANGEN = 'Bedankt! uw wijziging is succesvol ontvangen.';
    public static BSN_KOMT_AL_VOOR = `BSN komt al voor in de lijst.
                                       Annuleer eerst alle regels met deze BSN alvorens deze opnieuw toe te voegen.`;
    public static CONFIRM_DIENSTVERBANDEN_VERLOREN = 'Bij het inladen van een nieuwe lijst dienstverbanden gaan de huidige wijzigingen ' +
        'verloren, weet u het zeker?';
    public static CONFIRM_LOONGEGEVENS_VERLOREN = 'Bij het inladen van een nieuwe lijst loongegevens gaan de huidige wijzigingen ' +
        'verloren, weet u het zeker?';
    public static DIENSTVERBAND_KAN_NIET_BEWERKEN = 'Dienstverband kan niet worden bewerkt.';
    public static DIENSTVERBAND_KAN_NIET_VERWIJDEREN = 'Dienstverband kan niet worden verwijderd.';
    public static DROPDOWN_DEFAULT_KEUZE = 'Maak uw keuze';
    public static FOUT_TIJDENS_WIJZIGEN = 'Er ging iets mis tijdens het wijzigen.';
    public static GEEN_RESULTATEN_VOOR_BSN = 'Geen resultaten gevonden voor BSN.';
    public static GEEN_MUTATIES_OPGEGEVEN = 'Er zijn geen mutaties opgegeven.';
    public static NIET_ALLE_BSNS_GEVONDEN = 'Geen resultaten gevonden voor één of meer van de gekozen BSN(s).';
    public static ONBEKENDE_FOUT = 'Er is een onbekende fout opgetreden tijdens het verwerken van uw wijziging.';
    public static ONGELDIGE_AANVRAAG = 'Ongeldige aanvraag';
    public static WERKNEMER_NIET_GEVONDEN = 'Werknemer niet gevonden.';
    public static FOUT_OPHALEN_GEGEVENS = 'Er is iets fout gegaan bij het ophalen van de gegevens.';
    public static BEDANKT_VOOR_CONTACT = 'Bedankt voor het invullen van het contactformulier. ' +
        'Wij zullen zo spoedig mogelijk hierop reageren.';
    public static FOUT_TIJDENS_CONTACT = 'Er ging iets mis tijdens het versturen van het contactformulier.';
    public static OPENSTAANDE_WIJZIGINGEN =
        'Let op! U heeft wijzigingen gedaan aan het formulier. Als u de pagina verlaat zijn deze wijzigingen verloren.';
    public static SELECTEER_EERST_INDIENSTDATUM = 'Kies eerst begindatum.';
    public static SELECTEER_EERST_BEDRIJFSUITOEFENING = 'Kies eerst bedrijfsuitoefening.';
    public static LOONCORRECTIE_SUCCESVOL_AANGELEVERD = 'De looncorrectie is succesvol aangeleverd';
    public static LOONOPGAVE_SUCCESVOL_AANGELEVERD = 'De loonopgave is succesvol aangeleverd';
    public static FOUT_TIJDENS_VERSTUREN_LOONOPGAVE = 'Er ging iets fout tijdens het versturen van de loonopgave';
    public static FOUT_TIJDENS_VERSTUREN_LOONCORRECTIE = 'Er ging iets fout tijdens het versturen van de looncorrectie';
    public static PROBEER_LATER_NOGMAALS = 'Probeer het later nogmaals';
    public static FOUT_BIJ_BESTAND_UPLOAD = 'Het bestand kan niet worden verwerkt';
    public static FOUT_BIJ_BESTAND_DOWNLOAD = 'Het bestand kan niet worden gedownload';
    public static FOUTVERSLAG_DOWNLOADEN = 'U kunt het foutverslag downloaden via de link boven de knop "uploaden"';
    public static LOONOPGAVE_BEVESTIGING = '<p>Voor een juiste afrekening rekent u het loon van ' +
        'parttime medewerkers NIET om naar een fulltimeloon.</p> ' +
        '<p>Ook rekent u voor werknemers die niet het hele jaar in dienst zijn geweest het loon NIET om naar een jaarloon.</p>' +
        '<p>Wilt u doorgaan met het uploaden van de loongegevens?</p>';
    public static BESTANDSDOWNLOAD_FOUTMELDING = 'Er is iets misgegaan bij het downloaden van het bestand.';
    static GEEN_RESULTATEN_VOOR_FILTER = 'Geen resultaten gevonden voor filter.';
}
