import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../../authentication/authentication.service';
import {
    Adres,
    AdresType,
    Werkgever,
} from '../../model';
import { BedrijfsgegevensService } from '../../shared/services/bedrijfsgegevens.service';

@Component({
    styles: ['ul { list-style: none; padding: 0px; }',
             'ul li { margin: 5px 0px; }',
             'table th {color: #383838;}',
             'accordion { margin: 10px 0px;float: left;width: 100%; }'],
    template: require('./bedrijfsgegevens.component.html'),
})
export class BedrijfsgegevensComponent implements OnInit {
    werkgever: Werkgever;
    vestigingsadres: Adres;
    correspondentieadres: Adres;
    factuuradres: Adres;
    heeftSchrijvenRecht: boolean = false;

    constructor(private bedrijfsgegevensService: BedrijfsgegevensService,
                private authSvc: AuthenticationService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.bedrijfsgegevensService.getBedrijfsgegevens().subscribe((result) => {
            this.werkgever = result;
            result.adressen.forEach((adres) => {
                if (adres.adresType === AdresType.factuuradres) {
                    this.factuuradres = adres;
                } else if (adres.adresType === AdresType.correspondentieadres) {
                    this.correspondentieadres = adres;
                } else if (adres.adresType === AdresType.vestigingsadres) {
                    this.vestigingsadres = adres;
                }
            });
        });
        const isGemachtigde = (this.authSvc.currentUser.profile.accounttype === 'administratiekantoor' ||
        this.authSvc.currentUser.profile.accounttype === 'concern');
        if (isGemachtigde) {
            this.bedrijfsgegevensService.getGemachtigdenRecht()
            .subscribe((recht) => {
                if (recht === 'schrijven') {
                    this.heeftSchrijvenRecht = true;
                } else {
                    this.heeftSchrijvenRecht = false;
                }
            });
        } else {
            this.heeftSchrijvenRecht = this.authSvc.currentUser.profile.recht === 'schrijven';
        }
    }

    wijzigen(): void {
        this.router.navigate(['profiel/bedrijfsgegevens/adresgegevens']);
    }
}
