export * from './upa.model';
export * from './upa-account.model';
export * from './upa-mandaat.model';
export * from './upa-accounttype.model';
export * from './upa-inzender.model';
export * from './upa-inzend-bericht.model';
export * from './upa-inzend-inkomstenverhouding.model';
export * from './upa-inzend-inkomstenperiode.model';
export * from './upa-inzend-regelingdeelname.model';
export * from './upa-stamtabel.model';
export * from './upa-stamgegeven.model';
export * from './upa-inzendperiodes-response.model';
export * from './upa-periodejaar.model';
export * from './upa-softwareleverancier.model';
export * from './upa-softwarepakket.model';
export * from './upa-regeling.model';
