import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IngelogdGuard } from '../authentication/ingelogd-guard.service';
import { Urls } from '../navigatie';
import { OpenstaandeWijzigingenGuard } from '../shared';
import { UpaDetailsComponent } from './upa-details/upa-details.component';
import { UpaMandaatServicebureausComponent} from './upa-mandaat/upa-mandaat-servicebureaus.component';
import { UpaOverzichtComponent } from './upa-overzicht/upa-overzicht.component';
import { UpaWizardComponent } from './upa-wizard/upa-wizard.component';

const routes: Routes = [
    { path: Urls.Upa, component: UpaWizardComponent, canActivate: [IngelogdGuard]  },
    { path: Urls.UpaServiceBureaus + '/:mandaatId', component: UpaMandaatServicebureausComponent, canActivate: [IngelogdGuard]},
    { path: Urls.UpaOverzicht, component: UpaOverzichtComponent, canActivate: [IngelogdGuard]  },
    { path: Urls.UpaOverzicht + '/:upaid', component: UpaDetailsComponent, canActivate: [IngelogdGuard]  },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class UpaRoutingModule {
}
