import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Document } from '../../model';
import { ApiClient } from '../api-client.service';

@Injectable()
export class DocumentBeheerService {
    constructor(private api: ApiClient) { }

    zoekDocumenten(documentsoorten?: string[], fondsnamen?: string[],
        archiveringsDatumBegin?: Date, archiveringsDatumEind?: Date): Observable<Document[]> {
        const request = {
            Documentsoorten: documentsoorten,
            Fondsnamen: fondsnamen,
            ArchiveringsDatumBegin: archiveringsDatumBegin,
            ArchiveringsDatumEind: archiveringsDatumEind,
        };
        return this.api.post('documenten/zoekdocumenten', request);
    }

    ophalenDocument(documentId: number) {
        return this.api.getFile('documenten/ophalendocument?documentId=' + documentId, 'application/pdf');
    }

    ophalenInternDocument(documentNaam: string) {
        return this.api.getFile('documenten/ophaleninterndocument?documentNaam=' + documentNaam, 'application/pdf');
    }
}
