import {
    Adres,
    Machtiging,
} from '.';

export class Administratiekantoor {
    naam: string;
    relatienummer: number;
    adressen?: Adres[];
    machtigingen?: Machtiging[];
}
