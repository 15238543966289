import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyDateModel, IMyOptions } from 'mydatepicker';

import {
    Kerngegevens,
    VoorschotWijziging,
} from '../../model';
import { Constants, Validaties } from '../../shared';
import { DateValidators } from '../../shared/validators/date.validator';
import { NumberValidators } from '../../shared/validators/number.validator';
import { VoorschotService } from './voorschot.service';

@Component({
    styles: ['#uitleg { margin-top: 30px;} .legend table th {width: 150px;} '],
    template: require('./voorschot-edit.component.html'),
})
export class VoorschotEditComponent implements OnInit {
    uitleg: string;
    form: FormGroup;
    voorschotMutatie: VoorschotWijziging;

    ingangsdatumMessage: string;
    jaarpremieMessage: string;
    redenwijzigingMessage: string;

    private myDatePickerOptions: IMyOptions = {
        dateFormat: 'dd-mm-yyyy',
        height: '25px',
        selectionTxtFontSize: '14px',
        showClearDateBtn: false,
        indicateInvalidDate: false,
    };

    private ingangsdatumValidationMessages = {
        currentyear: 'Begindatum moet in het huidige jaar liggen.',
        required: 'Begindatum is verplicht.',
    };

    private jaarpremieValidationMessages = {
        range: 'Jaarpremie dient 0 of hoger te zijn.',
        required: 'Jaarpremie is verplicht',
        isnotround: Validaties.GEEF_GEHEEL_GETAL_OP,
    };

    private redenwijzigingValidationMessages = {
        required: 'Reden is verplicht',
    };

    constructor(
        private voorschotService: VoorschotService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToasterService) {
    }

    cancel() {
        this.router.navigate(['/premie/voorschotbedragen']);
    }

    ngOnInit(): void {
        const ingangsdatumControl = new FormControl('', [Validators.required, DateValidators.inyear(moment().year())]);
        const jaarpremieControl = new FormControl('', [Validators.required, NumberValidators.isRoundNumber(),
                                                       NumberValidators.currencyRange(0, Infinity)]);
        const redenwijzigingControl = new FormControl('', [Validators.required]);

        this.form = new FormGroup({
            ingangsdatum: ingangsdatumControl,
            jaarpremie: jaarpremieControl,
            redenwijziging: redenwijzigingControl,
        });

        jaarpremieControl.valueChanges.subscribe(() => this.setJaarpremieMessage(jaarpremieControl));
        redenwijzigingControl.valueChanges.subscribe(() => this.setRedenWijzigingMessage(redenwijzigingControl));

        const regelingnummerParam = 'regelingnummer';
        const regelingnummer = this.route.snapshot.params[regelingnummerParam];
        const beginParam = 'begin';
        const begin = this.route.snapshot.params[beginParam];
        this.voorschotService.getVoorschot(regelingnummer, begin)
            .subscribe(
            (item) => { this.voorschotMutatie = item; },
            (err) => { this.toasterService.pop('error', '', 'Er is iets fout gegaan bij het ophalen van de gegevens.'); },
        );

        this.voorschotService.getVoorschotUitleg(regelingnummer).subscribe((result) => {
            this.uitleg = result;
        });
    }

    onDateChanged(event: IMyDateModel): void {
        this.setIngangsdatumMessage(this.form.controls.ingangsdatum, event);
    }

    save(): void {
        this.voorschotMutatie.ingangsdatum = moment.utc(this.form.controls.ingangsdatum.value.formatted,
            Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();
        this.voorschotMutatie.bedrag = parseFloat(this.form.controls.jaarpremie.value.replace(',', '.'));
        this.voorschotMutatie.reden = this.form.controls.redenwijziging.value;

        const bedanktMessage: string = `Bedankt voor het doorgeven van het nieuwe voorschotbedrag
            	                         voor ${this.voorschotMutatie.voorschot.regeling.omschrijving}.
                                         Wij verwerken deze wijziging zo spoedig mogelijk.`;
        this.voorschotService.saveVoorschot(this.voorschotMutatie)
            .subscribe(() => {
                this.toasterService.pop('success', '', bedanktMessage);
                this.router.navigate(['/premie/voorschotbedragen']);
            },
            () => {
                this.toasterService.pop('error', '', 'Er is iets fout gegaan bij het opslaan.');
            });
    }

    updateJaarpremie(event: string): void {
        this.voorschotMutatie.bedrag = parseFloat(event.replace(',', '.'));
    }

    setIngangsdatumMessage(c: AbstractControl, event: IMyDateModel): void {
        const selectedYear = event.date.year;
        const year = moment().year();
        const isValid = selectedYear === year;
        if (selectedYear !== 0 && isValid === false) {
            this.ingangsdatumMessage = this.ingangsdatumValidationMessages.currentyear;
        } else if (selectedYear === 0) {
            this.ingangsdatumMessage = this.ingangsdatumValidationMessages.required;
        } else {
            this.ingangsdatumMessage = undefined;
        }
    }

    setJaarpremieMessage(c: AbstractControl): void {
        this.jaarpremieMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.jaarpremieMessage = Object.keys(c.errors).map((key) =>
                this.jaarpremieValidationMessages[key]).join(' ');
        }
    }

    setRedenWijzigingMessage(c: AbstractControl): void {
        this.redenwijzigingMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.redenwijzigingMessage = Object.keys(c.errors).map((key) =>
                this.redenwijzigingValidationMessages[key]).join(' ');
        }
    }
}
