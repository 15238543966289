import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BinnenlandsAdres } from '../../../model';
import {
    NumberValidators,
    PostcodeValidator,
} from '../../../shared';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';
import { PostcodeService } from '../../../shared/services/postcode.service';

@Component({
    selector: 'adres-edit',
    template: require('./adres-edit.component.html'),
})
export class AdresEditComponent implements OnChanges {
    @Input() adres: BinnenlandsAdres;
    @Input() form: FormGroup;

    huisnummerMessage: string;
    postcodeMessage: string;

    private postcodeValidationMessages = {
        postcode: 'Voer een geldige postcode in.',
        required: 'Postcode is een verplicht veld.',
        pattern: 'Postcode mag alleen alfanumerieke karakters bevatten.',
        maxlength: 'Postcode mag maximaal 6 karakters lang zijn.',
    };

    private huisnummerValidationMessages = {
        range: 'Voer een geldig huisnummer in.',
        required: 'Huisnummer is verplicht.',
    };

    innerForm: FormGroup;

    constructor(
        private bedrijfsgegevensService: BedrijfsgegevensService,
        private postcodeService: PostcodeService,
        private fb: FormBuilder) { }

    ngOnChanges(): void {
        this.initForm();
        if (this.form) {
            this.innerForm.setParent(this.form);
        }
    }

    addressChanged(): void {
        if (this.innerForm.get('huisnummer').value && this.innerForm.get('postcode').value) {
            this.enrichAddress();
        }
    }

    initForm() {
        if (!this.innerForm) {
            this.innerForm = this.fb.group({
                huisnummer: new FormControl('', [Validators.required, NumberValidators.range(1, Infinity)]),
                huisnummerToevoeging: new FormControl('', []),
                plaats: new FormControl('', [Validators.required]),
                postcode: new FormControl('', [Validators.required, PostcodeValidator.postcodeCheck()]),
                straat: new FormControl('', [Validators.required]),
            });
        }
        if (this.form && this.adres) {
            this.form.controls[this.adres.adresType] = this.innerForm;
        }
        if (this.adres) {
            this.innerForm.patchValue({ straat: this.adres.straat });
            this.innerForm.patchValue({ postcode: this.adres.postcode });
            this.innerForm.patchValue({ huisnummer: this.adres.huisnummer });
            this.innerForm.patchValue({ huisnummerToevoeging: this.adres.huisnummerToevoeging });
            this.innerForm.patchValue({ plaats: this.adres.plaats });
            this.innerForm.enable();
        } else {
            this.innerForm.disable();
        }

        const huisnummerControl = this.innerForm.get('huisnummer');
        huisnummerControl.valueChanges.subscribe(() => this.setHuisnummerMessage(huisnummerControl));
        const postcodeControl = this.innerForm.get('postcode');
        postcodeControl.valueChanges.subscribe(() => this.setPostcodeMessage(postcodeControl));
    }

    enrichAddress(): void {
        if (!this.innerForm) {
            return;
        }
        const huisnummerControl = this.innerForm.get('huisnummer');
        const postcodeControl = this.innerForm.get('postcode');
        if (!huisnummerControl.valid || !postcodeControl.valid) {
            return;
        }
        this.innerForm.get('straat').setValue(null);
        this.innerForm.get('plaats').setValue(null);

        this.postcodeService.zoekAdres(postcodeControl.value, +huisnummerControl.value).subscribe((result) => {
            this.innerForm.patchValue({ straat: result.straat });
            this.innerForm.patchValue({ plaats: result.plaats });
            this.adres.plaats = result.plaats;
            this.adres.straat = result.straat;
            this.adres.huisnummer = result.huisnummer;
            this.adres.postcode = result.postcode;

            if (result.straat === null || result.plaats === null) {
                this.postcodeMessage = 'Voer een geldige postcode/huisnummer combinatie in.';
                this.huisnummerMessage = 'Voer een geldige postcode/huisnummer combinatie in.';
            } else {
                this.postcodeMessage = '';
                this.huisnummerMessage = '';
            }

        });
    }

    setPostcodeMessage(c: AbstractControl): void {
        this.postcodeMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.postcodeMessage = Object.keys(c.errors).map((key) =>
                this.postcodeValidationMessages[key]).join(' ');
        }
    }

    setHuisnummerMessage(c: AbstractControl): void {
        this.huisnummerMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.huisnummerMessage = Object.keys(c.errors).map((key) =>
                this.huisnummerValidationMessages[key]).join(' ');
        }
    }
}
