import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PostcodeValidator {

    static postcodeCheck(): ValidatorFn {
        const postCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value !== undefined && !postCodeRegex.test(c.value)) {
                return { postcode: true };
            }
            return null;
        };
    }
}
