import { UpaInzendInkomstenPeriode, UpaInzendRegelingDeelname } from '.';

export class UpaInzendInkomstenverhouding {
    id: number;
    nummerInkomstenverhouding: number;
    begindatum: Date;
    einddatum?: Date;
    redenEindeInkomstenverhouding?: number;
    redenEindeInkomstenverhoudingFlex?: number;
    personeelsnummer: string;
    bsn: string;
    voorletters: string;
    voorvoegsels: string;
    achternaam: string;
    geboortedatum: Date;
    geslacht: number;
    datumOverlijden?: Date;
    straatnaam: string;
    huisnummer: string;
    huisnummerToevoeging: string;
    locatieOmschrijving: string;
    postcode: string;
    woonplaats: string;
    regio: string;
    landcode: string;
    regelingDeelnamen: UpaInzendRegelingDeelname[];
    inkomstenPerioden: UpaInzendInkomstenPeriode[];
}
