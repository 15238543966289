import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Regeling, Response } from '../model';
import { ApiClient } from '../shared/api-client.service';

@Injectable()
export class RegelingService {
    private heeftAsfPromise: Promise<boolean>;
    private heeftAsfRelatienummer: string;
    private heeftSfrPromise: Promise<boolean>;
    private heeftSfrRelatienummer: string;

    constructor(private api: ApiClient) { }

    getRegelingen(): Observable<Regeling[]> {
        return this.api.get<Response<Regeling>>('regelingen').map((result) => result.items);
    }

    getRegelingenByBuFu(buFu: any): Observable<Regeling[]> {
        return this.api.get<Response<Regeling>>('regelingen', buFu).map((x) => x.items);
    }

    getRegelingenById(dienstverbandId: number): Observable<Regeling[]> {
        return this.api.get<Regeling[]>('regelingen/' + dienstverbandId, null).map((x) => x);
    }

    getRegelingenHistorie(): Observable<Regeling[]> {
        return this.api.get<Regeling[]>('regelingen/historie');
    }

    getRegelingenForWerkgever(relatienummer: number): Observable<Regeling[]> {
        return this.api.get<Response<Regeling>>(`werkgevers/${relatienummer}/regelingen`).map((x) => x.items);
    }

    heeftAsf(relatienummer: string): Observable<boolean> {
        // By converting it to a promise, the API request will only be once in the lifetime of RegelingService
        // or when relatienummer changes
        if (!this.heeftAsfPromise || (this.heeftAsfRelatienummer !== relatienummer)) {
            this.heeftAsfPromise = this.api.get<boolean>('regelingen/actions/heeftAsf').toPromise();
            this.heeftAsfRelatienummer = relatienummer;
        }
        return Observable.fromPromise(this.heeftAsfPromise);
    }

    heeftSfr(relatienummer: string): Observable<boolean> {
        // By converting it to a promise, the API request will only be once in the lifetime of RegelingService
        // or when relatienummer changes
        if (!this.heeftSfrPromise || (this.heeftSfrRelatienummer !== relatienummer)) {
            this.heeftSfrPromise = this.api.get<boolean>('regelingen/actions/heeftSfr').toPromise();
            this.heeftSfrRelatienummer = relatienummer;
        }
        return Observable.fromPromise(this.heeftSfrPromise);
    }
}
