import {
    Aanleverperiodiciteit,
    Administratiekantoor,
    Adres,
    Bedrijfsuitoefening,
    BinnenlandsAdres,
    Concern,
    Partij,
    Rekeninggegevens,
    WerkgeversOrganisatie,
} from '.';

export class Werkgever {
    aanleverPeriodiciteit?: Aanleverperiodiciteit;
    relatienummer: number;
    identificatie: number;
    naam: string;
    kvkNummer: string;
    loonheffingsnummer: string;
    upaAanleverwijze?: string;
    upaAanleverwijzeStartdatum?: Date;
    heeftUpa?: boolean;
    rekeninggegevens?: Rekeninggegevens;
    adressen?: BinnenlandsAdres[];
    bedrijfsuitoefeningen?: Bedrijfsuitoefening[];
    werkgeversorganisaties?: WerkgeversOrganisatie[];
    administratiekantoor?: Administratiekantoor;
    concern?: Concern;
    heeftOpenstaandeLooncontrole?: boolean;
    magBestandenUitwisselen?: boolean;
    sectornummer?: number;
}
