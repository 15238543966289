import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Filter } from '../../model/filter.model';
import { SorteerRichting } from '../../model/sorteer-richting.model';
import { DeelnemerOuderschapsverlofMutatie, WerkgeverOuderschapsverlofMutatie } from '../../model';
import { OuderschapsverlofService } from '../../shared/services/ouderschapsverlof.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { User } from 'oidc-client';
import { ToasterService } from 'angular2-toaster';
import { Meldingen } from '../../shared/meldingen';

@Component({
    selector: 'ouderschapsverlof-tabel',
    template: require('./ouderschapsverlof-tabel.component.html'),
})
export class OuderschapsverlofTableComponent implements OnInit {
    user: User;
    @Input() ouderschapsverlofMutaties: DeelnemerOuderschapsverlofMutatie[];
    @Input() filter: Filter;

    @Output() sortingChanged = new EventEmitter();

    year = (new Date()).getFullYear();
    yearPrev = (this.year - 1);
    yearCurrent = (this.year);
    yearNext = (this.year + 1);

    public columns: any[] = [
        { title: 'Naam', name: 'naam', sortable: true, sort: 'asc', isSortActive: true, display: true },
        { title: 'Geslacht', name: 'geslacht', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Geboortedatum', name: 'geboortedatum', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'BSN', name: 'bsn', sortable: true, sort: 'desc', isSortActive: false, display: true },
        {
            title: this.yearPrev.toString(),
            name: this.yearPrev.toString(), sortable: false, sort: 'desc', isSortActive: 'false', display: true,
        },
        {
            title: this.yearCurrent.toString(),
            name: this.yearCurrent.toString(), sortable: false, sort: 'desc', isSortActive: 'false', display: true,
        },
        {
            title: this.yearNext.toString(),
            name: this.yearNext.toString(), sortable: false, sort: 'desc', isSortActive: 'false', display: true,
        },

    ];

    constructor(
        private ouderschapsverlofService: OuderschapsverlofService,
        private authService: AuthenticationService,
        private toasterServicer: ToasterService) {
        this.authService
            .getUser()
            .then((user) => this.user = user);
    }

    ngOnInit(): void {
        return;
    }

    onSelect(deelnemer: DeelnemerOuderschapsverlofMutatie): void {
        return;
    }

    onSelectionChanged(e: any) {
        const item = e.target.name.split('-');
        if (item[1].includes('PrevYear')) {
            this.doorgevenOuderschapsverlof(item[0], this.yearPrev, e.target.checked);
        } else if (item[1].includes('CurrentYear')) {
            this.doorgevenOuderschapsverlof(item[0], this.yearCurrent, e.target.checked);
        } else if (item[1].includes('NextYear')) {
            this.doorgevenOuderschapsverlof(item[0], this.yearNext, e.target.checked);
        }
    }

    doorgevenOuderschapsverlof(relatienummer: number, jaar: number, status: boolean) {
        const mutatie: WerkgeverOuderschapsverlofMutatie = new WerkgeverOuderschapsverlofMutatie();
        mutatie.jaar = jaar;
        mutatie.status = status;
        mutatie.deelnemerRelatienummer = relatienummer;
        mutatie.changedBy = this.user.profile.name;
        mutatie.changedOn = new Date();
        this.ouderschapsverlofService
            .doorgevenOuderschapsverlof(mutatie)
            .subscribe((id) => {
                this.toasterServicer.pop('success', '', Meldingen.SUCCESSVOL_ONTVANGEN);
            }, (error) => {
                this.toasterServicer.pop('error', '', Meldingen.ONBEKENDE_FOUT);
            });
    }

    toggleSorting(columnName: string) {
        let richting: SorteerRichting = SorteerRichting.Ascending;
        this.columns.forEach((c: any) => {
            if (c.name !== columnName) {
                c.isSortActive = false;
            } else {
                if (c.isSortActive) {
                    c.sort = c.sort === 'asc' ? 'desc' : 'asc';
                } else {
                    c.isSortActive = true;
                }
                richting = c.sort === 'asc' ? SorteerRichting.Ascending : SorteerRichting.Descending;
            }
        });

        this.filter.paginaNummer = 1;
        this.filter.sorteerRichting = richting;
        this.filter.sorteerVeld = columnName;

        this.sortingChanged.emit(this.filter);
    }
}
