import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { IMyInputFieldChanged, IMyOptions } from 'mydatepicker';

import { UpaAccount, UpaMandaat } from '../../model';

import { Constants } from '../../shared';
import { UpaService } from '../upa.service';

@Component({
    selector: 'upa-inzender-instellen',
    template: require('./upa-inzender-instellen.component.html'),
    styles: ['.radio label { margin-right: 10px; }'],
})
export class UpaInzenderInstellenComponent implements OnInit {
    @Input() mandaat: UpaMandaat;
    @Input() mandaatLijst: UpaMandaat[];
    @Output() opgeslagen = new EventEmitter();
    keuze: string = 'administratiekantoor';
    ingevuldeAk: string;
    form: FormGroup;
    servicekantoren: UpaAccount[];
    parentMandaatId: number;
    loonheffingsnummer: string;
    einddatumWaarschuwing: string;
    hasOverlappingMandate: boolean = false;

    constructor(private fb: FormBuilder,
        private service: UpaService,
        private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        let einddatumValidators: any[] = [];
        if (this.mandaat) {
            // Als er een nieuwe submandaat wordt gemaakt parentId in formulier vullen en mandaat legen
            if (this.mandaat.parentId && !this.mandaat.startdatum) {
                this.parentMandaatId = this.mandaat.parentId;
                this.loonheffingsnummer = this.mandaat.loonheffingsnummer;
                this.keuze = 'servicekantoor';
                this.mandaat = null;
            } else {
                einddatumValidators = [Validators.required];
            }
        }
        this.form = this.fb.group({
            administratiekantoor: this.fb.control(''),
            servicekantoor: this.fb.control(''),
            intrekken: this.fb.control(''),
            begindatum: this.fb.control('', [Validators.required]),
            einddatum: this.fb.control('', einddatumValidators),
        });

        this.fillServicekantoren();
        if (this.mandaat) {
            const startDatum = moment.utc(this.mandaat.startdatum);
            this.form.controls.begindatum.setValue({
                date: {
                    year: startDatum.year(),
                    month: startDatum.month() + 1,
                    day: startDatum.date(),
                },
                formatted: startDatum.format('dd-MM-yyyy'),
            });

            if (this.mandaat.einddatum) {
                const eindDatum = moment.utc(this.mandaat.einddatum);
                this.form.controls.einddatum.setValue({
                    date: {
                        year: eindDatum.year(),
                        month: eindDatum.month() + 1,
                        day: eindDatum.date(),
                    },
                    formatted: eindDatum.format('dd-MM-yyyy'),
                });
                this.form.controls.einddatum.disable();
            }
            this.form.controls.administratiekantoor.setValue(`${this.mandaat.partijnaam} (${this.mandaat.relatienummer})`);
            this.form.controls.administratiekantoor.disable();
            if (this.mandaat.ingetrokken) {
                this.form.controls.intrekken.disable();
            }
            // #8925: niet meer automatisch startdatum invullen
        // } else {
        //     const startDate: Date = new Date(moment().year(), 0, 1);
        //     this.form.controls.begindatum.setValue({
        //         date: { year: moment().year(), month: 1, day: 1 },
        //         formatted: '01-01-' + moment().year().toString(),
        //     });
        }
    }

    private handleStartDateChange(startDate: Date) {
        this.einddatumWaarschuwing = '';
        if (!this.mandaat) {
            this.form.controls.einddatum.setValue(null);

            // check whether the desired start date falls within an existing mandate
            if (this.overlapsWithExistingMandate(startDate)) {
                this.einddatumWaarschuwing = `Let op! Het is niet mogelijk om een mandaat aan te maken
                                            met de opgegeven startdatum omdat er overlap is met een actief mandaat.`;
                this.hasOverlappingMandate = true;
                return;
            }

            // if a mandate already exists that starts after the desired start date, set the end
            // date for the new mandate to the last day before the start of the existing mandate
            const nextDate = this.getStartDateForNextMandate(startDate);
            if (nextDate) {
                const endDate = moment(nextDate).subtract(1, 'days');
                if (endDate.toDate().setHours(0, 0, 0, 0) >= startDate.setHours(0, 0, 0, 0)) {
                    const nextDateFormatted = moment(nextDate).format('DD-MM-YYYY');
                    const endDateFormatted = endDate.format('DD-MM-YYYY');
                    this.einddatumWaarschuwing = `Let op! Vanwege een bestaand mandaat dat start op ${nextDateFormatted}
                        zal de einddatum van dit nieuwe mandaat ingesteld worden op ${endDateFormatted}.`;
                    this.form.controls.einddatum.setValue({
                        date: { year: endDate.year(), month: endDate.month(), day: endDate.day() },
                        formatted: endDateFormatted,
                    });
                }
            }
        }
        this.hasOverlappingMandate = false;
    }

    private getStartDateForNextMandate(date: Date): Date {
        if (!this.mandaatLijst) {
            return null;
        }
        let nextMandateStartDate: number;
        const newMandateStartDate = date.setHours(0, 0, 0, 0);
        for (const m of this.mandaatLijst) {
            const existingMandateStartDate = new Date(m.startdatum.toString()).setHours(0, 0, 0, 0);
            if (!m.ingetrokken && existingMandateStartDate <= newMandateStartDate &&
               (!m.einddatum || new Date(m.einddatum.toString()).setHours(0, 0, 0, 0) >= newMandateStartDate)) {
                // overlap met bestaand mandaat; geen "next date" teruggeven, zodat ook geen melding
                // over instellen einddatum wordt getoond (bij opslaan zal de melding over overlap getoond worden)
                return null;
            }
            if (existingMandateStartDate > newMandateStartDate &&
                    (!nextMandateStartDate || existingMandateStartDate < nextMandateStartDate) &&
                    !m.ingetrokken) {
                    nextMandateStartDate = existingMandateStartDate;
                }
        }
        return new Date(nextMandateStartDate);
    }

    private overlapsWithExistingMandate(date: Date): boolean {
        if (!this.mandaatLijst) {
            return false;
        }
        const newMandateStartDate = date.setHours(0, 0, 0, 0);
        for (const m of this.mandaatLijst) {
            if (!m.ingetrokken) {
                const existingMandateStartDate = new Date(m.startdatum.toString()).setHours(0, 0, 0, 0);
                if (newMandateStartDate >= existingMandateStartDate &&
                   (!m.einddatum || newMandateStartDate <= new Date(m.einddatum.toString()).setHours(0, 0, 0, 0))) {
                    // overlap met bestaand mandaat; geen "next date" teruggeven, zodat ook geen melding
                    // over instellen einddatum wordt getoond (bij opslaan zal de melding over overlap getoond worden)
                    return true;
                }
            }
        }
        return false;
    }

    onStartDateChange(event: IMyInputFieldChanged) {
        if (event.valid) {
            this.handleStartDateChange(moment(event.value, 'DD-MM-YYYY').toDate());
        }
    }

    fillServicekantoren() {
        this.service.getServicekantoren()
            .subscribe((result) => {

                this.servicekantoren = [];

                // tslint:disable-next-line:prefer-for-of
                for (let index = 0; index < result.length; index++) {
                    const servicekantoor = result[index];
                    if (servicekantoor) {
                        this.servicekantoren.push(servicekantoor);
                    }
                }
            });
    }

    save() {
        let ingetrokken: boolean = false;
        if (this.form.get('intrekken').value) {
            ingetrokken = true;
        }
        if (this.mandaat) {
            this.beeindigMandaat(ingetrokken);
        } else {
            const data = new UpaMandaat();

            const administratiekantoor = this.form.get('administratiekantoor').value;
            const servicekantoor = this.form.get('servicekantoor').value;

            data.startdatum = moment.utc(this.form.get('begindatum').value.formatted,
                Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();

            if (this.form.controls.begindatum.value) {
                if (this.form.controls.einddatum.value) {
                data.einddatum = moment.utc(this.form.controls.einddatum.value.formatted,
                    Constants.DATE_DEFAULT_FORMAT.toUpperCase()).toDate();
                }
            }

            if (administratiekantoor) {
                data.relatienummer = administratiekantoor;
            } else if (servicekantoor) {
                data.relatienummer = servicekantoor;
            } else {
                this.toasterService.pop('error', '', 'Selecteer een administratiekantoor of servicekantoor');
                return;
            }

            if (this.parentMandaatId) {
                data.parentId = this.parentMandaatId;
                data.loonheffingsnummer = this.loonheffingsnummer;
            }

            this.service.opslaanMandaat(data)
                .subscribe(
                    () => {
                        this.toasterService.pop('success', '', 'Succesvol aangemaakt!');
                        this.resetForm();
                        this.opgeslagen.emit();
                    },
                    (err: Response) => {
                        if (err.status === 400) {
                            // tslint:disable-next-line:no-string-literal
                            this.toasterService.pop('error', '', err.json()['message']);
                        } else {
                            this.toasterService.pop('error', '', 'Er is iets mis gegaan');
                        }
                    });
        }
    }

    beeindigMandaat(ingetrokken: boolean) {
        if (ingetrokken) {
            this.service.intrekkenMandaat(this.mandaat.id).subscribe(() => {
                this.toasterService.pop('success', '', 'Succesvol ingetrokken.');
                this.resetForm();
                this.opgeslagen.emit();
            },
                (err: Response) => {
                    if (err.status === 400) {
                        // tslint:disable-next-line:no-string-literal
                        this.toasterService.pop('error', '', err.json()['message']);
                    } else {
                        this.toasterService.pop('error', '', 'Er is iets mis gegaan');
                    }
                });
        } else {
            const eindDatum = this.form.get('einddatum').value.date;
            this.service.beeindigMandaat(this.mandaat.id,
                moment.utc([eindDatum.year, eindDatum.month - 1, eindDatum.day]).toDate()).subscribe(
                    () => {
                        this.toasterService.pop('success', '', 'Succesvol beëindigd.');
                        this.resetForm();
                        this.opgeslagen.emit();
                    },
                    (err: Response) => {
                        if (err.status === 400) {
                            // tslint:disable-next-line:no-string-literal
                            this.toasterService.pop('error', '', err.json()['message']);
                        } else {
                            this.toasterService.pop('error', '', 'Er is iets mis gegaan');
                        }
                    });
        }
    }

    zoekAdministratiekantoor(relatienummer: string) {
        if (relatienummer) {
            this.service.getAccountNaam(relatienummer).subscribe(
                (result) => {
                    this.ingevuldeAk = 'Gevonden: ' + result;
                },
                (err: Response) => {
                    if (err.status === 400) {
                        // tslint:disable-next-line:no-string-literal
                        this.ingevuldeAk = err.json()['message'];
                    } else {
                        this.ingevuldeAk = 'Er is iets mis gegaan';
                    }
                });
        }
    }

    maakKeuze(keuze: string) {
        this.keuze = keuze;
    }

    cancel() {
        this.resetForm();
        this.opgeslagen.emit();
    }

    resetForm() {
        this.mandaat = null;
        this.form.reset();
        this.ngOnInit();
    }

    mandaatIntrekken() {
        if (this.form.controls.intrekken.value === true &&
            (this.form.controls.einddatum.value === '' || this.form.controls.einddatum.value === null)) {
            this.form.controls.einddatum.setValue({
                date:
                    { year: moment().year(), month: moment().month() + 1, day: moment().date() },
            });
        }
    }
}
