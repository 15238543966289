import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';

import { ToasterService } from 'angular2-toaster';

import { BedragFormatPipe, PercentageFormatPipe } from '../../shared';

import { Mutatie, MutatieType } from '../../model';
import { MutatieService} from '../mutatie.service';

@Component({
    template: require('./mutatie-details.component.html'),
})
export class MutatieDetailsComponent implements OnInit {
    mutatie: Mutatie;
    mutatieOverzicht: any;

    constructor(private mutatieService: MutatieService,
                private route: ActivatedRoute,
                private router: Router,
                private toasterService: ToasterService) {
                 }

    ngOnInit(): void {
        const idParam = 'id';
        this.route.params
            .switchMap((params: Params) => this.mutatieService.getMutatie(params[idParam]))
            .subscribe((mutatie) => {
                this.mutatie = mutatie;
                this.setMutatieOverzicht(mutatie);
            },
            (err) => {
                this.toasterService.pop('error', '', `Ongeldige aanvraag`);
            });
    }

    onBack(): void {
        this.router.navigate(['/mutaties']);
    }

    setMutatieOverzicht(mutatie: any) {
        const mutatieTypeString = MutatieType[mutatie.mutatieType].toLowerCase();
        // Laad html template op basis van mutatietype
        this.mutatieService.getDetailTemplate(mutatieTypeString).subscribe((txt) => {
            this.mutatieOverzicht = this.genereerTemplate(txt, mutatie);
        });
    }

    /**
     * Replaced de placeholders in de html template op basis van de propertynamen
     * Voor specieke types worden onderstaande custom pipes gebruikt
     * Angular handelt zelf xss af: https://angular.io/guide/security#angulars-cross-site-scripting-security-model
     * @param {string} template
     * @param mutatie
     * @returns {string}
     */
    genereerTemplate(template: string, mutatie: any): string {
        // tslint:disable-next-line:only-arrow-functions
        template = template.replace(/{{(\w+)}}/g, function(match: string, property: string) {
            return typeof mutatie[property] !== 'undefined' && mutatie[property] !== null
                ? mutatie[property]
                : ''
            ;
            });

        template = this.formatDatum(mutatie, template);
        template = this.formatBoolean(mutatie, template);
        template = this.formatPercentage(mutatie, template);
        template = this.formatBedrag(mutatie, template);

        return template;
    }

    private formatDatum(mutatie: any, template: any) {
        // tslint:disable-next-line:only-arrow-functions
        template = template.replace(/({{ ?\w+ \| (datum)+ ?}})/g, function(match: string, property: string) {
            property = /\w+/g.exec(property)[0];
            const datePipe = new DatePipe('nl');

            return typeof mutatie[property] !== 'undefined' && mutatie[property] !== null
                ? datePipe.transform(mutatie[property], 'dd-MM-yyyy')
                : ''
            ;
            });
        return template;
    }

    private formatBoolean(mutatie: any, template: any) {
        // tslint:disable-next-line:only-arrow-functions
        template = template.replace(/({{ ?\w+ \| (boolean)+ ?}})/g, function(match: string, property: string) {
            property = /\w+/g.exec(property)[0];

            return typeof mutatie[property] !== 'undefined'
                ? mutatie[property] ? 'Ja' : 'Nee'
                : ''
            ;
            });
        return template;
    }

    private formatPercentage(mutatie: any, template: any) {
        // tslint:disable-next-line:only-arrow-functions
        template = template.replace(/({{ ?\w+ \| (percentage)+ ?}})/g, function(match: string, property: string) {
            property = /\w+/g.exec(property)[0];
            const percentagePipe = new PercentageFormatPipe();

            return typeof mutatie[property] !== 'undefined' && mutatie[property] !== null
                ? percentagePipe.transform(mutatie[property], 0)
                : ''
            ;
            });
        return template;
    }

    private formatBedrag(mutatie: any, template: any) {
        // tslint:disable-next-line:only-arrow-functions
        template = template.replace(/({{ ?\w+ \| (bedrag)+ ?}})/g, function(match: string, property: string) {
            property = /\w+/g.exec(property)[0];
            const bedragFormatPipe = new BedragFormatPipe();

            return typeof mutatie[property] !== 'undefined'
                ? bedragFormatPipe.transform(mutatie[property])
                : ''
            ;
            });
        return template;
    }
}
