import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import 'rxjs/add/operator/switchMap';

import {
    DienstverbandOverzicht,
    IndividueelContract,
    Persoon,
    Werkgever,
} from '../../../model';
import { DienstverbandPeriode } from '../../../model/dienstverband-periode.model';
import {
    RegelingService,
} from '../../../regelingen/regeling.service';
import {
    DienstverbandService,
    Meldingen,
} from '../../../shared';
import { Constants } from '../../../shared/constants';
import { LoaderService } from '../../../shared/modal/loading/loader.service';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';
import { UtilService } from '../../../shared/util.service';

@Component({
    styles: [`table {
        width: 100%;
        max-width: 100%;
    }`,
        `.werknemer-details {
        margin-top: 40px;
    }`],
    template: require('./dienstverband.component.html'),
})
export class DienstverbandComponent implements OnInit {
    dienstverbandOverzichten: DienstverbandOverzicht[];
    werknemer: Persoon;
    werkgever: Werkgever;

    ptHeader: string = 'Parttime %';
    displayGewerkteUren: boolean = false;
    displayParttimePercentage: boolean = false;

    bezigMetRegelingenLaden: boolean = false;
    extraPpContracten: IndividueelContract[];
    individueelANWContracten: IndividueelContract[];

    constructor(
        private dienstverbandenService: DienstverbandService,
        private bedrSvc: BedrijfsgegevensService,
        private regelingService: RegelingService,
        private utilService: UtilService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToasterService,
        private loaderService: LoaderService) { }

    ngOnInit(): void {
        const relatienummerParam = 'relatienummer';
        this.bedrSvc.getBedrijfsgegevens().subscribe((x) => this.werkgever = x);
        this.route.params
            .switchMap((params: Params) => this.dienstverbandenService.getDienstverbandOverzichtenWerknemer(+params[relatienummerParam]))
            .subscribe((response: DienstverbandOverzicht[]) => {
                if (!response || response.length === 0) {
                    this.toasterService.pop('error', '', Meldingen.WERKNEMER_NIET_GEVONDEN);
                } else {
                    this.dienstverbandOverzichten = response.sort(this.compareDesc);
                    const overzicht = this.dienstverbandOverzichten[0];
                    overzicht.geopendTonen = true;
                    this.werknemer = overzicht.dienstverband.werknemer;
                    this.bepaalWelkVeldVoorPtGetoondMoetWorden(overzicht.dienstverbandPeriodes);
                    this.haalRegelingenOp(this.dienstverbandOverzichten);
                    this.extraPpContracten = overzicht.individueleContracten.filter((x) => x.individueelContractType === 'P');
                    this.individueelANWContracten = overzicht.individueleContracten.filter((x) => x.individueelContractType === 'A');
                }
            },
            (err) => { this.toasterService.pop('error', '', 'Ongeldige aanvraag'); },
        );
    }

    onBack(): void {
        this.router.navigate(['/werknemers', 'dienstverbanden'],
            {
                queryParamsHandling: 'preserve',
            });
    }

    private compareDesc(a: DienstverbandOverzicht, b: DienstverbandOverzicht) {
        if (a.dienstverband.begin < b.dienstverband.begin) {
            return 1;
        }
        if (a.dienstverband.begin > b.dienstverband.begin) {
            return -1;
        }
        return 0;
    }

    private bepaalWelkVeldVoorPtGetoondMoetWorden(dvbps: DienstverbandPeriode[]): void {
        this.ptHeader = this.utilService.bepaalParttimePercentageHeader(dvbps, this.werkgever);
        if (this.ptHeader === Constants.GEWERKTE_UREN_HEADER) {
            this.displayGewerkteUren = true;
            this.displayParttimePercentage = false;
        } else {
            this.displayGewerkteUren = false;
            this.displayParttimePercentage = true;
        }
    }

    private haalRegelingenOp(dienstverbanden: DienstverbandOverzicht[]) {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < dienstverbanden.length; index++) {
            const dienstverband = dienstverbanden[index];
            this.bezigMetRegelingenLaden = true;
            this.regelingService.getRegelingenById(dienstverband.dienstverband.dienstverbandIdentificatie).subscribe((x) => {
                dienstverbanden[index].regelingen = x;
                this.bezigMetRegelingenLaden = false;
            });
            this.loaderService.hide();
        }
    }
}
