import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Profiel } from '../../../model';
import { Urls } from '../../../navigatie/urls';
import {
  EmailValidator,
  Meldingen,
  TelefoonnummerValidator,
  Validaties,
} from '../../../shared';
import { Accounttype } from '../../../shared/accounttype';
import { SubaccountsService } from '../subaccounts.service';

@Component({
  template: require('./subaccount-aanmaken.component.html'),
})
export class SubaccountAanmakenComponent implements OnInit {
  profiel: Profiel = new Profiel();
  rechttype: string;
  form: FormGroup;

  aanhefMessage: string;
  aanhefValidationMessages = {
    required: Validaties.AANHEF_VERPLICHT,
  };

  naamMessage: string;
  naamValidationMessages = {
    required: Validaties.NAAM_VERPLICHT,
  };

  emailadresMessage: string;
  emailadresValidationMessages = {
    email: Validaties.GELDIG_EMAIL,
    required: Validaties.EMAIL_VERPLICHT,
  };

  functieMessage: string;
  functieValidationMessages = {
    required: Validaties.FUNCTIE_VERPLICHT,
  };

  telefoonnummerMessage: string;
  telefoonnummerValidationMessages = {
    required: Validaties.TELEFOONNUMMER_VERPLICHT,
    telefoonnummer: Validaties.GELDIG_TELEFOONNUMMER,
  };

  rechttypeMessage: string;
  rechttypeValidationMessages = {
    required: Validaties.RECHTEN_VERPLICHT,
  };

  constructor(private router: Router,
    private subaccountSvc: SubaccountsService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.initForm();
  }

  save() {
    this.profiel.gebruikersnaam = 'placeholder';
    this.profiel.aanhef = this.form.controls.aanhef.value;
    this.profiel.voornaam = this.form.controls.voornaam.value;
    this.profiel.voorvoegsels = this.form.controls.voorvoegsels.value;
    this.profiel.achternaam = this.form.controls.achternaam.value;
    this.profiel.functie = this.form.controls.functie.value;
    this.profiel.telefoonnummer = this.form.controls.telefoonnummer.value;
    this.profiel.emailadres = this.form.controls.emailadres.value;
    const rechttype: string = this.form.controls.rechttype.value;

    this.subaccountSvc.createAccount(this.profiel, rechttype)
      .subscribe(
      () => {
        this.toasterService.pop('success', '', 'Het account is aangemaakt.');
        this.return();
      },
      () => {
        this.toasterService.pop('error', '', 'Er ging iets mis tijdens het aanmaken.');
      });
  }

  return() {
    this.router.navigate([Urls.SubaccountBeheer]);
  }

  public initForm(): void {
    const aanhefControl = new FormControl(this.profiel.aanhef, [Validators.required]);
    const voornaamControl = new FormControl(this.profiel.voornaam, [Validators.required]);
    const voorvoegselsControl = new FormControl(this.profiel.voorvoegsels);
    const achternaamControl = new FormControl(this.profiel.achternaam, [Validators.required]);
    const emailadresControl = new FormControl(this.profiel.emailadres, [Validators.required, EmailValidator.emailCheck()]);
    const functieControl = new FormControl(this.profiel.functie, [Validators.required]);
    const telefoonnummerControl = new FormControl(this.profiel.telefoonnummer,
      [Validators.required, TelefoonnummerValidator.telefoonnummerCheck()]);
    const rechttypeControl = new FormControl(this.rechttype, [Validators.required]);
    const schrijvenRechtControl = new FormControl('');
    const lezenRechtControl = new FormControl('');

    this.form = this.fb.group({
      aanhef: aanhefControl,
      voornaam: voornaamControl,
      voorvoegsels: voorvoegselsControl,
      achternaam: achternaamControl,
      emailadres: emailadresControl,
      functie: functieControl,
      telefoonnummer: telefoonnummerControl,
      rechttype: rechttypeControl,
      schrijvenRecht: schrijvenRechtControl,
      lezenRecht: lezenRechtControl,
    });

    aanhefControl.valueChanges.subscribe(() => {
      this.aanhefMessage = this.getValidationMessage(aanhefControl, this.aanhefValidationMessages);
    });
    voornaamControl.valueChanges.subscribe(() => {
      this.naamMessage =
        this.getValidationMessage(voornaamControl, this.naamValidationMessages);
    });
    achternaamControl.valueChanges.subscribe(() => {
      this.naamMessage =
        this.getValidationMessage(achternaamControl, this.naamValidationMessages);
    });
    emailadresControl.valueChanges.subscribe(() => {
      this.emailadresMessage =
        this.getValidationMessage(emailadresControl, this.emailadresValidationMessages);
    });
    functieControl.valueChanges.subscribe(() => {
      this.functieMessage =
        this.getValidationMessage(functieControl, this.functieValidationMessages);
    });
    telefoonnummerControl.valueChanges.subscribe(() => {
      this.telefoonnummerMessage =
        this.getValidationMessage(telefoonnummerControl, this.telefoonnummerValidationMessages);
    });
    lezenRechtControl.valueChanges.subscribe(() => {
      if (schrijvenRechtControl.value === true) {
        rechttypeControl.setValue('schrijven');
      } else if (lezenRechtControl.value === true) {
        rechttypeControl.setValue('lezen');
      } else {
        rechttypeControl.setValue('');
      }
      this.rechttypeMessage = this.getValidationMessage(rechttypeControl, this.rechttypeValidationMessages);
    });
    schrijvenRechtControl.valueChanges.subscribe(() => {
      if (schrijvenRechtControl.value === true) {
        rechttypeControl.setValue('schrijven');
      } else if (lezenRechtControl.value === true) {
        rechttypeControl.setValue('lezen');
      } else {
        rechttypeControl.setValue('');
      }
      this.rechttypeMessage = this.getValidationMessage(rechttypeControl, this.rechttypeValidationMessages);
    });
  }

  public isDirty(): boolean {
    return this.profiel.aanhef !== this.form.controls.aanhef.value ||
      this.profiel.voornaam !== this.form.controls.voornaam.value ||
      (!(this.form.controls.voorvoegsels.value === '' && this.profiel.voorvoegsels === null) &&
        this.profiel.voorvoegsels !== this.form.controls.voorvoegsels.value) ||
      this.profiel.achternaam !== this.form.controls.achternaam.value ||
      this.profiel.functie !== this.form.controls.functie.value ||
      this.profiel.telefoonnummer !== this.form.controls.telefoonnummer.value ||
      this.profiel.emailadres !== this.form.controls.emailadres.value ||
      this.rechttype !== this.form.controls.rechttype.value;
  }

  public getValidationMessage(c: AbstractControl, validationMessages: any): string {
    if ((c.touched || c.dirty) && c.errors) {
      return Object.keys(c.errors).map((key) => validationMessages[key]).join(' ');
    }

    return '';
  }

  updateDisplayRechten(src: HTMLInputElement, target: HTMLInputElement): void {
    if (!target.checked && src.checked) {
      target.click();
    }
    if (src.checked) {
      target.disabled = true;
    } else {
      target.disabled = false;
    }
  }
}
