import { Component, Input } from '@angular/core';
import {
    Adres,
    AdresType,
} from '../model';

@Component({
    selector: 'adres',
    template: `
                <h2 *ngIf="displayType">{{type}}</h2>
                <div>{{adres.straat}} {{adres.huisnummer}} {{adres.huisnummerToevoeging}}</div>
                <div>{{adres.postcode}} {{adres.plaats}}</div>
              `,
})

export class AdresComponent {
    // tslint:disable-next-line:variable-name
    private _adres: Adres;

    @Input()
    set adres(adres: Adres) {
        this.handleAdres(adres);
    }
    get adres(): Adres {
        return this._adres;
    }
    @Input() displayType: boolean;

    type: string;

    handleAdres(adres: Adres) {
        this._adres = adres;
        switch (adres.adresType) {
            case AdresType.vestigingsadres:
                this.type = 'Vestigingsadres';
                break;
            case AdresType.factuuradres:
                this.type = 'Factuuradres';
                break;
            case AdresType.correspondentieadres:
                this.type = 'Correspondentieadres';
                break;
        }
    }
}
