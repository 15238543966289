import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[alleenLetters][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AlleenLettersValidatorDirective, multi: true }],
})
export class AlleenLettersValidatorDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = alleenLettersValidator();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }
}

function alleenLettersValidator(): ValidatorFn {
  const regEx = /^[a-zA-Z]+$/i;
  return (control: AbstractControl): { [key: string]: any } => {
    const alleenLetters = regEx.test(control.value);
    return alleenLetters ? null : { alleenLetters: { value: control.value } };
  };
}
