import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IngelogdGuard } from '../authentication/ingelogd-guard.service';
import { InternMeekijkenGuard } from '../authentication/intern-meekijken-guard.service';
import { WerkgeverMasterSchrijvenGuard } from '../authentication/werkgever-master-schrijven-guard.service';
import { WijzigenGuard } from '../authentication/wijzigen-guard.service';
import { Urls } from '../navigatie/urls';
import { AdresgegevensComponent } from './bedrijfsgegevens/adresgegevens/adresgegevens.component';
import { BedrijfsgegevensComponent } from './bedrijfsgegevens/bedrijfsgegevens.component';
import { GebruikersgegevensComponent } from './gebruikersgegevens/gebruikersgegevens.component';
import { MachtigingenComponent } from './machtigingen/machtigingen.component';
import { SubaccountAanmakenComponent } from './subaccounts/subaccount-aanmaken/subaccount-aanmaken.component';
import { SubaccountDetailComponent } from './subaccounts/subaccount-detail/subaccount-detail.component';
import { SubaccountsOverzichtComponent } from './subaccounts/subaccounts-overzicht/subaccounts-overzicht.component';
import { VoorkeurenRegistrerenComponent } from './voorkeuren/voorkeuren-registreren.component';

const routes: Routes = [
    { path: 'profiel/bedrijfsgegevens', component: BedrijfsgegevensComponent, canActivate: [InternMeekijkenGuard] },
    { path: 'profiel/gebruikersgegevens', component: GebruikersgegevensComponent, canActivate: [IngelogdGuard] },
    { path: 'profiel/voorkeuren', component: VoorkeurenRegistrerenComponent, canActivate: [IngelogdGuard] },
    {
        canActivate: [WijzigenGuard],
        component: AdresgegevensComponent,
        path: 'profiel/bedrijfsgegevens/adresgegevens',
    },
    { path: Urls.Machtigingen, component: MachtigingenComponent, canActivate: [InternMeekijkenGuard] },
    { path: Urls.SubaccountBeheer, component: SubaccountsOverzichtComponent, canActivate: [WerkgeverMasterSchrijvenGuard]},
    { path: Urls.SubaccountAanmaken, component: SubaccountAanmakenComponent, canActivate: [WerkgeverMasterSchrijvenGuard]},
    { path: Urls.SubaccountDetail + '/:gebruikersnaam', component: SubaccountDetailComponent, canActivate: [WerkgeverMasterSchrijvenGuard]},
];

export const routedComponents = [
    AdresgegevensComponent,
    BedrijfsgegevensComponent,
    GebruikersgegevensComponent,
    MachtigingenComponent,
    SubaccountsOverzichtComponent,
    SubaccountAanmakenComponent,
    SubaccountDetailComponent,
    VoorkeurenRegistrerenComponent,
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class ProfielRoutingModule {

}
