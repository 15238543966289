import { Injectable } from '@angular/core';
import * as moment from 'moment';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { ApiClient } from '..';
import {
    Bedrijfsuitoefening,
    Functie,
    Response,
 } from '../../model';

@Injectable()
export class BufuService {

    constructor(private api: ApiClient) { }

    getBedrijfsuitoefeningen(peildatum: moment.Moment): Observable<Bedrijfsuitoefening[]> {
        const datumStr = peildatum.format('YYYY-MM-DD');
        return this.api.get<Response<Bedrijfsuitoefening>>('bufu/bedrijfsuitoefeningen?peildatum=' + datumStr).map((x) => x.items);
    }

    getFuncties(buCode: string, peildatum: moment.Moment): Observable<Functie[]> {
        const datumStr = peildatum.format('YYYY-MM-DD');
        return this.api.get<Response<Functie>>('bufu/functies/' + buCode + '/' + datumStr).map((x) => x.items);
    }
}
