import { NgModule } from '@angular/core';

import { TreeModule } from 'ng2-tree';

import { OpenstaandeWijzigingenGuard } from '../shared';
import { SharedModule } from '../shared/shared.module';
import { BestandsuitwisselingRoutingModule } from './bestandsuitwisseling-routing.module';
import { BestandsuitwisselingComponent } from './bestandsuitwisseling.component';

@NgModule({
    declarations: [
        BestandsuitwisselingComponent,
    ],
    exports: [
    ],
    imports: [
        BestandsuitwisselingRoutingModule,
        SharedModule,
        TreeModule,
    ],
    providers: [
        OpenstaandeWijzigingenGuard,
    ],
})
export class BestandsuitwisselingModule {
}
