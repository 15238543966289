import { Directive, ElementRef, forwardRef, Input, Provider, Renderer } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs/Observable';

@Directive({
    selector: '[debounceTime]',
    // host: {'(change)': 'doOnChange($event.target)', '(blur)': 'onTouched()'},
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DebounceInputControlValueAccessor), multi: true }],
})
export class DebounceInputControlValueAccessor implements ControlValueAccessor {
    @Input() debounceTime: number;
    onChange = (_: any) => { /**/ };
    onTouched = () => { /**/ };

    constructor(private elementRef: ElementRef, private renderer: Renderer) {
    }

    ngAfterViewInit() {
        Observable.fromEvent(this.elementRef.nativeElement, 'keyup')
            .debounceTime(this.debounceTime)
            .subscribe((event: any) => {
                this.onChange(event.target.value);
            });
    }

    writeValue(value: any): void {
        const normalizedValue = isBlank(value) ? null : value;
        this.renderer.setElementProperty(this.elementRef.nativeElement, 'value', normalizedValue);
    }

    registerOnChange(fn: () => any): void { this.onChange = fn; }
    registerOnTouched(fn: () => any): void { this.onTouched = fn; }
}

function isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
}
