import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeekijkenGuard } from '../authentication/meekijken-guard.service';
import { Urls } from '../navigatie';
import { ContactComponent } from './contact.component';
import { DitDoenWijComponent } from './dit-doen-wij.component';
import { DitDoetUComponent } from './dit-doet-u.component';
import { HomeComponent } from './home.component';
import { InternalServerErrorComponent } from './internal-server-error.component';
import { VeelgesteldeVragenComponent } from './veelgestelde-vragen.component';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [MeekijkenGuard] },
    { path: Urls.InternalServerError, component: InternalServerErrorComponent},
    { path: Urls.DitDoenWij, component: DitDoenWijComponent, canActivate: [MeekijkenGuard]},
    { path: Urls.DitDoetU, component: DitDoetUComponent, canActivate: [MeekijkenGuard]},
    { path: Urls.VeelgesteldeVragen, component: VeelgesteldeVragenComponent, canActivate: [MeekijkenGuard]},
    { path: 'contact', component: ContactComponent, canActivate: [MeekijkenGuard]},
];

/**
 * Gerouteerde componenten
 * @type {[HomeComponent]}
 */
export let routedComponents = [
    HomeComponent,
];

/**
 * Definieert routing logica voor de authenticatie module
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class HomeRoutingModule {
}
