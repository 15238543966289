import { Component } from '@angular/core';
import { ModalContainer } from '../modal-container.model';

@Component({
    selector: 'bevestiging-modal',
    styles: [require('../modal.scss')],
    template: require('./bevestiging-modal.component.html'),
})
export class BevestigingModalComponent extends ModalContainer {
    public title: string;
    public body: string;

    constructor() {
        super();
    }
}
