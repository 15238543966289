import { UpaAccountType, UpaSoftwarePakket } from '.';

export class UpaAccount {
    accountNaam: string;
    sleutel: string;
    accounttype: UpaAccountType;
    idLcr: string;
    emailContactpersoon: string;
    softwarePakketten: UpaSoftwarePakket[];
}
