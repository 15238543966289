// tslint:disable:interface-name

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from 'rxjs/Observable';

/**
 * Defines the application settings
 */
export interface Settings {
    /**
     * Settings for the authentication service
     */
    authentication: AuthenticationSettings;

    /**
     * Settings for communicating with the prescreening API
     */
    api: ApiSettings;

    /**
     * Settings for general options
     */
    general: GeneralSettings;

    /**
     * Settings for turning features on and off
     */
    featureToggles: FeatureToggles;
}

/**
 * Settings for turning features on and off
 */
export interface FeatureToggles {
    /**
     * Subaccounts feature
     */
    subaccounts: boolean;
}

/**
 * Authentication settings
 */
export interface AuthenticationSettings {
    /**
     * Client ID
     */
    clientId: string;

    /**
     * Redirect URL after login
     */
    redirectUrl: string;

    /**
     * Metadata URL
     */
    metadataUrl: string;

    /**
     * Authority (The URL of the identity server)
     */
    authority: string;

    /**
     * The type of response
     */
    responseType: string;

    /**
     * The scopes to authorize
     */
    scope: string;

    /**
     * The window of time (in seconds) to allow the current time to deviate when validating id_token's iat, nbf, and exp values.
     */
    clockSkew: number;

    /**
     * The url the idp will redirect to after logout.
     */
    postLogoutRedirectUrl: string;
}

/**
 * API settings for the API
 */
export interface ApiSettings {
    /**
     * The URL of the API
     */
    serviceUrl: string;
    /**
     * The maximum number of a file that is uploaded.
     */
    maxDocumentSizeInBytes: number;
}

/**
 * General settings
 */
export interface GeneralSettings {
    /**
     * Send statistics to Google Analtyics
     */
    googleAnalytics: boolean;

}

/**
 * Defines a way to get application settings into the app
 */
@Injectable()
export class ApplicationSettings {
    private cachedSettings: Settings;

    /**
     * Initializes a new instance of ApplicationSettings
     * @param http  the HTTP endpoint to use
     */
    constructor(private http: Http) {

    }

    /**
     * Downloads the settings from the server
     * @returns {Promise<settings>} A promise containing the application settings
     */
    public get(): Promise<Settings> {
        if (this.cachedSettings) {
            return Promise.resolve(this.cachedSettings);
        }

        return this.http.get('/settings.json')
            .map((response) => {
                const s = response.json() as Settings;
                this.cachedSettings = s;
                return s;
            })
            .toPromise();
    }
}
