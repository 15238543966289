import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { ToasterService } from 'angular2-toaster';

import { ApiClient } from '../shared/api-client.service';

import { Meldingen, UtilService } from '../shared';

@Component({
    template: require('./contact.component.html'),
    styles: [require('./contact.component.scss')],
})

export class ContactComponent implements OnInit {
    form: FormGroup;
    file: File;
    bestandUploadErrorMessage: string;

    onderwerpen = [
        { naam: 'Maak uw keuze', waarde: '' },
        { naam: 'Aanleveren jaarloongegevens 2017', waarde: 'Aanleveren jaarloongegevens 2017' },
        { naam: 'Pensioenadministratie', waarde: 'Pensioenadministratie' },
        { naam: 'Facturen en specificaties', waarde: 'Facturen en specificaties' },
        { naam: 'Pensioenregeling', waarde: 'Pensioenregeling' },
        { naam: 'Mijnpgbdesk.nl', waarde: 'Mijnpgbdesk.nl' },
        { naam: 'Machtigingen', waarde: 'Machtigingen' },
        { naam: 'Anders', waarde: 'Anders' },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private api: ApiClient,
        private utilService: UtilService,
        private toasterService: ToasterService) { }

    ngOnInit(): void {
        const onderwerpControl = this.fb.control('', Validators.required);
        const andersControl = this.fb.control('');

        this.form = this.fb.group({
            onderwerp: onderwerpControl,
            anders: andersControl,
            vraag: ['', Validators.required],
            kopie: false,
        });

        onderwerpControl.valueChanges.subscribe((value) => {
            if (value === 'Anders') {
                andersControl.setValidators(Validators.required);
            } else {
                andersControl.clearValidators();
                andersControl.setValue('');
                andersControl.markAsDirty();
            }
        });
    }

    fileChange(event: any) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
        }
    }

    cancel(): void {
        this.router.navigate(['/']);
    }

    post(data: any): Observable<Response> {
        return this.api.postFormData<Response>('mail/contact', data, this.file);
    }

    send(): void {
        let titel = this.form.controls.anders.value;

        if (!titel) {
            titel = this.form.controls.onderwerp.value;
        }

        const data = {
            onderwerp: titel,
            vraag: this.form.controls.vraag.value,
            kopieOntvangen: this.form.controls.kopie.value,
        };
        this.post(data).subscribe({
            next: (x: Response) => {
                this.toasterService.pop('success', '', Meldingen.BEDANKT_VOOR_CONTACT);
                this.router.navigate(['/']);
            },
            error: (err: any) => {
                const message = this.utilService.getErrorMessage(err);
                this.bestandUploadErrorMessage = message.Message;
            },
        });
    }
}
