import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { DienstverbandPeriode } from '../../../model/dienstverband-periode.model';
import { Filter } from '../../../model/filter.model';
import { SorteerRichting } from '../../../model/sorteer-richting.model';
import { Werkgever } from '../../../model/werkgever.model';
import { Constants } from '../../../shared/constants';
import { Sector } from '../../../shared/sector';
import { UtilService } from '../../../shared/util.service';

const parttimepercentage: string = 'parttimepercentage';
const gewerkteuren: string = 'gewerkteuren';
const gewerktedagen: string = 'gewerktedagen';

@Component({
    selector: 'loongegevens-tabel',
    template: require('./loongegevens-tabel.component.html'),
})
export class LoongegevensTabelComponent implements OnChanges {
    @Input() dienstverbandPeriodes: DienstverbandPeriode[];
    @Input() filter: Filter;
    @Input() werkgever: Werkgever;

    @Output() sortingChanged = new EventEmitter();

    displayParttimePercentage: boolean = true;

    public columns: any[] = [
        { title: 'Naam', name: 'naam', sortable: true, sort: 'asc', isSortActive: true, display: true },
        { title: 'Geslacht', name: 'geslacht', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Geboortedatum', name: 'geboortedatum', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'BSN', name: 'bsn', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Begindatum loontijdvak', name: 'dvbP.begin', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Einddatum loontijdvak', name: 'dvbP.eind', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Loon', name: 'loon', sortable: true, sort: 'desc', rightAlign: true, isSortActive: false, display: true },
        {
            title: 'Parttime %', name: parttimepercentage, sortable: false, sort: 'desc', rightAlign: true,
            isSortActive: false, display: true,
        },
        {
            title: 'Gewerkte uren/dagen', name: gewerkteuren, sortable: false, sort: 'desc', rightAlign: true,
            isSortActive: false, display: false,
        },
        { title: 'Loonbegrip', name: 'loonbegrigp', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Regeling', name: 'Regeling', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Kenmerk', name: 'Kenmerk', sortable: false, sort: 'desc', isSortActive: false, display: true },
        { title: 'Variant', name: 'Variant', sortable: false, sort: 'desc', isSortActive: false, display: true },
    ];

    constructor(private utilService: UtilService) { }

    ngOnChanges(): void {
        this.bepaalTeTonenVeld();
    }

    toggleSorting(columnName: string) {
        let richting: SorteerRichting = SorteerRichting.Ascending;
        this.columns.forEach((c: any) => {
            if (c.name !== columnName) {
                c.isSortActive = false;
            } else {
                if (c.isSortActive) {
                    c.sort = c.sort === 'asc' ? 'desc' : 'asc';
                } else {
                    c.isSortActive = true;
                }
                richting = c.sort === 'asc' ? SorteerRichting.Ascending : SorteerRichting.Descending;
            }
        });

        this.filter.paginaNummer = 1;
        this.filter.sorteerRichting = richting;
        this.filter.sorteerVeld = columnName;

        this.sortingChanged.emit(this.filter);
    }

    private bepaalTeTonenVeld(): void {
        const ptCol = this.columns.find((y) => y.name === parttimepercentage);
        const urenCol = this.columns.find((y) => y.name === gewerkteuren);
        if (this.werkgever && this.werkgever.sectornummer === Sector.Zeevisserij) {
            this.displayParttimePercentage = false;
        } else {
            // Het is geen zeevisser dus alleen maar parttimepercentage tonen
            this.displayParttimePercentage = true;
        }

        urenCol.display = !this.displayParttimePercentage;
        ptCol.display = this.displayParttimePercentage;
    }
}
