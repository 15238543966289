import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MyDatePickerModule } from 'mydatepicker';
import { HomePanelComponent } from '../home/home-panel.component';
import { AccordionComponent } from './accordion/accordion.component';
import {
    BedrijfsgegevensService,
    BestandsuitwisselingService,
    BufuService,
    LandService,
    LoonService,
    PostcodeService,
} from './services';
import {
    DienstverbandService,
} from './services/dienstverband.service';
import {
    OuderschapsverlofService,
} from './services/ouderschapsverlof.service';
import { UtilService } from './util.service';
import { BsnAddComponent } from './zoeken/bsn-add.component';
import { ZoekenComponent } from './zoeken/zoeken.component';

import { ToasterModule, ToasterService } from 'angular2-toaster';
import {
    AdresComponent,
    ApiClient,
    ApiRequestOptions,
    ApplicationSettings,
    BedragFormatPipe,
    Bsn11CheckValidatorDirective,
    DatetimePipe,
    HeeftUVragenComponent,
    LoaderComponent,
    LoaderService,
    PercentageFormatPipe,
} from '.';
import { DebounceInputControlValueAccessor } from './debounce-input-control.directive';
import { BevestigingCallbackModalComponent } from './modal/bevestiging/bevestiging-callback-modal.component';
import { BevestigingModalComponent } from './modal/bevestiging/bevestiging-modal.component';
import { ModalPlaceholderComponent } from './modal/modal-placeholder.component';
import { ModalService } from './modal/modal.service';
import { PagingComponent } from './paging/paging.component';
import { NotaService } from './services/nota.service';
import { AlleenLettersValidatorDirective } from './validators/alleen-letters.directive';
import { LeeftijdValidatorDirective } from './validators/leeftijd.directive';
import { MaxValidatorDirective } from './validators/max.directive';
import { MinValidatorDirective } from './validators/min.directive';
import { RoundNumberValidatorDirective } from './validators/round-number.directive';
import { HomeMeldingComponent } from '../home/home-melding.component';
import { SanitizePipe } from './sanitize.pipe';

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ],
    declarations: [
        AccordionComponent,
        AdresComponent,
        BedragFormatPipe,
        BsnAddComponent,
        ZoekenComponent,
        AlleenLettersValidatorDirective,
        Bsn11CheckValidatorDirective,
        DebounceInputControlValueAccessor,
        HeeftUVragenComponent,
        HomePanelComponent,
        HomeMeldingComponent,
        LoaderComponent,
        LeeftijdValidatorDirective,
        ModalPlaceholderComponent,
        MaxValidatorDirective,
        MinValidatorDirective,
        RoundNumberValidatorDirective,
        PagingComponent,
        PercentageFormatPipe,
        DatetimePipe,
        SanitizePipe,
        BevestigingModalComponent,
        BevestigingCallbackModalComponent,
    ],
    exports: [
        AccordionComponent,
        AdresComponent,
        AlleenLettersValidatorDirective,
        Bsn11CheckValidatorDirective,
        DebounceInputControlValueAccessor,
        BsnAddComponent,
        ZoekenComponent,
        LoaderComponent,
        FormsModule,
        HeeftUVragenComponent,
        HomePanelComponent,
        HomeMeldingComponent,
        SanitizePipe,
        ReactiveFormsModule,
        CommonModule,
        LeeftijdValidatorDirective,
        ModalPlaceholderComponent,
        MyDatePickerModule,
        BedragFormatPipe,
        PagingComponent,
        PercentageFormatPipe,
        DatetimePipe,
        MaxValidatorDirective,
        MinValidatorDirective,
        RoundNumberValidatorDirective,
    ],
    providers: [
        ApiClient,
        ApiRequestOptions,
        ApplicationSettings,
        DienstverbandService,
        BedrijfsgegevensService,
        BestandsuitwisselingService,
        BufuService,
        LandService,
        LoaderService,
        LoonService,
        ModalService,
        NotaService,
        PostcodeService,
        {
            provide: UtilService,
            useValue: new UtilService(new ToasterService()),
        },
        OuderschapsverlofService,
    ],
})
export class SharedModule {
}
