export class Profiel {
    gebruikersnaam: string;
    aanhef: string;
    achternaam: string;
    voorvoegsels: string;
    voornaam: string;
    emailadres: string;
    functie: string;
    telefoonnummer: string;
    magBestandenUitwisselen: boolean;
    digitaleNieuwsbriefIngeschakeld: boolean;
}
