import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AccordionComponent } from '../accordion/accordion.component';
import { BsnValidator } from '../validators/bsn.validator';

@Component({
    selector: 'zoeken',
    styles: [require('./zoeken.component.scss')],
    template: require('./zoeken.component.html'),
})
export class ZoekenComponent implements OnInit {
    @Output() zoekBsns = new EventEmitter<Set<string>>();
    @Output() zoekNaam = new EventEmitter<string>();
    @Output() toonAlles = new EventEmitter();
    @Input() hideAllesTonen: boolean = true;

    @ViewChild(AccordionComponent)
    private accordion: AccordionComponent;

    form: FormGroup;
    bsnsControl: FormControl;
    naamControl: FormControl;

    hideAnnuleren: boolean;
    geenNaamIngevuld: boolean = false;
    geenBsnIngevuld: boolean = false;
    ongeldigeBsns: string[] = [];

    constructor(
        private fb: FormBuilder,
        private router: Router) { }

    ngOnInit(): void {
        this.bsnsControl = this.fb.control('', []);
        this.naamControl = this.fb.control('', []);

        this.form = this.fb.group({
            bsnNummers: this.bsnsControl,
            naam: this.naamControl,
        });

        this.bsnsControl.valueChanges.subscribe(
            () => {
                this.ongeldigeBsns = [];
            });
        this.naamControl.valueChanges.subscribe(
            () => {
                this.ongeldigeBsns = [];
            });
    }

    zoeken(): void {
        this.ongeldigeBsns = [];
        this.geenBsnIngevuld = false;
        this.geenNaamIngevuld = false;
        if (this.naamControl.value) {
            const naam = this.naamControl.value;
            this.accordion.toggle();
            this.hideAnnuleren = true;
            this.zoekNaam.emit(naam);
        } else if (this.bsnsControl.value) {
            const bsns = Array.from(this.getBsns());
            const geldigeBsns = bsns.filter((bsn) => BsnValidator.bsn11Valid(bsn));
            this.ongeldigeBsns = bsns.filter((bsn) => !BsnValidator.bsn11Valid(bsn));
            if (geldigeBsns.length > 0 && this.ongeldigeBsns.length === 0) {
                this.accordion.toggle();
                this.hideAnnuleren = true;
                this.zoekBsns.emit(new Set(geldigeBsns));
            }
        } else {
            this.geenBsnIngevuld = true;
            this.geenNaamIngevuld = true;
        }
    }

    cancel() {
        this.router.navigate(['/werknemers/wijzigingendoorgeven']);
    }

    showAll() {
        this.hideAnnuleren = true;
        this.accordion.toggle();
        this.toonAlles.emit();
    }

    private getBsns(): Set<string> {
        const input: string = this.bsnsControl.value;
        if (!input) {
            return new Set<string>();
        }
        const bsns = new Array<string>();
        input.trim().replace(/\r?\n|\r|\t|;/g, ',').split(',').every((x: string) => {
            let bsn = x.trim();
            if (bsn.length === 8) {
                bsn = `0${bsn}`;
            }
            if (bsn) {
                bsns.push(bsn);
            }
            return true;
        });

        return new Set(bsns); // Remove duplicates.
    }
}
