import {
    Partij,
} from '.';

export class Machtiging {
    raadplegen: boolean;
    muteren: boolean;
    gemachtigdeRelatienummer: number;
    gemachtigdePartijTypeCode: string;
    gemachtigdeNaam: string;
    werkgeverRelatienummer: number;
    werkgeverPartijIdentificatie: number;
    werkgeverLoonheffingsnummer: number;
    werkgevernaam: string;
    werkgeverVestigingsplaats: string;
}
