import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../../authentication/authentication.service';
import {
    Machtiging,
    Werkgever,
} from '../../model';
import { BedrijfsgegevensService } from '../../shared';

@Component({
    styles: ['input.ng-invalid.ng-dirty { border: red 0.5px solid;'],
    template: require('./voorloopscherm.component.html'),
})
export class VoorloopschermComponent implements OnInit {
    machtigingen: Machtiging[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private toasterService: ToasterService,
                private bedrijfsgegevensService: BedrijfsgegevensService,
                private authSvc: AuthenticationService) { }

    ngOnInit(): void {
        this.bedrijfsgegevensService.getGemachtigden().subscribe((x) => {
            this.machtigingen = x.items;
        },
            (err) => {
                if (err.status === 404) {
                    this.toasterService.pop('error', '', `Machtigingen konden niet gevonden worden.`);
                } else {
                    this.toasterService.pop('error', '', `Er is iets fout gegaan tijdens het opvragen van de werkgever.`);
                }
            });
     }

    cancel(): void {
        this.router.navigate(['']);
    }

    impersonate(machtiging: Machtiging): void {
        document.cookie = `x-wgp-wgid=${machtiging.werkgeverRelatienummer}; path=/`;
        this.authSvc.startMeekijkenMetMachtiging(machtiging).subscribe((x) => {
                this.router.navigate(['']);
            });
    }
}
