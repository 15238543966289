import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { ApiClient } from '../shared/api-client.service';

import { Mutatie, MutatieFilter, MutatieType, Response } from '../model';

// tslint:disable-next-line:no-var-requires
const querystring = require('querystring');

@Injectable()
export class MutatieService {

    constructor(private api: ApiClient, private http: Http) { }

    getMutaties(filter: MutatieFilter): Observable<Response<Mutatie>> {
        return this.api.get<Response<Mutatie>>('mutaties', filter).map((mutaties) => mutaties);
    }

    getMutatie(id: number): Observable<Mutatie> {
        return this.api.get<Mutatie>('mutaties/' + id);
    }

    downloadMutaties(filter: MutatieFilter): Observable<Blob> {
        return this.api.getFile('mutaties/actions/download?' + querystring.stringify(filter), 'text/csv');
    }

    getMutatieTypeText(mutatieType: MutatieType): string {
        switch (mutatieType) {
            case MutatieType.DienstverbandDatumInDienstWijzigen:
                return 'Datum in dienst corrigeren';
            case MutatieType.DienstverbandDatumUitDienstWijzigen:
                return 'Datum uit dienst corrigeren';
            case MutatieType.DienstverbandFunctieWijzigen:
                return 'Functiegroep aanpassen';
            case MutatieType.DienstverbandVerwijderen:
                return 'Dienstverband verwijderen';
            case MutatieType.InDienst:
                return 'Werknemer aanmelden';
            case MutatieType.Looncorrectie:
                return 'Loon corrigeren';
            case MutatieType.OuderschapsverlofAanmelden:
                return 'Verlof aanmelden';
            case MutatieType.OuderschapsverlofAfmelden:
                return 'Verlof afmelden';
            case MutatieType.OuderschapsverlofPercentagesWijzigen:
                return 'Verlofpercentage corrigeren';
            case MutatieType.OuderschapsverlofPeriodeWijzigen:
                return 'Begindatum verlof corrigeren';
            case MutatieType.UitDienst:
                return 'Werknemer afmelden';
            case MutatieType.Voorschot:
                return 'Voorschot';
        }
    }

    getDetailTemplate(documentTitle: string): Observable<any> {
        return this.http.get(`assets/templates/${documentTitle}.html`).map((res) => res.text());
    }
}
