import {Dienstverband} from '.';
import {IBsnIdentificeerbaarItem} from './bsnIdentificeerbaarItem';

export class DienstverbandLoonderving implements IBsnIdentificeerbaarItem {
    begin: string;
    eind: string;
    loondervingPercentage: number;
    urendervingPercentage: number;
    parttimePercentageVoorVerlof?: number;
    dienstverband: Dienstverband;

    getBsn(): string {
        return this.dienstverband.werknemer.burgerServiceNummer;
    }
}
