import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { AuthenticationRoutingModule, routedComponents } from './authentication.routes';
import { AuthenticationService } from './authentication.service';
import { GemachtigdeGuard } from './gemachtigde-guard.service';
import { IngelogdGuard } from './ingelogd-guard.service';
import { InternApplicatiebeheerderSchrijvenGuard } from './intern-applicatiebeheerder-schrijven-guard.service';
import { InternMeekijkenGuard } from './intern-meekijken-guard.service';
import { InterneMedewerkerGuard } from './interne-medewerker-guard.service';
import { InterneMedewerkerSchrijvenGuard } from './interne-medewerker-schrijven-guard.service';
import { MeekijkenGuard } from './meekijken-guard.service';
import { WerkgeverMasterSchrijvenGuard } from './werkgever-master-schrijven-guard.service';
import { WijzigenGuard } from './wijzigen-guard.service';

/**
 * Bevat alle functionaliteit rondom het authenticeren van gebruikers
 */
@NgModule({
    declarations: [routedComponents],
    exports: [],
    imports: [
        HttpModule,
        AuthenticationRoutingModule,
    ],
    providers: [
        MeekijkenGuard,
        AuthenticationService,
        IngelogdGuard,
        InterneMedewerkerGuard,
        InterneMedewerkerSchrijvenGuard,
        InternMeekijkenGuard,
        InternApplicatiebeheerderSchrijvenGuard,
        GemachtigdeGuard,
        WerkgeverMasterSchrijvenGuard,
        WijzigenGuard,
    ],
})
export class AuthenticationModule {
}
