import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Dienstverband } from '../../../model';
import { Filter } from '../../../model/filter.model';
import { SorteerRichting } from '../../../model/sorteer-richting.model';
import { BedrijfsgegevensService } from '../../../shared/services/bedrijfsgegevens.service';

const bedrijfsuitoefeningColNaam = 'bu';
const functieColNaam = 'fu';
const fuBeginColNaam = 'fu.begin';
const fuEindColNaam = 'fu.eind';
const ikvColNaam = 'ikv';

@Component({
    selector: 'dienstverbanden-tabel',
    template: require('./dienstverband-tabel.component.html'),
})
export class DienstverbandTabelComponent implements OnInit {
    displayForUpa: boolean = false;
    @Input() dienstverbanden: Dienstverband[];
    @Input() filter: Filter;

    @Output() sortingChanged = new EventEmitter();

    public columns: any[] = [
        { title: 'Naam', name: 'naam', sortable: true, sort: 'asc', isSortActive: true, display: true },
        { title: 'Geslacht', name: 'geslacht', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Geboortedatum', name: 'geboortedatum', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'BSN', name: 'bsn', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Relatienummer', name: 'relatienummer', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Begindatum', name: 'dvb.begin', sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Einddatum', name: 'dvb.eind', sortable: true, sort: 'desc', isSortActive: false, display: true },
        {
            title: 'Bedrijfsuitoefening', name: bedrijfsuitoefeningColNaam, sortable: true,
            sort: 'desc', isSortActive: false, display: true,
        },
        { title: 'Functiegroep', sortable: true, name: functieColNaam, sort: 'desc', isSortActive: false, display: true },
        { title: 'Begindatum functiegroep', name: fuBeginColNaam, sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Einddatum functiegroep', name: fuEindColNaam, sortable: true, sort: 'desc', isSortActive: false, display: true },
        { title: 'Nummer Inkomstenverhouding', name: ikvColNaam, sortable: false, sort: 'desc', isSortActive: 'false', display: false },
    ];

    constructor(private router: Router,
        private bedrSvc: BedrijfsgegevensService) { }

    ngOnInit(): void {
        this.bedrSvc.getWerkgever().subscribe((w) => {
            this.displayForUpa = w.heeftUpa || false;
            this.bepaalTeTonenVelden();
        });
    }

    onSelect(dienstverband: Dienstverband): void {
        this.router.navigate(['/werknemers/dienstverbanden', dienstverband.werknemer.relatieNummer],
        {
            queryParams: this.filter,
        });
    }

    toggleSorting(columnName: string) {
        let richting: SorteerRichting = SorteerRichting.Ascending;
        this.columns.forEach((c: any) => {
            if (c.name !== columnName) {
                c.isSortActive = false;
            } else {
                if (c.isSortActive) {
                    c.sort = c.sort === 'asc' ? 'desc' : 'asc';
                } else {
                    c.isSortActive = true;
                }
                richting = c.sort === 'asc' ? SorteerRichting.Ascending : SorteerRichting.Descending;
            }
        });

        this.filter.paginaNummer = 1;
        this.filter.sorteerRichting = richting;
        this.filter.sorteerVeld = columnName;

        this.sortingChanged.emit(this.filter);
    }

    private bepaalTeTonenVelden(): void {
        const buCol = this.columns.find((y) => y.name === bedrijfsuitoefeningColNaam);
        const fuCol = this.columns.find((y) => y.name === functieColNaam);
        const fuBeginCol = this.columns.find((y) => y.name === fuBeginColNaam);
        const fuEindCol = this.columns.find((y) => y.name === fuEindColNaam);
        const ikvCol = this.columns.find((y) => y.name === ikvColNaam);

        buCol.display = !this.displayForUpa;
        fuCol.display = !this.displayForUpa;
        fuBeginCol.display = !this.displayForUpa;
        fuEindCol.display = !this.displayForUpa;
        ikvCol.display = this.displayForUpa;
    }
}
