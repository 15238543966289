import { NgModule } from '@angular/core';

import {
    AdresEditComponent,
    ProfielRoutingModule,
    routedComponents,
} from '.';
import { SharedModule } from '../shared/shared.module';
import { ProfielService } from './profiel.service';
import { SubaccountsService } from './subaccounts/subaccounts.service';

@NgModule({
    declarations: [
        routedComponents,
        AdresEditComponent,
    ],
    exports: [],
    imports: [
        SharedModule,
        ProfielRoutingModule,
    ],
    providers: [
        ProfielService,
        SubaccountsService,
    ],
})
export class ProfielModule {
}
