export class Sector {
    public static GrafischeIndustrie: number = 1;
    public static ChemischeIndustrie: number = 2;
    public static Uitgeverij: number = 3;
    public static Papierindustrie: number = 4;
    public static KunststofEnRubberIndustrie: number = 5;
    public static ZakelijkeDienstverlening: number = 6;
    public static Groothandel: number = 7;
    public static MaritiemeSector: number = 8;
    public static SectorProcesIndustrie: number = 9;
    public static Zeevisserij: number = 10;
    public static BloemenEnPlanten: number = 11;
    public static SectorVrijwillig: number = 12;
    public static Overig: number = 999;
}
