import {Compiler, ComponentRef, Injectable, Injector, ReflectiveInjector, ViewContainerRef} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { SharedModule } from '../shared.module';
import { BevestigingCallback, BevestigingCallbackModalComponent } from './bevestiging/bevestiging-callback-modal.component';
import { BevestigingModalComponent } from './bevestiging/bevestiging-modal.component';

@Injectable()
export class ModalService {
    // here we hold our placeholder
    private vcRef: ViewContainerRef;
    // here we hold our injector
    private injector: Injector;
    // we can use this to determine z-index of multiple modals
    public activeInstances: number = 0;

    registerViewContainerRef(vcRef: ViewContainerRef): void {
        this.vcRef = vcRef;
    }

    registerInjector(injector: Injector): void {
        this.injector = injector;
    }

    createBevestiging(title: string, body?: string) {
        const moduleType = SharedModule;
        const componentType = BevestigingModalComponent;
        this.create<BevestigingModalComponent>(moduleType, componentType)
        .subscribe((modal) => {
            modal.title = title;
            if (body) {
                modal.body = body;
            }
        });
    }

    createBevestigingMetCallback(title: string, callback: BevestigingCallback, body?: string) {
        const moduleType = SharedModule;
        const componentType = BevestigingCallbackModalComponent;
        this.create<BevestigingCallbackModalComponent>(moduleType, componentType)
        .subscribe((modal) => {
            modal.title = title;
            modal.callback = callback;
            if (body) {
                modal.body = body;
            }
        });
    }

    // update: as of rc6 we have to pass the module as well
    create<T>(module: any, component: any, parameters?: object): ReplaySubject<T> {
        // we return a stream so we can  access the componentref
        const componentRef$ = new ReplaySubject<T>();
        // compile the component based on its type and
        // create a component factory
        const compiler = this.injector.get(Compiler);
        compiler.compileModuleAndAllComponentsAsync(module)
            .then((factory) => {
                // look for the componentfactory in the modulefactory
                const componentFactory = factory.componentFactories
                    .filter((item) => item.componentType === component)[0];
                // the injector will be needed for DI in
                // the custom component
                const childInjector = ReflectiveInjector
                    .resolveAndCreate([], this.injector);
                // create the actual component
                const componentRef = this.vcRef
                    .createComponent<T>(componentFactory, 0, childInjector);
                // pass the @Input parameters to the instance
                Object.assign(componentRef.instance, parameters);
                this.activeInstances++;
                // add a destroy method to the modal instance
                const display = 'destroy';
                componentRef.instance[display] = () => {
                    this.activeInstances--;
                    // this will destroy the component
                    componentRef.destroy();
                };
                // the component is rendered into the ViewContainerRef
                // so we can update and complete the stream
                componentRef$.next(componentRef.instance);
                componentRef$.complete();
            });
        return componentRef$;
    }
}
