import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { ApiClient } from '../../shared/api-client.service';

import {
    OpslaanLoonopgaveResponse,
    RaadpleegLoongegevensRegelsByBsnsRequest,
    RaadpleegLoongegevensRegelsRequest,
    UploadLoongegevensRequest,
} from '.';

import {
    LoongegevensPeriode,
    LoongegevensRegel,
} from '../../model';

// tslint:disable-next-line:no-var-requires
const querystring = require('querystring');

@Injectable()
export class LoongegevensService {
    constructor(private api: ApiClient) { }

    raadpleegPeriodes(): Observable<LoongegevensPeriode[]> {
        return this.api.get<LoongegevensPeriode[]>('loongegevens/periodes').map((x) => x);
    }

    raadpleegVerwerktePeriodes(): Observable<LoongegevensPeriode[]> {
        return this.api.get<LoongegevensPeriode[]>('loongegevens/verwerkteperiodes').map((x) => x);
    }

    uploadLoongegevens(request: UploadLoongegevensRequest): Observable<Response> {
        return this.api.postFormData<Response>('loongegevens/upload', { begindatum: request.periode.begindatum,
                                                                        einddatum: request.periode.einddatum,
                                                                        isCorrectie: request.isLooncorrectie}, request.file).map((x) => x);
    }

    raadpleegLoongegevensRegels(request: RaadpleegLoongegevensRegelsRequest): Observable<LoongegevensRegel[]> {
        return this.api.post<LoongegevensRegel[]>('loongegevens/regels', request);
    }

    raadpleegLoongegevensRegelsByBsns(request: RaadpleegLoongegevensRegelsByBsnsRequest): Observable<LoongegevensRegel[]> {
        return this.api.post<LoongegevensRegel[]>('loongegevens', request);
    }

    opslaanLoonopgave(request: LoongegevensRegel[]): Observable<OpslaanLoonopgaveResponse> {
        return this.api.post<OpslaanLoonopgaveResponse>('loongegevens/opslaan/loonopgave', request);
    }

    opslaanLooncorrectie(request: LoongegevensRegel[]): Observable<OpslaanLoonopgaveResponse> {
        return this.api.post<OpslaanLoonopgaveResponse>('loongegevens/opslaan/looncorrectie', request);
    }

    downloadVooringevuldeLoonopgave(request: LoongegevensPeriode): Observable<Blob> {
        return this.api.getFile('loongegevens/downloadloonopgave?' + querystring.stringify(request), 'text/csv');
    }

    downloadVooringevuldeLooncorrectie(request: LoongegevensPeriode): Observable<Blob> {
        return this.api.getFile('loongegevens/downloadlooncorrectie?' + querystring.stringify(request), 'text/csv');
    }
}
