import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { ApiClient } from '../shared/api-client.service';
import { Melding } from '../model/melding.model';
import { Meldingen } from '../shared';

@Injectable()
export class MeldingenService {
    private meldingPromise: Promise<Melding>;

    constructor(private api: ApiClient, private http: Http) { }

    getMelding(): Observable<Melding> {
        // By converting it to a promise, the API request will only be once in the lifetime of MeldingenService
        if (!this.meldingPromise) {
            this.meldingPromise = this.api.get<Melding>('meldingen').map((melding) => melding).toPromise();
        }
        return Observable.fromPromise(this.meldingPromise);
    }
}
