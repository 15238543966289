import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { LooncontroleResponse } from '../../model/looncontrole-response.model';
import { Looncontrole } from '../../model/looncontrole.model';
import { ApiClient } from '../../shared/api-client.service';

@Injectable()
export class LooncontroleService {

    constructor(private api: ApiClient) { }

    getLooncontrole(): Observable<Looncontrole> {
        return this.api.get<LooncontroleResponse>('werkgevers/looncontrole').map((x) => x.looncontrole);
    }

    upload(file: File): Observable<Response> {
        return this.api.postfile<Response>('werkgevers/looncontrole', file);
    }
}
