import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import 'rxjs/add/operator/switchMap';

import { AuthenticationService } from '../../authentication/authentication.service';

import { PasswordValidator } from '../../shared/validators/password.validator';
import { AccountService } from '../account.service';

@Component({
    template: require('./wachtwoord-wijzigen.component.html'),
})
export class WachtwoordWijzigenComponent implements OnInit {
    passwordIsExpired: boolean = false;
    passwordResetIsRequired: boolean = false;
    form: FormGroup;

    oudWachtwoordMessage: string;
    wachtwoordMessage: string;
    bevestigWachtwoordMessage: string;

    private oudWachtwoordValidationMessages = {
        required: 'Wachtwoord is verplicht.',
    };
    private wachtwoordValidationMessages = {
        required: 'Wachtwoord is verplicht.',
        strength: 'Wachtwoord voldoet niet aan onze eisen.',
    };
    private bevestigWachtwoordValidationMessages = {
        matchPassword: 'De wachtwoorden komen niet overeen.',
        required: 'Bevestig wachtwoord is verplicht',
    };

    constructor(private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private accountService: AccountService,
                private toasterService: ToasterService,
                private authenticationService: AuthenticationService) { }

    ngOnInit(): void {
        this.passwordIsExpired = this.authenticationService.currentUser.profile.PasswordExpired &&
                                  document.cookie.indexOf('passwordisreset=true') < 0;

        this.passwordResetIsRequired = this.authenticationService.currentUser.profile.PasswordResetRequired &&
                                        document.cookie.indexOf('passwordisreset=true') < 0;

        this.form = this.fb.group({
            oudWachtwoord: ['', [Validators.required]],
            wachtwoord: ['', [Validators.required, PasswordValidator.strength()]],
            bevestigWachtwoord: ['', [Validators.required]],
        },
        {
            validator: PasswordValidator.matchPassword,
        });

        const oudeWachtwoordControl = this.form.get('oudWachtwoord');
        oudeWachtwoordControl.valueChanges.subscribe((value) => this.setOudWachtwoordMessage(oudeWachtwoordControl));

        const wachtwoordControl = this.form.get('wachtwoord');
        wachtwoordControl.valueChanges.subscribe((value) => this.setWachtwoordMessage(wachtwoordControl));

        const bevestigWachtwoordControl = this.form.get('bevestigWachtwoord');
        this.form.valueChanges.subscribe((value) => this.setBevestigWachtwoordMessage(bevestigWachtwoordControl));
    }

    save(): void {
        const oudWachtwoord = this.form.controls.oudWachtwoord.value;
        const nieuwWachtwoord = this.form.controls.wachtwoord.value;
        this.accountService.wachtwoordWijzigen(oudWachtwoord, nieuwWachtwoord).subscribe((response: any) => {
              this.toasterService.pop('success', '', `Uw wachtwoord is succesvol gewijzigd.`);
              this.authenticationService.getUser().then((user) => {
                if (user.profile.PasswordResetRequired) {
                    document.cookie = 'passwordisreset=true; path=/';
                }
              });
              this.router.navigate(['']);
        },
        (err: Response) => {
            if (err.status === 400) {
                // tslint:disable-next-line:no-string-literal
                this.toasterService.pop('error', '', err.json()['message']);
            } else {
                this.toasterService.pop('error', '', 'Er is iets mis gegaan');
            }
        });
    }

    cancel() {
        this.router.navigate(['']);
    }

    setOudWachtwoordMessage(c: AbstractControl): void {
        this.oudWachtwoordMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.oudWachtwoordMessage = Object.keys(c.errors).map((key) =>
                this.oudWachtwoordValidationMessages[key]).join(' ');
        }
    }

    setWachtwoordMessage(c: AbstractControl): void {
        this.wachtwoordMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.wachtwoordMessage = Object.keys(c.errors).map((key) =>
                this.wachtwoordValidationMessages[key]).join(' ');
        }
    }

    setBevestigWachtwoordMessage(c: AbstractControl): void {
        this.bevestigWachtwoordMessage = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.bevestigWachtwoordMessage = Object.keys(c.errors).map((key) =>
                this.bevestigWachtwoordValidationMessages[key]).join(' ');
        }
    }
}
