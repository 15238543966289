import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Account } from '../../../model';
import { Urls } from '../../../navigatie/urls';
import { SubaccountsService } from '../subaccounts.service';

@Component({
  template: require('./subaccounts-overzicht.component.html'),
})
export class SubaccountsOverzichtComponent implements OnInit {
  accounts: Account[];

  constructor(
      private subAccountSvc: SubaccountsService,
      private router: Router,
      private authSvc: AuthenticationService,
      private toasterService: ToasterService) {  }

  accountAanmaken(): void {
      this.router.navigate([Urls.SubaccountAanmaken]);
  }

  activeerAccount(gebruikersnaam: string): void {
      this.subAccountSvc.activeerAccount(gebruikersnaam)
                              .subscribe((x) => {
                                    this.toasterService.pop('success', '', 'Account is geactiveerd.');
                                    this.retrieveAccounts();
                              },
                              (err) => {
                                  this.toasterService.pop('error', '', 'Ongeldige aanvraag.');
                              });
  }

  deactiveerAccount(gebruikersnaam: string): void {
      this.subAccountSvc.deactiveerAccount(gebruikersnaam)
                              .subscribe((x) => {
                                    this.toasterService.pop('success', '', 'Account is gedeactiveerd.');
                                    this.retrieveAccounts();
                              },
                              (err) => {
                                  this.toasterService.pop('error', '', 'Ongeldige aanvraag.');
                              });
  }

  ngOnInit(): void {
      this.retrieveAccounts();
  }

  onSelect(account: Account): void {
      this.router.navigate([Urls.SubaccountDetail, account.username]);
  }

  private retrieveAccounts(): void {
    this.subAccountSvc.getAccounts()
              .subscribe((x) => {
                  this.accounts = x.items;
              },
              (err) => {
                  this.toasterService.pop('error', '', 'Ongeldige aanvraag.');
              });
  }
}
