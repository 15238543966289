import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {IMyOptions} from 'mydatepicker';

import {IKeyValuePair} from './key-value-pair';
import {IMutatieFormColumn} from './mutatie-form-column';
import {IMutatieFormConfig} from './mutatie-form-config';
import {MutatieFormService} from './mutatie-form.service';

@Component({
    selector: 'mutatie-form-td',
    template: require('./mutatie-form-td.component.html'),
})
export class MutatieFormTdComponent implements OnInit {
    @Input() column: IMutatieFormColumn[];
    @Input() config: IMutatieFormConfig;
    @Input() regel: FormGroup;

    private myDatePickerOptions: IMyOptions;

    constructor(private dvbMutatieService: MutatieFormService) {
    }

    ngOnInit(): void {
        this.myDatePickerOptions = Object.assign({}, this.dvbMutatieService.myDatePickerOptions);
    }

    public getContextDropdown(controlName: string): IKeyValuePair[] {
        return this.regel.get(controlName).value as IKeyValuePair[];
    }
}
