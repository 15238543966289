import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';
import { Accounttype } from '../../shared';

import { UpaAccount, UpaAccountType, UpaMandaat } from '../../model';
import { UpaService } from '../upa.service';

@Component({
    selector: 'upa-mandaatlijst',
    template: require('./upa-mandaat-overzicht.component.html'),
    styles: ['.doorkolom { width: 50% }'],
})
export class UpaMandaatOverzichtComponent implements OnInit {
    @Output() zelfInzenden = new EventEmitter();
    @Output() naarFormulier = new EventEmitter<UpaMandaat>();
    @Input() mandaatLijst: UpaMandaat[];
    showIngetrokkenMandaat = false;
    accountType: string;

    constructor(private authenticationService: AuthenticationService,
                private service: UpaService,
                private toasterService: ToasterService) {
    }

    ngOnInit(): void {
        this.accountType = this.authenticationService.currentUser.profile.accounttype;
    }

    nieuwMandaat() {
        this.naarFormulier.emit(null);
    }

    mandaatWijzigen(mandaat: UpaMandaat) {
        this.naarFormulier.emit(mandaat);
    }

    private clickzelfInstellen() {
        this.zelfInzenden.emit();
    }

    private checkOfInzendenMag(): boolean {
        if (this.authenticationService.currentUser !== null &&
            this.authenticationService.currentUser.profile.accounttype === Accounttype.ADMINISTRATIEKANTOOR) {
            return false;
        }

        if ((this.mandaatLijst && this.mandaatLijst.length === 0) ||
           (this.mandaatLijst && this.mandaatLijst.length > 0 &&
                                ((this.mandaatLijst[this.mandaatLijst.length - 1].einddatum) ||
                                  this.mandaatLijst[this.mandaatLijst.length - 1].ingetrokken))) {
                return true;
           } else {
               return false;
           }
    }

    listToggle(event: any) {
        this.showIngetrokkenMandaat = !(event.target.checked);
    }
}
