import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IngelogdGuard } from '../authentication/ingelogd-guard.service';
import { OpenstaandeWijzigingenGuard } from '../shared';
import { BestandsuitwisselingComponent } from './bestandsuitwisseling.component';

const routes: Routes = [
    { path: 'informatie/bestandsuitwisseling', component: BestandsuitwisselingComponent , canActivate: [IngelogdGuard]  },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class BestandsuitwisselingRoutingModule {
}
